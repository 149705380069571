import * as types from './migrateBusiness.constant';

const initialState = {
    authPaydirectUserLoading: false,
    authPaydirectUserError: null,
    authPaydirectUserSuccess: null,
    createUserProfileLoading: false,
    createUserProfileError: null,
    createUserProfileSuccess: null,
}

export default function(state = initialState, action) {
    switch(action.type) {
    
        case types.AUTHENTICATE_PAYDIRECT_USER:
            return {
                ...state,
                authPaydirectUserError: null,
                authPaydirectUserSuccess: null,
                authPaydirectUserLoading: true,
            }

        case types.AUTHENTICATE_PAYDIRECT_USER_SUCCESS:
            return {
                ...state,
                authPaydirectUserLoading: false,
                authPaydirectUserSuccess: action.payload,
            }

        case types.AUTHENTICATE_PAYDIRECT_USER_FAILURE:
            return {
                ...state,
                authPaydirectUserLoading: false,
                authPaydirectUserError: action.error,
            }

        case types.CREATE_USER_PROFILE:
            return {
                ...state,
                createUserProfileError: null,
                createUserProfileSuccess: null,
                createUserProfileLoading: true,
            }

        case types.CREATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                createUserProfileLoading: false,
                createUserProfileSuccess: action.payload,
            }

        case types.CREATE_USER_PROFILE_FAILURE:
            return {
                ...state,
                createUserProfileLoading: false,
                createUserProfileError: action.error,
            }
        
        default:
            return state;
    }
}
