import React from 'react'
import classes from "./Transaction.module.css";
import Nav from '../Home/Nav';

const TransactionHero = () => {
  return (
    <>
      <Nav trans/>
      <header className={classes.transactionHeader}>
        <div className={classes.transactionText}>
          <p className={classes.transactionText1}>Select a Payment Category <br/> Below to Start Paying</p> 
        </div>
      </header>
    </>  
  )
}

export default TransactionHero