import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { getPersistedState, persistState } from './helper';
import rootReducer from "./redux.reducer";
import rootSaga from "./redux.saga";

let storeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
    storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    getPersistedState(),
    storeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

store.subscribe(() => persistState({
    rxAuth: store.getState().rxAuth,
    rxPayments: store.getState().rxPayments,
}))

export default store;
