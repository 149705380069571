import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/payments';
import { GET_PAYMENT_FEE } from './paymentSetup.constant';
import { getPaymentFeeSuccess, getPaymentFeeFailure } from './paymentSetup.action';


async function getPaymentFeeRequest({ payload, productId }) {
    const options = endpoint.validateCustomer(payload, productId);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPaymentFeeWorker({ payload }) {
    try {
        const response = yield call(getPaymentFeeRequest, payload);
        yield put(getPaymentFeeSuccess(response.response));
    } catch (err) {
        yield put(getPaymentFeeFailure(err));
    }
}


function* getPaymentFeeWatcher() {
    yield takeLatest(GET_PAYMENT_FEE, getPaymentFeeWorker);
}


export default function* () {
    yield all([
        fork(getPaymentFeeWatcher),
    ]);
}
