import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Transaction from "../Pages/Transaction";

import Fallback from "../modules/shared/component/fallback";
//const Home = React.lazy(() => import("../modules/home"));
const Home = React.lazy(()=> import("../Pages/Home"));

const Login = React.lazy(() => import("../modules/auth/component/login"));
const Registration = React.lazy(() => import("../modules/auth/component/registration"));
const ResetPassword = React.lazy(() => import("../modules/auth/component/resetPassword"));
const SetPassword = React.lazy(() => import("../modules/auth/component/setPassword"));
const BusinessMigration = React.lazy(() => import("../modules/settings/migrateBusiness/component"));
const PrivateRoutes = React.lazy(() => import("./private"));

const AuthRoute = ({ authUser, component: Component, ...rest }) => (
    <Route {...rest} render={props => (authUser ? <Component authUser={authUser} {...props} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />)} />
);

function PublicRoutes(props) {
    const { authUser } = props;

    return (
        <Router>
            <React.Suspense fallback={Fallback}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/transaction" component={Transaction} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/login/:uuid" component={Login} />
                    { <Route exact path="/register" component={Registration} /> }
                    { <Route exact path="/register/:uuid" component={Registration} /> }
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/set-password/:uuid" component={SetPassword} />
                    { <Route exact path="/migrate-business/:req" component={BusinessMigration} /> }
                    <AuthRoute authUser={authUser} component={PrivateRoutes} />
                </Switch>
            </React.Suspense>
        </Router>
    );
}

function mapStateToProps({ rxAuth }) {
    return {
        authUser: rxAuth.authUser
    };
}
export default connect(mapStateToProps)(PublicRoutes);
