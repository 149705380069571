import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/reports';
import { GET_PAYMENT_REVERSAL_REPORT_PARAMETERS, GET_PAYMENT_REVERSAL_REPORT, GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD, TRACK_PAYMENT_REVERSAL_REPORT_DOWNLOAD } from './paymentReversalReport.constant';
import { getPaymentReversalReportParametersSuccess, getPaymentReversalReportParametersFailure, getPaymentReversalReportSuccess, getPaymentReversalReportFailure, getPaymentReversalReportDownloadSuccess, getPaymentReversalReportDownloadFailure, trackPaymentReversalReportDownloadSuccess } from './paymentReversalReport.action';


async function getPaymentReversalReportParametersRequest({productId, currencyCode}) {
    const options = endpoint.getPaymentReversalReportParameters(productId, currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPaymentReversalReportRequest(payload) {
    const options = endpoint.getPaymentReversalReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackPaymentReversalReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPaymentReversalReportParametersWorker({ payload }) {
    try {
        const response = yield call(getPaymentReversalReportParametersRequest, payload);
        yield put(getPaymentReversalReportParametersSuccess(response.data));
    } catch(err) {
        yield put(getPaymentReversalReportParametersFailure(err));
    }
}

function* getPaymentReversalReportWorker({ payload }) {
    try {
        const response = yield call(getPaymentReversalReportRequest, payload);
        yield put(getPaymentReversalReportSuccess(response.data));
    } catch(err) {
        yield put(getPaymentReversalReportFailure(err));
    }
}

function* getPaymentReversalReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getPaymentReversalReportRequest, payload);
        yield put(getPaymentReversalReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getPaymentReversalReportDownloadFailure(err));
    }
}

function* trackPaymentReversalReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackPaymentReversalReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackPaymentReversalReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getPaymentReversalReportDownloadFailure(err));
    }
}



function* getPaymentReversalReportParametersWatcher() {
    yield takeLatest(GET_PAYMENT_REVERSAL_REPORT_PARAMETERS, getPaymentReversalReportParametersWorker);
}

function* getPaymentReversalReportWatcher() {
    yield takeLatest(GET_PAYMENT_REVERSAL_REPORT, getPaymentReversalReportWorker);
}

function* getPaymentReversalReportDownloadWatcher() {
    yield takeLatest(GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD, getPaymentReversalReportDownloadWorker);
}

function* trackPaymentReversalReportDownloadWatcher() {
    yield takeLatest(TRACK_PAYMENT_REVERSAL_REPORT_DOWNLOAD, trackPaymentReversalReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getPaymentReversalReportParametersWatcher),
        fork(getPaymentReversalReportWatcher),
        fork(getPaymentReversalReportDownloadWatcher),
        fork(trackPaymentReversalReportDownloadWatcher)
    ]);
}
