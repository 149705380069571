import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/reports';
import { GET_BANK_BRANCH } from './common-report.constant';
import { getBankBranchSuccess, getBankBranchFailure } from './common-report.action';

async function getBankBranchRequest(bankId) {
    const options = endpoint.getBankBranch(bankId);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getBankBranchWorker({ payload }) {
    try {
        const response = yield call(getBankBranchRequest, payload);
        yield put(getBankBranchSuccess(response.data));
    } catch(err) {
        yield put(getBankBranchFailure(err));
    }
}



function* getBankBranchWatcher() {
    yield takeLatest(GET_BANK_BRANCH, getBankBranchWorker);
}


export default function*() {
    yield all([
        fork(getBankBranchWatcher),
    ]);
}
