import * as types from './feeConfigurations.constant';

export function getFeeConfiguration(params) {
    return {
        type: types.GET_FEE_CONFIGURATION,
        payload: params,
    }
}

export function getFeeConfigurationSuccess(response) {
    return {
        type: types.GET_FEE_CONFIGURATION_SUCCESS,
        payload: response,
    }
}

export function getFeeConfigurationFailure(error) {
    return {
        type: types.GET_FEE_CONFIGURATION_FAILURE,
        error,
    }
}

export function getFeeConfigurations(params) {
    return {
        type: types.GET_FEE_CONFIGURATIONS,
        payload: params,
    }
}

export function getFeeConfigurationsSuccess(response) {
    return {
        type: types.GET_FEE_CONFIGURATIONS_SUCCESS,
        payload: response,
    }
}

export function getFeeConfigurationsFailure(error) {
    return {
        type: types.GET_FEE_CONFIGURATIONS_FAILURE,
        error,
    }
}

export function createFeeConfiguration(payload) {
    return {
        type: types.CREATE_FEE_CONFIGURATION,
        payload,
    }
}

export function createFeeConfigurationSuccess(response) {
    return {
        type: types.CREATE_FEE_CONFIGURATION_SUCCESS,
        payload: response,
    }
}

export function createFeeConfigurationFailure(error) {
    return {
        type: types.CREATE_FEE_CONFIGURATION_FAILURE,
        error,
    }
}

export function updateFeeConfiguration(payload) {
    return {
        type: types.UPDATE_FEE_CONFIGURATION,
        payload,
    }
}

export function updateFeeConfigurationSuccess(response) {
    return {
        type: types.UPDATE_FEE_CONFIGURATION_SUCCESS,
        payload: response,
    }
}

export function updateFeeConfigurationFailure(error) {
    return {
        type: types.UPDATE_FEE_CONFIGURATION_FAILURE,
        error,
    }
}

export function updateFeeConfigurationStatus(payload) {
    return {
        type: types.UPDATE_FEE_CONFIGURATION_STATUS,
        payload,
    }
}

export function updateFeeConfigurationStatusSuccess(response) {
    return {
        type: types.UPDATE_FEE_CONFIGURATION_STATUS_SUCCESS,
        payload: response,
    }
}

export function updateFeeConfigurationStatusFailure(error) {
    return {
        type: types.UPDATE_FEE_CONFIGURATION_STATUS_FAILURE,
        error,
    }
}

export function getPendingFeeConfigurationApproval(params) {
    return {
        type: types.GET_PENDING_FEE_CONFIGURATION_APPROVAL,
        payload: params,
    }
}

export function getPendingFeeConfigurationApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_FEE_CONFIGURATION_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingFeeConfigurationApprovalFailure(error) {
    return {
        type: types.GET_PENDING_FEE_CONFIGURATION_APPROVAL_FAILURE,
        error,
    }
}

export function getPendingFeeConfigurationsApproval(params) {
    return {
        type: types.GET_PENDING_FEE_CONFIGURATIONS_APPROVAL,
        payload: params,
    }
}

export function getPendingFeeConfigurationsApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingFeeConfigurationsApprovalFailure(error) {
    return {
        type: types.GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_FAILURE,
        error,
    }
}

export function createFeeConfigurationApprovalVerdict(payload) {
    return {
        type: types.CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT,
        payload,
    }
}

export function createFeeConfigurationApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createFeeConfigurationApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_FAILURE,
        error,
    }
}