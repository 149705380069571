import * as types from './paymentNotifStatusReport.constant';

export function getPaymentNotifStatusReportParameters(params) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getPaymentNotifStatusReportParametersSuccess(response) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getPaymentNotifStatusReportParametersFailure(error) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getPaymentNotifStatusReport(params) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT,
        payload: params,
    }
}

export function getPaymentNotifStatusReportSuccess(response) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_SUCCESS,
        payload: response,
    }
}

export function getPaymentNotifStatusReportFailure(error) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_FAILURE,
        error,
    }
}

export function getPaymentNotifStatusReportDownload(params) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getPaymentNotifStatusReportDownloadSuccess(response) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getPaymentNotifStatusReportDownloadFailure(error) {
    return {
        type: types.GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackPaymentNotifStatusReportDownload(params) {
    return {
        type: types.TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackPaymentNotifStatusReportDownloadSuccess(response) {
    return {
        type: types.TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function resendPaymentNotification(payload) {
    return {
        type: types.RESEND_PAYMENT_NOTIFICATION,
        payload: payload
    }
}

export function resendPaymentNotificationSuccess(response) {
    return {
        type: types.RESEND_PAYMENT_NOTIFICATION_SUCCESS,
        payload: response
    }
}

export function resendPaymentNotificationFailure(error) {
    return {
        type: types.RESEND_PAYMENT_NOTIFICATION_FAILURE,
        error
    }
}
