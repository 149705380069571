export const GET_APPROVAL_CONFIGURATION = 'GET_APPROVAL_CONFIGURATION';
export const GET_APPROVAL_CONFIGURATION_SUCCESS = 'GET_APPROVAL_CONFIGURATION_SUCCESS';
export const GET_APPROVAL_CONFIGURATION_FAILURE = 'GET_APPROVAL_CONFIGURATION_FAILURE';

export const CREATE_APPROVAL_CONFIGURATION = 'CREATE_APPROVAL_CONFIGURATION';
export const CREATE_APPROVAL_CONFIGURATION_SUCCESS = 'CREATE_APPROVAL_CONFIGURATION_SUCCESS';
export const CREATE_APPROVAL_CONFIGURATION_FAILURE = 'CREATE_APPROVAL_CONFIGURATION_FAILURE';

export const EDIT_APPROVAL_CONFIGURATION = 'EDIT_APPROVAL_CONFIGURATION';
export const EDIT_APPROVAL_CONFIGURATION_SUCCESS = 'EDIT_APPROVAL_CONFIGURATION_SUCCESS';
export const EDIT_APPROVAL_CONFIGURATION_FAILURE = 'EDIT_APPROVAL_CONFIGURATION_FAILURE';
