const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    searchBusinesses: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `isw/business/search?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getBusinesses: () => ({
        baseUrl: BASE_URL,
        url: 'isw/business',
        method: 'GET',
        auth: true,
    }),

    getBusiness: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}`,
        method: 'GET',
        auth: true,
    }),

    toggleBusiness: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    mapBusinessToPaydirect: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}/map`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    searchPaydirectBillers: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `isw/business/search-billers?pageNumber=${page}&pageSize=${size}&q=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),
};
