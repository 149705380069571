import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/reports';
import {  SEARCH_PAYMENT_REPORT, SEARCH_PAYMENT_REPORT_DOWNLOAD } from './paymentSearch.constant';
import { searchPaymentReportSuccess, searchPaymentReportFailure, searchPaymentReportDownloadSuccess, searchPaymentReportDownloadFailure } from './paymentSearch.action';

async function searchPaymentReportRequest(payload) {
    const options = endpoint.searchPaymentReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* searchPaymentReportWorker({ payload }) {
    try {
        const response = yield call(searchPaymentReportRequest, payload);
        yield put(searchPaymentReportSuccess(response.responseContent));
    } catch(err) {
        yield put(searchPaymentReportFailure(err));
    }
}

function* searchPaymentReportDownloadWorker({ payload }) {
    try {
        const response = yield call(searchPaymentReportRequest, payload);
        yield put(searchPaymentReportDownloadSuccess(response.responseContent));
    } catch(err) {
        yield put(searchPaymentReportDownloadFailure(err));
    }
}

function* searchPaymentReportWatcher() {
    yield takeLatest(SEARCH_PAYMENT_REPORT, searchPaymentReportWorker);
}

function* searchPaymentReportDownloadWatcher() {
    yield takeLatest(SEARCH_PAYMENT_REPORT_DOWNLOAD, searchPaymentReportDownloadWorker);
}

export default function*() {
    yield all([
        fork(searchPaymentReportWatcher),
        fork(searchPaymentReportDownloadWatcher),
    ]);
}
