import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/payments';
import { GET_BILLER_CATEGORIES, SEARCH_BILLERS, GET_BILLER_INFO } from './billers.constant';
import { getBillerCategoriesSuccess, getBillerCategoriesFailure, searchBillersSuccess, searchBillersFailure, getBillerInfoSuccess, getBillerInfoFailure } from './billers.action';

async function getBillerCategoriesRequest () {
    const options = endpoint.getBillerCategories()
   
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function searchBillersRequest ({ pageNumber, pageSize, searchParam, category }) {
    const options = endpoint.searchBillers(pageNumber, pageSize, searchParam, category)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getBillerInfoRequest(productId) {
    const options = endpoint.getBillerInfo(productId)
    return await request.make(options).then(res => res).catch(request.parseError);
}



function* getBillerCategoriesWorker() {
    try {
        const response = yield call(getBillerCategoriesRequest);
        yield put(getBillerCategoriesSuccess(response));
    } catch(err) {
        yield put(getBillerCategoriesFailure(err));
    }
}

function* searchBillersWorker({ payload }) {
    try {
        let response = yield call(searchBillersRequest, payload);
        response =  JSON.parse(JSON.stringify(response)
            .replaceAll("ProductId", "productId")
            .replaceAll("ProductCode", "productCode"));
        yield put(searchBillersSuccess(response));
        console.log(response)
    } catch(err) {
        yield put(searchBillersFailure(err));
    }
}

function* getBillerInfoWorker({ payload }) {
    try {
        const response = yield call(getBillerInfoRequest, payload);
        yield put(getBillerInfoSuccess(response));
    } catch(err) {
        yield put(getBillerInfoFailure(err));
    }
}


function* getBillerInfoWatcher() {
    yield takeLatest(GET_BILLER_INFO, getBillerInfoWorker);
}

function* getBillerCategoriesWatcher() {
    yield takeLatest(GET_BILLER_CATEGORIES, getBillerCategoriesWorker);
}

function* searchBillersWatcher() {
    yield takeLatest(SEARCH_BILLERS, searchBillersWorker);
}


export default function*() {
    yield all([
        fork(getBillerCategoriesWatcher),
        fork(searchBillersWatcher),
        fork(getBillerInfoWatcher),
    ])
}
