const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    authenticatePaydirectUser: (payload) => ({
        baseUrl: BASE_URL,
        url: 'register/paydirect',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    createUserProfile: (payload) => ({
        baseUrl: BASE_URL,
        url: 'register/paydirect/user',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    nominateBusinessOwner: (payload) => ({
        baseUrl: BASE_URL,
        url: 'users/nominate/businessowner',
        method: 'POST',
        data: payload,
        auth: true,
    }),
};
