import * as types from './nominateBusinessOwner.constant';

const initialState = {
    nomBusinessOwnerLoading: false,
    nomBusinessOwnerSuccess: null,
    nomBusinessOwnerError: null
}



export default function( state = initialState, action ) {
    switch(action.type) {

        case types.NOMINATE_BUSINESS_OWNER:
            return {
                ...state,
                nomBusinessOwnerLoading: true,
                nomBusinessOwnerSuccess: null,
                nomBusinessOwnerError: null
            }

        case types.NOMINATE_BUSINESS_OWNER_SUCCESS:
            return {
                ...state,
                nomBusinessOwnerLoading: false,
                nomBusinessOwnerSuccess: action.payload
            }

        case types.NOMINATE_BUSINESS_OWNER_FAILURE:
            return {
                ...state,
                nomBusinessOwnerLoading: false,
                nomBusinessOwnerError: action.error
            }

        default:
            return state;

    }
}