import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/paymentOptions';
import { getCardBalanceSuccess, getCardBalanceFailure, getSecureDataSuccess, getSecureDataFailure, createPaymentInstrumentSuccess, createPaymentInstrumentFailure,  updatePaymentInstrumentSuccess, updatePaymentInstrumentFailure } from './paymentOptions.action';
import { GET_CARD_BALANCE, GET_SECURE_DATA, CREATE_PAYMENT_INSTRUMENT, UPDATE_PAYMENT_INSTRUMENT } from './paymentOptions.constant';

async function getCardBalanceRequest(payload) {
    const options = endpoint.getCardBalance(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getSecureDataRequest() {
    const options = endpoint.getSecureData();
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function createPaymentInstrumentRequest({ payload }) {
    const options = endpoint.createPaymentInstrument(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function updatePaymentInstrumentRequest({ payload }) {
    const options = endpoint.updatePaymentInstrument(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}


function* getCardBalanceWorker({ payload }) {
    try {
        const response = yield call(getCardBalanceRequest, payload);
        yield put(getCardBalanceSuccess(response.cardBalance));
    } catch(err) {
        yield put(getCardBalanceFailure(err));
    }
}

function* getSecureDataWorker() {
    try {
        const response = yield call(getSecureDataRequest);
        yield put(getSecureDataSuccess(response));
    } catch (err) {
        yield put(getSecureDataFailure(err));
    }
}

function* createPaymentInstrumentWorker(payload) {
    try {
        const response = yield call(createPaymentInstrumentRequest, payload);
        yield put(createPaymentInstrumentSuccess(response));
    } catch (err) {
        yield put(createPaymentInstrumentFailure(err));
    }
}

function* updatePaymentInstrumentWorker(payload) {
    try {
        let response = yield call(updatePaymentInstrumentRequest, payload);

        if (response.responseDescription) {
            response = 'PIN was changed successfully.';
        }
        yield put(updatePaymentInstrumentSuccess(response));
    } catch (err) {
        yield put(updatePaymentInstrumentFailure(err));
    }
}


function* getCardBalanceWatcher() {
    yield takeLatest(GET_CARD_BALANCE, getCardBalanceWorker);
}

function* getSecureDataWatcher() {
    yield takeLatest(GET_SECURE_DATA, getSecureDataWorker);
}

function* createPaymentInstrumentWatcher() {
    yield takeLatest(CREATE_PAYMENT_INSTRUMENT, createPaymentInstrumentWorker);
}

function* updatePaymentInstrumentWatcher() {
    yield takeLatest(UPDATE_PAYMENT_INSTRUMENT, updatePaymentInstrumentWorker);
}

export default function*() {
    yield all([
        fork(getCardBalanceWatcher),
        fork(getSecureDataWatcher),
        fork(createPaymentInstrumentWatcher),
        fork(updatePaymentInstrumentWatcher),
    ]);
}
