import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/payments';
import { GET_PAYMENT_INSTRUMENT_CONFIG, PROVIDE_PAYMENT_INSTRUMENT, MAKE_PAYMENT } from './paymentInstrument.constant';
import { getPaymentInstrumentConfigSuccess, getPaymentInstrumentConfigFailure, providePaymentInstrumentSuccess, providePaymentInstrumentFailure, makePaymentSuccess, makePaymentFailure } from './paymentInstrument.action';

async function getPaymentInstrumentConfigRequest() {
    const options = endpoint.getPaymentInstrumentConfig()
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function providePaymentInstrumentRequest({param}) {
    const options = endpoint.providePaymentMethod(param)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function makePaymentRequest({transactionId, payload}) {
    const options = endpoint.makePayment(transactionId, payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPaymentInstrumentConfigWorker() {
    try {
        const response = yield call(getPaymentInstrumentConfigRequest);
        yield put(getPaymentInstrumentConfigSuccess(response.businessPaymentProvider));
    } catch(err) {
        yield put(getPaymentInstrumentConfigFailure(err));
    }
}

function* providePaymentInstrumentWorker({payload}) {
    try {
        const response = yield call(providePaymentInstrumentRequest, payload);
        yield put(providePaymentInstrumentSuccess(response.providerData));
    } catch(err) {
        yield put(providePaymentInstrumentFailure(err));
    }
}

function* makePaymentWorker({ payload }) {
    try {
        const response = yield call(makePaymentRequest, payload);
        yield put(makePaymentSuccess(response));
    } catch(err) {
        yield put(makePaymentFailure(err));
    }
}


function* getPaymentInstrumentConfigWatcher() {
    yield takeLatest(GET_PAYMENT_INSTRUMENT_CONFIG, getPaymentInstrumentConfigWorker);
}

function* providePaymentInstrumentWatcher() {
    yield takeLatest(PROVIDE_PAYMENT_INSTRUMENT, providePaymentInstrumentWorker);
}

function* makePaymentWatcher() {
    yield takeLatest(MAKE_PAYMENT, makePaymentWorker);
}

export default function*() {
    yield all([
        fork(getPaymentInstrumentConfigWatcher),
        fork(providePaymentInstrumentWatcher),
        fork(makePaymentWatcher),
    ]);
}
