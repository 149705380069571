const BASE_URL = process.env.REACT_APP_BASE_URL_TRANS;

export default {
    trackReportDownload: (trackingId) => ({
        baseUrl: BASE_URL,
        url: `business/status?trackingId=${trackingId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getAllTransactionsList: (payload) => ({
        baseUrl: BASE_URL,
        url: 'business/transactions',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getSingleTransactionsList: (businessId, page, size, search) => ({
        baseUrl: BASE_URL,
        url: `business/${businessId}/single/transactions?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
    }),

    getBatchTransactionsList: (businessId, page, size, search) => ({
        baseUrl: BASE_URL,
        url: `business/${businessId}/batch/transactions?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
    }),

    getTransaction: (businessId, transactionId) => ({
        baseUrl: BASE_URL,
        url: `business/${businessId}/transactions/${transactionId}`,
        method: 'GET',
        auth: true,
    }),

    getTransactionBatch: (businessId, transactionId, page, size) => ({
        baseUrl: BASE_URL,
        url: `business/${businessId}/transactions/batch/${transactionId}?pageNumber=${page}&pageSize=${size}`,
        method: 'GET',
        auth: true,
    }),

    getTransactionBatchReceiptLink: (businessId, transactionId) => ({
        baseUrl: BASE_URL,
        url: `business/${businessId}/transactions/batchreceipt/${transactionId}`,
        method: 'GET',
        auth: true,
    }),

    getTransactionPayments: (transactionId, page, size) => ({
        baseUrl: BASE_URL,
        url: `transactions/${transactionId}/payments?pageNumber=${page}&pageSize=${size}`,
        method: 'GET',
        auth: true,
    }),

    getUserTransactionsPendingPayment: (userId) => ({
        baseUrl: BASE_URL,
        url: `users/${userId}/transactions/payments/pending`,
        method: 'GET',
        auth: true,
    }),
};
