import { all } from 'redux-saga/effects';
import authSaga from '../modules/auth/redux/auth.saga';
import feeMgtSaga from '../modules/feeMgt/feeMgt.saga';
import sharedSaga from '../modules/shared/redux/shared.saga';
import accountSaga from '../modules/account/account.saga';
import userMgtSaga from '../modules/userMgt/userMgt.saga';
import businessesSaga from '../modules/businesses/redux/businesses.saga';
import paymentsSaga from '../modules/payments/payments.saga';
import reportsSaga from '../modules/reports/reports.saga';
import approvalsSaga from '../modules/approvals/approvals.saga';
import auditTrailSaga from '../modules/auditTrail/redux/auditTrail.saga';
import transactionsSaga from '../modules/transactions/redux/transactions.saga';
import settingsSaga from '../modules/settings/settings.saga';
import paymentOptions from '../modules/paymentOptions/redux/paymentOptions.saga' ;
import transactionLimits from '../modules/transactionLimits/redux/transactionLimits.saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        feeMgtSaga(),
        sharedSaga(),
        accountSaga(),
        userMgtSaga(),
        businessesSaga(),
        paymentsSaga(),
        reportsSaga(),
        approvalsSaga(),
        auditTrailSaga(),
        transactionsSaga(),
        settingsSaga(),
        paymentOptions(),
        transactionLimits(),
    ]);
}
