const BASE_URL = process.env.REACT_APP_BASE_URL_USER + 'isw/fees/';

export default {
    getCumulDailyTransLimit: (id) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit/${id}`,
        method: 'GET',
        auth: true,
    }),

    getCumulDailyTransLimits: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit?pageNumber=${page}&pageSize=${size}&searchText=${search}`,
        method: 'GET',
        auth: true,
    }),

    createGlobalTransLimit: (payload) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit/global`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updateGlobalTransLimit: (payload) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit/global`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    createCumulDailyTransLimit: (payload) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updateCumulDailyTransLimit: (payload) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    updateBizCumulDailyTransLimit: (payload) => ({
        baseUrl: BASE_URL,
        url: `transactions/limit/business`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getPendingTransLimitApproval: (id) => ({
        baseUrl: BASE_URL,
        url: `approval/transactions/limit/${id}`,
        method: 'GET',
        auth: true,
    }),

    getPendingTransLimitsApproval: (page, size, status, action, search, paymentMethodId) => ({
        baseUrl: BASE_URL,
        url: `approval/transactions/limit?pageNumber=${page}&pageSize=${size}&searchText=${search}&requestType=${action}&approvalStatus=${status}&paymentMethodId=${paymentMethodId}`,
        method: 'GET',
        auth: true,
    }),

    createTransLimitApprovalVerdict: (payload) => ({
        baseUrl: BASE_URL,
        url: `approval/transactions/limit`,
        method: 'POST',
        data: payload,
        auth: true,
    }),
}