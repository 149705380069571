export const ADD_PAYMENT_TO_CART = 'ADD_PAYMENT_TO_CART';
export const EDIT_PAYMENT_IN_CART = 'EDIT_PAYMENT_IN_CART';
export const REMOVE_PAYMENT_FROM_CART = 'REMOVE_PAYMENT_FROM_CART';

export const SAVE_CART_PAYMENT = 'SAVE_CART_PAYMENT';
export const SAVE_CART_PAYMENT_SUCCESS = 'SAVE_CART_PAYMENT_SUCCESS';
export const SAVE_CART_PAYMENT_FAILURE = 'SAVE_CART_PAYMENT_FAILURE';

export const GET_SAVED_CART_PAYMENT = 'GET_SAVED_CART_PAYMENT';
export const GET_SAVED_CART_PAYMENT_SUCCESS = 'GET_SAVED_CART_PAYMENT_SUCCESS';
export const GET_SAVED_CART_PAYMENT_FAILURE = 'GET_SAVED_CART_PAYMENT_FAILURE';

export const EDIT_SAVED_CART_PAYMENT = 'EDIT_SAVED_CART_PAYMENT';
export const EDIT_SAVED_CART_PAYMENT_SUCCESS = 'EDIT_SAVED_CART_PAYMENT_SUCCESS';
export const EDIT_SAVED_CART_PAYMENT_FAILURE = 'EDIT_SAVED_CART_PAYMENT_FAILURE';

export const DELETE_SAVED_CART_PAYMENT = 'DELETE_SAVED_CART_PAYMENT';
export const DELETE_SAVED_CART_PAYMENT_SUCCESS = 'DELETE_SAVED_CART_PAYMENT_SUCCESS';
export const DELETE_SAVED_CART_PAYMENT_FAILURE = 'DELETE_SAVED_CART_PAYMENT_FAILURE';

export const SET_ACTIVE_PAYMENT = 'SET_ACTIVE_PAYMENT';
export const UNSET_ACTIVE_PAYMENT = 'UNSET_ACTIVE_PAYMENT';

export const OPEN_CART = 'OPEN_CART';
export const CLOSE_CART = 'CLOSE_CART';

export const DESTROY_CART = 'DESTROY_CART';
