export const GET_FEE_CONFIGURATION = 'GET_FEE_CONFIGURATION';
export const GET_FEE_CONFIGURATION_SUCCESS = 'GET_FEE_CONFIGURATION_SUCCESS';
export const GET_FEE_CONFIGURATION_FAILURE = 'GET_FEE_CONFIGURATION_FAILURE';

export const GET_FEE_CONFIGURATIONS = 'GET_FEE_CONFIGURATIONS';
export const GET_FEE_CONFIGURATIONS_SUCCESS = 'GET_FEE_CONFIGURATIONS_SUCCESS';
export const GET_FEE_CONFIGURATIONS_FAILURE = 'GET_FEE_CONFIGURATIONS_FAILURE';

export const CREATE_FEE_CONFIGURATION = 'CREATE_FEE_CONFIGURATION';
export const CREATE_FEE_CONFIGURATION_SUCCESS = 'CREATE_FEE_CONFIGURATION_SUCCESS';
export const CREATE_FEE_CONFIGURATION_FAILURE = 'CREATE_FEE_CONFIGURATION_FAILURE';

export const UPDATE_FEE_CONFIGURATION = 'UPDATE_FEE_CONFIGURATION';
export const UPDATE_FEE_CONFIGURATION_SUCCESS = 'UPDATE_FEE_CONFIGURATION_SUCCESS';
export const UPDATE_FEE_CONFIGURATION_FAILURE = 'UPDATE_FEE_CONFIGURATION_FAILURE';

export const UPDATE_FEE_CONFIGURATION_STATUS = 'UPDATE_FEE_CONFIGURATION_STATUS';
export const UPDATE_FEE_CONFIGURATION_STATUS_SUCCESS = 'UPDATE_FEE_CONFIGURATION_STATUS_SUCCESS';
export const UPDATE_FEE_CONFIGURATION_STATUS_FAILURE = 'UPDATE_FEE_CONFIGURATION_STATUS_FAILURE';

export const GET_PENDING_FEE_CONFIGURATION_APPROVAL = 'GET_PENDING_FEE_CONFIGURATION_APPROVAL';
export const GET_PENDING_FEE_CONFIGURATION_APPROVAL_SUCCESS = 'GET_PENDING_FEE_CONFIGURATION_APPROVAL_SUCCESS';
export const GET_PENDING_FEE_CONFIGURATION_APPROVAL_FAILURE = 'GET_PENDING_FEE_CONFIGURATION_APPROVAL_FAILURE';

export const GET_PENDING_FEE_CONFIGURATIONS_APPROVAL = 'GET_PENDING_FEE_CONFIGURATIONS_APPROVAL';
export const GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_SUCCESS = 'GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_SUCCESS';
export const GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_FAILURE = 'GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_FAILURE';

export const CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT = 'CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT';
export const CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_SUCCESS = 'CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_SUCCESS';
export const CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_FAILURE = 'CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_FAILURE';
