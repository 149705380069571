import { all } from 'redux-saga/effects';
import rolesSaga from './roles/redux/roles.saga';
import usersSaga from './users/redux/users.saga';

export default function*() {
    yield all([
        rolesSaga(),
        usersSaga(),
    ]);
}
