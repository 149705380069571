import * as types from './firsTaxPayments.constant';

export function getTaxTypes(payload) {
    return {
        type: types.GET_TAX_TYPES,
        payload
    }
}

export function getTaxTypesSuccess(response) {
    return {
        type: types.GET_TAX_TYPES_SUCCESS,
        payload: response
    }
}

export function getTaxTypesFailure(error) {
    return {
        type: types.GET_TAX_TYPES_FAILURE,
        error
    }
}

export function getTaxLocations(payload) {
    return {
        type: types.GET_TAX_LOCATIONS,
        payload
    }
}

export function getTaxLocationsSuccess(response) {
    return {
        type: types.GET_TAX_LOCATIONS_SUCCESS,
        payload: response
    }
}

export function getTaxLocationsFailure(error) {
    return {
        type: types.GET_TAX_LOCATIONS_FAILURE,
        error
    }
}

export function validateTin(payload) {
    return {
        type: types.VALIDATE_TIN,
        payload
    }
}

export function validateTinSuccess(response) {
    return {
        type: types.VALIDATE_TIN_SUCCESS,
        payload: response
    }
}

export function validateTinFailure(error) {
    return {
        type: types.VALIDATE_TIN_FAILURE,
        error
    }
}

export function validateDocumentNumber(payload) {
    return {
        type: types.VALIDATE_DOCUMENT_NUMBER,
        payload
    }
}

export function validateDocumentNumberSuccess(response) {
    return {
        type: types.VALIDATE_DOCUMENT_NUMBER_SUCCESS,
        payload: response
    }
}

export function validateDocumentNumberFailure(error) {
    return {
        type: types.VALIDATE_DOCUMENT_NUMBER_FAILURE,
        error
    }
}