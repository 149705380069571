import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/reports';
import { GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS, GET_SETTLEMENT_POSITIONS_REPORT, GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD, TRACK_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD } from './settlementPositionsReport.constant';
import { getSettlementPositionsReportParametersSuccess, getSettlementPositionsReportParametersFailure, getSettlementPositionsReportSuccess, getSettlementPositionsReportFailure, getSettlementPositionsReportDownloadSuccess, getSettlementPositionsReportDownloadFailure, trackSettlementPositionsReportDownloadSuccess } from './settlementPositionsReport.action';


async function getSettlementPositionsReportParametersRequest({productId, currencyCode}) {
    const options = endpoint.getSettlementPositionsReportParameters(productId, currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getDetailedSettlementPositionsReportRequest(payload) {
    const options = endpoint.getSettlementPositionsReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackSettlementPositionsReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getSettlementPositionsReportParametersWorker({ payload }) {
    try {
        const response = yield call(getSettlementPositionsReportParametersRequest, payload);
        yield put(getSettlementPositionsReportParametersSuccess(response.data));
    } catch(err) {
        yield put(getSettlementPositionsReportParametersFailure(err));
    }
}

function* getSettlementPositionsReportWorker({ payload }) {
    try {
        const response = yield call(getDetailedSettlementPositionsReportRequest, payload);
        yield put(getSettlementPositionsReportSuccess(response.data));
    } catch(err) {
        yield put(getSettlementPositionsReportFailure(err));
    }
}

function* getSettlementPositionsReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getDetailedSettlementPositionsReportRequest, payload);
        yield put(getSettlementPositionsReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getSettlementPositionsReportDownloadFailure(err));
    }
}

function* trackSettlementPositionsReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackSettlementPositionsReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackSettlementPositionsReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getSettlementPositionsReportDownloadFailure(err));
    }
}



function* getSettlementPositionsReportParametersWatcher() {
    yield takeLatest(GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS, getSettlementPositionsReportParametersWorker);
}

function* getSettlementPositionsReportWatcher() {
    yield takeLatest(GET_SETTLEMENT_POSITIONS_REPORT, getSettlementPositionsReportWorker);
}

function* getSettlementPositionsReportDownloadWatcher() {
    yield takeLatest(GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD, getSettlementPositionsReportDownloadWorker);
}

function* trackSettlementPositionsReportDownloadWatcher() {
    yield takeLatest(TRACK_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD, trackSettlementPositionsReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getSettlementPositionsReportParametersWatcher),
        fork(getSettlementPositionsReportWatcher),
        fork(getSettlementPositionsReportDownloadWatcher),
        fork(trackSettlementPositionsReportDownloadWatcher),
    ]);
}
