import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/feeMgt';
import { GET_REVENUE_SHARE_REPORT, GET_REVENUE_SHARE_REPORT_DOWNLOAD, TRACK_REVENUE_SHARE_REPORT_DOWNLOAD } from './revenueShareReport.constant';
import { getRevenueShareReportSuccess, getRevenueShareReportFailure, getRevenueShareReportDownloadSuccess, getRevenueShareReportDownloadFailure, trackRevenueShareReportDownloadSuccess } from './revenueShareReport.action';

async function getRevenueShareReportRequest(payload) {
    const options = endpoint.getRevenueShareReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackRevenueShareReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getRevenueShareReportWorker({ payload }) {
    try {
        const response = yield call(getRevenueShareReportRequest, payload);
        yield put(getRevenueShareReportSuccess(response));
    } catch(err) {
        yield put(getRevenueShareReportFailure(err));
    }
}

function* getRevenueShareReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getRevenueShareReportRequest, payload);
        yield put(getRevenueShareReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getRevenueShareReportDownloadFailure(err));
    }
}

function* trackRevenueShareReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackRevenueShareReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackRevenueShareReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getRevenueShareReportDownloadFailure(err));
    }
}


function* getRevenueShareReportWatcher() {
    yield takeLatest(GET_REVENUE_SHARE_REPORT, getRevenueShareReportWorker);
}

function* getRevenueShareReportDownloadWatcher() {
    yield takeLatest(GET_REVENUE_SHARE_REPORT_DOWNLOAD, getRevenueShareReportDownloadWorker);
}

function* trackRevenueShareReportDownloadWatcher() {
    yield takeLatest(TRACK_REVENUE_SHARE_REPORT_DOWNLOAD, trackRevenueShareReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getRevenueShareReportWatcher),
        fork(getRevenueShareReportDownloadWatcher),
        fork(trackRevenueShareReportDownloadWatcher)
    ]);
}
