import * as types from './common-report.constant';

export function getBankBranch(id) {
    return {
        type: types.GET_BANK_BRANCH,
        payload: id,
    }
}

export function getBankBranchSuccess(response) {
    return {
        type: types.GET_BANK_BRANCH_SUCCESS,
        payload: response,
    }
}

export function getBankBranchFailure(error) {
    return {
        type: types.GET_BANK_BRANCH_FAILURE,
        error,
    }
}
