import * as types from './paymentSetup.constant';

const initialState = {
    getPaymentFeeLoading: false,
    getPaymentFeeError: null,
    gotPaymentFee: false,
    showPaymentSetup: false,
    customerInfo: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case types.GET_PAYMENT_FEE:
            return {
                ...state,
                getPaymentFeeLoading: true,
                getPaymentFeeError: null,
                gotPaymentFee: false,
            }
        
        case types.GET_PAYMENT_FEE_SUCCESS:
            return {
                ...state,
                gotPaymentFee: true,
                getPaymentFeeLoading: false,
                customerInfo: action.payload,
            }

        case types.GET_PAYMENT_FEE_FAILURE:
            return {
                ...state,
                getPaymentFeeLoading: false,
                getPaymentFeeError: action.error,
            }

        case types.OPEN_PAYMENT_SETUP:
            return {
                ...state,
                showPaymentSetup: true,
            }

        case types.CLOSE_PAYMENT_SETUP:
            return {
                ...state,
                showPaymentSetup: false,
                customerInfo: null,
            }

        default:
            return state;
    }
}
