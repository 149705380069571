import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/payments';
import { GET_TAX_TYPES, GET_TAX_LOCATIONS, VALIDATE_TIN, VALIDATE_DOCUMENT_NUMBER } from './firsTaxPayments.constant';
import { getTaxTypesSuccess, getTaxTypesFailure, getTaxLocationsSuccess, getTaxLocationsFailure, validateTinSuccess, validateTinFailure, validateDocumentNumberSuccess, validateDocumentNumberFailure } from './firsTaxPayments.action';


async function getTaxTypesRequest(productId) {
    const options = endpoint.getTaxTypes(productId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getTaxLocationsRequest({productId, taxTypeCode}) {
    const options = endpoint.getTaxLocations(productId, taxTypeCode)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function validateTinRequest({productId, payload}) {
    const options = endpoint.validateTin(productId, payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function validateDocumentNumberRequest({productId, documentNumber}) {
    const options = endpoint.validateDocumentNumber(productId, documentNumber)
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getTaxTypesWorker({payload}) {
    try {
        const response = yield call(getTaxTypesRequest, payload);
        yield put(getTaxTypesSuccess(response.taxTypes));
    } catch(err) {
        yield put(getTaxTypesFailure(err));
    }
}

function* getTaxLocationsWorker({ payload }) {
    try {
        const response = yield call(getTaxLocationsRequest, payload);
        yield put(getTaxLocationsSuccess(response.stampDutyOffices));
    } catch(err) {
        yield put(getTaxLocationsFailure(err));
    }
}

function* validateTinWorker({ payload }) {
    try {
        const response = yield call(validateTinRequest, payload);
        yield put(validateTinSuccess(response.customer));
    } catch(err) {
        yield put(validateTinFailure(err));
    }
}

function* validateDocumentNumberWorker({ payload }) {
    try {
        const response = yield call(validateDocumentNumberRequest, payload);
        yield put(validateDocumentNumberSuccess(response.documentNumber));
    } catch(err) {
        yield put(validateDocumentNumberFailure(err));
    }
}


function* getTaxTypesWatcher() {
    yield takeLatest(GET_TAX_TYPES, getTaxTypesWorker);
}

function* getTaxLocationsWatcher() {
    yield takeLatest(GET_TAX_LOCATIONS, getTaxLocationsWorker);
}

function* validateTinWatcher() {
    yield takeLatest(VALIDATE_TIN, validateTinWorker);
}

function* validateDocumentNumberWatcher() {
    yield takeLatest(VALIDATE_DOCUMENT_NUMBER, validateDocumentNumberWorker);
}


export default function*() {
    yield all([
        fork(getTaxTypesWatcher),
        fork(getTaxLocationsWatcher),
        fork(validateTinWatcher),
        fork(validateDocumentNumberWatcher),
    ]);
}
