import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/feeMgt';
import { GET_PAYMENT_INSTRUMENT, GET_PAYMENT_INSTRUMENTS, CREATE_PAYMENT_INSTRUMENT_ISW, UPDATE_PAYMENT_INSTRUMENT_ISW, UPDATE_PAYMENT_INSTRUMENT_STATUS, GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL, GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL, CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT } from './paymentInstruments.constant';
import { getPaymentInstrumentSuccess, getPaymentInstrumentFailure, getPaymentInstrumentsSuccess, getPaymentInstrumentsFailure, createPaymentInstrumentIswSuccess, createPaymentInstrumentIswFailure, updatePaymentInstrumentIswSuccess, updatePaymentInstrumentIswFailure, updatePaymentInstrumentStatusSuccess, updatePaymentInstrumentStatusFailure, getPendingPaymentInstrumentApprovalSuccess, getPendingPaymentInstrumentApprovalFailure, getPendingPaymentInstrumentsApprovalSuccess, getPendingPaymentInstrumentsApprovalFailure, createPaymentInstrumentApprovalVerdictSuccess, createPaymentInstrumentApprovalVerdictFailure } from './paymentInstruments.action';

async function getPaymentInstrumentRequest({ instrumentId }) {
    const options = endpoint.getPaymentInstrument(instrumentId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPaymentInstrumentsRequest({ pageNumber, pageSize, status, searchParam }) {
    const options = endpoint.getPaymentInstruments(pageNumber, pageSize, status, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createPaymentInstrumentRequest(payload) {
    const options = endpoint.createPaymentInstrument(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updatePaymentInstrumentRequest(payload) {
    const options = endpoint.updatePaymentInstrument(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updatePaymentInstrumentStatusRequest(payload) {
    const options = endpoint.updatePaymentInstrumentStatus(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingPaymentInstrumentApprovalRequest({ requestId }) {
    const options = endpoint.getPendingPaymentInstrumentApproval(requestId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingPaymentInstrumentsApprovalRequest({ pageNumber, pageSize, status, actionType, searchParam }) {
    const options = endpoint.getPendingPaymentInstrumentsApproval(pageNumber, pageSize, status, actionType, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createPaymentInstrumentApprovalVerdictRequest(payload) {
    const options = endpoint.createPaymentInstrumentApprovalVerdict(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPaymentInstrumentWorker({ payload }) {
    try {
        const response = yield call(getPaymentInstrumentRequest, payload);
        yield put(getPaymentInstrumentSuccess(response));
    } catch(err) {
        yield put(getPaymentInstrumentFailure(err));
    }
}

function* getPaymentInstrumentsWorker({ payload }) {
    try {
        const response = yield call(getPaymentInstrumentsRequest, payload);
        yield put(getPaymentInstrumentsSuccess(response));
    } catch(err) {
        yield put(getPaymentInstrumentsFailure(err));
    }
}

function* createPaymentInstrumentWorker({ payload }) {
    try {
        const response = yield call(createPaymentInstrumentRequest, payload);
        yield put(createPaymentInstrumentIswSuccess(response.responseDescription));
    } catch(err) {
        yield put(createPaymentInstrumentIswFailure(err));
    }
}

function* updatePaymentInstrumentWorker({ payload }) {
    try {
        const response = yield call(updatePaymentInstrumentRequest, payload);
        yield put(updatePaymentInstrumentIswSuccess(response.responseDescription));
    } catch(err) {
        yield put(updatePaymentInstrumentIswFailure(err));
    }
}

function* updatePaymentInstrumentStatusWorker({ payload }) {
    try {
        const response = yield call(updatePaymentInstrumentStatusRequest, payload);
        yield put(updatePaymentInstrumentStatusSuccess(response.responseDescription));
    } catch(err) {
        yield put(updatePaymentInstrumentStatusFailure(err));
    }
}

function* getPendingPaymentInstrumentApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingPaymentInstrumentApprovalRequest, payload);
        yield put(getPendingPaymentInstrumentApprovalSuccess(response));
    } catch(err) {
        yield put(getPendingPaymentInstrumentApprovalFailure(err));
    }
}

function* getPendingPaymentInstrumentsApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingPaymentInstrumentsApprovalRequest, payload);
        const data = response.data || [];

        yield put(getPendingPaymentInstrumentsApprovalSuccess({
            pendingPaymentInstrumentsApproval: response,
            pendingPaymentInstrumentsApprovalCount: data.length,
        }));
    } catch(err) {
        yield put(getPendingPaymentInstrumentsApprovalFailure(err));
    }
}

function* createPaymentInstrumentApprovalVerdictWorker({ payload }) {
    try {
        const response = yield call(createPaymentInstrumentApprovalVerdictRequest, payload);
        yield put(createPaymentInstrumentApprovalVerdictSuccess(response.responseDescription));
    } catch(err) {
        yield put(createPaymentInstrumentApprovalVerdictFailure(err));
    }
}


function* getPaymentInstrumentWatcher() {
    yield takeLatest(GET_PAYMENT_INSTRUMENT, getPaymentInstrumentWorker);
}

function* getPaymentInstrumentsWatcher() {
    yield takeLatest(GET_PAYMENT_INSTRUMENTS, getPaymentInstrumentsWorker);
}

function* createPaymentInstrumentWatcher() {
    yield takeLatest(CREATE_PAYMENT_INSTRUMENT_ISW, createPaymentInstrumentWorker);
}

function* updatePaymentInstrumentWatcher() {
    yield takeLatest(UPDATE_PAYMENT_INSTRUMENT_ISW, updatePaymentInstrumentWorker);
}

function* updatePaymentInstrumentStatusWatcher() {
    yield takeLatest(UPDATE_PAYMENT_INSTRUMENT_STATUS, updatePaymentInstrumentStatusWorker);
}

function* getPendingPaymentInstrumentApprovalWatcher() {
    yield takeLatest(GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL, getPendingPaymentInstrumentApprovalWorker);
}

function* getPendingPaymentInstrumentsApprovalWatcher() {
    yield takeLatest(GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL, getPendingPaymentInstrumentsApprovalWorker);
}

function* createPaymentInstrumentApprovalVerdictWatcher() {
    yield takeLatest(CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT, createPaymentInstrumentApprovalVerdictWorker);
}


export default function*() {
    yield all([
        fork(getPaymentInstrumentWatcher),
        fork(getPaymentInstrumentsWatcher),
        fork(createPaymentInstrumentWatcher),
        fork(updatePaymentInstrumentWatcher),
        fork(updatePaymentInstrumentStatusWatcher),
        fork(getPendingPaymentInstrumentApprovalWatcher),
        fork(getPendingPaymentInstrumentsApprovalWatcher),
        fork(createPaymentInstrumentApprovalVerdictWatcher),
    ]);
}
