import * as types from './approvalRequests.constant';

const initialState = {
    createApprovalVerdictSuccess: null,
    createApprovalVerdictError: null,
    createApprovalVerdictLoading: false,
    approvalLogs: null,
    approvalLogsLoading: false,
    getApprovalLogsError: null,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.CREATE_APPROVAL_VERDICT:
            return {
                ...state,
                createApprovalVerdictLoading: true,
                createApprovalVerdictError: null,
                createApprovalVerdictSuccess: null,
            }

        case types.CREATE_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createApprovalVerdictLoading: false,
                createApprovalVerdictSuccess: action.payload,
            }

        case types.CREATE_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createApprovalVerdictLoading: false,
                createApprovalVerdictError: action.error,
            }

        case types.GET_APPROVAL_LOGS:
            return {
                ...state,
                approvalLogsLoading: true,
                getApprovalLogsError: null,
                approvalLogs: null,
            }

        case types.GET_APPROVAL_LOGS_SUCCESS:
            return {
                ...state,
                approvalLogsLoading: false,
                approvalLogs: action.payload,
            }

        case types.GET_APPROVAL_LOGS_FAILURE:
            return {
                ...state,
                approvalLogsLoading: false,
                getApprovalLogsError: action.error,
            }
        
        default:
            return state;
    }
}
