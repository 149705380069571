import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/transactionLimits';
import { GET_CUMUL_DAILY_TRANS_LIMIT, GET_CUMUL_DAILY_TRANS_LIMITS, CREATE_GLOBAL_TRANS_LIMIT, UPDATE_GLOBAL_TRANS_LIMIT, CREATE_CUMUL_DAILY_TRANS_LIMIT, UPDATE_CUMUL_DAILY_TRANS_LIMIT, UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT, GET_PENDING_TRANS_LIMIT_APPROVAL, GET_PENDING_TRANS_LIMITS_APPROVAL, CREATE_TRANS_LIMIT_APPROVAL_VERDICT } from './transactionLimits.constant';
import { getCumulDailyTransLimitSuccess, getCumulDailyTransLimitFailure, getCumulDailyTransLimitsSuccess, getCumulDailyTransLimitsFailure, createGlobalTransLimitSuccess, createGlobalTransLimitFailure, updateGlobalTransLimitSuccess, updateGlobalTransLimitFailure, createCumulDailyTransLimitSuccess, createCumulDailyTransLimitFailure, updateCumulDailyTransLimitSuccess, updateCumulDailyTransLimitFailure, updateBizCumulDailyTransLimitSuccess, updateBizCumulDailyTransLimitFailure, getPendingTransLimitApprovalSuccess, getPendingTransLimitApprovalFailure, getPendingTransLimitsApprovalSuccess, getPendingTransLimitsApprovalFailure, createTransLimitApprovalVerdictSuccess, createTransLimitApprovalVerdictFailure } from './transactionLimits.action';

async function getCumulDailyTransLimitRequest({ paymentMethodId }) {
    const options = endpoint.getCumulDailyTransLimit(paymentMethodId);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getCumulDailyTransLimitsRequest({ pageNumber, pageSize, searchText }) {
    const options = endpoint.getCumulDailyTransLimits(pageNumber, pageSize, searchText);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function createGlobalTransLimitRequest(payload) {
    const options = endpoint.createGlobalTransLimit(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function updateGlobalTransLimitRequest(payload) {
    const options = endpoint.updateGlobalTransLimit(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function createCumulDailyTransLimitRequest(payload) {
    const options = endpoint.createCumulDailyTransLimit(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function updateCumulDailyTransLimitRequest(payload) {
    const options = endpoint.updateCumulDailyTransLimit(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function updateBizCumulDailyTransLimitRequest(payload) {
    const options = endpoint.updateBizCumulDailyTransLimit(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getPendingTransLimitApprovalRequest({ requestId }) {
    const options = endpoint.getPendingTransLimitApproval(requestId);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getPendingTransLimitsApprovalRequest({ pageNumber, pageSize, status, actionType, searchParam, paymentMethodId }) {
    const options = endpoint.getPendingTransLimitsApproval(pageNumber, pageSize, status, actionType, searchParam, paymentMethodId);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function createTransLimitApprovalVerdictRequest(payload) {
    const options = endpoint.createTransLimitApprovalVerdict(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

function* getCumulDailyTransLimitWorker({ payload }) {
    try {
        const response = yield call(getCumulDailyTransLimitRequest, payload);
        yield put(getCumulDailyTransLimitSuccess(response.config));
    } catch (err) {
        yield put(getCumulDailyTransLimitFailure(err));
    }
}

function* getCumulDailyTransLimitsWorker({ payload }) {
    try {
        const response = yield call(getCumulDailyTransLimitsRequest, payload);
        yield put(getCumulDailyTransLimitsSuccess(response));
    } catch (err) {
        yield put(getCumulDailyTransLimitsFailure(err));
    }
}

function* createGlobalTransLimitWorker({ payload }) {
    try {
        const response = yield call(createGlobalTransLimitRequest, payload);
        yield put(createGlobalTransLimitSuccess(response.responseDescription));
    } catch (err) {
        yield put(createGlobalTransLimitFailure(err));
    }
}

function* updateGlobalTransLimitWorker({ payload }) {
    try {
        const response = yield call(updateGlobalTransLimitRequest, payload);
        yield put(updateGlobalTransLimitSuccess(response.responseDescription));
    } catch (err) {
        yield put(updateGlobalTransLimitFailure(err));
    }
}

function* createCumulDailyTransLimitWorker({ payload }) {
    try {
        const response = yield call(createCumulDailyTransLimitRequest, payload);
        yield put(createCumulDailyTransLimitSuccess(response.responseDescription));
    } catch (err) {
        yield put(createCumulDailyTransLimitFailure(err));
    }
}

function* updateCumulDailyTransLimitWorker({ payload }) {
    try {
        const response = yield call(updateCumulDailyTransLimitRequest, payload);
        yield put(updateCumulDailyTransLimitSuccess(response.responseDescription));
    } catch (err) {
        yield put(updateCumulDailyTransLimitFailure(err));
    }
}

function* updateBizCumulDailyTransLimitWorker({ payload }) {
    try {
        const response = yield call(updateBizCumulDailyTransLimitRequest, payload);
        yield put(updateBizCumulDailyTransLimitSuccess(response.responseDescription));
    } catch (err) {
        yield put(updateBizCumulDailyTransLimitFailure(err));
    }
}

function* getPendingTransLimitApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingTransLimitApprovalRequest, payload);
        yield put(getPendingTransLimitApprovalSuccess(response.config));
    } catch (err) {
        yield put(getPendingTransLimitApprovalFailure(err));
    }
}

function* getPendingTransLimitsApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingTransLimitsApprovalRequest, payload);
        yield put(getPendingTransLimitsApprovalSuccess(response));
    } catch (err) {
        yield put(getPendingTransLimitsApprovalFailure(err));
    }
}

function* createTransLimitApprovalVerdictWorker({ payload }) {
    try {
        const response = yield call(createTransLimitApprovalVerdictRequest, payload);
        yield put(createTransLimitApprovalVerdictSuccess(response.responseDescription));
    } catch (err) {
        yield put(createTransLimitApprovalVerdictFailure(err));
    }
}


function* getCumulDailyTransLimitWatcher() {
    yield takeLatest(GET_CUMUL_DAILY_TRANS_LIMIT, getCumulDailyTransLimitWorker);
}

function* getCumulDailyTransLimitsWatcher() {
    yield takeLatest(GET_CUMUL_DAILY_TRANS_LIMITS, getCumulDailyTransLimitsWorker);
}

function* createGlobalTransLimitWatcher() {
    yield takeLatest(CREATE_GLOBAL_TRANS_LIMIT, createGlobalTransLimitWorker);
}

function* updateGlobalTransLimitWatcher() {
    yield takeLatest(UPDATE_GLOBAL_TRANS_LIMIT, updateGlobalTransLimitWorker);
}

function* createCumulDailyTransLimitWatcher() {
    yield takeLatest(CREATE_CUMUL_DAILY_TRANS_LIMIT, createCumulDailyTransLimitWorker);
}

function* updateCumulDailyTransLimitWatcher() {
    yield takeLatest(UPDATE_CUMUL_DAILY_TRANS_LIMIT, updateCumulDailyTransLimitWorker);
}

function* updateBizCumulDailyTransLimitWatcher() {
    yield takeLatest(UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT, updateBizCumulDailyTransLimitWorker);
}

function* getPendingTransLimitApprovalWatcher() {
    yield takeLatest(GET_PENDING_TRANS_LIMIT_APPROVAL, getPendingTransLimitApprovalWorker);
}

function* getPendingTransLimitsApprovalWatcher() {
    yield takeLatest(GET_PENDING_TRANS_LIMITS_APPROVAL, getPendingTransLimitsApprovalWorker);
}

function* createTransLimitApprovalVerdictWatcher() {
    yield takeLatest(CREATE_TRANS_LIMIT_APPROVAL_VERDICT, createTransLimitApprovalVerdictWorker);
}

export default function* () {
    yield all([
        fork(createGlobalTransLimitWatcher),
        fork(updateGlobalTransLimitWatcher),
        fork(getCumulDailyTransLimitWatcher),
        fork(getCumulDailyTransLimitsWatcher),
        fork(createCumulDailyTransLimitWatcher),
        fork(updateCumulDailyTransLimitWatcher),
        fork(updateBizCumulDailyTransLimitWatcher),
        fork(getPendingTransLimitApprovalWatcher),
        fork(getPendingTransLimitsApprovalWatcher),
        fork(createTransLimitApprovalVerdictWatcher)
    ]);
}
