import { combineReducers } from 'redux';
import { LOGOUT } from '../modules/shared/redux/shared.constant';
import rxAuth from '../modules/auth/redux/auth.reducer';
import rxFeeMgt from '../modules/feeMgt/feeMgt.reducer';
import rxAccount from '../modules/account/account.reducer';
import rxShared from '../modules/shared/redux/shared.reducer';
import rxUserMgt from '../modules/userMgt/userMgt.reducer';
import rxBusinesses from '../modules/businesses/redux/businesses.reducer';
import rxPayments from '../modules/payments/payments.reducer';
import rxReports from '../modules/reports/reports.reducer';
import rxApprovals from '../modules/approvals/approvals.reducer';
import rxAuditTrail from '../modules/auditTrail/redux/auditTrail.reducer';
import rxTransactions from '../modules/transactions/redux/transactions.reducer';
import rxSettings from '../modules/settings/settings.reducer';
import rxPaymentOptions from '../modules/paymentOptions/redux/paymentOptions.reducer';
import rxTransactionLimits from '../modules/transactionLimits/redux/transactionLimits.reducer';

const appReducer = combineReducers({
    rxAuth,
    rxFeeMgt,
    rxShared,
    rxAccount,
    rxUserMgt,
    rxBusinesses,
    rxPayments,
    rxReports,
    rxApprovals,
    rxAuditTrail,
    rxTransactions,
    rxSettings,
    rxPaymentOptions,
    rxTransactionLimits,
});

function rootReducer(state, action) {
    if (action.type === LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action);
}

export default rootReducer;
