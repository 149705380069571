export const GET_TAX_TYPES = 'GET_TAX_TYPES';
export const GET_TAX_TYPES_SUCCESS = 'GET_TAX_TYPES_SUCCESS';
export const GET_TAX_TYPES_FAILURE = 'GET_TAX_TYPES_FAILURE';

export const GET_TAX_LOCATIONS = 'GET_TAX_LOCATIONS';
export const GET_TAX_LOCATIONS_SUCCESS = 'GET_TAX_LOCATIONS_SUCCESS';
export const GET_TAX_LOCATIONS_FAILURE = 'GET_TAX_LOCATIONS_FAILURE';

export const VALIDATE_TIN = 'VALIDATE_CUSTOMER_TIN';
export const VALIDATE_TIN_SUCCESS = 'VALIDATE_TIN_SUCCESS';
export const VALIDATE_TIN_FAILURE = 'VALIDATE_TIN_FAILURE';

export const VALIDATE_DOCUMENT_NUMBER = 'VALIDATE_DOCUMENT_NUMBER';
export const VALIDATE_DOCUMENT_NUMBER_SUCCESS = 'VALIDATE_DOCUMENT_NUMBER_SUCCESS';
export const VALIDATE_DOCUMENT_NUMBER_FAILURE = 'VALIDATE_DOCUMENT_NUMBER_FAILURE';
