export const GET_PAYMENT_REPORT_PARAMETERS = 'GET_PAYMENT_REPORT_PARAMETERS';
export const GET_PAYMENT_REPORT_PARAMETERS_SUCCESS = 'GET_PAYMENT_REPORT_PARAMETERS_SUCCESS';
export const GET_PAYMENT_REPORT_PARAMETERS_FAILURE = 'GET_PAYMENT_REPORT_PARAMETERS_FAILURE';

export const GET_PAYMENT_REPORT = 'GET_PAYMENT_REPORT';
export const GET_PAYMENT_REPORT_SUCCESS = 'GET_PAYMENT_REPORT_SUCCESS';
export const GET_PAYMENT_REPORT_FAILURE = 'GET_PAYMENT_REPORT_FAILURE';

export const GET_PAYMENT_REPORT_DOWNLOAD = 'GET_PAYMENT_REPORT_DOWNLOAD';
export const GET_PAYMENT_REPORT_DOWNLOAD_SUCCESS = 'GET_PAYMENT_REPORT_DOWNLOAD_SUCCESS';
export const GET_PAYMENT_REPORT_DOWNLOAD_FAILURE = 'GET_PAYMENT_REPORT_DOWNLOAD_FAILURE';

export const TRACK_PAYMENT_REPORT_DOWNLOAD = 'TRACK_PAYMENT_REPORT_DOWNLOAD';
export const TRACK_PAYMENT_REPORT_DOWNLOAD_SUCCESS = 'TRACK_PAYMENT_REPORT_DOWNLOAD_SUCCESS';
