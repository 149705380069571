import * as types from './generalTaxPayments.constant';

export function getTaxAuthoritiesInfo() {
    return {
        type: types.GET_TAX_AUTHORITIES_INFO,
    };
}

export function getTaxAuthoritiesInfoSuccess(response) {
    return {
        type: types.GET_TAX_AUTHORITIES_INFO_SUCCESS,
        payload: response
    };
}

export function getTaxAuthoritiesInfoFailure(error) {
    return {
        type: types.GET_TAX_AUTHORITIES_INFO_FAILURE,
        error
    };
}

export function validateTaxUpload(payload) {
    return {
        type: types.VALIDATE_TAX_UPLOAD,
        payload
    }
}

export function validateTaxUploadSuccess(response) {
    return {
        type: types.VALIDATE_TAX_UPLOAD_SUCCESS,
        payload: response
    }
}

export function validateTaxUploadFailure(error) {
    return {
        type: types.VALIDATE_TAX_UPLOAD_FAILURE,
        error
    }
}