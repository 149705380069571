const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    getCardBalance: (payload) => ({
        baseUrl: BASE_URL,
        url: 'paymentprovider/card/balance',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getSecureData: () => ({
        baseUrl: BASE_URL,
        url: 'paymentprovider/secure',
        method: 'GET',
        auth: true,
    }),

    createPaymentInstrument: (payload) => ({
        baseUrl: BASE_URL,
        url: 'paymentprovider',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updatePaymentInstrument: (payload) => ({
        baseUrl: BASE_URL,
        url: 'paymentprovider/pin/change',
        method: 'PUT',
        data: payload,
        auth: true,
    }),
};
