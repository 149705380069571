import * as types from './paymentReversalReport.constant';

const initialState = {
    getPaymentReversalReportParametersLoading: false,
    getPaymentReversalReportParametersError: null,
    paymentReversalReportParameters: null,
    getPaymentReversalReportLoading: false,
    getPaymentReversalReportError: null,
    paymentReversalReport: null,
    getPaymentReversalReportDownloadLoading: false,
    getPaymentReversalReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_PAYMENT_REVERSAL_REPORT_PARAMETERS:
            return {
                ...state,
                getPaymentReversalReportParametersLoading: true,
                getPaymentReversalReportParametersError: null,
                paymentReversalReportParameters: null,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getPaymentReversalReportParametersLoading: false,
                paymentReversalReportParameters: action.payload,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getPaymentReversalReportParametersLoading: false,
                getPaymentReversalReportParametersError: action.error,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT:
            return {
                ...state,
                getPaymentReversalReportLoading: true,
                getPaymentReversalReportError: null,
                paymentReversalReport: null,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_SUCCESS:
            return {
                ...state,
                getPaymentReversalReportLoading: false,
                paymentReversalReport: action.payload,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_FAILURE:
            return {
                ...state,
                getPaymentReversalReportLoading: false,
                getPaymentReversalReportError: action.error,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD:
            return {
                ...state,
                getPaymentReversalReportDownloadLoading: true,
                getPaymentReversalReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getPaymentReversalReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getPaymentReversalReportDownloadLoading: false,
                getPaymentReversalReportDownloadError: action.error,
            }

        case types.TRACK_PAYMENT_REVERSAL_REPORT_DOWNLOAD:
            return {
                ...state,
                getPaymentReversalReportDownloadLoading: true,
                getPaymentReversalReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_PAYMENT_REVERSAL_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getPaymentReversalReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }
        
        default:
            return state;
    }
}
