import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/payments';
import { GET_TAX_AUTHORITIES_INFO, VALIDATE_TAX_UPLOAD } from './generalTaxPayments.constant';
import { getTaxAuthoritiesInfoSuccess, getTaxAuthoritiesInfoFailure, validateTaxUploadSuccess, validateTaxUploadFailure } from './generalTaxPayments.action';

async function getTaxAuthoritiesInfoRequest() {
    return [
        {
          productId: 4554,
          productCode: 'FCTIRS',
          productName: 'FCT-IRS',
          productDescription: 'FCT Internal Revenue Service (FCT-IRS)'
        },
        // {
        //   productId: 3,
        //   productCode: 'FIRS',
        //   productName: 'FIRS PAYDirect',
        //   productDescription: 'Federal Inland Revenue Service (FIRS)'
        // },
        {
          productCode: process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? 'LASGQ' : 'ABETAB',
          productName: 'LIRS',
          productDescription: 'Lagos Internal Revenue Service (LIRS)'
        },
    ]
}

async function validateTaxUploadRequest({payload, taxAuthority}) {
    const options = endpoint.validateTaxUpload(payload, taxAuthority)
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getTaxAuthoritiesInfoWorker() {
    try {
        const response = yield call(getTaxAuthoritiesInfoRequest);
        yield put(getTaxAuthoritiesInfoSuccess(response));
    } catch(err) {
        yield put(getTaxAuthoritiesInfoFailure(err));
    }
}

function* validateTaxUploadWorker({ payload }) {
    try {
        const response = yield call(validateTaxUploadRequest, payload);
        yield put(validateTaxUploadSuccess(response));
    } catch(err) {
        yield put(validateTaxUploadFailure(err));
    }
}


function* getTaxAuthoritiesInfoWatcher() {
    yield takeLatest(GET_TAX_AUTHORITIES_INFO, getTaxAuthoritiesInfoWorker);
}

function* validateTaxUploadWatcher() {
    yield takeLatest(VALIDATE_TAX_UPLOAD, validateTaxUploadWorker);
}


export default function*() {
    yield all([
        fork(getTaxAuthoritiesInfoWatcher),
        fork(validateTaxUploadWatcher)
    ]);
}


