import * as types from './revenueShareReport.constant';

export function getRevenueShareReport(params) {
    return {
        type: types.GET_REVENUE_SHARE_REPORT,
        payload: params,
    }
}

export function getRevenueShareReportSuccess(response) {
    return {
        type: types.GET_REVENUE_SHARE_REPORT_SUCCESS,
        payload: response,
    }
}

export function getRevenueShareReportFailure(error) {
    return {
        type: types.GET_REVENUE_SHARE_REPORT_FAILURE,
        error,
    }
}

export function getRevenueShareReportDownload(params) {
    return {
        type: types.GET_REVENUE_SHARE_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getRevenueShareReportDownloadSuccess(response) {
    return {
        type: types.GET_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getRevenueShareReportDownloadFailure(error) {
    return {
        type: types.GET_REVENUE_SHARE_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackRevenueShareReportDownload(params) {
    return {
        type: types.TRACK_REVENUE_SHARE_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackRevenueShareReportDownloadSuccess(response) {
    return {
        type: types.TRACK_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}
