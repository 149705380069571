export const GET_BUSINESS_FEE_CONFIG = 'GET_BUSINESS_FEE_CONFIG';
export const GET_BUSINESS_FEE_CONFIG_SUCCESS = 'GET_BUSINESS_FEE_CONFIG_SUCCESS';
export const GET_BUSINESS_FEE_CONFIG_FAILURE = 'GET_BUSINESS_FEE_CONFIG_FAILURE';

export const ASSIGN_FEE_CONFIG_TO_BUSINESS = 'ASSIGN_FEE_CONFIG_TO_BUSINESS';
export const ASSIGN_FEE_CONFIG_TO_BUSINESS_SUCCESS = 'ASSIGN_FEE_CONFIG_TO_BUSINESS_SUCCESS';
export const ASSIGN_FEE_CONFIG_TO_BUSINESS_FAILURE = 'ASSIGN_FEE_CONFIG_TO_BUSINESS_FAILURE';

export const UPDATE_BUSINESS_FEE_CONFIG = 'UPDATE_BUSINESS_FEE_CONFIG';
export const UPDATE_BUSINESS_FEE_CONFIG_SUCCESS = 'UPDATE_BUSINESS_FEE_CONFIG_SUCCESS';
export const UPDATE_BUSINESS_FEE_CONFIG_FAILURE = 'UPDATE_BUSINESS_FEE_CONFIG_FAILURE';

export const UPDATE_BUSINESS_FEE_CONFIG_STATUS = 'UPDATE_BUSINESS_FEE_CONFIG_STATUS';
export const UPDATE_BUSINESS_FEE_CONFIG_STATUS_SUCCESS = 'UPDATE_BUSINESS_FEE_CONFIG_STATUS_SUCCESS';
export const UPDATE_BUSINESS_FEE_CONFIG_STATUS_FAILURE = 'UPDATE_BUSINESS_FEE_CONFIG_STATUS_FAILURE';

export const GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL = 'GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL';
export const GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_SUCCESS = 'GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_SUCCESS';
export const GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_FAILURE = 'GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_FAILURE';

export const GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL = 'GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL';
export const GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_SUCCESS = 'GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_SUCCESS';
export const GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_FAILURE = 'GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_FAILURE';

export const CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT = 'CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT';
export const CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_SUCCESS = 'CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_SUCCESS';
export const CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_FAILURE = 'CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_FAILURE';
