import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/payments';
import { DOWNLOAD_TEMPLATE, DOWNLOAD_VALIDATION_STATUS, VALIDATE_CUSTOMER, GET_PAYMENT_ITEMS, GET_CUSTOMER_VALIDATION_INFO, VALIDATE_CUSTOMER_BULK, VALIDATE_CUSTOMER_BULK_TAX, VALIDATE_CUSTOMER_BULK_FIRS, GET_BULK_VALIDATION_STATUS } from './customerValidation.constant';
import { downloadTemplateFailure, downloadValidationStatusFailure, validateCustomerFailure, validateCustomerSuccess, validateCustomerBulkSuccess, validateCustomerBulkFailure, getPaymentItemsSuccess, getPaymentItemsFailure, getCustomerValidationInfoSuccess, getCustomerValidationInfoFailure, getBulkValidationStatusSuccess, getBulkValidationStatusFailure } from './customerValidation.action';

async function validateCustomerRequest({ payload, productId }) {
    const options = endpoint.validateCustomer(payload, productId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function validateCustomerBulkRequest({payload, validationType}) {
    const options = endpoint.validateCustomerBulk(payload, validationType);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function validateCustomerBulkTaxRequest({payload, contentType}) {
    const options = endpoint.validateCustomerBulkTax(payload, contentType);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function validateCustomerBulkFirsRequest({payload, contentType, itemType}) {
    const options = endpoint.validateCustomerBulkFirs(payload, contentType, itemType);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPaymentItemsRequest(productId) {
   
    const options = endpoint.getPaymentItems(productId)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getCustomerValidationInfoRequest(productId) {
   
    const options = endpoint.getCustomerValidationInfo(productId)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getBulkValidationStatusRequest(payload) {
    const options = endpoint.getBulkValidationStatus(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function downloadTemplateRequest({itemType, contentType}) {
    const options = endpoint.downloadTemplate(itemType, contentType);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function downloadValidationStatusRequest(batchId) {
    const options = endpoint.downloadValidationStatus(batchId);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* validateCustomerWorker({ payload }) { 
    try {
        const response = yield call(validateCustomerRequest, payload);
        yield put(validateCustomerSuccess(response.response));
    } catch (err) {
        yield put(validateCustomerFailure(err));
    }
}

function* validateCustomerBulkWorker({payload}) {
    try {
        const response = yield call(validateCustomerBulkRequest, payload);
        yield put(validateCustomerBulkSuccess(response.batchId));
    } catch (err) {
        yield put(validateCustomerBulkFailure(err));
    }
}

function* validateCustomerBulkTaxWorker({payload}) {
    try {
        const response = yield call(validateCustomerBulkTaxRequest, payload);
        yield put(validateCustomerBulkSuccess(response.batchId));
    } catch (err) {
        yield put(validateCustomerBulkFailure(err));
    }
}

function* validateCustomerBulkFirsWorker({payload}) {
    try {
        const response = yield call(validateCustomerBulkFirsRequest, payload);
        yield put(validateCustomerBulkSuccess(response.batchId));
    } catch (err) {
        yield put(validateCustomerBulkFailure(err));
    }
}

function* getPaymentItemsWorker({ payload }) {
 
    try {
        const response = yield call(getPaymentItemsRequest, payload);
    
        yield put(getPaymentItemsSuccess(response));
    } catch (err) {
        yield put(getPaymentItemsFailure(err));
    }
}

function* getCustomerValidationInfoWorker({ payload }) {
    try {
        const response = yield call(getCustomerValidationInfoRequest, payload);
        yield put(getCustomerValidationInfoSuccess(response.product));
    } catch (err) {
        yield put(getCustomerValidationInfoFailure(err));
    }
}

function* getBulkValidationStatusWorker({ payload }) {
    try {
        const response = yield call(getBulkValidationStatusRequest, payload);
        yield put(getBulkValidationStatusSuccess(response));
    } catch (err) {
        yield put(getBulkValidationStatusFailure(err));
    }
}

function* downloadTemplateWorker({ payload }) {
    try {
        const response = yield call(downloadTemplateRequest, payload);
        const fileURL = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Template.csv');
        document.body.appendChild(fileLink);
        fileLink.click();
    } catch (err) {
        yield put(downloadTemplateFailure(err));
    }
}

function* downloadValidationStatusWorker({ payload }) {
    try {
        const response = yield call(downloadValidationStatusRequest, payload);
        const fileURL = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Validation Status.csv');
        document.body.appendChild(fileLink);
        fileLink.click();
    } catch (err) {
        yield put(downloadValidationStatusFailure(err));
    }
}


function* validateCustomerWatcher() {
    yield takeLatest(VALIDATE_CUSTOMER, validateCustomerWorker);
}

function* validateCustomerBulkWatcher() {
    yield takeLatest(VALIDATE_CUSTOMER_BULK, validateCustomerBulkWorker);
}

function* validateCustomerBulkTaxWatcher() {
    yield takeLatest(VALIDATE_CUSTOMER_BULK_TAX, validateCustomerBulkTaxWorker);
}

function* validateCustomerBulkFirsWatcher() {
    yield takeLatest(VALIDATE_CUSTOMER_BULK_FIRS, validateCustomerBulkFirsWorker);
}

function* getPaymentItemsWatcher() {
    yield takeLatest(GET_PAYMENT_ITEMS, getPaymentItemsWorker);
}

function* getCustomerValidationInfoWatcher() {
    yield takeLatest(GET_CUSTOMER_VALIDATION_INFO, getCustomerValidationInfoWorker);
}

function* getBulkValidationStatusWatcher() {
    yield takeLatest(GET_BULK_VALIDATION_STATUS, getBulkValidationStatusWorker);
}

function* downloadTemplateWatcher() {
    yield takeLatest(DOWNLOAD_TEMPLATE, downloadTemplateWorker);
}

function* downloadValidationStatusWatcher() {
    yield takeLatest(DOWNLOAD_VALIDATION_STATUS, downloadValidationStatusWorker);
}


export default function* () {
    yield all([
        fork(getPaymentItemsWatcher),
        fork(validateCustomerWatcher),
        fork(downloadTemplateWatcher),
        fork(validateCustomerBulkWatcher),
        fork(validateCustomerBulkTaxWatcher),
        fork(validateCustomerBulkFirsWatcher),
        fork(getBulkValidationStatusWatcher),
        fork(downloadValidationStatusWatcher),
        fork(getCustomerValidationInfoWatcher),
    ]);
}
