import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/shared';
import { getBusinessTypesSuccess, getBusinessTypesFailure, getBanksListSuccess, getBanksListFailure } from './shared.action';
import { GET_BANKS_LIST, GET_BUSINESS_TYPES } from './shared.constant';

async function getBusinessTypesRequest() {
    const options = endpoint.getBusinessTypes();
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getBanksListRequest({ pageNumber, pageSize, searchParam }) {
    const options = endpoint.getBanksList(pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getBusinessTypesWorker() {
    try {
        const response = yield call(getBusinessTypesRequest);
        yield put(getBusinessTypesSuccess(response.businesses));
    } catch(err) {
        yield put(getBusinessTypesFailure(err));
    }
}

function* getBanksListWorker({ payload }) {
    try {
        const response = yield call(getBanksListRequest, payload);
        yield put(getBanksListSuccess(response.banks));
    } catch(err) {
        yield put(getBanksListFailure(err));
    }
}


function* getBusinessTypesWatcher() {
    yield takeLatest(GET_BUSINESS_TYPES, getBusinessTypesWorker);
}

function* getBanksListWatcher() {
    yield takeLatest(GET_BANKS_LIST, getBanksListWorker);
}


export default function*() {
    yield all([
        fork(getBanksListWatcher),
        fork(getBusinessTypesWatcher),
    ]);
}
