import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/auth';
import { authenticateUserSuccess, authenticateUserFailure, registerUserSuccess, registerUserFailure, resetPasswordSuccess, resetPasswordFailure, setPasswordSuccess, setPasswordFailure, verifyUserSuccess, verifyUserFailure, resendVerficationSuccess, resendVerficationFailure, getBusinessOwnerSuccess, getBusinessOwnerFailure, registerBusinessOwnerSuccess, registerBusinessOwnerFailure, verifyOtpSuccess, verifyOtpFailure, resendOtpSuccess, resendOtpFailure } from './auth.action';
import { AUTHENTICATE_USER, REGISTER_USER, RESET_PASSWORD, SET_PASSWORD, VERIFY_USER, RESEND_VERFICATION, GET_BUSINESS_OWNER, REGISTER_BUSINESS_OWNER, VERIFY_OTP, RESEND_OTP } from './auth.constant';

async function loginRequest(payload) {
    const options = endpoint.login(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function registerRequest(payload) {
    const options = endpoint.register(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function resetPasswordRequest(payload) {
    const options = endpoint.resetPassword(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function setPasswordRequest(payload) {
    const options = endpoint.setPassword(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function verfiyUserRequest(payload) {
    const options = endpoint.verifyUser(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function resendVerificationRequest(payload) {
    const options = endpoint.resendVerification(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getBusinessOwnerRequest(payload) {
    const options = endpoint.getBusinessOwner(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function registerBusinessOwnerRequest(payload) {
    const options = endpoint.registerBusinessOwner(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function verifyOtpRequest(payload) {
    const options = endpoint.verifyOtp(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function resendOtpRequest(payload) {
    const options = endpoint.resendOtp(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}


function* loginWorker({ payload }) {
    try {
        const response = yield call(loginRequest, payload);

        yield put(authenticateUserSuccess(response));
    } catch (err) {
        yield put(authenticateUserFailure(err));
    }
}

function* registerWorker({ payload }) {
    try {
        const response = yield call(registerRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(registerUserSuccess(message));
    } catch (err) {
        yield put(registerUserFailure(err));
    }
}

function* resetPasswordWorker({ payload }) {
    try {
        const response = yield call(resetPasswordRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(resetPasswordSuccess(message));
    } catch (err) {
        yield put(resetPasswordFailure(err));
    }
}

function* setPasswordWorker({ payload }) {
    try {
        const response = yield call(setPasswordRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(setPasswordSuccess(message));
    } catch (err) {
        yield put(setPasswordFailure(err));
    }
}

function* verifyUserWorker({ payload }) {
    try {
        const response = yield call(verfiyUserRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(verifyUserSuccess(message));
    } catch (err) {
        yield put(verifyUserFailure(err));
    }
}

function* resendVerificationWorker({ payload }) {
    try {
        const response = yield call(resendVerificationRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(resendVerficationSuccess(message));
    } catch (err) {
        yield put(resendVerficationFailure(err));
    }
}

function* getBusinessOwnerWorker({ payload }) {
    try {
        const response = yield call(getBusinessOwnerRequest, payload);
        yield put(getBusinessOwnerSuccess(response));
    } catch (err) {
        yield put(getBusinessOwnerFailure(err));
    }
}

function* registerBusinessOwnerWorker({ payload }) {
    try {
        const response = yield call(registerBusinessOwnerRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(registerBusinessOwnerSuccess(message));
    } catch (err) {
        yield put(registerBusinessOwnerFailure(err));
    }
}

function* verifyOtpWorker({ payload }) {
    try {
        const response = yield call(verifyOtpRequest, payload);
        yield put(verifyOtpSuccess(response));
    } catch (err) {
        yield put(verifyOtpFailure(err));
    }
}

function* resendOtpWorker({ payload }) {
    try {
        const response = yield call(resendOtpRequest, payload);
        yield put(resendOtpSuccess(response));
    } catch (err) {
        yield put(resendOtpFailure(err));
    }
}

function* loginWatcher() {
    yield takeLatest(AUTHENTICATE_USER, loginWorker);
}

function* registerWatcher() {
    yield takeLatest(REGISTER_USER, registerWorker);
}

function* resetPasswordWatcher() {
    yield takeLatest(RESET_PASSWORD, resetPasswordWorker);
}

function* setPasswordWatcher() {
    yield takeLatest(SET_PASSWORD, setPasswordWorker);
}

function* verifyUserWatcher() {
    yield takeLatest(VERIFY_USER, verifyUserWorker);
}

function* resendVerficationWatcher() {
    yield takeLatest(RESEND_VERFICATION, resendVerificationWorker);
}

function* getBusinessOwnerWatcher() {
    yield takeLatest(GET_BUSINESS_OWNER, getBusinessOwnerWorker);
}

function* registerBusinessOwnerWatcher() {
    yield takeLatest(REGISTER_BUSINESS_OWNER, registerBusinessOwnerWorker);
}

function* verifyOtpWatcher() {
    yield takeLatest(VERIFY_OTP, verifyOtpWorker);
}

function* resendOtpWatcher() {
    yield takeLatest(RESEND_OTP, resendOtpWorker);
}

export default function* () {
    yield all([
        fork(loginWatcher),
        fork(registerWatcher),
        fork(resetPasswordWatcher),
        fork(setPasswordWatcher),
        fork(verifyUserWatcher),
        fork(resendVerficationWatcher),
        fork(getBusinessOwnerWatcher),
        fork(registerBusinessOwnerWatcher),
        fork(verifyOtpWatcher),
        fork(resendOtpWatcher),
    ]);
}
