import * as types from './paymentOptions.constant';

const initialState = {
    cardBalanceInfo: null,
    getCardBalanceError: null,
    getCardBalanceLoading: false,
    secureData: null,
    getSecureDataError: null,
    getSecureDataLoading: false,
    createPaymentInstrumentError: null,
    createPaymentInstrumentSuccess: null,
    createPaymentInstrumentLoading: false,
    updatePaymentInstrumentError: null,
    updatePaymentInstrumentSuccess: null,
    updatePaymentInstrumentLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case types.GET_CARD_BALANCE:
            return {
                ...state,
                getCardBalanceLoading: true,
                getCardBalanceError: null,
                cardBalanceInfo: null,
            }

        case types.GET_CARD_BALANCE_SUCCESS:
            return {
                ...state,
                getCardBalanceLoading: false,
                cardBalanceInfo: action.payload,
            }

        case types.GET_CARD_BALANCE_FAILURE:
            return {
                ...state,
                getCardBalanceLoading: false,
                getCardBalanceError: action.error,
            }

        case types.GET_CARD_BALANCE_RESET:
            return {
                ...state,
                getCardBalanceLoading: false,
                getCardBalanceError: null,
                cardBalanceInfo: null,
            }

        case types.CREATE_PAYMENT_INSTRUMENT:
            return {
                ...state,
                createPaymentInstrumentSuccess: null,
                createPaymentInstrumentLoading: true,
                createPaymentInstrumentError: null,
            };

        case types.CREATE_PAYMENT_INSTRUMENT_SUCCESS:
            return {
                ...state,
                createPaymentInstrumentLoading: false,
                createPaymentInstrumentSuccess: action.payload,
            };

        case types.CREATE_PAYMENT_INSTRUMENT_FAILURE:
            return {
                ...state,
                createPaymentInstrumentLoading: false,
                createPaymentInstrumentError: action.error,
            };

        case types.CREATE_PAYMENT_INSTRUMENT_RESET:
            return {
                ...state,
                createPaymentInstrumentSuccess: null,
                createPaymentInstrumentLoading: false,
                createPaymentInstrumentError: null,
            };

        case types.UPDATE_PAYMENT_INSTRUMENT:
            return {
                ...state,
                updatePaymentInstrumentSuccess: null,
                updatePaymentInstrumentLoading: true,
                updatePaymentInstrumentError: null,
            };

        case types.UPDATE_PAYMENT_INSTRUMENT_SUCCESS:
            return {
                ...state,
                updatePaymentInstrumentSuccess: action.payload,
                updatePaymentInstrumentLoading: false,
            };

        case types.UPDATE_PAYMENT_INSTRUMENT_FAILURE:
            return {
                ...state,
                updatePaymentInstrumentLoading: false,
                updatePaymentInstrumentError: action.error,
            };


        case types.UPDATE_PAYMENT_INSTRUMENT_RESET:
            return {
                ...state,
                updatePaymentInstrumentSuccess: null,
                updatePaymentInstrumentLoading: false,
                updatePaymentInstrumentError: null,
            };

        case types.GET_SECURE_DATA:
            return {
                ...state,
                secureData: null,
                getSecureDataError: null,
                getSecureDataLoading: true,
            };

        case types.GET_SECURE_DATA_SUCCESS:
            return {
                ...state,
                getSecureDataLoading: false,
                secureData: action.payload,
            };

        case types.GET_SECURE_DATA_FAILURE:
            return {
                ...state,
                getSecureDataLoading: false,
                getSecureDataError: action.error,
            };

        default:
            return state;
    }
}
