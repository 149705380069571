const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    login: (payload) => ({
        baseUrl: BASE_URL,
        url: 'login',
        method: 'POST',
        data: payload,
    }),

    resendVerification: (payload) => ({
        baseUrl: BASE_URL,
        url: 'users/verify/notify',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    register: (payload) => ({
        baseUrl: BASE_URL,
        url: 'register',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    resetPassword: (payload) => ({
        baseUrl: BASE_URL,
        url: 'password/notify',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    setPassword: (payload) => ({
        baseUrl: BASE_URL,
        url: 'password/reset',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    verifyUser: (payload) => ({
        baseUrl: BASE_URL,
        url: 'users/verify',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    getBusinessOwner: (id) => ({
        baseUrl: BASE_URL,
        url: `register/paydirect/getowner?reference=${id}`,
        method: 'GET',
        gatewayAuth: true,
    }),

    registerBusinessOwner: (payload) => ({
        baseUrl: BASE_URL,
        url: 'register/paydirect/owner',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    verifyOtp: (payload) => ({
        baseUrl: BASE_URL,
        url: 'login/2fa/authenticate',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),

    resendOtp: (payload) => ({
        baseUrl: BASE_URL,
        url: 'login/otp/generate',
        method: 'POST',
        data: payload,
        gatewayAuth: true,
    }),
};
