import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/userMgt';
import { CHANGE_PASSWORD } from './changePassword.constant';
import { changePasswordSuccess, changePasswordFailure } from './changePassword.action';

async function changePasswordRequest(payload) {
    const options = endpoint.changePassword(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

function* changePasswordWorker({ payload }) {
    try {
        const response = yield call(changePasswordRequest, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(changePasswordSuccess(message));
    } catch(err) {
        yield put(changePasswordFailure(err));
    }
}


function* changePasswordWatcher() {
    yield takeLatest(CHANGE_PASSWORD, changePasswordWorker);
}

export default function*() {
    yield all([
        fork(changePasswordWatcher),
    ]);
}
