import * as types from './transactions.constant';

const initialState = {
    allTransactionsListError: null,
    allTransactionsListSuccess: null,
    allTransactionsListLoading: false,
    singleTransactionsListError: null,
    singleTransactionsListSuccess: null,
    singleTransactionsListLoading: false,
    batchTransactionsListError: null,
    batchTransactionsListSuccess: null,
    batchTransactionsListLoading: false,
    transactionsAwaitingPaymentError: null,
    transactionsAwaitingPaymentSuccess: null,
    transactionsAwaitingPaymentLoading: false,
    transactionsAwaitingPaymentSuccessCount: null,
    transactionPaymentsError: null,
    transactionPaymentsSuccess: null,
    transactionPaymentsLoading: false,
    transactionBatchError: null,
    transactionBatchSuccess: null,
    transactionBatchLoading: false,
    transactionBatchReceiptLink: null,
    transactionBatchReceiptLinkError: null,
    transactionBatchReceiptLinkLoading: false,
    getAllTransactionsListDownloadLoading: false,
    getAllTransactionsListDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_TRANSACTIONS_LIST:
            return {
                ...state,
                allTransactionsListLoading: true,
                allTransactionsListSuccess: null,
                allTransactionsListError: null,
            };

        case types.GET_ALL_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...state,
                allTransactionsListLoading: false,
                allTransactionsListSuccess: action.payload,
            };

        case types.GET_ALL_TRANSACTIONS_LIST_FAILURE:
            return {
                ...state,
                allTransactionsListLoading: false,
                allTransactionsListError: action.error,
            };

        case types.GET_SINGLE_TRANSACTIONS_LIST:
            return {
                ...state,
                singleTransactionsListLoading: true,
                singleTransactionsListSuccess: null,
                singleTransactionsListError: null,
            };

        case types.GET_SINGLE_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...state,
                singleTransactionsListLoading: false,
                singleTransactionsListSuccess: action.payload,
            };

        case types.GET_SINGLE_TRANSACTIONS_LIST_FAILURE:
            return {
                ...state,
                singleTransactionsListLoading: false,
                singleTransactionsListError: action.error,
            };

        case types.GET_BATCH_TRANSACTIONS_LIST:
            return {
                ...state,
                batchTransactionsListError: null,
                batchTransactionsListSuccess: null,
                batchTransactionsListLoading: true,
            };

        case types.GET_BATCH_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...state,
                batchTransactionsListSuccess: action.payload,
                batchTransactionsListLoading: false,
            };

        case types.GET_BATCH_TRANSACTIONS_LIST_FAILURE:
            return {
                ...state,
                batchTransactionsListError: action.error,
                batchTransactionsListLoading: false,
            };

        case types.GET_TRANSACTIONS_AWAITING_PAYMENT:
            return {
                ...state,
                transactionsAwaitingPaymentLoading: true,
                transactionsAwaitingPaymentSuccess: null,
                transactionsAwaitingPaymentError: null,
            };

        case types.GET_TRANSACTIONS_AWAITING_PAYMENT_SUCCESS:
            return {
                ...state,
                transactionsAwaitingPaymentLoading: false,
                ...action.payload,
            };

        case types.GET_TRANSACTIONS_AWAITING_PAYMENT_FAILURE:
            return {
                ...state,
                transactionsAwaitingPaymentLoading: false,
                transactionsAwaitingPaymentError: action.error,
            };

        case types.GET_TRANSACTION_PAYMENTS:
            return {
                ...state,
                transactionPaymentsError: null,
                transactionPaymentsSuccess: null,
                transactionPaymentsLoading: true,
            };

        case types.GET_TRANSACTION_PAYMENTS_SUCCESS:
            return {
                ...state,
                transactionPaymentsSuccess: action.payload,
                transactionPaymentsLoading: false,
            };

        case types.GET_TRANSACTION_PAYMENTS_FAILURE:
            return {
                ...state,
                transactionPaymentsError: action.error,
                transactionPaymentsLoading: false,
            };

        case types.GET_TRANSACTION_BATCH:
            return {
                ...state,
                transactionBatchError: null,
                transactionBatchSuccess: null,
                transactionBatchLoading: true,
            };

        case types.GET_TRANSACTION_BATCH_SUCCESS:
            return {
                ...state,
                transactionBatchSuccess: action.payload,
                transactionBatchLoading: false,
            };

        case types.GET_TRANSACTION_BATCH_FAILURE:
            return {
                ...state,
                transactionBatchError: action.error,
                transactionBatchLoading: false,
            };

        case types.GET_TRANSACTION_BATCH_RECEIPT_LINK:
            return {
                ...state,
                transactionBatchReceiptLink: null,
                transactionBatchReceiptLinkError: null,
                transactionBatchReceiptLinkLoading: true,
            };

        case types.GET_TRANSACTION_BATCH_RECEIPT_LINK_SUCCESS:
            return {
                ...state,
                transactionBatchReceiptLink: action.payload,
                transactionBatchReceiptLinkLoading: false,
            };

        case types.GET_TRANSACTION_BATCH_RECEIPT_LINK_FAILURE:
            return {
                ...state,
                transactionBatchReceiptLinkError: action.error,
                transactionBatchReceiptLinkLoading: false,
            };

        case types.GET_ALL_TRANSACTIONS_LIST_DOWNLOAD:
            return {
                ...state,
                getAllTransactionsListDownloadLoading: true,
                getAllTransactionsListDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getAllTransactionsListDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_FAILURE:
            return {
                ...state,
                getAllTransactionsListDownloadLoading: false,
                getAllTransactionsListDownloadError: action.error,
            }

        case types.TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD:
            return {
                ...state,
                getAllTransactionsListDownloadLoading: true,
                getTransactionReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getAllTransactionsListDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }

        default:
            return state;
    }
}
