import * as types from './auth.constant';

export function authenticateUser(payload) {
    return {
        type: types.AUTHENTICATE_USER,
        payload,
    }
}

export function authenticateUserSuccess(response) {
    return {
        type: types.AUTHENTICATE_USER_SUCCESS,
        payload: response,
    }
}

export function authenticateUserFailure(error) {
    return {
        type: types.AUTHENTICATE_USER_FAILURE,
        error
    }
}

export function registerUser(payload) {
    return {
        type: types.REGISTER_USER,
        payload,
    }
}

export function registerUserSuccess(response) {
    return {
        type: types.REGISTER_USER_SUCCESS,
        payload: response,
    }
}

export function registerUserFailure(error) {
    return {
        type: types.REGISTER_USER_FAILURE,
        error,
    }
}

export function resetPassword(payload) {
    return {
        type: types.RESET_PASSWORD,
        payload,
    }
}

export function resetPasswordSuccess(response) {
    return {
        type: types.RESET_PASSWORD_SUCCESS,
        payload: response,
    }
}

export function resetPasswordFailure(error) {
    return {
        type: types.RESET_PASSWORD_FAILURE,
        error,
    }
}

export function setPassword(payload) {
    return {
        type: types.SET_PASSWORD,
        payload,
    }
}

export function setPasswordSuccess(response) {
    return {
        type: types.SET_PASSWORD_SUCCESS,
        payload: response,
    }
}

export function setPasswordFailure(error) {
    return {
        type: types.SET_PASSWORD_FAILURE,
        error,
    }
}

export function verifyUser(id) {
    return {
        type: types.VERIFY_USER,
        payload: id,
    }
}

export function verifyUserSuccess(response) {
    return {
        type: types.VERIFY_USER_SUCCESS,
        payload: response,
    }
}

export function verifyUserFailure(error) {
    return {
        type: types.VERIFY_USER_FAILURE,
        error,
    }
}

export function resendVerfication(payload) {
    return {
        type: types.RESEND_VERFICATION,
        payload,
    }
}

export function resendVerficationSuccess(response) {
    return {
        type: types.RESEND_VERFICATION_SUCCESS,
        payload: response,
    }
}

export function resendVerficationFailure(error) {
    return {
        type: types.RESEND_VERFICATION_FAILURE,
        error,
    }
}

export function getBusinessOwner(id) {
    return {
        type: types.GET_BUSINESS_OWNER,
        payload: id,
    }
}

export function getBusinessOwnerSuccess(response) {
    return {
        type: types.GET_BUSINESS_OWNER_SUCCESS,
        payload: response,
    }
}

export function getBusinessOwnerFailure(error) {
    return {
        type: types.GET_BUSINESS_OWNER_FAILURE,
        error,
    }
}

export function registerBusinessOwner(payload) {
    return {
        type: types.REGISTER_BUSINESS_OWNER,
        payload,
    }
}

export function registerBusinessOwnerSuccess(response) {
    return {
        type: types.REGISTER_BUSINESS_OWNER_SUCCESS,
        payload: response,
    }
}

export function registerBusinessOwnerFailure(error) {
    return {
        type: types.REGISTER_BUSINESS_OWNER_FAILURE,
        error,
    }
}

export function verifyOtp(payload) {
    return {
        type: types.VERIFY_OTP,
        payload,
    }
}

export function verifyOtpSuccess(response) {
    return {
        type: types.VERIFY_OTP_SUCCESS,
        payload: response,
    }
}

export function verifyOtpFailure(error) {
    return {
        type: types.VERIFY_OTP_FAILURE,
        error
    }
}

export function resendOtp(payload) {
    return {
        type: types.RESEND_OTP,
        payload,
    }
}

export function resendOtpSuccess(response) {
    return {
        type: types.RESEND_OTP_SUCCESS,
        payload: response,
    }
}

export function resendOtpFailure(error) {
    return {
        type: types.RESEND_OTP_FAILURE,
        error
    }
}


