import * as types from './users.constant';


export function getAllUsers(params) {
    return {
        type: types.GET_ALL_USERS,
        payload: params,
    }
}

export function getAllUsersSuccess(response) {
    return {
        type: types.GET_ALL_USERS_SUCCESS,
        payload: response,
    }
}

export function getAllUsersFailure(error) {
    return {
        type: types.GET_ALL_USERS_FAILURE,
        error,
    }
}


export function createUser(payload) {
    return {
        type: types.CREATE_USER,
        payload,
    }
}

export function createUserSuccess(response) {
    return {
        type: types.CREATE_USER_SUCCESS,
        payload: response,
    }
}

export function createUserFailure(error) {
    return {
        type: types.CREATE_USER_FAILURE,
        error,
    }
}

export function editUser(payload) {
    return {
        type: types.EDIT_USER,
        payload,
    }
}

export function editUserSuccess(response) {
    return {
        type: types.EDIT_USER_SUCCESS,
        payload: response,
    }
}

export function editUserFailure(error) {
    return {
        type: types.EDIT_USER_FAILURE,
        error,
    }
}

export function toggleUser(payload) {
    return {
        type: types.TOGGLE_USER,
        payload,
    }
}

export function toggleUserSuccess(response) {
    return {
        type: types.TOGGLE_USER_SUCCESS,
        payload: response,
    }
}

export function toggleUserFailure(error) {
    return {
        type: types.TOGGLE_USER_FAILURE,
        error,
    }
}

export function updateUserRole(payload) {
    return {
        type: types.UPDATE_USER_ROLE,
        payload,
    }
}

export function updateUserRoleSuccess(response) {
    return {
        type: types.UPDATE_USER_ROLE_SUCCESS,
        payload: response,
    }
}

export function updateUserRoleFailure(error) {
    return {
        type: types.UPDATE_USER_ROLE_FAILURE,
        error,
    }
}

export function resetUserPassword(payload) {
    return {
        type: types.RESET_USER_PASSWORD,
        payload,
    }
}

export function resetUserPasswordSuccess(response) {
    return {
        type: types.RESET_USER_PASSWORD_SUCCESS,
        payload: response,
    }
}

export function resetUserPasswordFailure(error) {
    return {
        type: types.RESET_USER_PASSWORD_FAILURE,
        error,
    }
}
