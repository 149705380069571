import * as types from './businessesFeeConfig.constant';

export function getBusinessFeeConfig(params) {
    return {
        type: types.GET_BUSINESS_FEE_CONFIG,
        payload: params,
    }
}

export function getBusinessFeeConfigSuccess(response) {
    return {
        type: types.GET_BUSINESS_FEE_CONFIG_SUCCESS,
        payload: response,
    }
}

export function getBusinessFeeConfigFailure(error) {
    return {
        type: types.GET_BUSINESS_FEE_CONFIG_FAILURE,
        error,
    }
}

export function assignFeeConfigToBusiness(payload) {
    return {
        type: types.ASSIGN_FEE_CONFIG_TO_BUSINESS,
        payload,
    }
}

export function assignFeeConfigToBusinessSuccess(response) {
    return {
        type: types.ASSIGN_FEE_CONFIG_TO_BUSINESS_SUCCESS,
        payload: response,
    }
}

export function assignFeeConfigToBusinessFailure(error) {
    return {
        type: types.ASSIGN_FEE_CONFIG_TO_BUSINESS_FAILURE,
        error,
    }
}

export function updateBusinessFeeConfig(payload) {
    return {
        type: types.UPDATE_BUSINESS_FEE_CONFIG,
        payload,
    }
}

export function updateBusinessFeeConfigSuccess(response) {
    return {
        type: types.UPDATE_BUSINESS_FEE_CONFIG_SUCCESS,
        payload: response,
    }
}

export function updateBusinessFeeConfigFailure(error) {
    return {
        type: types.UPDATE_BUSINESS_FEE_CONFIG_FAILURE,
        error,
    }
}

export function updateBusinessFeeConfigStatus(payload) {
    return {
        type: types.UPDATE_BUSINESS_FEE_CONFIG_STATUS,
        payload,
    }
}

export function updateBusinessFeeConfigStatusSuccess(response) {
    return {
        type: types.UPDATE_BUSINESS_FEE_CONFIG_STATUS_SUCCESS,
        payload: response,
    }
}

export function updateBusinessFeeConfigStatusFailure(error) {
    return {
        type: types.UPDATE_BUSINESS_FEE_CONFIG_STATUS_FAILURE,
        error,
    }
}

export function getPendingBusinessFeeConfigApproval(params) {
    return {
        type: types.GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL,
        payload: params,
    }
}

export function getPendingBusinessFeeConfigApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingBusinessFeeConfigApprovalFailure(error) {
    return {
        type: types.GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_FAILURE,
        error,
    }
}

export function getPendingBusinessesFeeConfigApproval(params) {
    return {
        type: types.GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL,
        payload: params,
    }
}

export function getPendingBusinessesFeeConfigApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingBusinessesFeeConfigApprovalFailure(error) {
    return {
        type: types.GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_FAILURE,
        error,
    }
}

export function createBusinessFeeConfigApprovalVerdict(payload) {
    return {
        type: types.CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT,
        payload,
    }
}

export function createBusinessFeeConfigApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createBusinessFeeConfigApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_FAILURE,
        error,
    }
}