import * as types from './paymentReport.constant';

export function getPaymentReportParameters(params) {
    return {
        type: types.GET_PAYMENT_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getPaymentReportParametersSuccess(response) {
    return {
        type: types.GET_PAYMENT_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getPaymentReportParametersFailure(error) {
    return {
        type: types.GET_PAYMENT_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getPaymentReport(params) {
    return {
        type: types.GET_PAYMENT_REPORT,
        payload: params,
    }
}

export function getPaymentReportSuccess(response) {
    return {
        type: types.GET_PAYMENT_REPORT_SUCCESS,
        payload: response,
    }
}

export function getPaymentReportFailure(error) {
    return {
        type: types.GET_PAYMENT_REPORT_FAILURE,
        error,
    }
}

export function getPaymentReportDownload(params) {
    return {
        type: types.GET_PAYMENT_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getPaymentReportDownloadSuccess(response) {
    return {
        type: types.GET_PAYMENT_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getPaymentReportDownloadFailure(error) {
    return {
        type: types.GET_PAYMENT_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackPaymentReportDownload(params) {
    return {
        type: types.TRACK_PAYMENT_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackPaymentReportDownloadSuccess(response) {
    return {
        type: types.TRACK_PAYMENT_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}
