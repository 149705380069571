import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import store from './utils/redux.store';
import App from './app';
import './index.css';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={1}>
            <App />
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
