export const GET_CUMUL_DAILY_TRANS_LIMIT = 'GET_CUMUL_DAILY_TRANS_LIMIT';
export const GET_CUMUL_DAILY_TRANS_LIMIT_SUCCESS = 'GET_CUMUL_DAILY_TRANS_LIMIT_SUCCESS';
export const GET_CUMUL_DAILY_TRANS_LIMIT_FAILURE = 'GET_CUMUL_DAILY_TRANS_LIMIT_FAILURE';

export const GET_CUMUL_DAILY_TRANS_LIMITS = 'GET_CUMUL_DAILY_TRANS_LIMITS';
export const GET_CUMUL_DAILY_TRANS_LIMITS_SUCCESS = 'GET_CUMUL_DAILY_TRANS_LIMITS_SUCCESS';
export const GET_CUMUL_DAILY_TRANS_LIMITS_FAILURE = 'GET_CUMUL_DAILY_TRANS_LIMITS_FAILURE';

export const CREATE_GLOBAL_TRANS_LIMIT = 'CREATE_GLOBAL_TRANS_LIMIT';
export const CREATE_GLOBAL_TRANS_LIMIT_SUCCESS = 'CREATE_GLOBAL_TRANS_LIMIT_SUCCESS';
export const CREATE_GLOBAL_TRANS_LIMIT_FAILURE = 'CREATE_GLOBAL_TRANS_LIMIT_FAILURE';

export const UPDATE_GLOBAL_TRANS_LIMIT = 'UPDATE_GLOBAL_TRANS_LIMIT';
export const UPDATE_GLOBAL_TRANS_LIMIT_SUCCESS = 'UPDATE_GLOBAL_TRANS_LIMIT_SUCCESS';
export const UPDATE_GLOBAL_TRANS_LIMIT_FAILURE = 'UPDATE_GLOBAL_TRANS_LIMIT_FAILURE';

export const CREATE_CUMUL_DAILY_TRANS_LIMIT = 'CREATE_CUMUL_DAILY_TRANS_LIMIT';
export const CREATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS = 'CREATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS';
export const CREATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE = 'CREATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE';

export const UPDATE_CUMUL_DAILY_TRANS_LIMIT = 'UPDATE_CUMUL_DAILY_TRANS_LIMIT';
export const UPDATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS = 'UPDATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS';
export const UPDATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE = 'UPDATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE';

export const UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT = 'UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT';
export const UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_SUCCESS = 'UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_SUCCESS';
export const UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_FAILURE = 'UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_FAILURE';

export const GET_PENDING_TRANS_LIMIT_APPROVAL = 'GET_PENDING_TRANS_LIMIT_APPROVAL';
export const GET_PENDING_TRANS_LIMIT_APPROVAL_SUCCESS = 'GET_PENDING_TRANS_LIMIT_APPROVAL_SUCCESS';
export const GET_PENDING_TRANS_LIMIT_APPROVAL_FAILURE = 'GET_PENDING_TRANS_LIMIT_APPROVAL_FAILURE';

export const GET_PENDING_TRANS_LIMITS_APPROVAL = 'GET_PENDING_TRANS_LIMITS_APPROVAL';
export const GET_PENDING_TRANS_LIMITS_APPROVAL_SUCCESS = 'GET_PENDING_TRANS_LIMITS_APPROVAL_SUCCESS';
export const GET_PENDING_TRANS_LIMITS_APPROVAL_FAILURE = 'GET_PENDING_TRANS_LIMITS_APPROVAL_FAILURE';

export const CREATE_TRANS_LIMIT_APPROVAL_VERDICT = 'CREATE_TRANS_LIMIT_APPROVAL_VERDICT';
export const CREATE_TRANS_LIMIT_APPROVAL_VERDICT_SUCCESS = 'CREATE_TRANS_LIMIT_APPROVAL_VERDICT_SUCCESS';
export const CREATE_TRANS_LIMIT_APPROVAL_VERDICT_FAILURE = 'CREATE_TRANS_LIMIT_APPROVAL_VERDICT_FAILURE';
