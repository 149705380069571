import * as types from './businesses.constant';

const initialState = {
    allBusinesses: null,
    getAllBusinessesError: null,
    getAllBusinessesLoading: false,
    business: null,
    getBusinessError: null,
    getBusinessLoading: false,
    toggleBusinessLoading: false,
    toggleBusinessSuccess: null,
    toggleBusinessError: null,
    mapBusinessToPaydirectLoading: false,
    mapBusinessToPaydirectSuccess: null,
    mapBusinessToPaydirectError: null,
    searchPaydirectBillersLoading: false,
    searchPaydirectBillersError: null,
    paydirectBillers: null,
}

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_ALL_BUSINESSES:
            return {
                ...state,
                getAllBusinessesLoading: true,
                getAllBusinessesError: null,
                allBusinesses: null,
            }
        
        case types.GET_ALL_BUSINESSES_SUCCESS:
            return {
                ...state,
                getAllBusinessesLoading: false,
                allBusinesses: action.payload,
            }

        case types.GET_ALL_BUSINESSES_FAILURE:
            return {
                ...state,
                getAllBusinessesLoading: false,
                getAllBusinessesError: action.error,
            }

        case types.GET_BUSINESS:
            return {
                ...state,
                getBusinessLoading: true,
                getBusinessError: null,
                business: null,
            }
        
        case types.GET_BUSINESS_SUCCESS:
            return {
                ...state,
                getBusinessLoading: false,
                business: action.payload,
            }

        case types.GET_BUSINESS_FAILURE:
            return {
                ...state,
                getBusinessLoading: false,
                getBusinessError: action.error,
            }

        case types.TOGGLE_BUSINESS:
            return {
                ...state,
                toggleBusinessLoading: true,
                toggleBusinessSuccess: null,
                toggleBusinessError: null,
            }
        
        case types.TOGGLE_BUSINESS_SUCCESS:
            return {
                ...state,
                toggleBusinessLoading: false,
                toggleBusinessSuccess: action.payload,
            }

        case types.TOGGLE_BUSINESS_FAILURE:
            return {
                ...state,
                toggleBusinessLoading: false,
                toggleBusinessError: action.error,
            }

        case types.MAP_BUSINESS_TO_PAYDIRECT:
            return {
                ...state,
                mapBusinessToPaydirectLoading: true,
                mapBusinessToPaydirectError: null,
                mapBusinessToPaydirectSuccess: null,
            }
        
        case types.MAP_BUSINESS_TO_PAYDIRECT_SUCCESS:
            return {
                ...state,
                mapBusinessToPaydirectLoading: false,
                mapBusinessToPaydirectSuccess: action.payload,
            }

        case types.MAP_BUSINESS_TO_PAYDIRECT_FAILURE:
            return {
                ...state,
                mapBusinessToPaydirectLoading: false,
                mapBusinessToPaydirectError: action.error,
            }

        case types.SEARCH_PAYDIRECT_BILLERS:
            return {
                ...state,
                searchPaydirectBillersLoading: true,
                searchPaydirectBillersError: null,
                paydirectBillers: null,
            }

        case types.SEARCH_PAYDIRECT_BILLERS_SUCCESS:
            return {
                ...state,
                searchPaydirectBillersLoading: false,
                paydirectBillers: action.payload,
            }
        
        case types.SEARCH_PAYDIRECT_BILLERS_FAILURE:
            return {
                ...state,
                searchPaydirectBillersLoading: false,
                searchPaydirectBillersError: action.error,
            }

        default:
            return state;
    }
}
