export const GET_ALL_BUSINESSES = 'GET_ALL_BUSINESSES';
export const GET_ALL_BUSINESSES_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS';
export const GET_ALL_BUSINESSES_FAILURE = 'GET_ALL_BUSINESSES_FAILURE';

export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILURE = 'GET_BUSINESS_FAILURE';

export const TOGGLE_BUSINESS = 'TOGGLE_BUSINESS';
export const TOGGLE_BUSINESS_SUCCESS = 'TOGGLE_BUSINESS_SUCCESS';
export const TOGGLE_BUSINESS_FAILURE = 'TOGGLE_BUSINESS_FAILURE';

export const MAP_BUSINESS_TO_PAYDIRECT = 'MAP_BUSINESS_TO_PAYDIRECT';
export const MAP_BUSINESS_TO_PAYDIRECT_SUCCESS = 'MAP_BUSINESS_TO_PAYDIRECT_SUCCESS';
export const MAP_BUSINESS_TO_PAYDIRECT_FAILURE = 'MAP_BUSINESS_TO_PAYDIRECT_FAILURE';

export const SEARCH_PAYDIRECT_BILLERS = 'SEARCH_PAYDIRECT_BILLERS';
export const SEARCH_PAYDIRECT_BILLERS_SUCCESS = 'SEARCH_PAYDIRECT_BILLERS_SUCCESS';
export const SEARCH_PAYDIRECT_BILLERS_FAILURE = 'SEARCH_PAYDIRECT_BILLERS_FAILURE';
