import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/business';
import { GET_ALL_BUSINESSES, GET_BUSINESS, TOGGLE_BUSINESS, MAP_BUSINESS_TO_PAYDIRECT, SEARCH_PAYDIRECT_BILLERS } from './businesses.constant';
import { getBusinessSuccess, getBusinessFailure, getAllBusinessesSuccess, getAllBusinessesFailure, toggleBusinessSuccess, toggleBusinessFailure, mapBusinessToPaydirectSuccess, mapBusinessToPaydirectFailure, searchPaydirectBillersSuccess, searchPaydirectBillersFailure } from './businesses.action';

async function getAllBusinessesRequest() {
    const options = endpoint.getBusinesses();
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPaginatedBusinessesRequest({pageNumber, pageSize, searchParam}) {
    const options = endpoint.searchBusinesses(pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getBusinessRequest(id) {
    const options = endpoint.getBusiness(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function toggleBusinessRequest({id, payload}) {
    const options = endpoint.toggleBusiness(id, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function mapBusinessToPaydirectRequest({id, payload}) {
    const options = endpoint.mapBusinessToPaydirect(id, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function searchPaydirectBillersRequest({pageNumber, pageSize, searchParam}) {
    const options = endpoint.searchPaydirectBillers(pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getBusinessWorker({ payload }) {
    try {
        const response = yield call(getBusinessRequest, payload);
        yield put(getBusinessSuccess(response.business));
    } catch(err) {
        yield put(getBusinessFailure(err));
    }
}

function* getAllBusinessesWorker({ payload }) {
    try {
        let requestMethod = getAllBusinessesRequest;

        if (payload.pageNumber) {
            requestMethod = getPaginatedBusinessesRequest;
        }
        const response = yield call(requestMethod, payload);
        yield put(getAllBusinessesSuccess(response));
    } catch(err) {
        yield put(getAllBusinessesFailure(err));
    }
}

function* toggleBusinessWorker({ payload }) {
    try {
        yield call(toggleBusinessRequest, payload);
        const message = ('Business updated successfully').toString();
        yield put(toggleBusinessSuccess(message));
    } catch(err) {
        yield put(toggleBusinessFailure(err));
    }
}

function* mapBusinessToPaydirectWorker({ payload }) {
    try {
        const response = yield call(mapBusinessToPaydirectRequest, payload);
        const message = (response.responseDescription || 'Business mapped successfully').toString();
        yield put(mapBusinessToPaydirectSuccess(message));
    } catch(err) {
        yield put(mapBusinessToPaydirectFailure(err));
    }
}

function* searchPaydirectBillersWorker({ payload }) {
    try {
        const response = yield call(searchPaydirectBillersRequest, payload);
        yield put(searchPaydirectBillersSuccess(response));
    } catch(err) {
        yield put(searchPaydirectBillersFailure(err));
    }
} 



function* getBusinessWatcher() {
    yield takeLatest(GET_BUSINESS, getBusinessWorker);
}

function* getAllBusinessesWatcher() {
    yield takeLatest(GET_ALL_BUSINESSES, getAllBusinessesWorker);
}

function* toggleBusinessWatcher() {
    yield takeLatest(TOGGLE_BUSINESS, toggleBusinessWorker);
}

function* mapBusinessToPaydirectWatcher() {
    yield takeLatest(MAP_BUSINESS_TO_PAYDIRECT, mapBusinessToPaydirectWorker);
}

function* searchPaydirectBillersWatcher() {
    yield takeLatest(SEARCH_PAYDIRECT_BILLERS, searchPaydirectBillersWorker);
}


export default function*() {
    yield all([
        fork(getAllBusinessesWatcher),
        fork(getBusinessWatcher),
        fork(toggleBusinessWatcher),
        fork(mapBusinessToPaydirectWatcher),
        fork(searchPaydirectBillersWatcher),
    ]);
}
