import React from 'react'
import TransactionFooter from './TransactionFooter';
import TransactionHero from './TransactionHero';
import TransactionMain from './TransactionMain';

const Transaction = () => {
  window.scrollTo(0,0)
  return (
    <>
      <TransactionHero />
      <TransactionMain />
      <TransactionFooter />
    </>
  )
}

export default Transaction;