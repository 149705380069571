export const GET_SAVED_PAYMENTS = 'GET_SAVED_PAYMENTS';
export const GET_SAVED_PAYMENTS_SUCCESS = 'GET_SAVED_PAYMENTS_SUCCESS';
export const GET_SAVED_PAYMENTS_FAILURE = 'GET_SAVED_PAYMENTS_FAILURE';

export const GET_SAVED_PAYMENT = 'GET_SAVED_PAYMENT';
export const GET_SAVED_PAYMENT_SUCCESS = 'GET_SAVED_PAYMENT_SUCCESS';
export const GET_SAVED_PAYMENT_FAILURE = 'GET_SAVED_PAYMENT_FAILURE';

export const CREATE_SAVED_PAYMENT = 'CREATE_SAVED_PAYMENT';
export const CREATE_SAVED_PAYMENT_SUCCESS = 'CREATE_SAVED_PAYMENT_SUCCESS';
export const CREATE_SAVED_PAYMENT_FAILURE = 'CREATE_SAVED_PAYMENT_FAILURE';

export const EDIT_SAVED_PAYMENT = 'EDIT_SAVED_PAYMENT';
export const EDIT_SAVED_PAYMENT_SUCCESS = 'EDIT_SAVED_PAYMENT_SUCCESS';
export const EDIT_SAVED_PAYMENT_FAILURE = 'EDIT_SAVED_PAYMENT_FAILURE';

export const DELETE_SAVED_PAYMENT = 'DELETE_SAVED_PAYMENT';
export const DELETE_SAVED_PAYMENT_SUCCESS = 'DELETE_SAVED_PAYMENT_SUCCESS';
export const DELETE_SAVED_PAYMENT_FAILURE = 'DELETE_SAVED_PAYMENT_FAILURE';

export const UPDATE_PAYMENT_TO_SAVED_PAYMENTS = 'UPDATE_PAYMENT_TO_SAVED_PAYMENTS';
export const UPDATE_PAYMENT_ITEM_TO_PAYMENT_ITEMS = 'UPDATE_PAYMENT_ITEM_TO_PAYMENT_ITEMS';

export const HIDE_ALERT_SAVED_PAYMENTS_MESSAGE = 'HIDE_ALERT_SAVED_PAYMENTS_MESSAGE';
