import * as types from './auditTrail.constant';

const initialState = {
    auditTrail: null,
    getAuditTrailError: null,
    getAuditTrailLoading: false,
    auditTrailDownloadLink: null,
    getAuditTrailDownloadError: null,
    getAuditTrailDownloadLoading: false,
};

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_AUDIT_TRAIL:
            return {
                ...state,
                getAuditTrailLoading: true,
                getAuditTrailError: null,
                auditTrail: null,
            }

        case types.GET_AUDIT_TRAIL_SUCCESS:
            return {
                ...state,
                getAuditTrailLoading: false,
                auditTrail: action.payload,
            }

        case types.GET_AUDIT_TRAIL_FAILURE:
            return {
                ...state,
                getAuditTrailLoading: false,
                getAuditTrailError: action.error,
            }

        case types.GET_AUDIT_TRAIL_DOWNLOAD:
            return {
                ...state,
                getAuditTrailDownloadLoading: true,
                getAuditTrailDownloadError: null,
                auditTrailDownloadLink: null,
            }

        case types.GET_AUDIT_TRAIL_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getAuditTrailDownloadLoading: false,
                auditTrailDownloadLink: action.payload,
            }

        case types.GET_AUDIT_TRAIL_DOWNLOAD_FAILURE:
            return {
                ...state,
                getAuditTrailDownloadLoading: false,
                getAuditTrailDownloadError: action.error,
            }

            case types.SEARCH_AUDIT_TRAIL:
                return {
                    ...state,
                    getAuditTrailLoading: true,
                    getAuditTrailError: null,
                    auditTrail: null,
                }

            case types.SEARCH_AUDIT_TRAIL_SUCCESS:
                return {
                    ...state,
                    getAuditTrailLoading: false,
                    auditTrail: action.payload,
                }

            case types.SEARCH_AUDIT_TRAIL_FAILURE:
                return {
                    ...state,
                    getAuditTrailLoading: false,
                    getAuditTrailError: action.error,
                }

            case types.SEARCH_AUDIT_TRAIL_DOWNLOAD:
                return {
                    ...state,
                    getAuditTrailDownloadLoading: true,
                    getAuditTrailDownloadError: null,
                    auditTrailDownloadLink: null,
                }

            case types.SEARCH_AUDIT_TRAIL_DOWNLOAD_SUCCESS:
                return {
                    ...state,
                    getAuditTrailDownloadLoading: false,
                    auditTrailDownloadLink: action.payload,
                }

            case types.SEARCH_AUDIT_TRAIL_DOWNLOAD_FAILURE:
                return {
                    ...state,
                    getAuditTrailDownloadLoading: false,
                    getAuditTrailDownloadError: action.error,
                }

        default:
            return state;
    }
}
