import MigrateBusiness from './migrateBusiness/redux/migrateBusiness.reducer';
import NominateBusinessOwner from './nominateBusinessOwner/redux/nominateBusinessOwner.reducer';
import PendingBizOwnerApprovals from './businessOwnerApprovals/pendingBizOwnerApprovals/redux/pendingBizOwnerApprovals.reducer';
import BizOwnerApprovalRequests from './businessOwnerApprovals/bizOwnerApprovalRequests/redux/bizOwnerApprovalRequests.reducer';

import { combineReducers } from 'redux';

const settingsReducer = combineReducers({
    rxMigrateBusiness: MigrateBusiness,
    rxNominateBusinessOwner: NominateBusinessOwner,
    rxPendingBizOwnerApprovals: PendingBizOwnerApprovals,
    rxBizOwnerApprovalRequests: BizOwnerApprovalRequests
});


export default settingsReducer;
