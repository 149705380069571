import * as types from './settlementDetailsReport.constant';

const initialState = {
    getSettlementDetailsReportParametersLoading: false,
    getSettlementDetailsReportParametersError: null,
    settlementDetailsReportParameters: null,
    getSettlementDetailsReportLoading: false,
    getSettlementDetailsReportError: null,
    settlementDetailsReport: null,
    getSettlementDetailsReportDownloadLoading: false,
    getSettlementDetailsReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS:
            return {
                ...state,
                getSettlementDetailsReportParametersLoading: true,
                getSettlementDetailsReportParametersError: null,
                settlementDetailsReportParameters: null,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getSettlementDetailsReportParametersLoading: false,
                settlementDetailsReportParameters: action.payload,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getSettlementDetailsReportParametersLoading: false,
                getSettlementDetailsReportParametersError: action.error,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT:
            return {
                ...state,
                getSettlementDetailsReportLoading: true,
                getSettlementDetailsReportError: null,
                settlementDetailsReport: null,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_SUCCESS:
            return {
                ...state,
                getSettlementDetailsReportLoading: false,
                settlementDetailsReport: action.payload,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_FAILURE:
            return {
                ...state,
                getSettlementDetailsReportLoading: false,
                getSettlementDetailsReportError: action.error,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD:
            return {
                ...state,
                getSettlementDetailsReportDownloadLoading: true,
                getSettlementDetailsReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getSettlementDetailsReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getSettlementDetailsReportDownloadLoading: false,
                getSettlementDetailsReportDownloadError: action.error,
            }

        case types.TRACK_SETTLEMENT_DETAILS_REPORT_DOWNLOAD:
            return {
                ...state,
                getSettlementDetailsReportDownloadLoading: true,
                getSettlementDetailsReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_SETTLEMENT_DETAILS_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getSettlementDetailsReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }
        
        default:
            return state;
    }
}
