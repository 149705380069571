import * as types from './pendingApprovals.constant';

export function getPendingApprovals(params) {
    return {
        type: types.GET_PENDING_APPROVALS,
        payload: params,
    }
}

export function getPendingApprovalsSuccess(response) {
    return {
        type: types.GET_PENDING_APPROVALS_SUCCESS,
        payload: response,
    }
}

export function getPendingApprovalsFailure(error) {
    return {
        type: types.GET_PENDING_APPROVALS_FAILURE,
        error,
    }
}
