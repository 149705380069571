import { all } from 'redux-saga/effects';
import MigrateBusiness from './migrateBusiness/redux/migrateBusiness.saga';
import NominateBusinessOwner from './nominateBusinessOwner/redux/nominateBusinessOwner.saga';
import PendingBizOwnerApprovals from './businessOwnerApprovals/pendingBizOwnerApprovals/redux/pendingBizOwnerApprovals.saga';
import BizOwnerApprovalRequests from './businessOwnerApprovals/bizOwnerApprovalRequests/redux/bizOwnerApprovalRequests.saga';


export default function*() {
    yield all([
        MigrateBusiness(),
        NominateBusinessOwner(),
        PendingBizOwnerApprovals(),
        BizOwnerApprovalRequests()
    ]);
}
