const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    getBusinessTypes: () => ({
        baseUrl: BASE_URL,
        url: 'business/types',
        method: 'GET',
        gatewayAuth: true,
    }),

    getBanksList: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `isw/fees/banks?pageNumber=${page}&pageSize=${size}&searchKey=${search}`,
        method: 'GET',
        auth: true,
    }),
};
