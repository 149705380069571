import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/payments';
import { SAVE_CART_PAYMENT, DELETE_SAVED_CART_PAYMENT, EDIT_SAVED_CART_PAYMENT, GET_SAVED_CART_PAYMENT } from './cart.constant';
import { getSavedCartPaymentFailure, getSavedCartPaymentSuccess, saveCartPaymentSuccess, saveCartPaymentFailure, editSavedCartPaymentSuccess, editSavedCartPaymentFailure, deleteSavedCartPaymentSuccess, deleteSavedCartPaymentFailure } from './cart.action';


async function getSavedCartPaymentRequest({id, businessId, withDetails}) {
    const options = endpoint.getSavedPayment(id, businessId, withDetails)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function saveCartPaymentRequest(payload) {
    const options = endpoint.createSavedPayment(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function editSavedCartPaymentRequest(payload) {
    const options = endpoint.editSavedPayment(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function deleteSavedCartPaymentRequest(payload) {
    const options = endpoint.deleteSavedPayment(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getSavedCartPaymentWorker({ payload }) {
    try {
        const response = yield call(getSavedCartPaymentRequest, payload);
        yield put(getSavedCartPaymentSuccess(response));
    } catch(err) {
        yield put(getSavedCartPaymentFailure(err));
    }
}

function* saveCartPaymentWorker({ payload }) {
    try {
        let response = yield call(saveCartPaymentRequest, payload);

        if (typeof response !== 'string') {
            response = 'Payment was successfully saved';
        }
        yield put(saveCartPaymentSuccess(response));
    } catch(err) {
        yield put(saveCartPaymentFailure(err));
    }
}

function* editSavedCartPaymentWorker({ payload }) {
    try {
        let response = yield call(editSavedCartPaymentRequest, payload);

        if (typeof response !== 'string') {
            response = 'Payment was successfully updated';
        }
        yield put(editSavedCartPaymentSuccess(response));
    } catch(err) {
        yield put(editSavedCartPaymentFailure(err));
    }
}

function* deleteSavedCartPaymentWorker({ payload }) {
    try {
        let response = yield call(deleteSavedCartPaymentRequest, payload);

        if (typeof response !== 'string') {
            response = 'Payment was successfully deleted';
        }
        yield put(deleteSavedCartPaymentSuccess(response));
    } catch(err) {
        yield put(deleteSavedCartPaymentFailure(err));
    }
}


function* getSavedCartPaymentWatcher() {
    yield takeLatest(GET_SAVED_CART_PAYMENT, getSavedCartPaymentWorker);
}

function* saveCartPaymentWatcher() {
    yield takeLatest(SAVE_CART_PAYMENT, saveCartPaymentWorker);
}

function* editSavedCartPaymentWatcher() {
    yield takeLatest(EDIT_SAVED_CART_PAYMENT, editSavedCartPaymentWorker);
}

function* deleteSavedCartPaymentWatcher() {
    yield takeLatest(DELETE_SAVED_CART_PAYMENT, deleteSavedCartPaymentWorker);
}


export default function*() {
    yield all([
        fork(getSavedCartPaymentWatcher),
        fork(saveCartPaymentWatcher),
        fork(editSavedCartPaymentWatcher),
        fork(deleteSavedCartPaymentWatcher),
    ]);
}
