import * as types from './paymentInstrument.constant';

export function getPaymentInstrumentConfig(params) {
    return {
        type: types.GET_PAYMENT_INSTRUMENT_CONFIG,
        payload: params
    }
}

export function getPaymentInstrumentConfigSuccess(response) {
    return {
        type: types.GET_PAYMENT_INSTRUMENT_CONFIG_SUCCESS,
        payload: response,
    }
}

export function getPaymentInstrumentConfigFailure(error) {
    return {
        type: types.GET_PAYMENT_INSTRUMENT_CONFIG_FAILURE,
        error,
    }
}

export function providePaymentInstrument(params) {
    return {
        type: types.PROVIDE_PAYMENT_INSTRUMENT,
        payload: params
    }
}

export function providePaymentInstrumentSuccess(response) {
    return {
        type: types.PROVIDE_PAYMENT_INSTRUMENT_SUCCESS,
        payload: response,
    }
}

export function providePaymentInstrumentFailure(error) {
    return {
        type: types.PROVIDE_PAYMENT_INSTRUMENT_FAILURE,
        error,
    }
}

export function makePayment(payload) {
    return {
        type: types.MAKE_PAYMENT,
        payload
    }
}

export function makePaymentSuccess(response) {
    return {
        type: types.MAKE_PAYMENT_SUCCESS,
        payload: response,
    }
}

export function makePaymentFailure(error) {
    return {
        type: types.MAKE_PAYMENT_FAILURE,
        error,
    }
}

export function openPaymentInstrumentDialog() {
    return {
        type: types.SHOW_PAYMENT_INSTRUMENT_DIALOG,
    }
}

export function closePaymentInstrumentDialog() {
    return {
        type: types.HIDE_PAYMENT_INSTRUMENT_DIALOG,
    }
}


