import * as types from './roles.constant';

const initialState = {
    getRoleError: null,
    getRoleLoading: false,
    role: null,
    getRoleUsersError: null,
    getRoleUsersLoading: false,
    roleUsers: null,
    getAllRolesError: null,
    getAllRolesLoading: false,
    allRoles: null,
    createRoleLoading: false,
    createRoleSuccess: null,
    createRoleError: null,
    editRoleLoading: false,
    editRoleSuccess: null,
    editRoleError: null,
    deleteRoleLoading: false,
    deleteRoleSuccess: null,
    deleteRoleError: null,
    assignPermissionsToRoleLoading: false,
    assignPermissionsToRoleSuccess: null,
    assignPermissionsToRoleError: null,
    getPermissionsLoading: false,
    permissions: null,
    getPermissionsError: null,
}

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_ALL_ROLES:
            return {
                ...state,
                getAllRolesLoading: true,
                getAllRolesError: null,
                allRoles: null,
            }

        case types.GET_ALL_ROLES_SUCCESS:
            return {
                ...state,
                getAllRolesLoading: false,
                allRoles: action.payload,
            }

        case types.GET_ALL_ROLES_FAILURE:
            return {
                ...state,
                getAllRolesLoading: false,
                getAllRolesError: action.error,
            }

        case types.GET_ROLE:
            return {
                ...state,
                getRoleLoading: true,
                getRoleError: null,
                role: null,
            }

        case types.GET_ROLE_SUCCESS:
            return {
                ...state,
                getRoleLoading: false,
                role: action.payload,
            }

        case types.GET_ROLE_FAILURE:
            return {
                ...state,
                getRoleLoading: false,
                getRoleError: action.error,
            }

        case types.GET_ROLE_USERS:
            return {
                ...state,
                getRoleUsersLoading: true,
                getRoleUsersError: null,
                roleUsers: null,
            }

        case types.GET_ROLE_USERS_SUCCESS:
            return {
                ...state,
                getRoleUsersLoading: false,
                roleUsers: action.payload,
            }

        case types.GET_ROLE_USERS_FAILURE:
            return {
                ...state,
                getRoleUsersLoading: false,
                getRoleUsersError: action.error,
            }

        case types.CREATE_ROLE:
            return {
                ...state,
                createRoleLoading: true,
                createRoleSuccess: null,
                createRoleError: null,
            }

        case types.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                createRoleLoading: false,
                createRoleSuccess: action.payload,
            }

        case types.CREATE_ROLE_FAILURE:
            return {
                ...state,
                createRoleLoading: false,
                createRoleError: action.error,
            }

        case types.EDIT_ROLE:
            return {
                ...state,
                editRoleLoading: true,
                editRoleSuccess: null,
                editRoleError: null,
            }

        case types.EDIT_ROLE_SUCCESS:
            return {
                ...state,
                editRoleLoading: false,
                editRoleSuccess: action.payload,
            }

        case types.EDIT_ROLE_FAILURE:
            return {
                ...state,
                editRoleLoading: false,
                editRoleError: action.error,
            }

        case types.DELETE_ROLE:
            return {
                ...state,
                deleteRoleLoading: true,
                deleteRoleSuccess: null,
                deleteRoleError: null,
            }

        case types.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                deleteRoleLoading: false,
                deleteRoleSuccess: action.payload,
            }

        case types.DELETE_ROLE_FAILURE:
            return {
                ...state,
                deleteRoleLoading: false,
                deleteRoleError: action.error,
            }

        case types.ASSIGN_PERMISSIONS_TO_ROLE:
            return {
                ...state,
                assignPermissionsToRoleLoading: true,
                assignPermissionsToRoleSuccess: null,
                assignPermissionsToRoleError: null,
            }

        case types.ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS:
            return {
                ...state,
                assignPermissionsToRoleLoading: false,
                assignPermissionsToRoleSuccess: action.payload,
            }

        case types.ASSIGN_PERMISSIONS_TO_ROLE_FAILURE:
            return {
                ...state,
                assignPermissionsToRoleLoading: false,
                assignPermissionsToRoleError: action.error,
            }

        case types.GET_PERMISSIONS:
            return {
                ...state,
                getPermissionsLoading: true,
                permissions: null,
                getPermissionsError: null,
            }

        case types.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                getPermissionsLoading: false,
                permissions: action.payload,
            }

        case types.GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                getPermissionsLoading: false,
                getPermissionsError: action.error,
            }

        default:
            return state;
    }
}
