import { combineReducers } from 'redux';
import CommonReportReducer from './common/redux/common-report.reducer';
import PaymentSearchReducer from './paymentSearch/redux/paymentSearch.reducer';
import PaymentReportReducer from './paymentReport/redux/paymentReport.reducer';
import TransactionReportReducer from './transactionReport/redux/transactionReport.reducer';
import PaymentReversalReportReducer from './paymentReversalReport/redux/paymentReversalReport.reducer'
import SettlementSplitReportReducer from './settlementReport/split/redux/settlementSplitReport.reducer';
import SettlementDetailsReportReducer from './settlementReport/details/redux/settlementDetailsReport.reducer';
import PaymentNotifStatusReportReducer from './paymentNotifStatusReport/redux/paymentNotifStatusReport.reducer';
import SettlementPositionsReportReducer from './settlementReport/positions/redux/settlementPositionsReport.reducer';

const reportReducers = combineReducers({
    rxCommon: CommonReportReducer,
    rxPaymentSearch: PaymentSearchReducer,
    rxPaymentReport: PaymentReportReducer,
    rxTransactionReport: TransactionReportReducer,
    rxPaymentReversalReport: PaymentReversalReportReducer,
    rxSettlementSplitReport: SettlementSplitReportReducer,
    rxSettlementDetailsReport: SettlementDetailsReportReducer,
    rxPaymentNotifStatusReport: PaymentNotifStatusReportReducer,
    rxSettlementPositionsReport: SettlementPositionsReportReducer,
})

export default reportReducers;
