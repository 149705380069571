import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/reports';
import { GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS, GET_SETTLEMENT_SPLIT_REPORT, GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD, TRACK_SETTLEMENT_SPLIT_REPORT_DOWNLOAD } from './settlementSplitReport.constant';
import { getSettlementSplitReportParametersSuccess, getSettlementSplitReportParametersFailure, getSettlementSplitReportSuccess, getSettlementSplitReportFailure, getSettlementSplitReportDownloadSuccess, getSettlementSplitReportDownloadFailure, trackSettlementSplitReportDownloadSuccess } from './settlementSplitReport.action';


async function getSettlementSplitReportParametersRequest({currencyCode}) {
    const options = endpoint.getSettlementSplitReportParameters(currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getSettlementSplitReportRequest(payload) {
    const options = endpoint.getSettlementSplitReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackSettlementSplitReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getSettlementSplitReportParametersWorker({ payload }) {
    try {
        const response = yield call(getSettlementSplitReportParametersRequest, payload);
        yield put(getSettlementSplitReportParametersSuccess(response.parameter));
    } catch(err) {
        yield put(getSettlementSplitReportParametersFailure(err));
    }
}

function* getSettlementSplitReportWorker({ payload }) {
    try {
        const response = yield call(getSettlementSplitReportRequest, payload);
        yield put(getSettlementSplitReportSuccess(response));
    } catch(err) {
        yield put(getSettlementSplitReportFailure(err));
    }
}

function* getSettlementSplitReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getSettlementSplitReportRequest, payload);
        yield put(getSettlementSplitReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getSettlementSplitReportDownloadFailure(err));
    }
}

function* trackSettlementSplitReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackSettlementSplitReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackSettlementSplitReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getSettlementSplitReportDownloadFailure(err));
    }
}



function* getSettlementSplitReportParametersWatcher() {
    yield takeLatest(GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS, getSettlementSplitReportParametersWorker);
}

function* getSettlementSplitReportWatcher() {
    yield takeLatest(GET_SETTLEMENT_SPLIT_REPORT, getSettlementSplitReportWorker);
}

function* getSettlementSplitReportDownloadWatcher() {
    yield takeLatest(GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD, getSettlementSplitReportDownloadWorker);
}

function* trackSettlementSplitReportDownloadWatcher() {
    yield takeLatest(TRACK_SETTLEMENT_SPLIT_REPORT_DOWNLOAD, trackSettlementSplitReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getSettlementSplitReportParametersWatcher),
        fork(getSettlementSplitReportWatcher),
        fork(getSettlementSplitReportDownloadWatcher),
        fork(trackSettlementSplitReportDownloadWatcher),
    ]);
}
