export const GET_ALL_TRANSACTIONS_LIST = 'GET_ALL_TRANSACTIONS_LIST';
export const GET_ALL_TRANSACTIONS_LIST_SUCCESS = 'GET_ALL_TRANSACTIONS_LIST_SUCCESS';
export const GET_ALL_TRANSACTIONS_LIST_FAILURE = 'GET_ALL_TRANSACTIONS_LIST_FAILURE';

export const GET_ALL_TRANSACTIONS_LIST_DOWNLOAD = 'GET_ALL_TRANSACTIONS_LIST_DOWNLOAD';
export const GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS = 'GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS';
export const GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_FAILURE = 'GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_FAILURE';

export const TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD = 'TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD';
export const TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS = 'TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS';

export const GET_SINGLE_TRANSACTIONS_LIST = 'GET_SINGLE_TRANSACTIONS_LIST';
export const GET_SINGLE_TRANSACTIONS_LIST_SUCCESS = 'GET_SINGLE_TRANSACTIONS_LIST_SUCCESS';
export const GET_SINGLE_TRANSACTIONS_LIST_FAILURE = 'GET_SINGLE_TRANSACTIONS_LIST_FAILURE';

export const GET_BATCH_TRANSACTIONS_LIST = 'GET_BATCH_TRANSACTIONS_LIST';
export const GET_BATCH_TRANSACTIONS_LIST_SUCCESS = 'GET_BATCH_TRANSACTIONS_LIST_SUCCESS';
export const GET_BATCH_TRANSACTIONS_LIST_FAILURE = 'GET_BATCH_TRANSACTIONS_LIST_FAILURE';

export const GET_TRANSACTIONS_AWAITING_PAYMENT = 'GET_TRANSACTIONS_AWAITING_PAYMENT';
export const GET_TRANSACTIONS_AWAITING_PAYMENT_SUCCESS = 'GET_TRANSACTIONS_AWAITING_PAYMENT_SUCCESS';
export const GET_TRANSACTIONS_AWAITING_PAYMENT_FAILURE = 'GET_TRANSACTIONS_AWAITING_PAYMENT_FAILURE';

export const GET_TRANSACTION_PAYMENTS = 'GET_TRANSACTION_PAYMENTS';
export const GET_TRANSACTION_PAYMENTS_SUCCESS = 'GET_TRANSACTION_PAYMENTS_SUCCESS';
export const GET_TRANSACTION_PAYMENTS_FAILURE = 'GET_TRANSACTION_PAYMENTS_FAILURE';

export const GET_TRANSACTION_BATCH = 'GET_TRANSACTION_BATCH';
export const GET_TRANSACTION_BATCH_SUCCESS = 'GET_TRANSACTION_BATCH_SUCCESS';
export const GET_TRANSACTION_BATCH_FAILURE = 'GET_TRANSACTION_BATCH_FAILURE';

export const GET_TRANSACTION_BATCH_RECEIPT_LINK = 'GET_TRANSACTION_BATCH_RECEIPT_LINK';
export const GET_TRANSACTION_BATCH_RECEIPT_LINK_SUCCESS = 'GET_TRANSACTION_BATCH_RECEIPT_LINK_SUCCESS';
export const GET_TRANSACTION_BATCH_RECEIPT_LINK_FAILURE = 'GET_TRANSACTION_BATCH_RECEIPT_LINK_FAILURE';
