import * as types from './billers.constant';

const initialState = {
    billerCategories: null,
    billerCategoriesError: null,
    billerCategoriesLoading: false,
    billers: null,
    billersError: null,
    billersLoading: false,
    selectedBiller: null,
    selectedBillerError: null,
    selectedBillerLoading: false,
}

export default function (state = initialState, action) {
    switch(action.type) {

        case types.GET_BILLER_CATEGORIES:
            return {
                ...state,
                billerCategories: null,
                billerCategoriesError: null,
                billerCategoriesLoading: true,
            }
        
        case types.GET_BILLER_CATEGORIES_SUCCESS:
            return {
                ...state,
                billerCategories: action.payload,
                billerCategoriesLoading: false,
            }

        case types.GET_BILLER_CATEGORIES_FAILURE:
            return {
                ...state,
                billerCategoriesError: action.error,
                billerCategoriesLoading: false,
            }

        case types.SEARCH_BILLERS:
            return {
                ...state,
                billers: null,
                billersError: null,
                billersLoading: true,
            }
        
        case types.SEARCH_BILLERS_SUCCESS:
            return {
                ...state,
                billers: action.payload,
                billersLoading: false,
            }

        case types.SEARCH_BILLERS_FAILURE:
            return {
                ...state,
                billersError: action.error,
                billersLoading: false,
            }

        
        case types.GET_BILLER_INFO:
            return {
                ...state,
                selectedBillerLoading: true,
                selectedBillerError: null,
                selectedBiller: null,
            }

        case types.GET_BILLER_INFO_SUCCESS:
            return {
                ...state,
                selectedBillerLoading: false,
                selectedBiller: action.payload,
            }

        case types.GET_BILLER_INFO_FAILURE:
            return {
                ...state,
                selectedBillerLoading: false,
                selectedBillerError: action.error,
            }
            
        default:
            return state;
    }
}
