import * as types from './auth.constant';

const initialState = {
    authUser: null,
    authError: null,
    authLoading: false,
    regError: null,
    regSuccess: null,
    regLoading: false,
    passResetError: null,
    passResetSuccess: null,
    passResetLoading: false,
    setPassError: null,
    setPassSuccess: null,
    setPassLoading: false,
    verifyUserLoading: false,
    verifyUserSuccess: null,
    verifyUserError: null,
    verifyOtpError: null,
    verifyOtpLoading: false,
    verifyOtpSucccess : null,
    resendOtpError: null,
    resendOtpLoading: false,
    resendOtpSucccess : null,
    resendVerficationLoading: false,
    resendVerificationSuccess: null,
    resendVerificationError: null,
    migrateBusinessLoading: false,
    migrateBusinessError: null,
    migrateBusinessSuccess: null,
    createUserProfileLoading: false,
    createUserProfileError: null,
    createUserProfileSuccess: null,
    getBusinessOwnerLoading: false,
    getBusinessOwnerError: null,
    getBusinessOwnerSuccess: null
}

export default function(state = initialState, action) {
    switch(action.type) {

        case types.AUTHENTICATE_USER:
            return {
                ...state,
                authUser: null,
                authError: null,
                authLoading: true,
                resendVerificationError: null,
            }

        case types.AUTHENTICATE_USER_SUCCESS:
            return {
                ...state,
                authLoading: false,
                authUser: action.payload,
            }

        case types.AUTHENTICATE_USER_FAILURE:
            return {
                ...state,
                authLoading: false,
                authError: action.error,
            }

        case types.RESEND_VERFICATION:
            return {
                ...state,
                resendVerficationLoading: true,
                resendVerificationSuccess: null,
                resendVerificationError: null,
                authError: null,
            }

        case types.RESEND_VERFICATION_SUCCESS:
            return {
                ...state,
                resendVerficationLoading: false,
                resendVerificationSuccess: action.payload,
            }

        case types.RESEND_VERFICATION_FAILURE:
            return {
                ...state,
                resendVerficationLoading: false,
                resendVerificationError: action.error,
            }

        case types.REGISTER_USER:
            return {
                ...state,
                regError: null,
                regSuccess: null,
                regLoading: true,
            }

        case types.REGISTER_USER_SUCCESS:
            return {
                ...state,
                regLoading: false,
                regSuccess: action.payload,
            }

        case types.REGISTER_USER_FAILURE:
            return {
                ...state,
                regLoading: false,
                regError: action.error,
            }

        case types.RESET_PASSWORD:
            return {
                ...state,
                passResetError: null,
                passResetSuccess: null,
                passResetLoading: true,
            }

        case types.RESET_PASSWORD_SUCCESS:
            return {
                passResetLoading: false,
                passResetSuccess: action.payload,
            }

        case types.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                passResetLoading: false,
                passResetError: action.error,
            }

        case types.SET_PASSWORD:
            return {
                ...state,
                setPassError: null,
                setPassSuccess: null,
                setPassLoading: true,
            }

        case types.SET_PASSWORD_SUCCESS:
            return {
                ...state,
                setPassLoading: false,
                setPassSuccess: action.payload,
            }

        case types.SET_PASSWORD_FAILURE:
            return {
                ...state,
                setPassLoading: false,
                setPassError: action.error,
            }

        case types.VERIFY_USER:
            return {
                ...state,
                verifyUserError: null,
                verifyUserSuccess: null,
                verifyUserLoading: true,
            }

        case types.VERIFY_USER_SUCCESS:
            return {
                ...state,
                verifyUserLoading: false,
                verifyUserSuccess: action.payload,
            }

        case types.VERIFY_USER_FAILURE:
            return {
                ...state,
                verifyUserLoading: false,
                verifyUserError: action.error,
            }

        case types.GET_BUSINESS_OWNER:
            return {
                ...state,
                getBusinessOwnerError: null,
                getBusinessOwnerSuccess: null,
                getBusinessOwnerLoading: true,
            }

        case types.GET_BUSINESS_OWNER_SUCCESS:
            return {
                ...state,
                getBusinessOwnerLoading: false,
                getBusinessOwnerSuccess: action.payload,
            }

        case types.GET_BUSINESS_OWNER_FAILURE:
            return {
                ...state,
                getBusinessOwnerLoading: false,
                getBusinessOwnerError: action.error,
            }

        case types.REGISTER_BUSINESS_OWNER:
            return {
                ...state,
                regError: null,
                regSuccess: null,
                regLoading: true,
            }

        case types.REGISTER_BUSINESS_OWNER_SUCCESS:
            return {
                ...state,
                regLoading: false,
                regSuccess: action.payload,
            }

        case types.REGISTER_BUSINESS_OWNER_FAILURE:
            return {
                ...state,
                regLoading: false,
                regError: action.error,
            }

        case types.VERIFY_OTP:
            return {
                ...state,
                authUser: null,
                verifyOtpError: null,
                verifyOtpSuccess: null,
                verifyOtpLoading: true,
            }

        case types.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOtpLoading: false,
                authUser: action.payload,
            }

        case types.VERIFY_OTP_FAILURE:
            return {
                ...state,
                verifyOtpLoading: false,
                verifyOtpError: action.error,
            }

        case types.RESEND_OTP:
            return {
                ...state,
                resendOtpError: null,
                resendOtpSuccess: null,
                resendOtpLoading: true,
            }

        case types.RESEND_OTP_SUCCESS:
            return {
                ...state,
                resendOtpLoading: false,
                resendOtpSuccess: action.payload,
            }

        case types.RESEND_OTP_FAILURE:
            return {
                ...state,
                resendOtpLoading: false,
                resendOtpError: action.error,
            }

        default:
            return state;
    }
}
