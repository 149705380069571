import * as types from './initiatePayment.constant';

const initialState = {
    initiatePaymentError: null,
    initiatePaymentSuccess: null,
    initiatePaymentLoading: false,
    initiateBulkPaymentError: null,
    initiateBulkPaymentSuccess: null,
    initiateBulkPaymentLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.INITIATE_PAYMENT:
            return {
                ...state,
                initiatePaymentError: null,
                initiatePaymentSuccess: null,
                initiatePaymentLoading: true,
            }

        case types.INITIATE_PAYMENT_SUCCESS:
            return {
                ...state,
                initiatePaymentLoading: false,
                initiatePaymentSuccess: action.payload,
            }

        case types.INITIATE_PAYMENT_FAILURE:
            return {
                ...state,
                initiatePaymentLoading: false,
                initiatePaymentError: action.error,
            }

        case types.INITIATE_FIRS_PAYMENT:
            return {
                ...state,
                initiatePaymentError: null,
                initiatePaymentSuccess: null,
                initiatePaymentLoading: true,
            }

        case types.INITIATE_BULK_PAYMENT:
            return {
                ...state,
                initiateBulkPaymentError: null,
                initiateBulkPaymentSuccess: null,
                initiateBulkPaymentLoading: true,
            }

        case types.INITIATE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                initiateBulkPaymentLoading: false,
                initiateBulkPaymentSuccess: action.payload,
            }

        case types.INITIATE_BULK_PAYMENT_FAILURE:
            return {
                ...state,
                initiateBulkPaymentLoading: false,
                initiateBulkPaymentError: action.error,
            }

        default:
            return state;
    }
}
