import * as types from './paymentSearch.constant';

export function searchPaymentReport(params) {
    return {
        type: types.SEARCH_PAYMENT_REPORT,
        payload: params,
    }
}

export function searchPaymentReportSuccess(response) {
    return {
        type: types.SEARCH_PAYMENT_REPORT_SUCCESS,
        payload: response,
    }
}

export function searchPaymentReportFailure(error) {
    return {
        type: types.SEARCH_PAYMENT_REPORT_FAILURE,
        error,
    }
}

export function searchPaymentReportDownload(params) {
    return {
        type: types.SEARCH_PAYMENT_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function searchPaymentReportDownloadSuccess(response) {
    return {
        type: types.SEARCH_PAYMENT_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function searchPaymentReportDownloadFailure(error) {
    return {
        type: types.SEARCH_PAYMENT_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}
