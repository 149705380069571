import * as types from './bulkPayment.constant';

const initialState = {
    showBulkPayment: false,
}


export default function (state= initialState, action) {
    switch(action.type) {
        case types.OPEN_BULK_PAYMENT:
            return {
                ...state,
                showBulkPayment: true
            }

        case types.CLOSE_BULK_PAYMENT:
            return {
                ...state,
                showBulkPayment: false
            }

            default:
                return state;
    }
}