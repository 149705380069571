import * as types from '../redux/profile.constant';

const initialState = {
    userProfile: null,
    viewProfileError: null,
    viewProfileLoading: false,
    updateProfileError: null,
    updateProfileSuccess: null,
    updateProfileLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.VIEW_PROFILE:
            return {
                ...state,
                userProfile: null,
                viewProfileError: null,
                viewProfileLoading: true,
            }

        case types.VIEW_PROFILE_SUCCESS:
            return {
                ...state,
                viewProfileLoading: false,
                userProfile: action.payload,
            }
        
        case types.VIEW_PROFILE_FAILURE:
            return {
                ...state,
                viewProfileLoading: false,
                viewProfileError: action.error,
            }

        case types.UPDATE_PROFILE:
            return {
                ...state,
                updateProfileLoading: true,
                updateProfileSuccess: null,
                updateProfileError: null,
            }

        case types.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileSuccess: action.payload,
            }

        case types.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileError: action.error,
            }
        
        default:
            return state;
    }
}