import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/auditTrail';
import { isIswAdmin } from '../../../utils/helper';
import { GET_AUDIT_TRAIL, GET_AUDIT_TRAIL_DOWNLOAD, SEARCH_AUDIT_TRAIL ,SEARCH_AUDIT_TRAIL_DOWNLOAD} from './auditTrail.constant';
import { getAuditTrailSuccess, getAuditTrailFailure, getAuditTrailDownloadSuccess, getAuditTrailDownloadFailure, searchAuditTrailSuccess, searchAuditTrailFailure, searchAuditTrailDownloadSuccess, searchAuditTrailDownloadFailure } from './auditTrail.action';


async function getAuditTrailRequest(payload) {
    const options = endpoint.getAuditTrail(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function searchAuditTrailRequest(payload) {
    const options = endpoint.searchAuditTrail(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function iswAdminGetAuditTrailRequest(payload) {
    const options = endpoint.iswAdminGetAuditTrail(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function iswAdminSearchAuditTrailRequest(payload) {
    const options = endpoint.iswAdminSearchAuditTrail(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getAuditTrailWorker({ payload }) {
    try {
        let requestMethod = getAuditTrailRequest;

        if (isIswAdmin()) {
            requestMethod = iswAdminGetAuditTrailRequest;
        }

        const response = yield call(requestMethod, payload);
        yield put(getAuditTrailSuccess(response));
    } catch(err) {
        yield put(getAuditTrailFailure(err));
    }
}

function* getAuditTrailDownloadWorker({ payload }) {
    try {
        let requestMethod = getAuditTrailRequest;

        if (isIswAdmin()) {
            requestMethod = iswAdminGetAuditTrailRequest;
        }

        const response = yield call(requestMethod, payload);
        yield put(getAuditTrailDownloadSuccess(response.downloadUrl));
    } catch(err) {
        yield put(getAuditTrailDownloadFailure(err));
    }
}

function* searchAuditTrailWorker({ payload }) {
    try {
        let requestMethod = searchAuditTrailRequest;

        if (isIswAdmin()) {
            requestMethod = iswAdminSearchAuditTrailRequest;
        }

        const response = yield call(requestMethod, payload);
        yield put(searchAuditTrailSuccess(response));
    } catch(err) {
        yield put(searchAuditTrailFailure(err));
    }
}


function* searchAuditTrailDownloadWorker({ payload }) {
    try {
        let requestMethod = searchAuditTrailRequest;

        if (isIswAdmin()) {
            requestMethod = iswAdminSearchAuditTrailRequest;
        }

        const response = yield call(requestMethod, payload);
        yield put(searchAuditTrailDownloadSuccess(response.downloadUrl));
    } catch(err) {
        yield put(searchAuditTrailDownloadFailure(err));
    }
}


function* getAuditTrailWatcher() {
    yield takeLatest(GET_AUDIT_TRAIL, getAuditTrailWorker);
}

function* getAuditTrailDownloadWatcher() {
    yield takeLatest(GET_AUDIT_TRAIL_DOWNLOAD, getAuditTrailDownloadWorker);
}

function* searchAuditTrailWatcher() {
    yield takeLatest(SEARCH_AUDIT_TRAIL, searchAuditTrailWorker);
}

function* searchAuditTrailDownloadWatcher() {
    yield takeLatest(SEARCH_AUDIT_TRAIL_DOWNLOAD, searchAuditTrailDownloadWorker);
}


export default function*() {
    yield all([
        fork(getAuditTrailWatcher),
        fork(getAuditTrailDownloadWatcher),
        fork(searchAuditTrailWatcher),
        fork(searchAuditTrailDownloadWatcher),
    ]);
}
