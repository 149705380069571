import * as types from '../redux/profile.constant';

export function viewProfile() {
    return {
        type: types.VIEW_PROFILE,
    }
}

export function viewProfileSuccess(response) {
    return {
        type: types.VIEW_PROFILE_SUCCESS,
        payload: response,
    }
}

export function viewProfileFailure(error) {
    return {
        type: types.VIEW_PROFILE_FAILURE,
        error,
    }
}

export function updateProfile(payload) {
    return {
        type: types.UPDATE_PROFILE,
        payload,
    }
}

export function updateProfileSuccess(response) {
    return {
        type: types.UPDATE_PROFILE_SUCCESS,
        payload: response,
    }
}

export function updateProfileFailure(error) {
    return {
        type: types.UPDATE_PROFILE_FAILURE,
        error,
    }
}
