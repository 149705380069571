import * as types from './settlementParties.constant';

const initialState = {
    settlementParty: null,
    settlementPartyError: null,
    settlementPartyLoading: false,
    settlementParties: null,
    settlementPartiesError: null,
    settlementPartiesLoading: false,
    createSettlementPartyError: null,
    createSettlementPartySuccess: null,
    createSettlementPartyLoading: false,
    updateSettlementPartyError: null,
    updateSettlementPartySuccess: null,
    updateSettlementPartyLoading: false,
    updateSettlementPartyStatusError: null,
    updateSettlementPartyStatusSuccess: null,
    updateSettlementPartyStatusLoading: false,
    pendingSettlementPartyApproval: null,
    getPendingSettlementPartyApprovalError: null,
    getPendingSettlementPartyApprovalLoading: false,
    pendingSettlementPartiesApproval: null,
    pendingSettlementPartiesApprovalCount: null,
    getPendingSettlementPartiesApprovalError: null,
    getPendingSettlementPartiesApprovalLoading: false,
    createSettlementPartyApprovalVerdictError: null,
    createSettlementPartyApprovalVerdictSuccess: null,
    createSettlementPartyApprovalVerdictLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_SETTLEMENT_PARTY:
            return {
                ...state,
                settlementPartyLoading: true,
                settlementPartyError: null,
                settlementParty: null,
            }

        case types.GET_SETTLEMENT_PARTY_SUCCESS:
            return {
                ...state,
                settlementPartyLoading: false,
                settlementParty: action.payload,
            }

        case types.GET_SETTLEMENT_PARTY_FAILURE:
            return {
                ...state,
                settlementPartyLoading: false,
                settlementPartyError: action.error,
            }

        case types.GET_SETTLEMENT_PARTIES:
            return {
                ...state,
                settlementPartiesLoading: true,
                settlementPartiesError: null,
                settlementParties: null,
            }

        case types.GET_SETTLEMENT_PARTIES_SUCCESS:
            return {
                ...state,
                settlementPartiesLoading: false,
                settlementParties: action.payload,
            }

        case types.GET_SETTLEMENT_PARTIES_FAILURE:
            return {
                ...state,
                settlementPartiesLoading: false,
                settlementPartiesError: action.error,
            }

        case types.CREATE_SETTLEMENT_PARTY:
            return {
                ...state,
                createSettlementPartyError: null,
                createSettlementPartySuccess: null,
                createSettlementPartyLoading: true,
            }

        case types.CREATE_SETTLEMENT_PARTY_SUCCESS:
            return {
                ...state,
                createSettlementPartyLoading: false,
                createSettlementPartySuccess: action.payload,
            }

        case types.CREATE_SETTLEMENT_PARTY_FAILURE:
            return {
                ...state,
                createSettlementPartyLoading: false,
                createSettlementPartyError: action.error,
            }

        case types.UPDATE_SETTLEMENT_PARTY:
            return {
                ...state,
                updateSettlementPartyError: null,
                updateSettlementPartySuccess: null,
                updateSettlementPartyLoading: true,
            }

        case types.UPDATE_SETTLEMENT_PARTY_SUCCESS:
            return {
                ...state,
                updateSettlementPartyLoading: false,
                updateSettlementPartySuccess: action.payload,
            }

        case types.UPDATE_SETTLEMENT_PARTY_FAILURE:
            return {
                ...state,
                updateSettlementPartyLoading: false,
                updateSettlementPartyError: action.error,
            }

        case types.UPDATE_SETTLEMENT_PARTY_STATUS:
            return {
                ...state,
                updateSettlementPartyStatusError: null,
                updateSettlementPartyStatusSuccess: null,
                updateSettlementPartyStatusLoading: true,
            }

        case types.UPDATE_SETTLEMENT_PARTY_STATUS_SUCCESS:
            return {
                ...state,
                updateSettlementPartyStatusLoading: false,
                updateSettlementPartyStatusSuccess: action.payload,
            }

        case types.UPDATE_SETTLEMENT_PARTY_STATUS_FAILURE:
            return {
                ...state,
                updateSettlementPartyStatusLoading: false,
                updateSettlementPartyStatusError: action.error,
            }

        case types.GET_PENDING_SETTLEMENT_PARTY_APPROVAL:
            return {
                ...state,
                pendingSettlementPartyApproval: null,
                getPendingSettlementPartyApprovalError: null,
                getPendingSettlementPartyApprovalLoading: true,
            }

        case types.GET_PENDING_SETTLEMENT_PARTY_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingSettlementPartyApprovalLoading: false,
                pendingSettlementPartyApproval: action.payload,
            }

        case types.GET_PENDING_SETTLEMENT_PARTY_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingSettlementPartyApprovalLoading: false,
                getPendingSettlementPartyApprovalError: action.error,
            }

        case types.GET_PENDING_SETTLEMENT_PARTIES_APPROVAL:
            return {
                ...state,
                pendingSettlementPartiesApproval: null,
                getPendingSettlementPartiesApprovalError: null,
                getPendingSettlementPartiesApprovalLoading: true,
            }

        case types.GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingSettlementPartiesApprovalLoading: false,
                ...action.payload,
            }

        case types.GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingSettlementPartiesApprovalLoading: false,
                getPendingSettlementPartiesApprovalError: action.error,
            }

        case types.CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT:
            return {
                ...state,
                createSettlementPartyApprovalVerdictError: null,
                createSettlementPartyApprovalVerdictSuccess: null,
                createSettlementPartyApprovalVerdictLoading: true,
            }

        case types.CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createSettlementPartyApprovalVerdictLoading: false,
                createSettlementPartyApprovalVerdictSuccess: action.payload,
            }

        case types.CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createSettlementPartyApprovalVerdictLoading: false,
                createSettlementPartyApprovalVerdictError: action.error,
            }

        default:
            return state;
    }
}
