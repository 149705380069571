import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/approvals';
import { GET_PENDING_APPROVALS } from './pendingApprovals.constant';
import { getPendingApprovalsSuccess, getPendingApprovalsFailure } from './pendingApprovals.action';


async function getPendingApprovalsRequest({ businessId, userId }) {
    const options = endpoint.getUserPendingApprovals(businessId, userId);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPendingApprovalsWorker({ payload }) {
    try {
        const response = yield call(getPendingApprovalsRequest, payload);
        const data = response.responseData || [];
        yield put(getPendingApprovalsSuccess({
            pendingApprovals: data,
            pendingApprovalsCount: data.length,
        }));
    } catch(err) {
        yield put(getPendingApprovalsFailure(err));
    }
}


function* getPendingApprovalsWatcher() {
    yield takeLatest(GET_PENDING_APPROVALS, getPendingApprovalsWorker);
}


export default function*() {
    yield all([
        fork(getPendingApprovalsWatcher),
    ]);
}
