export const GET_CARD_BALANCE = 'GET_CARD_BALANCE';
export const GET_CARD_BALANCE_SUCCESS = 'GET_CARD_BALANCE_SUCCESS';
export const GET_CARD_BALANCE_FAILURE = 'GET_CARD_BALANCE_FAILURE';

export const GET_CARD_BALANCE_RESET = 'GET_CARD_BALANCE_RESET';

export const GET_SECURE_DATA = 'GET_SECURE_DATA';
export const GET_SECURE_DATA_SUCCESS = 'GET_SECURE_DATA_SUCCESS';
export const GET_SECURE_DATA_FAILURE = 'GET_SECURE_DATA_FAILURE';

export const CREATE_PAYMENT_INSTRUMENT = 'CREATE_PAYMENT_INSTRUMENT';
export const CREATE_PAYMENT_INSTRUMENT_RESET = 'CREATE_PAYMENT_INSTRUMENT_RESET';
export const CREATE_PAYMENT_INSTRUMENT_SUCCESS = 'CREATE_PAYMENT_INSTRUMENT_SUCCESS';
export const CREATE_PAYMENT_INSTRUMENT_FAILURE = 'CREATE_PAYMENT_INSTRUMENT_FAILURE';

export const UPDATE_PAYMENT_INSTRUMENT = 'UPDATE_PAYMENT_INSTRUMENT';
export const UPDATE_PAYMENT_INSTRUMENT_RESET = 'UPDATE_PAYMENT_INSTRUMENT_RESET';
export const UPDATE_PAYMENT_INSTRUMENT_SUCCESS = 'UPDATE_PAYMENT_INSTRUMENT_SUCCESS';
export const UPDATE_PAYMENT_INSTRUMENT_FAILURE = 'UPDATE_PAYMENT_INSTRUMENT_FAILURE';



