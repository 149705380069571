import * as types from './customerValidation.constant';

const initialState = {
    customerInfo: null,
    customerValidated: false,
    validateCustomerError: null,
    validateCustomerLoading: false,
    customerBulkValidated: false,
    validateCustomerBulkError: null,
    validateCustomerBulkSuccess: null,
    validateCustomerBulkLoading: false,
    bulkValidationStatus: null,
    bulkValidationStatusError: null,
    bulkValidationStatusLoading: false,
    paymentItems: null,
    paymentItemsError: null,
    paymentItemsLoading: false,
    customerValidationInfo: null,
    customerValidationInfoError: null,
    customerValidationInfoLoading: false,
    customerValidationSnapshot: null,
    showCustomerValidation: false,
    resetValidationForm: false,
    showPreviousBillerPayment: false,
    taxPaymentActive: false,
    downloadTemplateError: null,
    downloadValidationStatusError: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.VALIDATE_CUSTOMER:
            return {
                ...state,
                validateCustomerLoading: true,
                validateCustomerError: null,
                customerValidated: false,
                customerInfo: null,
            };

        case types.VALIDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                validateCustomerLoading: false,
                customerValidated: true,
                customerInfo: action.payload,
            };

        case types.VALIDATE_CUSTOMER_FAILURE:
            return {
                ...state,
                validateCustomerLoading: false,
                validateCustomerError: action.error,
            };

        case types.VALIDATE_CUSTOMER_BULK:
            return {
                ...state,
                validateCustomerBulkLoading: true,
                validateCustomerBulkError: null,
                customerInfo: null,
            };

        case types.VALIDATE_CUSTOMER_BULK_SUCCESS:
            return {
                ...state,
                validateCustomerBulkSuccess: action.payload,
                validateCustomerBulkLoading: false,
            };

        case types.VALIDATE_CUSTOMER_BULK_FAILURE:
            return {
                ...state,
                validateCustomerBulkLoading: false,
                validateCustomerBulkError: action.error,
            };

        case types.VALIDATE_CUSTOMER_BULK_TAX:
            return {
                ...state,
                validateCustomerBulkLoading: true,
                validateCustomerBulkError: null,
                customerInfo: null,
            };

        case types.VALIDATE_CUSTOMER_BULK_FIRS:
            return {
                ...state,
                validateCustomerBulkLoading: true,
                validateCustomerBulkError: null,
                customerInfo: null,
            };

        case types.UNDO_VALIDATED_CUSTOMER:
            return {
                ...state,
                validateCustomerBulkLoading: false,
                validateCustomerBulkError: null,
                customerBulkValidated: false,
                validateCustomerLoading: false,
                validateCustomerError: null,
                customerValidated: false,
                bulkValidationStatusLoading: false,
                bulkValidationStatusError: null,
                customerInfo: null,
            };

        case types.GET_BULK_VALIDATION_STATUS:
            return {
                ...state,
                bulkValidationStatusLoading: true,
                bulkValidationStatusError: null,
                customerBulkValidated: false,
                bulkValidationStatus: null,
            };

        case types.GET_BULK_VALIDATION_STATUS_SUCCESS:
            return {
                ...state,
                customerBulkValidated: true,
                bulkValidationStatusLoading: false,
                bulkValidationStatus: action.payload,
            };

        case types.GET_BULK_VALIDATION_STATUS_FAILURE:
            return {
                ...state,
                bulkValidationStatusLoading: false,
                bulkValidationStatusError: action.error,
            };

        case types.GET_PAYMENT_ITEMS:
            return {
                ...state,
                paymentItemsLoading: true,
                paymentItemsError: null,
                paymentItems: null,
            };

        case types.GET_PAYMENT_ITEMS_SUCCESS:
            return {
                ...state,
                paymentItemsLoading: false,
                paymentItems: action.payload,
            };

        case types.GET_PAYMENT_ITEMS_FAILURE:
            return {
                ...state,
                paymentItemsLoading: false,
                paymentItemsError: action.error,
            };

        case types.GET_CUSTOMER_VALIDATION_INFO:
            return {
                ...state,
                customerValidationInfoLoading: true,
                customerValidationInfoError: null,
                customerValidationInfo: null,
                customerValidated: false,
            };

        case types.GET_CUSTOMER_VALIDATION_INFO_SUCCESS:
            return {
                ...state,
                customerValidationInfoLoading: false,
                customerValidationInfo: action.payload,
            };

        case types.GET_CUSTOMER_VALIDATION_INFO_FAILURE:
            return {
                ...state,
                customerValidationInfoLoading: false,
                customerValidationInfoError: action.error,
            };

        case types.SAVE_CUSTOMER_VALIDATION_SNAPSHOT:
            return {
                ...state,
                customerValidationSnapshot: action.payload,
            };

        case types.REMOVE_CUSTOMER_VALIDATION_SNAPSHOT:
            return {
                ...state,
                customerValidationSnapshot: null,
            };

        case types.RESET_CUSTOMER_VALIDATION:
            return {
                ...state,
                resetValidationForm: true,
            };

        case types.UNRESET_CUSTOMER_VALIDATION:
            return {
                ...state,
                resetValidationForm: false,
            };

        case types.SHOW_ADD_PREVIOUS_BILLER_PAYMENT:
            return {
                ...state,
                showPreviousBillerPayment: true,
            };

        case types.HIDE_ADD_PREVIOUS_BILLER_PAYMENT:
            return {
                ...state,
                showPreviousBillerPayment: false,
            };

        case types.OPEN_CUSTOMER_VALIDATION:
            return {
                ...state,
                showCustomerValidation: true,
            };

        case types.CLOSE_CUSTOMER_VALIDATION:
            return {
                ...state,
                customerValidationSnapshot: null,
                showCustomerValidation: false,
                customerInfo: null,
            };

        case types.SET_TAX_PAYMENT_ACTIVE:
            return {
                ...state,
                taxPaymentActive: true,
            };

        case types.SET_TAX_PAYMENT_INACTIVE:
            return {
                ...state,
                taxPaymentActive: false,
            };

        case types.DOWNLOAD_TEMPLATE:
            return {
                ...state,
                downloadTemplateError: null,
            };

        case types.DOWNLOAD_TEMPLATE_FAILURE:
            return {
                ...state,
                downloadTemplateError: action.error,
            };

        case types.DOWNLOAD_VALIDATION_STATUS:
            return {
                ...state,
                downloadValidationStatusError: action.null,
            };

        case types.DOWNLOAD_VALIDATION_STATUS_FAILURE:
            return {
                ...state,
                downloadValidationStatusError: action.error,
            };

        default:
            return state;
    }
}
