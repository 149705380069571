import React from 'react';

const Fallback = (
    <div className="custom_loader_container">
        <div className="custom_loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Fallback;
