import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/payments';
import { GET_PAYMENT_UPLOADS } from './paymentUploads.constant';
import { getPaymentUploadsSuccess, getPaymentUploadsFailure } from './paymentUploads.action';

async function getPaymentUploadsRequest(payload) {
    const options = endpoint.getPaymentUploads(payload)
    return await request.make(options).then(res => res).catch(request.parseError)
}

function* getPaymentUploadsWorker({ payload }) {
    try{
        const response = yield call(getPaymentUploadsRequest, payload)
        yield put(getPaymentUploadsSuccess(response))
    } catch(err) {
        yield put(getPaymentUploadsFailure(err))
    }
}


function* getPaymentUploadsWatcher() {
    yield takeLatest(GET_PAYMENT_UPLOADS, getPaymentUploadsWorker);
}


export default function*() {
    yield all([
        fork(getPaymentUploadsWatcher)
    ])
}