import Billers from './makePayment/billers/redux/billers.reducer';
import CustomerValidation from './makePayment/customerValidation/redux/customerValidation.reducer';
import PaymentSetup from './makePayment/paymentSetup/redux/paymentSetup.reducer';
import Cart from './makePayment/cart/redux/cart.reducer';
import SavedPayments from './savedPayments/redux/savedPayments.reducer';
import PaymentUploads from './paymentUploads/redux/paymentUploads.reducer';
import BulkPayment from './makePayment/bulkPayment/redux/bulkPayment.reducer';
import InitiatePayment from './initiatePayment/redux/initiatePayment.reducer';
import PaymentInstrument from './paymentInstrument/redux/paymentInstrument.reducer';
import FirsTaxPayments from './taxPayments/firs/redux/firsTaxPayments.reducer';
import GeneralTaxPayments from './taxPayments/general/redux/generalTaxPayments.reducer';
import { combineReducers } from 'redux';

const paymentsReducer = combineReducers({
    rxBillers: Billers,
    rxCustomerValidation: CustomerValidation,
    rxPaymentSetup: PaymentSetup,
    rxCart: Cart,
    rxBulkPayment: BulkPayment,
    rxSavedPayments: SavedPayments,
    rxPaymentUploads: PaymentUploads,
    rxInitiatePayment: InitiatePayment,
    rxPaymentInstrument: PaymentInstrument,
    rxFirsTaxPayments: FirsTaxPayments,
    rxGeneralTaxPayments: GeneralTaxPayments
});

export default paymentsReducer;
