import * as types from './paymentSetup.constant';

export function getPaymentFee(payload) {
    return {
        type: types.GET_PAYMENT_FEE,
        payload,
    }
}

export function getPaymentFeeSuccess(response) {
    return {
        type: types.GET_PAYMENT_FEE_SUCCESS,
        payload: response,
    }
}

export function getPaymentFeeFailure(error) {
    return {
        type: types.GET_PAYMENT_FEE_FAILURE,
        error,
    }
}


export function openPaymentSetup() {
    return {
        type: types.OPEN_PAYMENT_SETUP,
    }
}

export function closePaymentSetup() {
    return {
        type: types.CLOSE_PAYMENT_SETUP,
    }
}
