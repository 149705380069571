import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/settings';
import { authenticatePaydirectUserSuccess, authenticatePaydirectUserFailure, createUserProfileSuccess, createUserProfileFailure } from './migrateBusiness.action';
import { AUTHENTICATE_PAYDIRECT_USER, CREATE_USER_PROFILE } from './migrateBusiness.constant';


async function authenticatePaydirectUserRequest(payload) {
    const options = endpoint.authenticatePaydirectUser(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createUserProfileRequest(payload) {
    const options = endpoint.createUserProfile(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}



function* authenticatePaydirectUserWorker({ payload }) {
    try {
        const response = yield call(authenticatePaydirectUserRequest, payload);
        yield put(authenticatePaydirectUserSuccess(response));
    } catch(err) {
        yield put(authenticatePaydirectUserFailure(err));
    }
}

function* createUserProfileWorker({ payload }) {
    try {
        const response = yield call(createUserProfileRequest, payload);
        yield put(createUserProfileSuccess(response));
    } catch(err) {
        yield put(createUserProfileFailure(err));
    }
}




function* authenticatePaydirectUserWatcher() {
    yield takeLatest(AUTHENTICATE_PAYDIRECT_USER, authenticatePaydirectUserWorker)
}

function* createUserProfileWatcher() {
    yield takeLatest(CREATE_USER_PROFILE, createUserProfileWorker)
}


export default function*() {
    yield all([
        fork(authenticatePaydirectUserWatcher),
        fork(createUserProfileWatcher),
    ]);
}