import * as types from './paymentNotifStatusReport.constant';

const initialState = {
    getPaymentNotifStatusReportParametersLoading: false,
    getPaymentNotifStatusReportParametersError: null,
    PaymentNotifStatusReportParameters: null,
    getPaymentNotifStatusReportLoading: false,
    getPaymentNotifStatusReportError: null,
    paymentNotifStatusReport: null,
    getPaymentNotifStatusReportDownloadLoading: false,
    getPaymentNotifStatusReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
    resendPaymentNotificationLoading: false,
    resendPaymentNotificationSuccess: null,
    resendPaymentNotificationError: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS:
            return {
                ...state,
                getPaymentNotifStatusReportParametersLoading: true,
                getPaymentNotifStatusReportParametersError: null,
                paymentNotifStatusReportParameters: null,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getPaymentNotifStatusReportParametersLoading: false,
                paymentNotifStatusReportParameters: action.payload,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getPaymentNotifStatusReportParametersLoading: false,
                getPaymentNotifStatusReportParametersError: action.error,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT:
            return {
                ...state,
                getPaymentNotifStatusReportLoading: true,
                getPaymentNotifStatusReportError: null,
                paymentNotifStatusReport: null,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_SUCCESS:
            return {
                ...state,
                getPaymentNotifStatusReportLoading: false,
                paymentNotifStatusReport: action.payload,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_FAILURE:
            return {
                ...state,
                getPaymentNotifStatusReportLoading: false,
                getPaymentNotifStatusReportError: action.error,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD:
            return {
                ...state,
                getPaymentNotifStatusReportDownloadLoading: true,
                getPaymentNotifStatusReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getPaymentNotifStatusReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getPaymentNotifStatusReportDownloadLoading: false,
                getPaymentNotifStatusReportDownloadError: action.error,
            }

        case types.TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD:
            return {
                ...state,
                getPaymentNotifStatusReportDownloadLoading: true,
                getPaymentNotifStatusReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getPaymentNotifStatusReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }

        case types.RESEND_PAYMENT_NOTIFICATION:
            return {
                ...state,
                resendPaymentNotificationLoading: true,
                resendPaymentNotificationSuccess: null,
                resendPaymentNotificationError: null,
            }

        case types.RESEND_PAYMENT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                resendPaymentNotificationLoading: false,
                resendPaymentNotificationSuccess: action.payload,
            }

        case types.RESEND_PAYMENT_NOTIFICATION_FAILURE:
            return {
                ...state,
                resendPaymentNotificationLoading: false,
                resendPaymentNotificationError: action.error,
            }
        
        default:
            return state;
    }
}
