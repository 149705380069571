import * as types from './configuration.constant';

const initialState = {
    approvalConfiguration: null,
    approvalConfigurationError: null,
    approvalConfigurationLoading: false,
    createApprovalConfigurationError: null,
    createApprovalConfigurationSuccess: null,
    createApprovalConfigurationLoading: false,
    editApprovalConfigurationError: null,
    editApprovalConfigurationSuccess: null,
    editApprovalConfigurationLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_APPROVAL_CONFIGURATION:
            return {
                ...state,
                approvalConfigurationLoading: true,
                approvalConfigurationError: null,
                approvalConfiguration: null,
            }

        case types.GET_APPROVAL_CONFIGURATION_SUCCESS:
            return {
                ...state,
                approvalConfigurationLoading: false,
                approvalConfiguration: action.payload,
            }

        case types.GET_APPROVAL_CONFIGURATION_FAILURE:
            return {
                ...state,
                approvalConfigurationLoading: false,
                approvalConfigurationError: action.error,
            }
        
        case types.CREATE_APPROVAL_CONFIGURATION:
            return {
                ...state,
                createApprovalConfigurationError: null,
                createApprovalConfigurationSuccess: null,
                createApprovalConfigurationLoading: true,
            }

        case types.CREATE_APPROVAL_CONFIGURATION_SUCCESS:
            return {
                ...state,
                createApprovalConfigurationLoading: false,
                createApprovalConfigurationSuccess: action.payload,
            }

        case types.CREATE_APPROVAL_CONFIGURATION_FAILURE:
            return {
                ...state,
                createApprovalConfigurationLoading: false,
                createApprovalConfigurationError: action.error,
            }

        case types.EDIT_APPROVAL_CONFIGURATION:
            return {
                ...state,
                editApprovalConfigurationError: null,
                editApprovalConfigurationSuccess: null,
                editApprovalConfigurationLoading: true,
            }

        case types.EDIT_APPROVAL_CONFIGURATION_SUCCESS:
            return {
                ...state,
                editApprovalConfigurationLoading: false,
                editApprovalConfigurationSuccess: action.payload,
            }

        case types.EDIT_APPROVAL_CONFIGURATION_FAILURE:
            return {
                ...state,
                editApprovalConfigurationLoading: false,
                editApprovalConfigurationError: action.error,
            }

        default:
            return state;
    }
}
