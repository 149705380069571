import moment from "moment";
import numeral from "numeral";
import CryptoJs from "crypto-js";

import { STORAGE_KEY, AUTH_USER, SECURE_KEY } from "../constants";

/*-------------------------------------------------------------------
                    STATE MANAGEMENT - GET && SET
--------------------------------------------------------------------*/
const _jwt = require("jwt-simple");
const _key = STORAGE_KEY;

export function getPersistedState() {
  try {
    const persistedState = sessionStorage.getItem(AUTH_USER);

    if (persistedState) return _jwt.decode(persistedState, _key);
    return undefined;
  } catch (e) {
    return undefined;
  }
}

export function persistState(state) {
  try {
    const data = _jwt.encode(state, _key);
    sessionStorage.setItem(AUTH_USER, data);
  } catch (e) {
    return;
  }
}

/*-------------------------------------------------------------------
                        GET AUTHENTICATED USER
--------------------------------------------------------------------*/
export function getUser() {
  const state = getPersistedState();

  if (state) {
    const { rxAuth } = state;
    const authUser = rxAuth && rxAuth.authUser;
    return authUser || {};
  }
  return {};
}

export function getUsername() {
  let username = '';
  const { user } = getUser();

  if (user) {
      if (user.firstName) {
          username += `${user.firstName} `;
      }

      if (user.lastName) {
          username += user.lastName;
      }
  }
  return username.trim();
}

/*-------------------------------------------------------------------
                            CLEAR APP DATA
--------------------------------------------------------------------*/
export function clearStorage() {
  try {
    sessionStorage.removeItem(AUTH_USER);
  } catch (e) {
    return;
  }
}

/*-------------------------------------------------------------------
                        VALIDATOR OBJECT
--------------------------------------------------------------------*/
export const validators = {
  minLength: (field, d) => {
    return String(field).length >= d;
  },
  maxLength: (field, d) => {
    return String(field).length <= d;
  },
  isNumeric: field => {
    return /^\d*$/.test(field);
  },
  isValidMobile: field => {
    return /^\d{10,15}$/.test(field);
  },
  isValidEmail: field => {
    return /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(field);
  },
  isValidName: field => {
    return /^[0-9a-zA-Z]+$/.test(field);
  },
  isNumberOnly: field => {
    return /^[0-9]*$/gm.test(field);
  },
  isMatched: (field, regex) => {
    return regex.test(field);
  }
};

/*-------------------------------------------------------------------
                        TABLES STATUS CLASS
--------------------------------------------------------------------*/
export const getStatusClass = (status) => {
  let useClass;

  switch(status) {
      case true :
          useClass = 'valid_item';
          break;
      default:
          useClass = 'invalid_item'
          break;
  }
  return useClass;
}

export const getApprovalStatusClass = (status) => {
  let useClass;

  switch(status.toLowerCase()) {
      case 'approved':
          useClass = 'valid_item';
          break;
      case 'rejected':
          useClass = 'invalid_item';
          break;
      default:
          useClass = 'pending_item'
          break;
  }
  return useClass;
}

/*-------------------------------------------------------------------
                        PARSE PERCENTAGE
--------------------------------------------------------------------*/
export function parsePercent(value) {
  if (!value) {
    return "-";
  }
  return value + '%';
}

/*-------------------------------------------------------------------
                        PARSE NUMBERS
--------------------------------------------------------------------*/
export function parseNumber(value) {
  let number = Number(value);

  if (isNaN(number)) {
     return '-'
  }

  if(number > 999 && number < 1000000){
    return Math.trunc((number/1000) * Math.pow(10, 1)) / Math.pow(10, 1) + 'K';
  }

  if(number > 1000000){
      return Math.trunc((number/1000000) * Math.pow(10, 1)) / Math.pow(10, 1) + 'M';
  }

  return value;
}

/*-------------------------------------------------------------------
                        PARSE DATE FN
--------------------------------------------------------------------*/
export function parseDate(value) {
  if (!value) {
    return "-";
  }

  if (!moment(value).isValid()) {
    return value;
  }

  return moment(value).format("MMMM Do YYYY, h:mm:ss a");
}

/*-------------------------------------------------------------------
                        STRING-TO-SENTENCE-CASE
--------------------------------------------------------------------*/
export function toSentenceCase(string) {
  const regex = /[A-Z]/;
  let result = "";

  if (!string) {
    return result;
  }

  for (let char of string) {
    result += regex.test(char) ? " " + char : char;
  }
  return result.slice(0, 1).toUpperCase() + result.slice(1);
}

/*-------------------------------------------------------------------
                PARSE MONEY AND CURRENCY
--------------------------------------------------------------------*/
export function parseMoney (amount, denomination = 'major') {
  if (isNaN(amount) && !amount) {
    return "-";
  }

  if (denomination === 'minor') {
    return "₦" + numeral(amount / 100).format("0,0.00");
  }

  return "₦" + numeral(amount).format("0,0.00");
}

export function parseCurrency(value) {
  const currencies = {
    "566": "Naira"
  };
  return currencies[value] || "-";
}

/*-------------------------------------------------------------------
                        PARSE CARD EXPIRY AND CARD PAN
--------------------------------------------------------------------*/
export function parseCardExpiry(value) {
  if (!value) {
    return "-";
  }

  const decryptedExpiry = decryptData(value);
  const month = decryptedExpiry.substr(2, 2);
  const year = decryptedExpiry.substr(0, 2);

  return (`${month}/${year}`)
}

export function unParseCardExpiry(value) {
  if (!value) {
    return "-";
  }

  const month = value.substr(0, 2);
  const year = value.substr(3, 2);

  return (`${year + month}`)
}

export function parseCardPan(value) {
  if (!value) {
    return "-";
  }

  return (`**** **** **** **** ${value}`)
}

/*-------------------------------------------------------------------
                        ENCRYPT AND DECRYPT DATA
--------------------------------------------------------------------*/
export function encryptData(value) {
  if (!value) {
    return "-";
  }

  const key = sessionStorage.getItem(SECURE_KEY);
  const keyBytes = CryptoJs.enc.Utf8.parse(key);
  const encryptedData = CryptoJs.TripleDES.encrypt(value, CryptoJs.MD5(keyBytes), { mode: CryptoJs.mode.ECB });
  return encryptedData.toString();
}

export function decryptData(value) {
  if (!value) {
    return "-";
  }

  const key = sessionStorage.getItem(SECURE_KEY);
  const keyBytes = CryptoJs.enc.Utf8.parse(key);
  const decryptedData = CryptoJs.TripleDES.decrypt(value, CryptoJs.MD5(keyBytes), { mode: CryptoJs.mode.ECB });
  return decryptedData.toString(CryptoJs.enc.Utf8);
}

/*-------------------------------------------------------------------
                        PERMISSIONS HELPER
--------------------------------------------------------------------*/
export function hasOneOfPermissions(dataString) {
  const permissionsToCheck = dataString.split(',');
  const { user: { role: { permissions = [] } = {} } = {} } = getUser();
  return permissionsToCheck.some((value) => permissions.map( item => item.permissionName).indexOf(value.trim()) > -1);
}

export function hasAllPermissions(dataString) {
  const permissionsToCheck = dataString.split(',');
  const { user: { role: { permissions = [] } = {} } = {} } = getUser();
  return permissionsToCheck.every((value) => permissions.map( item => item.permissionName).indexOf(value.trim()) > -1);
}

/*-------------------------------------------------------------------
                BUSINESS OWNER CHECK: hasBusinessOwner
--------------------------------------------------------------------*/
export function hasBusinessOwner() {
  const { business } = getUser();
  const businessOwner = business && business.userId;

  if (businessOwner === -1) {
    return false;
  }
  return true;
}

/*-------------------------------------------------------------------
                USER-TYPE CHECK: iswAdmin, BusinessOwner
--------------------------------------------------------------------*/
export function isIswAdmin() {
  const authUser = getUser();
  return authUser.userIsIswAdmin;
}

export function isBusinessOwner() {
  const authUser = getUser();
  return authUser.userIsBusinessOwner;
}

/*-------------------------------------------------------------------
                    PERMISSIONS - USER MANAGEMENT
--------------------------------------------------------------------*/
export function canActivateUserManagment() {
  return hasOneOfPermissions(
    "CreateUser, EditUser, DeleteUser, CreateRole, ViewUsers, ViewUser, UpdateUserRole, ViewRoles, ViewRole, UpdateRole, ViewPermissions, ResetPass,  ManageUserStatus, UpdateUserProfile, DeleteRole, IswCreateRole, IswViewRoles, IswViewRole, IswUpdateRole, IswViewPermissions, IswDeleteRole, IswCreateAdminRole, IswViewAdminRoles, IswViewAdminRole, IswUpdateAdminRole, IswViewAdminPermissions, IswDeleteAdminRole "
  );
}

export function canActivateUsers() {
  return hasOneOfPermissions(
    "CreateUser, EditUser, DeleteUser, ViewUsers, ViewUser, UpdateUserRole, ResetPass,  ManageUserStatus, UpdateUserProfile"
  );
}

export function canActivateRoles() {
  return hasOneOfPermissions(
    "CreateRole, ViewRoles, ViewRole, UpdateRole, ViewPermissions, DeleteRole, IswCreateRole, IswViewRoles, IswViewRole, IswUpdateRole, IswViewPermissions, IswDeleteRole, IswCreateAdminRole, IswViewAdminRoles, IswViewAdminRole, IswUpdateAdminRole, IswViewAdminPermissions, IswDeleteAdminRole"
  );
}

/*-------------------------------------------------------------------
                    PERMISSIONS - REPORT
--------------------------------------------------------------------*/
export function canActivateReport() {
  return hasOneOfPermissions("ViewAndDownloadReport");
}

/*-------------------------------------------------------------------
                PERMISSIONS - APPROVAL CONFIGURATION
--------------------------------------------------------------------*/
export function canActivateApprovalConfiguration() {
  return hasOneOfPermissions(
    "CreateApprovalConfig, UpdateApprovalLevels, ViewBusinessApproval"
  );
}

/*-------------------------------------------------------------------
                PERMISSIONS - APPROVAL CONFIGURATION
--------------------------------------------------------------------*/
export function canActivateUpdateApprovalStatus() {
  return hasOneOfPermissions("UpdateApprovalStatus");
}

/*-------------------------------------------------------------------
                PERMISSIONS - AUDIT TRAIL
--------------------------------------------------------------------*/
export function canActivateViewDownloadAuditTrail() {
  return hasOneOfPermissions("ViewAndDownloadAuditTrail");
}


/*-------------------------------------------------------------------
                PERMISSIONS - MANAGE PAYEMENT OPTIONS
--------------------------------------------------------------------*/
export function canActivatePaymentOptions() {
  return hasOneOfPermissions(
    "CreatePaymentProviderType, ViewPaymentProviderType, ChangePaymentProviderTypePin,"
  );
}

/*-------------------------------------------------------------------
                PERMISSIONS - FEE MANAGEMENT
--------------------------------------------------------------------*/
export function canActivateSettlementParties() {
  return hasOneOfPermissions(
    "IswCreateSettlementParty, IswUpdateSettlementParty, IswViewSettlementParty, IswApproveSettlementPartyRequest"
  );
}

export function canActivatePaymentInstruments() {
  return hasOneOfPermissions(
    "IswCreatePaymentInstrument, IswUpdatePaymentInstrument, IswViewPaymentInstrument, IswApprovePaymentInstrument"
  );
}

export function canActivateFeeConfigs() {
  return hasOneOfPermissions(
    "IswCreateFeeConfig, IswUpdateFeeConfig, IswViewFeeConfig, IswApproveFeeConfigRequest"
  );
}

export function canActivateBusinessFeeConfigs() {
  return hasOneOfPermissions(
    "IswCreateBusinessFeeConfig, IswUpdateBusinessFeeConfig, IswViewBusinessFeeConfig, IswApproveBusinessFeeConfig"
  );
}


/*-------------------------------------------------------------------
                    PERMISSIONS - ADMIN USER MANAGEMENT
--------------------------------------------------------------------*/
export function canActivateAdminUserManagment() {
  return hasOneOfPermissions(
    "IswCreateAdminUser, UpdateAdminUserProfile, IswViewAdminUser, IswViewAdminUsers, IswResetAdminPassword, IswUpdateAdminUserRole, IswManageAdminUserStatus, IswCreateAdminRole, IswViewAdminRoles, IswViewAdminRole, IswUpdateAdminRole, IswDeleteAdminRole, IswViewAdminPermissions, IswViewAdminRoleUsers"
  );
}

/*-------------------------------------------------------------------
                    PERMISSIONS - ADMIN REPORT
--------------------------------------------------------------------*/
export function canActivateAdminReport() {
  return hasOneOfPermissions("ISWViewAndDownloadRevenueShareReport");
}

/*-------------------------------------------------------------------
                PERMISSIONS - ADMIN AUDIT TRAIL
--------------------------------------------------------------------*/
export function canActivateAdminViewDownloadBusinessAuditTrail() {
  return hasOneOfPermissions("IswViewAndDownloadBusinessAuditTrail");
}

/*-------------------------------------------------------------------
                PERMISSIONS - ADMIN BUSINESSES
--------------------------------------------------------------------*/
export function canActivateAdminViewBuisnesses() {
  return hasOneOfPermissions("IswViewBusinesses");
}

/*-------------------------------------------------------------------
                PERMISSIONS - TRANSACTION LIMITS
--------------------------------------------------------------------*/
export function canActivateTransactionLimits() {
  return hasOneOfPermissions("IswCreateTransactionLimitConfig, IswViewTransactionLimitConfig, IswUpdateTransactionLimitConfig, IswApproveTransactionLimitConfig");
}

