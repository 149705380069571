import {
    getAllUsersRequest,
    adminGetAllAdminUsersRequest,
    adminGetAllBusinessUsersRequest,
    getPaginatedBusinessUsersRequest,
    adminGetPaginatedBusinessUsersRequest,

    createBusinessUserRequest,
    adminCreateAdminUserRequest,
    adminCreateBusinessUserRequest,

    editBusinessUserRequest,
    adminEditAdminUserRequest,

    resetBusinessUserPasswordRequest,
    adminResetAdminUserPasswordRequest,
    adminResetBusinessUserPasswordRequest,

    toggleBusinessUserRequest,
    adminToggleAdminUserRequest,
    adminToggleBusinessUserRequest,

    updateBusinessUserRoleRequest,
    adminUpdateAdminUserRoleRequest,
    adminUpdateBusinessUserRoleRequest } from "./users/redux/users.saga";

import {
    getAllBusinessRolesRequest,
    adminGetAllAdminRolesRequest,
    adminGetAllBusinessRolesRequest,
    getPaginatedBusinessRolesRequest,
    adminGetPaginatedAdminRolesRequest,
    adminGetPaginatedBusinessRolesRequest,

    getBusinessRoleUsersRequest,
    adminGetAdminRoleUsersRequest,

    getBusinessRoleRequest,
    adminGetAdminRoleRequest,
    adminGetBusinessRoleRequest,

    editBusinessRoleRequest,
    adminEditAdminRoleRequest,
    adminEditBusinessRoleRequest,

    createBusinessRoleRequest,
    adminCreateAdminRoleRequest,
    adminCreateBusinessRoleRequest,

    deleteBusinessRoleRequest,
    adminDeleteAdminRoleRequest,
    adminDeleteBusinessRoleRequest,

    getPermissionsRequest,
    adminGetPermissionsRequest,
    adminGetAdminPermissionsRequest,

    assignPermissionsToRoleRequest,
    adminAssignPermissionsToRoleRequest,
    adminAssignAdminPermissionsToRoleRequest } from "./roles/redux/roles.saga";



export const getRoleTypeId = (type) => {
    switch(type) {
        case 'adminUsers':
            return 2;
        default:
            return 1;
    }
}

export const checkRequestPayload = ({ isAdminUsers, businessId, pageNumber }) => ({
    actionScopeType: isAdminUsers ? 'admin' : businessId ? 'assisted' : 'business',
    isPaginated: pageNumber ? true : false
})

export const userMgtRequestsObj = {
    roles: {
        get: (isPaginated) => ({
            assisted: isPaginated ? adminGetPaginatedBusinessRolesRequest : adminGetAllBusinessRolesRequest,
            business: isPaginated ? getPaginatedBusinessRolesRequest : getAllBusinessRolesRequest,
            admin: isPaginated ? adminGetPaginatedAdminRolesRequest : adminGetAllAdminRolesRequest,
        }),
    },
    role: {
        get: {
            assisted: adminGetBusinessRoleRequest,
            business: getBusinessRoleRequest,
            admin: adminGetAdminRoleRequest,
        },
        edit: {
            assisted: adminEditBusinessRoleRequest,
            business: editBusinessRoleRequest,
            admin: adminEditAdminRoleRequest,
        },
        delete: {
            assisted: adminDeleteBusinessRoleRequest,
            business: deleteBusinessRoleRequest,
            admin: adminDeleteAdminRoleRequest,
        },
        create: {
            assisted: adminCreateBusinessRoleRequest,
            business: createBusinessRoleRequest,
            admin: adminCreateAdminRoleRequest,
        },
        getUsers: {
            assisted: getBusinessRoleUsersRequest,
            business: getBusinessRoleUsersRequest,
            admin: adminGetAdminRoleUsersRequest,
        },
    },
    permissions: {
        get: {
            assisted: adminGetPermissionsRequest,
            business: getPermissionsRequest,
            admin: adminGetAdminPermissionsRequest,
        },
        edit: {
            assisted: adminAssignPermissionsToRoleRequest,
            business: assignPermissionsToRoleRequest,
            admin: adminAssignAdminPermissionsToRoleRequest,
        },
    },

    users: {
        get: (isPaginated) => ({
            assisted: isPaginated ? adminGetPaginatedBusinessUsersRequest : adminGetAllBusinessUsersRequest,
            business: isPaginated ? getPaginatedBusinessUsersRequest : getAllUsersRequest,
            admin: adminGetAllAdminUsersRequest,
        }),
    },
    user: {
        create: {
            assisted: adminCreateBusinessUserRequest,
            business: createBusinessUserRequest,
            admin: adminCreateAdminUserRequest,
        },
        edit: {
            business: editBusinessUserRequest,
            admin: adminEditAdminUserRequest,
        },
        toggle: {
            assisted: adminToggleBusinessUserRequest,
            business: toggleBusinessUserRequest,
            admin: adminToggleAdminUserRequest,
        },
        resetPassword: {
            assisted: adminResetBusinessUserPasswordRequest,
            business: resetBusinessUserPasswordRequest,
            admin: adminResetAdminUserPasswordRequest,
        },
        updateRole: {
            assisted: adminUpdateBusinessUserRoleRequest,
            business: updateBusinessUserRoleRequest,
            admin: adminUpdateAdminUserRoleRequest,
        },
    },
}