import { all } from 'redux-saga/effects';
import commonReportSaga from './common/redux/common-report.saga';
import paymentSearchSaga from './paymentSearch/redux/paymentSearch.saga';
import paymentReportSaga from './paymentReport/redux/paymentReport.saga';
import transactionReportSaga from './transactionReport/redux/transactionReport.saga';
import paymentReversalReportSaga from './paymentReversalReport/redux/paymentReversalReport.saga';
import settlementSplitReportSaga from './settlementReport/split/redux/settlementSplitReport.saga';
import settlementDetailsReportSaga from './settlementReport/details/redux/settlementDetailsReport.saga';
import paymentNotifStatusReportSaga from './paymentNotifStatusReport/redux/paymentNotifStatusReport.saga';
import settlementPositionsReportSaga from './settlementReport/positions/redux/settlementPositionsReport.saga';


export default function*() {
    yield all([
        commonReportSaga(),
        paymentSearchSaga(),
        paymentReportSaga(),
        transactionReportSaga(),
        paymentReversalReportSaga(),
        settlementSplitReportSaga(),
        settlementDetailsReportSaga(),
        paymentNotifStatusReportSaga(),
        settlementPositionsReportSaga(),
    ]);
}
