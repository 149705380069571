import * as types from './settlementDetailsReport.constant';

export function getSettlementDetailsReportParameters(params) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getSettlementDetailsReportParametersSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getSettlementDetailsReportParametersFailure(error) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getSettlementDetailsReport(params) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT,
        payload: params,
    }
}

export function getSettlementDetailsReportSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_SUCCESS,
        payload: response,
    }
}

export function getSettlementDetailsReportFailure(error) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_FAILURE,
        error,
    }
}

export function getSettlementDetailsReportDownload(params) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getSettlementDetailsReportDownloadSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getSettlementDetailsReportDownloadFailure(error) {
    return {
        type: types.GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackSettlementDetailsReportDownload(params) {
    return {
        type: types.TRACK_SETTLEMENT_DETAILS_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackSettlementDetailsReportDownloadSuccess(response) {
    return {
        type: types.TRACK_SETTLEMENT_DETAILS_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}
