import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/reports';
import { GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS, GET_SETTLEMENT_DETAILS_REPORT, GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD, TRACK_SETTLEMENT_DETAILS_REPORT_DOWNLOAD } from './settlementDetailsReport.constant';
import { getSettlementDetailsReportParametersSuccess, getSettlementDetailsReportParametersFailure, getSettlementDetailsReportSuccess, getSettlementDetailsReportFailure, getSettlementDetailsReportDownloadSuccess, getSettlementDetailsReportDownloadFailure, trackSettlementDetailsReportDownloadSuccess } from './settlementDetailsReport.action';


async function getSettlementDetailsReportParametersRequest({productId, currencyCode}) {
    const options = endpoint.getSettlementDetailsReportParameters(productId, currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getSummarySettlementDetailsReportRequest(payload) {
    const options = endpoint.getSummarySettlementDetailsReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getDetailedSettlementDetailsReportRequest(payload) {
    const options = endpoint.getDetailedSettlementDetailsReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackSettlementDetailsReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}



function* getSettlementDetailsReportParametersWorker({ payload }) {
    try {
        const response = yield call(getSettlementDetailsReportParametersRequest, payload);
        yield put(getSettlementDetailsReportParametersSuccess(response.parameter));
    } catch(err) {
        yield put(getSettlementDetailsReportParametersFailure(err));
    }
}

function* getSettlementDetailsReportWorker({ payload }) {
    try {
        let request;

        if (payload['reportView'] === 'summary') {
            request = getSummarySettlementDetailsReportRequest;
        } else if (payload['reportView'] === 'detailed') {
            request = getDetailedSettlementDetailsReportRequest;
        }

        const response = yield call(request, payload);
        yield put(getSettlementDetailsReportSuccess(response));
    } catch(err) {
        yield put(getSettlementDetailsReportFailure(err));
    }
}

function* getSettlementDetailsReportDownloadWorker({ payload }) {
    try {
        let request;

        if (payload['reportView'] === 'summary') {
            request = getSummarySettlementDetailsReportRequest;
        } else if (payload['reportView'] === 'detailed') {
            request = getDetailedSettlementDetailsReportRequest;
        }
        
        const response = yield call(request, payload);
        yield put(getSettlementDetailsReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getSettlementDetailsReportDownloadFailure(err));
    }
}

function* trackSettlementDetailsReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackSettlementDetailsReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackSettlementDetailsReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getSettlementDetailsReportDownloadFailure(err));
    }
}



function* getSettlementDetailsReportParametersWatcher() {
    yield takeLatest(GET_SETTLEMENT_DETAILS_REPORT_PARAMETERS, getSettlementDetailsReportParametersWorker);
}

function* getSettlementDetailsReportWatcher() {
    yield takeLatest(GET_SETTLEMENT_DETAILS_REPORT, getSettlementDetailsReportWorker);
}

function* getSettlementDetailsReportDownloadWatcher() {
    yield takeLatest(GET_SETTLEMENT_DETAILS_REPORT_DOWNLOAD, getSettlementDetailsReportDownloadWorker);
}

function* trackSettlementDetailsReportDownloadWatcher() {
    yield takeLatest(TRACK_SETTLEMENT_DETAILS_REPORT_DOWNLOAD, trackSettlementDetailsReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getSettlementDetailsReportParametersWatcher),
        fork(getSettlementDetailsReportWatcher),
        fork(getSettlementDetailsReportDownloadWatcher),
        fork(trackSettlementDetailsReportDownloadWatcher)
    ]);
}
