import * as types from './paymentInstrument.constant';

const initialState = {
    makePaymentError: null,
    makePaymentSuccess: null,
    makePaymentLoading: false,
    providePaymentInstrumentError: null,
    providePaymentInstrumentSuccess: null,
    providePaymentInstrumentLoading: false,
    paymentInstrumentConfig: null,
    getPaymentInstrumentConfigError: null,
    getPaymentInstrumentConfigLoading: false,
    showPaymentInstrumentDialog: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_PAYMENT_INSTRUMENT_CONFIG:
            return {
                ...state,
                getPaymentInstrumentConfigLoading: true,
                getPaymentInstrumentConfigError: null,
                paymentInstrumentConfig: null,
            }

        case types.GET_PAYMENT_INSTRUMENT_CONFIG_SUCCESS:
            return {
                ...state,
                getPaymentInstrumentConfigLoading: false,
                paymentInstrumentConfig: action.payload,
            }

        case types.GET_PAYMENT_INSTRUMENT_CONFIG_FAILURE:
            return {
                ...state,
                getPaymentInstrumentConfigLoading: false,
                getPaymentInstrumentConfigError: action.error,
            }

        case types.PROVIDE_PAYMENT_INSTRUMENT:
            return {
                ...state,
                providePaymentInstrumentError: null,
                providePaymentInstrumentSuccess: null,
                providePaymentInstrumentLoading: true,
            }

        case types.PROVIDE_PAYMENT_INSTRUMENT_SUCCESS:
            return {
                ...state,
                providePaymentInstrumentLoading: false,
                providePaymentInstrumentSuccess: action.payload,
            }

        case types.PROVIDE_PAYMENT_INSTRUMENT_FAILURE:
            return {
                ...state,
                providePaymentInstrumentLoading: false,
                providePaymentInstrumentError: action.error,
            }

        case types.MAKE_PAYMENT:
            return {
                ...state,
                makePaymentError: null,
                makePaymentSuccess: null,
                makePaymentLoading: true,
            }

        case types.MAKE_PAYMENT_SUCCESS:
            return {
                ...state,
                makePaymentLoading: false,
                makePaymentSuccess: action.payload,
            }

        case types.MAKE_PAYMENT_FAILURE:
            return {
                ...state,
                makePaymentLoading: false,
                makePaymentError: action.error,
            }

        case types.SHOW_PAYMENT_INSTRUMENT_DIALOG:
            return {
                ...state,
                showPaymentInstrumentDialog: true,
            }

        case types.HIDE_PAYMENT_INSTRUMENT_DIALOG:
            return {
                ...state,
                showPaymentInstrumentDialog: false,
            }

        default:
            return state;
    }
}
