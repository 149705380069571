import * as types from './revenueShareReport.constant';

const initialState = {
    getRevenueShareReportLoading: false,
    getRevenueShareReportError: null,
    revenueShareReport: null,
    getRevenueShareReportDownloadLoading: false,
    getRevenueShareReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_REVENUE_SHARE_REPORT:
            return {
                ...state,
                getRevenueShareReportLoading: true,
                getRevenueShareReportError: null,
                feeReport: null,
            }

        case types.GET_REVENUE_SHARE_REPORT_SUCCESS:
            return {
                ...state,
                getRevenueShareReportLoading: false,
                feeReport: action.payload,
            }

        case types.GET_REVENUE_SHARE_REPORT_FAILURE:
            return {
                ...state,
                getRevenueShareReportLoading: false,
                getRevenueShareReportError: action.error,
            }

        case types.GET_REVENUE_SHARE_REPORT_DOWNLOAD:
            return {
                ...state,
                getRevenueShareReportDownloadLoading: true,
                getRevenueShareReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getRevenueShareReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_REVENUE_SHARE_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getRevenueShareReportDownloadLoading: false,
                getRevenueShareReportDownloadError: action.error,
            }

        case types.TRACK_REVENUE_SHARE_REPORT_DOWNLOAD:
            return {
                ...state,
                getRevenueShareReportDownloadLoading: true,
                getRevenueShareReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getRevenueShareReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }

        default:
            return state;
    }
}
