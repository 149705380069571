import * as types from './cart.constant';


export function addPaymentToCart(payload) {
    return {
        type: types.ADD_PAYMENT_TO_CART,
        payload,
    }
}

export function editPaymentInCart(payload) {
    return {
        type: types.EDIT_PAYMENT_IN_CART,
        payload,
    }
}

export function removePaymentFromCart(index) {
    return {
        type: types.REMOVE_PAYMENT_FROM_CART,
        payload: index,
    }
}


export function saveCartPayment(payload) {
    return {
        type: types.SAVE_CART_PAYMENT,
        payload,
    }
}
export function saveCartPaymentSuccess(response) {
    return {
        type: types.SAVE_CART_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function saveCartPaymentFailure(error) {
    return {
        type: types.SAVE_CART_PAYMENT_FAILURE,
        error,
    }
}


export function getSavedCartPayment(payload) {
    return {
        type: types.GET_SAVED_CART_PAYMENT,
        payload,
    }
}
export function getSavedCartPaymentSuccess(response) {
    return {
        type: types.GET_SAVED_CART_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function getSavedCartPaymentFailure(error) {
    return {
        type: types.GET_SAVED_CART_PAYMENT_FAILURE,
        error,
    }
}


export function editSavedCartPayment(payload) {
    return {
        type: types.EDIT_SAVED_CART_PAYMENT,
        payload,
    }
}
export function editSavedCartPaymentSuccess(response) {
    return {
        type: types.EDIT_SAVED_CART_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function editSavedCartPaymentFailure(error) {
    return {
        type: types.EDIT_SAVED_CART_PAYMENT_FAILURE,
        error,
    }
}


export function deleteSavedCartPayment(payload) {
    return {
        type: types.DELETE_SAVED_CART_PAYMENT,
        payload,
    }
}
export function deleteSavedCartPaymentSuccess(response) {
    return {
        type: types.DELETE_SAVED_CART_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function deleteSavedCartPaymentFailure(error) {
    return {
        type: types.DELETE_SAVED_CART_PAYMENT_FAILURE,
        error,
    }
}


export function setActivePayment(index) {
    return {
        type: types.SET_ACTIVE_PAYMENT,
        payload: index,
    }
}

export function unsetActivePayment() {
    return {
        type: types.UNSET_ACTIVE_PAYMENT,
    }
}


export function openCart() {
    return {
        type: types.OPEN_CART,
    }
}
export function closeCart() {
    return {
        type: types.CLOSE_CART,
    }
}


export function destroyCart() {
    return {
        type: types.DESTROY_CART,
    }
}
