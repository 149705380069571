import * as types from './shared.constant';

const initialState = {
    businessTypes: null,
    getBusinessTypesErr: null,
    isLoading: false,
    banksList: null,
    getBanksListError: null,
    getBanksListLoading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_BUSINESS_TYPES:
            return {
                ...state,
                isLoading: true,
                businessTypes: null,
                getBusinessTypesErr: null,
            }

        case types.GET_BUSINESS_TYPES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                businessTypes: action.payload
            }

        case types.GET_BUSINESS_TYPES_FAILURE:
            return {
                ...state,
                isLoading: false,
                getBusinessTypesErr: action.error,
            }

        case types.GET_BANKS_LIST:
            return {
                ...state,
                getBanksListLoading: true,
                getBanksListError: null,
                banksList: null,
            }

        case types.GET_BANKS_LIST_SUCCESS:
            return {
                ...state,
                getBanksListLoading: false,
                banksList: action.payload,
            }

        case types.GET_BANKS_LIST_FAILURE:
            return {
                ...state,
                getBanksListLoading: false,
                getBanksListError: action.error,
            }

        default:
            return state;
    }
}
