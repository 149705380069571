import * as types from './bizOwnerApprovalRequests.constant';

export function createBizOwnerApprovalVerdict(payload) {
    return {
        type: types.CREATE_BUSINESS_OWNER_APPROVAL_VERDICT,
        payload,
    }
}

export function createBizOwnerApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_BUSINESS_OWNER_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createBizOwnerApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_BUSINESS_OWNER_APPROVAL_VERDICT_FAILURE,
        error,
    }
}

