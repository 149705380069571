import * as types from './paymentUploads.constant';

const initialState = {
    paymentUploads: null,
    getPaymentUploadsError: null,
    getPaymentUploadsLoading: false,

}


export default function(state = initialState, action) {
    switch (action.type) {
        case types.GET_PAYMENT_UPLOADS:
            return {
                ...state,
                paymentUploads: null,
                getPaymentUploadsError: null,
                getPaymentUploadsLoading: true,
            }

        case types.GET_PAYMENT_UPLOADS_SUCCESS:
            return {
                ...state,
                paymentUploads: action.payload,
                getPaymentUploadsLoading: false,
            }

        case types.GET_PAYMENT_UPLOADS_FAILURE:
            return {
                ...state,
                getPaymentUploadsError: action.error,
                getPaymentUploadsLoading: false,
            }

        default:
            return state
    }
}