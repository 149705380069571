const BASE_URL = process.env.REACT_APP_BASE_URL + 'v1/';
const BASE_URL_USER = process.env.REACT_APP_BASE_URL_USER;
const BASE_URL_TRANS = process.env.REACT_APP_BASE_URL_TRANS;
const BASE_URL_UPLOADS = process.env.REACT_APP_BASE_URL_UPLOADS;


export default {
    getBillerCategories: () => ({
        baseUrl: BASE_URL,
        url: `products/categories`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    searchBillers: (page, size, search, category) => ({
        baseUrl: BASE_URL,
        url: `products/search?pageNumber=${page}&pageSize=${size}&q=${search}&categoryId=${category}&source=enterprise`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getBillerInfo: (productId) => ({
        baseUrl: BASE_URL,
        url: `products/productinfo?productid=${productId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getPaymentItems: (productId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/paymentitem?productid=${productId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getCustomerValidationInfo: (productId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/productInfo?productId=${productId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    validateCustomer: (payload, productId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/validation?Productid=${productId}`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getSavedPayments: (business, page, size, withDetails) => ({
        baseUrl: BASE_URL_TRANS,
        url: `saved/collections/business/${business}?pageNumber=${page}&pageSize=${size}&includeDetails=${withDetails}`,
        method: 'GET',
        auth: true,
    }),

    getSavedPayment: (id, business, withDetails) => ({
        baseUrl: BASE_URL_TRANS,
        url: `saved/collections/${id}/business/${business}?includeDetails=${withDetails}`,
        method: 'GET',
        auth: true,
    }),

    createSavedPayment: (payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `saved`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    editSavedPayment: (payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `saved`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    deleteSavedPayment: (payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `saved`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    initiatePayment: (payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `payments`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getPaymentInstrumentConfig: () => ({
        baseUrl: BASE_URL_USER,
        url: `paymentprovider/business`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    providePaymentMethod: (type) => ({
        baseUrl: BASE_URL_USER,
        url: `paymentprovider/type?Type=${type}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    makePayment: (transactionId, payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `transactions/${transactionId}/pay`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    downloadTemplate: (itemType, contentType) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `upload/template/${contentType}/${itemType}/download`,
        method: 'GET',
        auth: true,
        encode: true,
        download: true
    }),

    downloadValidationStatus: (batchId) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `upload/download/${batchId}/statusresult`,
        method: 'GET',
        auth: true,
        encode: true,
        download: true
    }),

    validateCustomerBulk: (payload, validationType) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `upload/uploadfile/billpayment/${validationType}`,
        method: 'POST',
        data: payload,
        upload: true,
        auth: true,
    }),

    validateCustomerBulkTax: (payload, contentType) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `billpayment/${contentType}/file`,
        method: 'POST',
        data: payload,
        upload: true,
        auth: true,
    }),

    validateCustomerBulkFirs: (payload, contentType, itemType) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `upload/${itemType}/${contentType}`,
        method: 'POST',
        data: payload,
        upload: true,
        auth: true,
    }),

    getBulkValidationStatus: ({ batchId, pageNumber, pageSize, status, taxType }) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `upload/${batchId}/status?PageSize=${pageSize}&PageNumber=${pageNumber}&Status=${status}&TaxType=${taxType}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getPaymentUploads: ({ userId, pageNumber, pageSize, status, productCode }) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `upload/user/uploads?PageSize=${pageSize}&PageNumber=${pageNumber}&Status=${status}&ProductCode=${productCode}`,
        method: 'POST',
        data: userId,
        auth: true,
    }),

    initiateBulkPayment: (payload, batchId, multitax) => ({
        baseUrl: BASE_URL_UPLOADS,
        url: `Upload/${batchId}/authorize`,
        method: 'POST',
        data: payload,
        auth: true,
        multitax
    }),

    getTaxTypes: (productId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/taxtypes?productId=${productId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getTaxLocations: (productId, taxTypeCode) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/stampduty/locations?productId=${productId}&taxTypeCode=${taxTypeCode}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    validateTin: (productId, payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/validateTin?productId=${productId}`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    validateDocumentNumber: (productId, documentNumber) => ({
        baseUrl: BASE_URL_TRANS,
        url: `flow/documentNumber?productId=${productId}&documentNumber=${documentNumber}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    initiateFirsPayment: (payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `payments/firs`,
        method: 'POST',
        data: payload,
        auth: true,
    }),
};
