import * as types from './configuration.constant';

export function getApprovalConfiguration() {
    return {
        type: types.GET_APPROVAL_CONFIGURATION,
    }
}

export function getApprovalConfigurationSuccess(response) {
    return {
        type: types.GET_APPROVAL_CONFIGURATION_SUCCESS,
        payload: response,
    }
}

export function getApprovalConfigurationFailure(error) {
    return {
        type: types.GET_APPROVAL_CONFIGURATION_FAILURE,
        error,
    }
}

export function createApprovalConfiguration(payload) {
    return {
        type: types.CREATE_APPROVAL_CONFIGURATION,
        payload,
    }
}

export function createApprovalConfigurationSuccess(response) {
    return {
        type: types.CREATE_APPROVAL_CONFIGURATION_SUCCESS,
        payload: response,
    }
}

export function createApprovalConfigurationFailure(error) {
    return {
        type: types.CREATE_APPROVAL_CONFIGURATION_FAILURE,
        error,
    }
}

export function editApprovalConfiguration(payload) {
    return {
        type: types.EDIT_APPROVAL_CONFIGURATION,
        payload,
    }
}

export function editApprovalConfigurationSuccess(response) {
    return {
        type: types.EDIT_APPROVAL_CONFIGURATION_SUCCESS,
        payload: response,
    }
}

export function editApprovalConfigurationFailure(error) {
    return {
        type: types.EDIT_APPROVAL_CONFIGURATION_FAILURE,
        error,
    }
}
