import { combineReducers } from 'redux';
import rxChangePassword from './changePassword/redux/changePassword.reducer';
import rxProfile from './profile/redux/profile.reducer';

const accountReducers = combineReducers({
    rxChangePassword,
    rxProfile,
});

export default accountReducers;
