export const GET_SETTLEMENT_PARTY = 'GET_SETTLEMENT_PARTY';
export const GET_SETTLEMENT_PARTY_SUCCESS = 'GET_SETTLEMENT_PARTY_SUCCESS';
export const GET_SETTLEMENT_PARTY_FAILURE = 'GET_SETTLEMENT_PARTY_FAILURE';

export const GET_SETTLEMENT_PARTIES = 'GET_SETTLEMENT_PARTIES';
export const GET_SETTLEMENT_PARTIES_SUCCESS = 'GET_SETTLEMENT_PARTIES_SUCCESS';
export const GET_SETTLEMENT_PARTIES_FAILURE = 'GET_SETTLEMENT_PARTIES_FAILURE';

export const CREATE_SETTLEMENT_PARTY = 'CREATE_SETTLEMENT_PARTY';
export const CREATE_SETTLEMENT_PARTY_SUCCESS = 'CREATE_SETTLEMENT_PARTY_SUCCESS';
export const CREATE_SETTLEMENT_PARTY_FAILURE = 'CREATE_SETTLEMENT_PARTY_FAILURE';

export const UPDATE_SETTLEMENT_PARTY = 'UPDATE_SETTLEMENT_PARTY';
export const UPDATE_SETTLEMENT_PARTY_SUCCESS = 'UPDATE_SETTLEMENT_PARTY_SUCCESS';
export const UPDATE_SETTLEMENT_PARTY_FAILURE = 'UPDATE_SETTLEMENT_PARTY_FAILURE';

export const UPDATE_SETTLEMENT_PARTY_STATUS = 'UPDATE_SETTLEMENT_PARTY_STATUS';
export const UPDATE_SETTLEMENT_PARTY_STATUS_SUCCESS = 'UPDATE_SETTLEMENT_PARTY_STATUS_SUCCESS';
export const UPDATE_SETTLEMENT_PARTY_STATUS_FAILURE = 'UPDATE_SETTLEMENT_PARTY_STATUS_FAILURE';

export const GET_PENDING_SETTLEMENT_PARTY_APPROVAL = 'GET_PENDING_SETTLEMENT_PARTY_APPROVAL';
export const GET_PENDING_SETTLEMENT_PARTY_APPROVAL_SUCCESS = 'GET_PENDING_SETTLEMENT_PARTY_APPROVAL_SUCCESS';
export const GET_PENDING_SETTLEMENT_PARTY_APPROVAL_FAILURE = 'GET_PENDING_SETTLEMENT_PARTY_APPROVAL_FAILURE';

export const GET_PENDING_SETTLEMENT_PARTIES_APPROVAL = 'GET_PENDING_SETTLEMENT_PARTIES_APPROVAL';
export const GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_SUCCESS = 'GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_SUCCESS';
export const GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_FAILURE = 'GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_FAILURE';

export const CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT = 'CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT';
export const CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_SUCCESS = 'CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_SUCCESS';
export const CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_FAILURE = 'CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_FAILURE';
