import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/approvals';
import { CREATE_BUSINESS_OWNER_APPROVAL_VERDICT } from './bizOwnerApprovalRequests.constant';
import { createBizOwnerApprovalVerdictSuccess, createBizOwnerApprovalVerdictFailure } from './bizOwnerApprovalRequests.action';


async function createBizOwnerApprovalVerdictRequest(payload) {
    const options = endpoint.createBizOwnerApprovalVerdict(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* createBizOwnerApprovalVerdictWorker({ payload }) {
    try {
        let response = yield call(createBizOwnerApprovalVerdictRequest, payload);
        yield put(createBizOwnerApprovalVerdictSuccess(response.responseMessage));
    } catch(err) {
        yield put(createBizOwnerApprovalVerdictFailure(err));
    }
}


function* createBizOwnerApprovalVerdictWatcher() {
    yield takeLatest(CREATE_BUSINESS_OWNER_APPROVAL_VERDICT, createBizOwnerApprovalVerdictWorker);
}


export default function*() {
    yield all([
        fork(createBizOwnerApprovalVerdictWatcher),
    ]);
}
