import { all } from 'redux-saga/effects';
import changePasswordSaga from './changePassword/redux/changePassword.saga';
import profileSaga from './profile/redux/profile.saga';

export default function*() {
    yield all([
        changePasswordSaga(),
        profileSaga(),
    ]);
}
