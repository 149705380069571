import * as types from './customerValidation.constant';

export function validateCustomer(payload) {
    return {
        type: types.VALIDATE_CUSTOMER,
        payload
    };
}

export function validateCustomerSuccess(response) {
    return {
        type: types.VALIDATE_CUSTOMER_SUCCESS,
        payload: response
    };
}

export function validateCustomerFailure(error) {
    return {
        type: types.VALIDATE_CUSTOMER_FAILURE,
        error
    };
}

export function getPaymentItems(payload) {
    return {
        type: types.GET_PAYMENT_ITEMS,
        payload
    };
}

export function getPaymentItemsSuccess(response) {
    return {
        type: types.GET_PAYMENT_ITEMS_SUCCESS,
        payload: response
    };
}

export function getPaymentItemsFailure(error) {
    return {
        type: types.GET_PAYMENT_ITEMS_FAILURE,
        error
    };
}

export function getCustomerValidationInfo(payload) {
    return {
        type: types.GET_CUSTOMER_VALIDATION_INFO,
        payload
    };
}

export function getCustomerValidationInfoSuccess(response) {
    return {
        type: types.GET_CUSTOMER_VALIDATION_INFO_SUCCESS,
        payload: response
    };
}

export function getCustomerValidationInfoFailure(error) {
    return {
        type: types.GET_CUSTOMER_VALIDATION_INFO_FAILURE,
        error
    };
}

export function saveCustomerValidationSnapshot(payload) {
    return {
        type: types.SAVE_CUSTOMER_VALIDATION_SNAPSHOT,
        payload
    };
}

export function removeCustomerValidationSnapshot() {
    return {
        type: types.REMOVE_CUSTOMER_VALIDATION_SNAPSHOT
    };
}

export function resetCustomerValidation() {
    return {
        type: types.RESET_CUSTOMER_VALIDATION
    };
}

export function unResetCustomerValidation() {
    return {
        type: types.UNRESET_CUSTOMER_VALIDATION
    };
}

export function undoValidatedCustomer() {
    return {
        type: types.UNDO_VALIDATED_CUSTOMER
    };
}

export function showAddPrevBillerPayment() {
    return {
        type: types.SHOW_ADD_PREVIOUS_BILLER_PAYMENT
    };
}

export function hideAddPrevBillerPayment() {
    return {
        type: types.HIDE_ADD_PREVIOUS_BILLER_PAYMENT
    };
}

export function openCustomerValidation() {
    return {
        type: types.OPEN_CUSTOMER_VALIDATION
    };
}

export function closeCustomerValidation() {
    return {
        type: types.CLOSE_CUSTOMER_VALIDATION
    };
}

export function setTaxPaymentActive() {
    return {
        type: types.SET_TAX_PAYMENT_ACTIVE
    }
}

export function setTaxPaymentInactive() {
    return {
        type: types.SET_TAX_PAYMENT_INACTIVE
    }
}

export function validateCustomerBulk(payload) {
    return {
        type: types.VALIDATE_CUSTOMER_BULK,
        payload
    };
}

export function validateCustomerBulkSuccess(response) {
    return {
        type: types.VALIDATE_CUSTOMER_BULK_SUCCESS,
        payload: response
    };
}

export function validateCustomerBulkFailure(error) {
    return {
        type: types.VALIDATE_CUSTOMER_BULK_FAILURE,
        error
    };
}

export function validateCustomerBulkTax(payload) {
    return {
        type: types.VALIDATE_CUSTOMER_BULK_TAX,
        payload
    };
}

export function validateCustomerBulkFirs(payload) {
    return {
        type: types.VALIDATE_CUSTOMER_BULK_FIRS,
        payload
    };
}

export function getBulkValidationStatus(params) {
    return {
        type: types.GET_BULK_VALIDATION_STATUS,
        payload: params
    };
}

export function getBulkValidationStatusSuccess(response) {
    return {
        type: types.GET_BULK_VALIDATION_STATUS_SUCCESS,
        payload: response
    };
}

export function getBulkValidationStatusFailure(error) {
    return {
        type: types.GET_BULK_VALIDATION_STATUS_FAILURE,
        error
    };
}

export function downloadTemplate(params) {
    return {
        type: types.DOWNLOAD_TEMPLATE,
        payload: params
    }
}

export function downloadTemplateFailure(error) {
    return {
        type: types.DOWNLOAD_TEMPLATE_FAILURE,
        error
    };
}


export function downloadValidationStatus(payload) {
    return {
        type: types.DOWNLOAD_VALIDATION_STATUS,
        payload
    }
}

export function downloadValidationStatusFailure(error) {
    return {
        type: types.DOWNLOAD_VALIDATION_STATUS_FAILURE,
        error
    };
}


