import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/userMgt';
import { checkRequestPayload, userMgtRequestsObj } from '../../_helper';
import { GET_ALL_ROLES, EDIT_ROLE, CREATE_ROLE, DELETE_ROLE, ASSIGN_PERMISSIONS_TO_ROLE, GET_PERMISSIONS, GET_ROLE, GET_ROLE_USERS } from './roles.constant';
import { getAllRolesSuccess, getAllRolesFailure, editRoleSuccess, editRoleFailure, createRoleSuccess, createRoleFailure, deleteRoleSuccess, deleteRoleFailure, assignPermissionsToRoleSuccess, assignPermissionsToRoleFailure, getPermissionsSuccess, getPermissionsFailure, getRoleSuccess, getRoleFailure, getRoleUsersSuccess, getRoleUsersFailure } from './roles.action';

export async function getAllBusinessRolesRequest() {
    const options = endpoint.getBusinessRoles();
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function getPaginatedBusinessRolesRequest({ pageNumber, pageSize, searchParam }) {
    const options = endpoint.searchBusinessRoles(pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAllAdminRolesRequest() {
    const options = endpoint.adminGetAdminRoles();
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetPaginatedAdminRolesRequest({ pageNumber, pageSize, roleTypeId, searchParam }) {
    const options = endpoint.adminSearchAdminRoles(pageNumber, pageSize, roleTypeId, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAllBusinessRolesRequest({ businessId }) {
    const options = endpoint.adminGetBusinessRoles(businessId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetPaginatedBusinessRolesRequest({ businessId, pageNumber, pageSize, searchParam }) {
    const options = endpoint.adminSearchBusinessRoles(businessId, pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function getBusinessRoleRequest({id}) {
    const options = endpoint.getBusinessRole(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetBusinessRoleRequest({id}) {
    const options = endpoint.adminGetBusinessRole(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAdminRoleRequest({id}) {
    const options = endpoint.adminGetAdminRole(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function getBusinessRoleUsersRequest({id}) {
    const options = endpoint.getBusinessRoleUsers(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAdminRoleUsersRequest({id}) {
    const options = endpoint.adminGetAdminRoleUsers(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function editBusinessRoleRequest(payload) {
    const options = endpoint.editBusinessRole(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminEditBusinessRoleRequest(payload) {
    const options = endpoint.adminEditBusinessRole(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminEditAdminRoleRequest(payload) {
    const options = endpoint.adminEditAdminRole(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function createBusinessRoleRequest(payload) {
    const options = endpoint.createBusinessRole(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminCreateBusinessRoleRequest(payload) {
    const options = endpoint.adminCreateBusinessRole(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminCreateAdminRoleRequest(payload) {
    const options = endpoint.adminCreateAdminRole(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function deleteBusinessRoleRequest({ id }) {
    const options = endpoint.deleteBusinessRole(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminDeleteBusinessRoleRequest({ id }) {
    const options = endpoint.adminDeleteBusinessRole({id});
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminDeleteAdminRoleRequest({ id }) {
    const options = endpoint.adminDeleteAdminRole(id);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function assignPermissionsToRoleRequest({id, data}) {
    const options = endpoint.assignPermissionstoRole(id, data);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminAssignPermissionsToRoleRequest({id, data}) {
    const options = endpoint.adminAssignPermissionstoRole(id, data);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminAssignAdminPermissionsToRoleRequest({id, data}) {
    const options = endpoint.adminAssignAdminPermissionstoRole(id, data);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function getPermissionsRequest() {
    const options = endpoint.getPermissions();
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetPermissionsRequest() {
    const options = endpoint.adminGetPermissions();
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAdminPermissionsRequest() {
    const options = endpoint.adminGetAdminPermissions();
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getRoleWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['role']['get'][actionScopeType];

        const response = yield call(requestMethod, payload);
        yield put(getRoleSuccess(response.role));
    } catch(err) {
        yield put(getRoleFailure(err));
    }
}

function* getRoleUsersWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['role']['getUsers'][actionScopeType];

        const response = yield call(requestMethod, payload);
        yield put(getRoleUsersSuccess(response.users));
    } catch(err) {
        yield put(getRoleUsersFailure(err));
    }
}

function* getAllRolesWorker({ payload }) {
    try {
        const { actionScopeType, isPaginated } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['roles']['get'](isPaginated)[actionScopeType];

        const response = yield call(requestMethod, payload);
        yield put(getAllRolesSuccess(response));
    } catch(err) {
        yield put(getAllRolesFailure(err));
    }
}

function* editRoleWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['role']['edit'][actionScopeType];

        const response = yield call(requestMethod, payload.data);
        const message = (response.responseDescription || 'Role edited successfully').toString();
        yield put(editRoleSuccess(message));
    } catch(err) {
        yield put(editRoleFailure(err));
    }
}

function* createRoleWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['role']['create'][actionScopeType];

        const response = yield call(requestMethod, payload);
        const message = (response.responseDescription || 'Role created successfully').toString();
        yield put(createRoleSuccess(message));
    } catch(err) {
        yield put(createRoleFailure(err));
    }
}

function* deleteRoleWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['role']['delete'][actionScopeType];

        const response = yield call(requestMethod, payload);
        const message = (response.responseDescription || 'Role deleted successfully').toString();
        yield put(deleteRoleSuccess(message));
    } catch(err) {
        yield put(deleteRoleFailure(err));
    }
}

function* assignPermissionsToRoleWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['permissions']['edit'][actionScopeType];

        const response = yield call(requestMethod, payload);
        const message = (response.responseDescription || 'Role updated successfully').toString();
        yield put(assignPermissionsToRoleSuccess(message));
    } catch(err) {
        yield put(assignPermissionsToRoleFailure(err));
    }
}

function* getPermissionsWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['permissions']['get'][actionScopeType];

        const response = yield call(requestMethod);
        yield put(getPermissionsSuccess(response.permissions));
    } catch(err) {
        yield put(getPermissionsFailure(err));
    }
}


function* getAllRolesWatcher() {
    yield takeLatest(GET_ALL_ROLES, getAllRolesWorker);
}

function* getRoleWatcher() {
    yield takeLatest(GET_ROLE, getRoleWorker);
}

function* getRoleUsersWatcher() {
    yield takeLatest(GET_ROLE_USERS, getRoleUsersWorker);
}

function* editRoleWatcher() {
    yield takeLatest(EDIT_ROLE, editRoleWorker);
}

function* createRoleWatcher() {
    yield takeLatest(CREATE_ROLE, createRoleWorker);
}

function* deleteRoleWatcher() {
    yield takeLatest(DELETE_ROLE, deleteRoleWorker);
}

function* assignPermissionsToRoleWatcher() {
    yield takeLatest(ASSIGN_PERMISSIONS_TO_ROLE, assignPermissionsToRoleWorker);
}

function* getPermissionsWatcher() {
    yield takeLatest(GET_PERMISSIONS, getPermissionsWorker);
}

export default function*() {
    yield all([
        fork(getAllRolesWatcher),
        fork(getRoleWatcher),
        fork(getRoleUsersWatcher),
        fork(editRoleWatcher),
        fork(createRoleWatcher),
        fork(deleteRoleWatcher),
        fork(assignPermissionsToRoleWatcher),
        fork(getPermissionsWatcher),
    ]);
}
