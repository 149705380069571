import { all } from 'redux-saga/effects';
import Billers from './makePayment/billers/redux/billers.saga';
import CustomerValidation from './makePayment/customerValidation/redux/customerValidation.saga';
import PaymentSetup from './makePayment/paymentSetup/redux/paymentSetup.saga';
import Cart from './makePayment/cart/redux/cart.saga';
import SavedPayments from './savedPayments/redux/savedPayments.saga';
import PaymentUploads from './paymentUploads/redux/paymentUploads.saga';
import InitiatePayment from './initiatePayment/redux/initiatePayment.saga';
import PaymentInstrument from './paymentInstrument/redux/paymentInstrument.saga';
import FirsTaxPayments from './taxPayments/firs/redux/firsTaxPayments.saga';
import GeneralTaxPayments from './taxPayments/general/redux/generalTaxPayments.saga'

export default function*() {
    yield all([
        Billers(),
        CustomerValidation(),
        PaymentSetup(),
        Cart(),
        SavedPayments(),
        PaymentUploads(),
        InitiatePayment(),
        PaymentInstrument(),
        FirsTaxPayments(),
        GeneralTaxPayments(),
    ]);
}
