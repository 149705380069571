import * as types from './nominateBusinessOwner.constant';

export function nominateBusinessOwner(payload) {
    return {
        type: types.NOMINATE_BUSINESS_OWNER,
        payload,
    }
}

export function nominateBusinessOwnerSuccess(response) {
    return {
        type: types.NOMINATE_BUSINESS_OWNER_SUCCESS,
        payload: response,
    }
}

export function nominateBusinessOwnerFailure(error) {
    return {
        type: types.NOMINATE_BUSINESS_OWNER_FAILURE,
        error
    }
}