import { combineReducers } from 'redux';
import rxRoles from './roles/redux/roles.reducer';
import rxUsers from './users/redux/users.reducer';

const userMgtReducers = combineReducers({
    rxRoles,
    rxUsers,
});

export default userMgtReducers;
