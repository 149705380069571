const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_REPORTS = process.env.REACT_APP_BASE_URL_REPORTS;

export default {
    getBankBranch: (bankId) => ({
        baseUrl: BASE_URL,
        url: `paymentreport/get/bankbranch?bankId=${bankId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    trackReportDownload: (trackingId) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `values/getdownload?trackingId=${trackingId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getPaymentReportParameters: (productId, currencyCode) => ({
        baseUrl: BASE_URL,
        url: `paymentreport/get/paymentreportdetails?productId=${productId}&currencyCode=${currencyCode}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getPaymentReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `paymentreport/get/paymentreport`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    searchPaymentReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `paymentsearchreport/payment-search`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getPaymentReversalReportParameters: () => ({
        baseUrl: BASE_URL_REPORTS,
        url: `paymentreversal/report/parameters`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getPaymentReversalReport: (payload) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `paymentreversal/report/view`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getPaymentNotifStatusReportParameters: (productId) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `paymentnotificationstatus/get/paymentnotificationdetails?productId=${productId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getPaymentNotifStatusReport: (payload) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `paymentnotificationstatus/get/paymentnotificationstatus`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    resendPaymentNotification: (productId, payload) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `paymentnotificationstatus/resetnotifications?productId=${productId}`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getTransactionReportParameters: (productId, currencyCode) => ({
        baseUrl: BASE_URL,
        url: `transactionreport/get/transactionreportdetails?productId=${productId}&currencyCode=${currencyCode}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getTransactionReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `transactionreport/get/transactionreport`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getSettlementSplitReportParameters: (currencyCode) => ({
        baseUrl: BASE_URL,
        url: `settlementreport/get/settlementsplit/details?currencyCode=${currencyCode}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getSettlementSplitReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `settlementreport/get/settlementsplitreport`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getSettlementDetailsReportParameters: (productId, currencyCode) => ({
        baseUrl: BASE_URL,
        url: `settlementreport/get/settlementdetails/parameters?currencyCode=${currencyCode}&productId=${productId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getSummarySettlementDetailsReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `settlementreport/get/settlementdetailsreport/summary`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getDetailedSettlementDetailsReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `settlementreport/get/settlementdetailsreport/detailed`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getSettlementPositionsReportParameters: (productId, currencyCode) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `settlement/position/type/report/parameters?productId=${productId}&currencyCode=${currencyCode}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getSettlementPositionsReport: (payload) => ({
        baseUrl: BASE_URL_REPORTS,
        url: `settlement/position/type/report/view`,
        method: 'POST',
        data: payload,
        auth: true,
    }),
};
