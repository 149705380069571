import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/userMgt';
import { checkRequestPayload, userMgtRequestsObj } from '../../_helper';
import { GET_ALL_USERS, CREATE_USER, EDIT_USER, TOGGLE_USER, UPDATE_USER_ROLE, RESET_USER_PASSWORD } from './users.constant';
import { getAllUsersSuccess, getAllUsersFailure, createUserSuccess, createUserFailure, editUserSuccess, editUserFailure, toggleUserSuccess, toggleUserFailure, updateUserRoleSuccess, updateUserRoleFailure, resetUserPasswordSuccess, resetUserPasswordFailure } from './users.action';

export async function getAllUsersRequest() {
    const options = endpoint.getBusinessUsers();
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAllBusinessUsersRequest({businessId}) {
    const options = endpoint.adminGetBusinessUsers(businessId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetAllAdminUsersRequest({pageNumber, pageSize, searchParam}) {
    const options = endpoint.adminSearchAllAdminUsers(pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminGetPaginatedBusinessUsersRequest({businessId, pageNumber, pageSize, searchParam}) {
    const options = endpoint.adminSearchBusinessUsers(businessId, pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function getPaginatedBusinessUsersRequest({pageNumber, pageSize, searchParam}) {
    const options = endpoint.searchBusinessUsers(pageNumber, pageSize, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function createBusinessUserRequest(payload) {
    const options = endpoint.createBusinessUser(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminCreateBusinessUserRequest(payload) {
    const options = endpoint.adminCreateBusinessUser(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminCreateAdminUserRequest(payload) {
    const options = endpoint.adminCreateAdminUser(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function editBusinessUserRequest(payload) {
    const options = endpoint.editBusinessUser(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminEditAdminUserRequest(payload) {
    const options = endpoint.adminEditAdminUser(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function toggleBusinessUserRequest({id, payload}) {
    const options = endpoint.toggleBusinessUser(id, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminToggleBusinessUserRequest({id, payload}) {
    const options = endpoint.adminToggleBusinessUser(id, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminToggleAdminUserRequest({id, payload}) {
    const options = endpoint.adminToggleAdminUser(id, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function updateBusinessUserRoleRequest({id, payload}) {
    const options = endpoint.updateBusinessUserRole(id, payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminUpdateBusinessUserRoleRequest({id, payload}) {
    const options = endpoint.adminUpdateBusinessUserRole(id, payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminUpdateAdminUserRoleRequest({id, payload}) {
    const options = endpoint.adminUpdateAdminUserRole(id, payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function resetBusinessUserPasswordRequest({data}) {
    const options = endpoint.resetBusinessUserPassword(data)
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminResetBusinessUserPasswordRequest({data}) {
    const options = endpoint.adminResetBusinessUserPassword(data)
    return await request.make(options).then(res => res).catch(request.parseError);
}

export async function adminResetAdminUserPasswordRequest({data}) {
    const options = endpoint.adminResetAdminUserPassword(data)
    return await request.make(options).then(res => res).catch(request.parseError);
}



function* getAllUsersWorker({ payload }) {
    try {
        const { actionScopeType, isPaginated } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['users']['get'](isPaginated)[actionScopeType];

        const response = yield call(requestMethod, payload);
        yield put(getAllUsersSuccess(response));
    } catch(err) {
        yield put(getAllUsersFailure(err));
    }
}

function* createUserWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['user']['create'][actionScopeType];

        const response = yield call(requestMethod, payload);
        const message = (response.responseDescription || 'User created successfully').toString();
        yield put(createUserSuccess(message));
    } catch(err) {
        yield put(createUserFailure(err));
    }
}

function* editUserWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['user']['edit'][actionScopeType];

        const response = yield call(requestMethod, payload);
        const message = (response.responseDescription || 'User updated successfully').toString();
        yield put(editUserSuccess(message));
    } catch(err) {
        yield put(editUserFailure(err));
    }
}

function* toggleUserWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['user']['toggle'][actionScopeType];

        yield call(requestMethod, payload);
        const message = ('User updated successfully').toString();
        yield put(toggleUserSuccess(message));
    } catch(err) {
        yield put(toggleUserFailure(err));
    }
}

function* updateUserRoleWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['user']['updateRole'][actionScopeType];

        yield call(requestMethod, payload);
        const message = ('User updated successfully').toString();
        yield put(updateUserRoleSuccess(message));
    } catch(err) {
        yield put(updateUserRoleFailure(err));
    }
}

function* resetUserPasswordWorker({ payload }) {
    try {
        const { actionScopeType } = checkRequestPayload(payload);
        const requestMethod = userMgtRequestsObj['user']['resetPassword'][actionScopeType];

        const response = yield call(requestMethod, payload);
        const message = (response.responseDescription || 'Success').toString();
        yield put(resetUserPasswordSuccess(message));
    } catch(err) {
        yield put(resetUserPasswordFailure(err));
    }
}



function* getAllUsersWatcher() {
    yield takeLatest(GET_ALL_USERS, getAllUsersWorker);
}

function* createUserWatcher() {
    yield takeLatest(CREATE_USER, createUserWorker);
}

function* editUserWatcher() {
    yield takeLatest(EDIT_USER, editUserWorker);
}

function* toggleUserWatcher() {
    yield takeLatest(TOGGLE_USER, toggleUserWorker);
}

function* updateUserRoleWatcher() {
    yield takeLatest(UPDATE_USER_ROLE, updateUserRoleWorker);
}

function* resetUserPasswordWatcher() {
    yield takeLatest(RESET_USER_PASSWORD, resetUserPasswordWorker);
}


export default function*() {
    yield all([
        fork(getAllUsersWatcher),
        fork(createUserWatcher),
        fork(editUserWatcher),
        fork(toggleUserWatcher),
        fork(updateUserRoleWatcher),
        fork(resetUserPasswordWatcher),
    ]);
}
