import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/userMgt';
import { VIEW_PROFILE, UPDATE_PROFILE } from './profile.constant';
import { viewProfileSuccess, viewProfileFailure, updateProfileSuccess, updateProfileFailure} from './profile.action';

async function viewProfileRequest() {
    const options = endpoint.getProfile();
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateProfileRequest(payload) {
    const options = endpoint.updateProfile(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}



function* viewProfileWorker() {
    try {
        const response = yield call(viewProfileRequest);
        yield put(viewProfileSuccess(response.user));
    } catch(err) {
        yield put(viewProfileFailure(err));
    }
}

function* updateProfileWorker({ payload }) {
    try {
        const response = yield call(updateProfileRequest, payload);
        const message = (response.responseDescription || 'Profile updated successfully')
        yield put(updateProfileSuccess(message));
    } catch(err) {
        yield put(updateProfileFailure(err));
    }
}


function* viewProfileWatcher() {
    yield takeLatest(VIEW_PROFILE, viewProfileWorker);
}

function* updateProfileWatcher() {
    yield takeLatest(UPDATE_PROFILE, updateProfileWorker);
}

export default function*() {
    yield all([
        fork(viewProfileWatcher),
        fork(updateProfileWatcher),
    ]);
}
