import * as types from './pendingBizOwnerApprovals.constant';

const initialState = {
    pendingBizOwnerApprovals: null,
    pendingBizOwnerApprovalsError: null,
    pendingBizOwnerApprovalsLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_PENDING_BUSINESS_OWNER_APPROVALS:
            return {
                ...state,
                pendingBizOwnerApprovalsLoading: true,
                pendingBizOwnerApprovalsError: null,
                pendingBizOwnerApprovals: null,
            }

        case types.GET_PENDING_BUSINESS_OWNER_APPROVALS_SUCCESS:
            return {
                ...state,
                pendingBizOwnerApprovalsLoading: false,
                pendingBizOwnerApprovals: action.payload,
            }

        case types.GET_PENDING_BUSINESS_OWNER_APPROVALS_FAILURE:
            return {
                ...state,
                pendingBizOwnerApprovalsLoading: false,
                pendingBizOwnerApprovalsError: action.error,
            }
        
        default:
            return state;
    }
}
