import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/payments';
import { GET_SAVED_PAYMENTS, GET_SAVED_PAYMENT, CREATE_SAVED_PAYMENT, EDIT_SAVED_PAYMENT, DELETE_SAVED_PAYMENT } from './savedPayments.constant';
import { getSavedPaymentsSuccess, getSavedPaymentsFailure, getSavedPaymentSuccess, getSavedPaymentFailure, createSavedPaymentSuccess, createSavedPaymentFailure, editSavedPaymentSuccess, editSavedPaymentFailure, deleteSavedPaymentSuccess, deleteSavedPaymentFailure } from './savedPayments.action';


async function getSavedPaymentsRequest({businessId, pageNumber, pageSize, withDetails}) {
    const options = endpoint.getSavedPayments(businessId, pageNumber, pageSize, withDetails);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getSavedPaymentRequest({id, businessId, withDetails}) {
    const options = endpoint.getSavedPayment(id, businessId, withDetails)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createSavedPaymentRequest(payload) {
    const options = endpoint.createSavedPayment(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function editSavedPaymentRequest(payload) {
    const options = endpoint.editSavedPayment(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function deleteSavedPaymentRequest(payload) {
    const options = endpoint.deleteSavedPayment(payload)
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getSavedPaymentsWorker({ payload }) {
    try {
        const response = yield call(getSavedPaymentsRequest, payload);
        yield put(getSavedPaymentsSuccess(response));
    } catch(err) {
        yield put(getSavedPaymentsFailure(err));
    }
}

function* getSavedPaymentWorker({ payload }) {
    try {
        const response = yield call(getSavedPaymentRequest, payload);
        yield put(getSavedPaymentSuccess(response));
    } catch(err) {
        yield put(getSavedPaymentFailure(err));
    }
}

function* createSavedPaymentWorker({ payload }) {
    try {
        yield call(createSavedPaymentRequest, payload);
        yield put(createSavedPaymentSuccess('Payment was successfully saved'));
    } catch(err) {
        yield put(createSavedPaymentFailure(err));
    }
}

function* editSavedPaymentWorker({ payload }) {
    try {
        yield call(editSavedPaymentRequest, payload);
        yield put(editSavedPaymentSuccess('Record was successfully updated'));
    } catch(err) {
        yield put(editSavedPaymentFailure(err));
    }
}

function* deleteSavedPaymentWorker({ payload }) {
    try {
        yield call(deleteSavedPaymentRequest, payload);
        yield put(deleteSavedPaymentSuccess('Record was successfully deleted'));
    } catch(err) {
        yield put(deleteSavedPaymentFailure(err));
    }
}


function* getSavedPaymentsWatcher() {
    yield takeLatest(GET_SAVED_PAYMENTS, getSavedPaymentsWorker);
}

function* getSavedPaymentWatcher() {
    yield takeLatest(GET_SAVED_PAYMENT, getSavedPaymentWorker);
}

function* createSavedPaymentWatcher() {
    yield takeLatest(CREATE_SAVED_PAYMENT, createSavedPaymentWorker);
}

function* editSavedPaymentWatcher() {
    yield takeLatest(EDIT_SAVED_PAYMENT, editSavedPaymentWorker);
}

function* deleteSavedPaymentWatcher() {
    yield takeLatest(DELETE_SAVED_PAYMENT, deleteSavedPaymentWorker);
}


export default function*() {
    yield all([
        fork(getSavedPaymentsWatcher),
        fork(getSavedPaymentWatcher),
        fork(createSavedPaymentWatcher),
        fork(editSavedPaymentWatcher),
        fork(deleteSavedPaymentWatcher),
    ]);
}
