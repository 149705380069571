export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';

export const GET_ROLE_USERS = 'GET_ROLE_USERS';
export const GET_ROLE_USERS_SUCCESS = 'GET_ROLE_USERS_SUCCESS';
export const GET_ROLE_USERS_FAILURE = 'GET_ROLE_USERS_FAILURE';

export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAILURE = 'GET_ALL_ROLES_FAILURE';

export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const ASSIGN_PERMISSIONS_TO_ROLE = 'ASSIGN_PERMISSIONS_TO_ROLE';
export const ASSIGN_PERMISSIONS_TO_ROLE_FAILURE = 'ASSIGN_PERMISSIONS_TO_ROLE_FAILURE';
export const ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS = 'ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS';

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
