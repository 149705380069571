import * as types from './paymentReport.constant';

const initialState = {
    getPaymentReportParametersLoading: false,
    getPaymentReportParametersError: null,
    paymentReportParameters: null,
    getPaymentReportLoading: false,
    getPaymentReportError: null,
    paymentReport: null,
    getPaymentReportDownloadLoading: false,
    getPaymentReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_PAYMENT_REPORT_PARAMETERS:
            return {
                ...state,
                getPaymentReportParametersLoading: true,
                getPaymentReportParametersError: null,
                paymentReportParameters: null,
            }

        case types.GET_PAYMENT_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getPaymentReportParametersLoading: false,
                paymentReportParameters: action.payload,
            }

        case types.GET_PAYMENT_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getPaymentReportParametersLoading: false,
                getPaymentReportParametersError: action.error,
            }

        case types.GET_PAYMENT_REPORT:
            return {
                ...state,
                getPaymentReportLoading: true,
                getPaymentReportError: null,
                paymentReport: null,
            }

        case types.GET_PAYMENT_REPORT_SUCCESS:
            return {
                ...state,
                getPaymentReportLoading: false,
                paymentReport: action.payload,
            }

        case types.GET_PAYMENT_REPORT_FAILURE:
            return {
                ...state,
                getPaymentReportLoading: false,
                getPaymentReportError: action.error,
            }

        case types.GET_PAYMENT_REPORT_DOWNLOAD:
            return {
                ...state,
                getPaymentReportDownloadLoading: true,
                getPaymentReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_PAYMENT_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getPaymentReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_PAYMENT_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getPaymentReportDownloadLoading: false,
                getPaymentReportDownloadError: action.error,
            }

        case types.TRACK_PAYMENT_REPORT_DOWNLOAD:
            return {
                ...state,
                getPaymentReportDownloadLoading: true,
                getPaymentReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_PAYMENT_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getPaymentReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }
        
        default:
            return state;
    }
}
