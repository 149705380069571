import React from 'react'
import side from '../../Assets/sideicon.svg'
import id from '../../Assets/idCard.svg'
import status from '../../Assets/status.svg'
import learn from '../../Assets/learn.svg'
import right from '../../Assets/CaretRight.svg'
import min from '../../Assets/Icon.svg'
import classes from './Transaction.module.css'
import { Link } from 'react-router-dom';

const TransactionSide = () => {
  return (
    <aside className={classes.sideContainer}>
        <div className={classes.sideContainer1}>
            <img style={{width:20, alignSelf:'flex-end', margin:'5px 0', cursor:'pointer'}} src={min} alt="close button" />
            <p className={classes.sideHeader}>Do More with Quickteller <br/> Corporate</p>
            <p className={classes.sideText}>Check your transaction status, create a Quickteller Corporate account etc.</p>
            <img style={{width:'40%', alignSelf:'flex-end',}} src={side} alt="system" />
        </div>
        <a href='#.com' target="_blank" rel="noopener noreferrer" className={classes.sideContainer2}>
            <img src={learn} alt="identification Card" />
            <div className={classes.sideTextContainer} >
                <p className={classes.sideContainer2Text1}>Check Transaction Status</p>
                <p className={classes.sideContainer2Text2}>Check the status of a previous transaction. All you need is the reference number</p>
            </div>
            <img src={right} alt="caret right" />
        </a>
        <a href='#.com' target="_blank" rel="noopener noreferrer" className={classes.sideContainer2}>
            <img src={status} alt="identification Card" />
            <div className={classes.sideTextContainer} >
                <p className={classes.sideContainer2Text1}>Learn More About Quickteller Corporate</p>
                <p className={classes.sideContainer2Text2}>Learn more about what Quickteller Corporate offers to customers</p>
            </div>
            <img src={right} alt="caret right" />
        </a>
        <Link to='/login' target="_blank" rel="noopener noreferrer" className={classes.sideContainer2}>
            <img src={id} alt="identification Card" />
            <div className={classes.sideTextContainer} >
                <p className={classes.sideContainer2Text1}>Create a Quickteller Corporate Account</p>
            </div>
            <img src={right} alt="caret right" />
        </Link>
    </aside>
  )
}

export default TransactionSide