import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../utils/request';
import endpoint from '../../../uris/transactions';
import { GET_ALL_TRANSACTIONS_LIST_DOWNLOAD, TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD, GET_ALL_TRANSACTIONS_LIST, GET_SINGLE_TRANSACTIONS_LIST, GET_BATCH_TRANSACTIONS_LIST, GET_TRANSACTIONS_AWAITING_PAYMENT, GET_TRANSACTION_PAYMENTS, GET_TRANSACTION_BATCH, GET_TRANSACTION_BATCH_RECEIPT_LINK } from './transactions.constant';
import { getAllTransactionsListSuccess, getAllTransactionsListFailure, getSingleTransactionsListSuccess, getSingleTransactionsListFailure, getBatchTransactionsListSuccess, getBatchTransactionsListFailure, getTransactionsAwaitingPaymentSuccess, getTransactionsAwaitingPaymentFailure, getTransactionPaymentsSuccess, getTransactionPaymentsFailure, getTransactionBatchSuccess, getTransactionBatchFailure, getTransactionBatchReceiptLinkSuccess, getTransactionBatchReceiptLinkFailure, getAllTransactionsListDownloadSuccess, getAllTransactionsListDownloadFailure, trackAllTransactionsListDownloadSuccess } from './transactions.action';

async function getAllTransactionsListRequest(payload) {
    const options = endpoint.getAllTransactionsList(payload);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getSingleTransactionsListRequest({ businessId, pageNumber, pageSize, searchParam }) {
    const options = endpoint.getSingleTransactionsList(businessId, pageNumber, pageSize, searchParam);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getBatchTransactionsListRequest({ businessId, pageNumber, pageSize, searchParam }) {
    const options = endpoint.getBatchTransactionsList(businessId, pageNumber, pageSize, searchParam);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getTransactionsAwaitingPaymentRequest({ userId }) {
    const options = endpoint.getUserTransactionsPendingPayment(userId);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getTransactionPaymentsRequest({ transactionId, pageNumber, pageSize }) {
    const options = endpoint.getTransactionPayments(transactionId, pageNumber, pageSize);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getTransactionBatchRequest({ businessId, transactionId, pageNumber, pageSize }) {
    const options = endpoint.getTransactionBatch(businessId, transactionId, pageNumber, pageSize);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function getTransactionBatchReceiptLinkRequest({ businessId, transactionId }) {
    const options = endpoint.getTransactionBatchReceiptLink(businessId, transactionId);
    return await request.make(options).then((res) => res).catch(request.parseError);
}

async function trackAllTransactionsListRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getAllTransactionsListWorker({ payload }) {
    try {
        const response = yield call(getAllTransactionsListRequest, payload);
        yield put(getAllTransactionsListSuccess(response));
    } catch (err) {
        yield put(getAllTransactionsListFailure(err));
    }
}

function* getSingleTransactionsListWorker({ payload }) {
    try {
        const response = yield call(getSingleTransactionsListRequest, payload);
        yield put(getSingleTransactionsListSuccess(response));
    } catch (err) {
        yield put(getSingleTransactionsListFailure(err));
    }
}

function* getBatchTransactionsListWorker({ payload }) {
    try {
        const response = yield call(getBatchTransactionsListRequest, payload);
        yield put(getBatchTransactionsListSuccess(response));
    } catch (err) {
        yield put(getBatchTransactionsListFailure(err));
    }
}

function* getTransactionsAwaitingPaymentWorker({ payload }) {
    try {
        const response = yield call(getTransactionsAwaitingPaymentRequest, payload);
        const data = response.transactions || [];
        yield put(
            getTransactionsAwaitingPaymentSuccess({
                transactionsAwaitingPaymentSuccess: data,
                transactionsAwaitingPaymentSuccessCount: data.length,
            })
        );
    } catch (err) {
        yield put(getTransactionsAwaitingPaymentFailure(err));
    }
}

function* getTransactionPaymentsWorker({ payload }) {
    try {
        const response = yield call(getTransactionPaymentsRequest, payload);
        yield put(getTransactionPaymentsSuccess(response));
    } catch (err) {
        yield put(getTransactionPaymentsFailure(err));
    }
}

function* getTransactionBatchWorker({ payload }) {
    try {
        const response = yield call(getTransactionBatchRequest, payload);
        yield put(getTransactionBatchSuccess(response));
    } catch (err) {
        yield put(getTransactionBatchFailure(err));
    }
}

function* getTransactionBatchReceiptLinkWorker({ payload }) {
    try {
        const response = yield call(getTransactionBatchReceiptLinkRequest, payload);
        const fileURL = response.batchReceiptUrl;
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.target = '_blank';
        document.body.appendChild(fileLink);
        fileLink.click();

        yield put(getTransactionBatchReceiptLinkSuccess(response.batchReceiptUrl));
    } catch (err) {
        yield put(getTransactionBatchReceiptLinkFailure(err));
    }
}

function* getAllTransactionsListDownloadWorker({ payload }) {
    try {
        const response = yield call(getAllTransactionsListRequest, payload);
        yield put(getAllTransactionsListDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getAllTransactionsListDownloadFailure(err));
    }
}

function* trackAllTransactionsListDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackAllTransactionsListRequest, payload);
            if (response.downloadLink) {
                yield put(trackAllTransactionsListDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getAllTransactionsListDownloadFailure(err));
    }
}


function* getAllTransactionsListWatcher() {
    yield takeLatest(GET_ALL_TRANSACTIONS_LIST, getAllTransactionsListWorker);
}

function* getSingleTransactionsListWatcher() {
    yield takeLatest(GET_SINGLE_TRANSACTIONS_LIST, getSingleTransactionsListWorker);
}

function* getBatchTransactionsListWatcher() {
    yield takeLatest(GET_BATCH_TRANSACTIONS_LIST, getBatchTransactionsListWorker);
}

function* getTransactionsAwaitingPaymentWatcher() {
    yield takeLatest(GET_TRANSACTIONS_AWAITING_PAYMENT, getTransactionsAwaitingPaymentWorker);
}

function* getTransactionsPaymentsWatcher() {
    yield takeLatest(GET_TRANSACTION_PAYMENTS, getTransactionPaymentsWorker);
}

function* getTransactionBatchWatcher() {
    yield takeLatest(GET_TRANSACTION_BATCH, getTransactionBatchWorker);
}

function* getTransactionBatchReceiptLinkWatcher() {
    yield takeLatest(GET_TRANSACTION_BATCH_RECEIPT_LINK, getTransactionBatchReceiptLinkWorker);
}

function* getAllTransactionsListDownloadWatcher() {
    yield takeLatest(GET_ALL_TRANSACTIONS_LIST_DOWNLOAD, getAllTransactionsListDownloadWorker);
}

function* trackAllTransactionsListDownloadWatcher() {
    yield takeLatest(TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD, trackAllTransactionsListDownloadWorker);
}

export default function* () {
    yield all([
        fork(getAllTransactionsListWatcher),
        fork(getSingleTransactionsListWatcher),
        fork(getBatchTransactionsListWatcher),
        fork(getTransactionsPaymentsWatcher),
        fork(getTransactionBatchWatcher),
        fork(getTransactionBatchReceiptLinkWatcher),
        fork(getTransactionsAwaitingPaymentWatcher),
        fork(getAllTransactionsListDownloadWatcher),
        fork(trackAllTransactionsListDownloadWatcher),
    ]);
}
