import * as types from './savedPayments.constant';

export function hideAlertSavedPaymentsMessage() {
    return {
        type: types.HIDE_ALERT_SAVED_PAYMENTS_MESSAGE
    }
}

export function getSavedPayments(params) {
    return {
        type: types.GET_SAVED_PAYMENTS,
        payload: params,
    }
}
export function getSavedPaymentsSuccess(response) {
    return {
        type: types.GET_SAVED_PAYMENTS_SUCCESS,
        payload: response,
    }
}
export function getSavedPaymentsFailure(error) {
    return {
        type: types.GET_SAVED_PAYMENTS_FAILURE,
        error,
    }
}

export function getSavedPayment(params) {
    return {
        type: types.GET_SAVED_PAYMENT,
        payload: params,
    }
}
export function getSavedPaymentSuccess(response) {
    return {
        type: types.GET_SAVED_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function getSavedPaymentFailure(error) {
    return {
        type: types.GET_SAVED_PAYMENT_FAILURE,
        error,
    }
}

export function createSavedPayment(payload) {
    return {
        type: types.CREATE_SAVED_PAYMENT,
        payload,
    }
}
export function createSavedPaymentSuccess(response) {
    return {
        type: types.CREATE_SAVED_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function createSavedPaymentFailure(error) {
    return {
        type: types.CREATE_SAVED_PAYMENT_FAILURE,
        error,
    }
}

export function editSavedPayment(payload) {
    return {
        type: types.EDIT_SAVED_PAYMENT,
        payload,
    }
}
export function editSavedPaymentSuccess(response) {
    return {
        type: types.EDIT_SAVED_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function editSavedPaymentFailure(error) {
    return {
        type: types.EDIT_SAVED_PAYMENT_FAILURE,
        error,
    }
}

export function deleteSavedPayment(payload) {
    return {
        type: types.DELETE_SAVED_PAYMENT,
        payload
    }
}
export function deleteSavedPaymentSuccess(response) {
    return {
        type: types.DELETE_SAVED_PAYMENT_SUCCESS,
        payload: response,
    }
}
export function deleteSavedPaymentFailure(error) {
    return {
        type: types.DELETE_SAVED_PAYMENT_FAILURE,
        error,
    }
}

export function updatePaymentToSavedPayments(payload) {
    return {
        type: types.UPDATE_PAYMENT_TO_SAVED_PAYMENTS,
        payload,
    }
}

export function updatePaymentItemToPaymentItems(payload) {
    return {
        type: types.UPDATE_PAYMENT_ITEM_TO_PAYMENT_ITEMS,
        payload,
    }
}
