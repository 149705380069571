import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/feeMgt';
import { GET_SETTLEMENT_PARTY, GET_SETTLEMENT_PARTIES, CREATE_SETTLEMENT_PARTY, UPDATE_SETTLEMENT_PARTY, UPDATE_SETTLEMENT_PARTY_STATUS, GET_PENDING_SETTLEMENT_PARTY_APPROVAL, GET_PENDING_SETTLEMENT_PARTIES_APPROVAL, CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT } from './settlementParties.constant';
import { getSettlementPartySuccess, getSettlementPartyFailure, getSettlementPartiesSuccess, getSettlementPartiesFailure, createSettlementPartySuccess, createSettlementPartyFailure, updateSettlementPartySuccess, updateSettlementPartyFailure, updateSettlementPartyStatusSuccess, updateSettlementPartyStatusFailure, getPendingSettlementPartyApprovalSuccess, getPendingSettlementPartyApprovalFailure, getPendingSettlementPartiesApprovalSuccess, getPendingSettlementPartiesApprovalFailure, createSettlementPartyApprovalVerdictSuccess, createSettlementPartyApprovalVerdictFailure } from './settlementParties.action';

async function getSettlementPartyRequest({ partyId }) {
    const options = endpoint.getSettlementParty(partyId)
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getSettlementPartiesRequest({ pageNumber, pageSize, status, searchParam }) {
    const options = endpoint.getSettlementParties(pageNumber, pageSize, status, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createSettlementPartyRequest(payload) {
    const options = endpoint.createSettlementParty(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateSettlementPartyRequest(payload) {
    const options = endpoint.updateSettlementParty(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateSettlementPartyStatusRequest(payload) {
    const options = endpoint.updateSettlementPartyStatus(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingSettlementPartyApprovalRequest({ requestId }) {
    const options = endpoint.getPendingSettlementPartyApproval(requestId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingSettlementPartiesApprovalRequest({ pageNumber, pageSize, status, actionType, searchParam }) {
    const options = endpoint.getPendingSettlementPartiesApproval(pageNumber, pageSize, status, actionType, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createSettlementPartyApprovalVerdictRequest(payload) {
    const options = endpoint.createSettlementPartyApprovalVerdict(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getSettlementPartyWorker({ payload }) {
    try {
        const response = yield call(getSettlementPartyRequest, payload);
        yield put(getSettlementPartySuccess(response));
    } catch(err) {
        yield put(getSettlementPartyFailure(err));
    }
}

function* getSettlementPartiesWorker({ payload }) {
    try {
        const response = yield call(getSettlementPartiesRequest, payload);
        yield put(getSettlementPartiesSuccess(response));
    } catch(err) {
        yield put(getSettlementPartiesFailure(err));
    }
}

function* createSettlementPartyWorker({ payload }) {
    try {
        const response = yield call(createSettlementPartyRequest, payload);
        yield put(createSettlementPartySuccess(response.responseDescription));
    } catch(err) {
        yield put(createSettlementPartyFailure(err));
    }
}

function* updateSettlementPartyWorker({ payload }) {
    try {
        const response = yield call(updateSettlementPartyRequest, payload);
        yield put(updateSettlementPartySuccess(response.responseDescription));
    } catch(err) {
        yield put(updateSettlementPartyFailure(err));
    }
}

function* updateSettlementPartyStatusWorker({ payload }) {
    try {
        const response = yield call(updateSettlementPartyStatusRequest, payload);
        yield put(updateSettlementPartyStatusSuccess(response.responseDescription));
    } catch(err) {
        yield put(updateSettlementPartyStatusFailure(err));
    }
}

function* getPendingSettlementPartyApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingSettlementPartyApprovalRequest, payload);
        yield put(getPendingSettlementPartyApprovalSuccess(response));
    } catch(err) {
        yield put(getPendingSettlementPartyApprovalFailure(err));
    }
}

function* getPendingSettlementPartiesApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingSettlementPartiesApprovalRequest, payload);
        const data = response.data || [];

        yield put(getPendingSettlementPartiesApprovalSuccess({
            pendingSettlementPartiesApproval: response,
            pendingSettlementPartiesApprovalCount: data.length,
        }));
    } catch(err) {
        yield put(getPendingSettlementPartiesApprovalFailure(err));
    }
}

function* createSettlementPartyApprovalVerdictWorker({ payload }) {
    try {
        const response = yield call(createSettlementPartyApprovalVerdictRequest, payload);
        yield put(createSettlementPartyApprovalVerdictSuccess(response.responseDescription));
    } catch(err) {
        yield put(createSettlementPartyApprovalVerdictFailure(err));
    }
}


function* getSettlementPartyWatcher() {
    yield takeLatest(GET_SETTLEMENT_PARTY, getSettlementPartyWorker);
}

function* getSettlementPartiesWatcher() {
    yield takeLatest(GET_SETTLEMENT_PARTIES, getSettlementPartiesWorker);
}

function* createSettlementPartyWatcher() {
    yield takeLatest(CREATE_SETTLEMENT_PARTY, createSettlementPartyWorker);
}

function* updateSettlementPartyWatcher() {
    yield takeLatest(UPDATE_SETTLEMENT_PARTY, updateSettlementPartyWorker);
}

function* updateSettlementPartyStatusWatcher() {
    yield takeLatest(UPDATE_SETTLEMENT_PARTY_STATUS, updateSettlementPartyStatusWorker);
}

function* getPendingSettlementPartyApprovalWatcher() {
    yield takeLatest(GET_PENDING_SETTLEMENT_PARTY_APPROVAL, getPendingSettlementPartyApprovalWorker);
}

function* getPendingSettlementPartiesApprovalWatcher() {
    yield takeLatest(GET_PENDING_SETTLEMENT_PARTIES_APPROVAL, getPendingSettlementPartiesApprovalWorker);
}

function* createSettlementPartyApprovalVerdictWatcher() {
    yield takeLatest(CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT, createSettlementPartyApprovalVerdictWorker);
}


export default function*() {
    yield all([
        fork(getSettlementPartyWatcher),
        fork(getSettlementPartiesWatcher),
        fork(createSettlementPartyWatcher),
        fork(updateSettlementPartyWatcher),
        fork(updateSettlementPartyStatusWatcher),
        fork(getPendingSettlementPartyApprovalWatcher),
        fork(getPendingSettlementPartiesApprovalWatcher),
        fork(createSettlementPartyApprovalVerdictWatcher),
    ]);
}
