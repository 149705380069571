export const GET_TRANSACTION_REPORT_PARAMETERS = 'GET_TRANSACTION_REPORT_PARAMETERS';
export const GET_TRANSACTION_REPORT_PARAMETERS_SUCCESS = 'GET_TRANSACTION_REPORT_PARAMETERS_SUCCESS';
export const GET_TRANSACTION_REPORT_PARAMETERS_FAILURE = 'GET_TRANSACTION_REPORT_PARAMETERS_FAILURE';

export const GET_TRANSACTION_REPORT = 'GET_TRANSACTION_REPORT';
export const GET_TRANSACTION_REPORT_SUCCESS = 'GET_TRANSACTION_REPORT_SUCCESS';
export const GET_TRANSACTION_REPORT_FAILURE = 'GET_TRANSACTION_REPORT_FAILURE';

export const GET_TRANSACTION_REPORT_DOWNLOAD = 'GET_TRANSACTION_REPORT_DOWNLOAD';
export const GET_TRANSACTION_REPORT_DOWNLOAD_SUCCESS = 'GET_TRANSACTION_REPORT_DOWNLOAD_SUCCESS';
export const GET_TRANSACTION_REPORT_DOWNLOAD_FAILURE = 'GET_TRANSACTION_REPORT_DOWNLOAD_FAILURE';

export const TRACK_TRANSACTION_REPORT_DOWNLOAD = 'TRACK_TRANSACTION_REPORT_DOWNLOAD';
export const TRACK_TRANSACTION_REPORT_DOWNLOAD_SUCCESS = 'TRACK_TRANSACTION_REPORT_DOWNLOAD_SUCCESS';

