import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/approvals';
import { GET_APPROVAL_CONFIGURATION, CREATE_APPROVAL_CONFIGURATION, EDIT_APPROVAL_CONFIGURATION } from './configuration.constant';
import { getApprovalConfigurationSuccess, getApprovalConfigurationFailure, createApprovalConfigurationSuccess, createApprovalConfigurationFailure, editApprovalConfigurationSuccess, editApprovalConfigurationFailure } from './configuration.action';


async function getApprovalConfigurationRequest() {
    const options = endpoint.getApprovalConfiguration()
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createApprovalConfigurationRequest(payload) {
    const options = endpoint.createApprovalConfiguration(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function editApprovalConfigurationRequest({id, payload}) {
    const options = endpoint.updateApprovalConfiguration(id, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getApprovalConfigurationWorker() {
    try {
        const response = yield call(getApprovalConfigurationRequest);
        yield put(getApprovalConfigurationSuccess(response.approvalConfig));
    } catch(err) {
        yield put(getApprovalConfigurationFailure(err));
    }
}

function* createApprovalConfigurationWorker({ payload }) {
    try {
        const response = yield call(createApprovalConfigurationRequest, payload);
        yield put(createApprovalConfigurationSuccess(response.approvalConfig));
    } catch(err) {
        yield put(createApprovalConfigurationFailure(err));
    }
}

function* editApprovalConfigurationWorker({ payload }) {
    try {
        let response = yield call(editApprovalConfigurationRequest, payload);
        
        if (typeof response !== 'string') {
            response = 'Approval configuration was successfully updated';
        }
        yield put(editApprovalConfigurationSuccess(response));
    } catch(err) {
        yield put(editApprovalConfigurationFailure(err));
    }
}


function* getApprovalConfigurationWatcher() {
    yield takeLatest(GET_APPROVAL_CONFIGURATION, getApprovalConfigurationWorker);
}


function* createApprovalConfigurationWatcher() {
    yield takeLatest(CREATE_APPROVAL_CONFIGURATION, createApprovalConfigurationWorker);
}

function* editApprovalConfigurationWatcher() {
    yield takeLatest(EDIT_APPROVAL_CONFIGURATION, editApprovalConfigurationWorker);
}


export default function*() {
    yield all([
        fork(getApprovalConfigurationWatcher),
        fork(createApprovalConfigurationWatcher),
        fork(editApprovalConfigurationWatcher),
    ]);
}
