import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../../utils/request';
import endpoint from '../../../../../uris/approvals';
import { GET_PENDING_BUSINESS_OWNER_APPROVALS } from './pendingBizOwnerApprovals.constant';
import { getPendingBizOwnerApprovalsSuccess, getPendingBizOwnerApprovalsFailure } from './pendingBizOwnerApprovals.action';


async function getPendingBizOwnerApprovalsRequest() {
    const options = endpoint.getPendingBizOwnerApprovals();
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPendingBizOwnerApprovalsWorker() {
    try {
        const response = yield call(getPendingBizOwnerApprovalsRequest);
        yield put(getPendingBizOwnerApprovalsSuccess(response.pendingApprovals));
    } catch(err) {
        yield put(getPendingBizOwnerApprovalsFailure(err));
    }
}


function* getPendingBizOwnerApprovalsWatcher() {
    yield takeLatest(GET_PENDING_BUSINESS_OWNER_APPROVALS, getPendingBizOwnerApprovalsWorker);
}


export default function*() {
    yield all([
        fork(getPendingBizOwnerApprovalsWatcher),
    ]);
}
