import * as types from './transactionReport.constant';

export function getTransactionReportParameters(params) {
    return {
        type: types.GET_TRANSACTION_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getTransactionReportParametersSuccess(response) {
    return {
        type: types.GET_TRANSACTION_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getTransactionReportParametersFailure(error) {
    return {
        type: types.GET_TRANSACTION_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getTransactionReport(params) {
    return {
        type: types.GET_TRANSACTION_REPORT,
        payload: params,
    }
}

export function getTransactionReportSuccess(response) {
    return {
        type: types.GET_TRANSACTION_REPORT_SUCCESS,
        payload: response,
    }
}

export function getTransactionReportFailure(error) {
    return {
        type: types.GET_TRANSACTION_REPORT_FAILURE,
        error,
    }
}

export function getTransactionReportDownload(params) {
    return {
        type: types.GET_TRANSACTION_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getTransactionReportDownloadSuccess(response) {
    return {
        type: types.GET_TRANSACTION_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getTransactionReportDownloadFailure(error) {
    return {
        type: types.GET_TRANSACTION_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackTransactionReportDownload(params) {
    return {
        type: types.TRACK_TRANSACTION_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackTransactionReportDownloadSuccess(response) {
    return {
        type: types.TRACK_TRANSACTION_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}
