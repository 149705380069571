import seamless1 from '../../Assets/Ornaments/seamless1.png'
import seamless2 from '../../Assets/Ornaments/seamless2.png'
import seamless3 from '../../Assets/Ornaments/seamless3.png'
import seamless4 from '../../Assets/Ornaments/seamless4.png'
import mobile1 from '../../Assets/Ornaments/mobile1.png'
import mobile2 from '../../Assets/Ornaments/mobile2.png'
import mobile3 from '../../Assets/Ornaments/mobile3.jpg'
import mobile4 from '../../Assets/Ornaments/mobile4.png'
import bills1 from '../../Assets/Ornaments/bills1.png'
import bills2 from '../../Assets/Ornaments/bills2.png'
import bills3 from '../../Assets/Ornaments/bills3.png'
import icon1 from '../../Assets/illustration.svg'
import icon2 from '../../Assets/illustration2.svg'
import icon3 from '../../Assets/illustration3.svg'
import icon4 from '../../Assets/illustration4.svg'
import imageframe1 from '../../Assets/imageFrame1.png'
import imageframe2 from '../../Assets/imageFrame2.png'
import imageframe3 from '../../Assets/imageFrame3.png'
import imageframe4 from '../../Assets/imageFrame4.png'
import powering1 from '../../Assets/powering1.png'
import powering2 from '../../Assets/powering2.png'
import powering3 from '../../Assets/powering3.png'

export const seamless =[
    {
        img:seamless1,
        p:'Detailed Analytics',
        span:'Gain financial insights with a concise view of all your accounts and transactions.'
    },
    {
        img:seamless2,
        p:'Easier Auditing',
        span:'Examine operating accounts quickly and easily to ensure accuracy of your organization’s financial statements.'
    },
    {
        img:seamless3,
        p:'Inter-Bank Transfer',
        span:'Move funds safely and securely from one bank to another.'
    },
    {
        img:seamless4,
        p:'Simplified Maker-Checker Process',
        span:'Initiate and authorize transactions easily to help protect and meet the individual needs of your business.'
    },
]

export const bills =[
    {
        img:bills1,
        p:'Electronic Invoicing',
        span:'Send vendor bills, generate reciepts, and invoices to customers digitally. '
    },
    {
        img:bills2,
        p:'Vendor Management',
        span:'Maintain and control all services relating to existing vendors/partners'
    },
    {
        img:bills3,
        p:'Travel & Logistics',
        span:'Plan and manage all travel and logistics operations for your organization without hassle.'
    },
]

export const section2 = [
    {
        img:imageframe1,
        icon:icon1,
        text:'Government Related Payments',
        p:'Drive organisational efficiency with seamless and convenient disbursement and remittance possibilities that mirror your approval processes.'
    },
    {
        img:imageframe2,
        icon:icon2,
        text:'Staff Salaries',
        p:'Easy and automated generation of payroll advice, salary payment plans, disbursement and monitoring; all from the same platform.'
    },
    {
        img:imageframe3,
        icon:icon3,
        text:'Bill Payments',
        p:'Purchase vital operational commodities such as electricity and telecommunication bills easily; anytime, anywhere.'
    },
    {
        img:imageframe4,
        icon:icon4,
        text:'Vendors',
        p:'Generate electronic invoices, make instant payments to vendors, seamlessly track and obtain receipt of payment.'
    },
]
export const section3 = [
    {
        img:powering1,
        text:'Company Insights',
        p:'Drive your organization to success with real-time insights and analytics. Leverage data about your transactional activities to make informed business decisions.'
    },
    {
        img:powering2,
        text:'Convenient Integration',
        p:'Connect your ERP software inorder to promote increased efficiency of your business processes and workflow, giving a unified view of all information in real time.'
    },
    {
        img:powering3,
        text:'Excellent Collaboration',
        p:'Facilitate your day-to-day business operations thereby driving quicker turn around time for processes, between teams.'
    },
]
export const accordionData = [
    {
        title:'About us',
        links: [
            {
                name:'About Quickteller',
                link:'https://www.interswitchgroup.com/quickteller'
            },
            {
                name:'About Intwerswitch',
                link:'https://www.interswitchgroup.com/about-us'
            },
            {
                name:'Leadership',
                link:'https://www.interswitchgroup.com/leadership'
            },
            {
                name:'Coporate Responsibility',
                link:'https://www.interswitchgroup.com/corporate-responsibility'
            },
            {
                name:'Careers',
                link:'https://www.interswitchgroup.com/career/'
            },
        ]
    },
    {
        title:'Quickteller Products',
        links: [
            {
                name:'Quickteller',
                link:'https://www.interswitchgroup.com/quickteller'
            },
            {
                name:'Quickteller Business',
                link:'https://www.interswitchgroup.com/quickteller-for-business'
            },
            {
                name:'Quickteller Corporate',
                link:'/'
            },
            {
                name:'Quickteller Paypoint',
                link:'https://www.interswitchgroup.com/financial-inclusion'
            },
        ]
    },
    {
        title:'More',
        links: [
            {
                name:'Security',
                link:'https://www.interswitchgroup.com/security'
            },
            {
                name:'Privacy Policy',
                link:'https://www.interswitchgroup.com/privacy'
            },
            {
                name:'Terms and Conditions',
                link:'https://www.interswitchgroup.com/terms-condition'
            },
            {
                name:'Press Kit',
                link:'https://www.interswitchgroup.com/press-kit'
            },
        ]
    },
]
export const sectionMobile = [mobile1, mobile2, mobile3,mobile4]