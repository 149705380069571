import React, {useState} from 'react'
import classes from '../Home/Home.module.css'
import facebook from '../../Assets/TranSocial/facebook.svg'
import instagram from '../../Assets/TranSocial/instagram.svg'
import linkedin from '../../Assets/TranSocial/linkedin.svg'
import youtube from '../../Assets/TranSocial/youtube.svg'
import twitter from '../../Assets/TranSocial/twitter.svg'
import logo from '../../Assets/TranSocial/footerlogo.svg'
import accordion from '../../Assets/accordionwhite.svg'
import { accordionData } from '../Home/SectionData'
import { useMediaQuery } from '@material-ui/core';

const TransactionFooter = () => {
    const smScreen = useMediaQuery('(max-width:800px)')
    const [index, setIndex] = useState(null);
  
    const handleToogle = (i) => {
        if(i === index){
            return setIndex(null);
        }else{
            setIndex(i)
        }
    }
    const FooterAccordion = () => {
    return (
        <div className={classes.accordionContainer} style={{backgroundColor:'#fff', borderTop:'1x solid #F3F5F6'}}>
        {
            accordionData.map((e,i) => (
            <div key={i} className={classes.accordionTitleContainer}>
                <div className={classes.accordionHeader}  onClick={()=>{
                    handleToogle(i)
                }}>
                    <p className={classes.accordionTitle} style={{color:'#00425F'}}>{e.title}</p> 
                    <img src={accordion} alt='accordion button' className={index === i ? classes.imgRotate : null}/>
                </div>
                {index === i ? <div className={classes.accordionLinks}>
                    {e.links.map((y,x) => <a key={x} href={y.link} target="_blank" rel="noopener noreferrer" style={{color:'#353F50',fontFamily:'AvertaRegular',}}>{y.name}</a>)}
                </div>: null}
            </div>
            ))
        }
        <div className={classes.accordionTitleContainer}>
            <p className={classes.accordionTitle} style={{padding:'10px 0', color:'#00425F'}}>Interswitch</p> 
            <p style={{
                fontFamily:'AvertaRegular', fontWeight:400, fontSize:'1.3rem', color: '#353F50',marginTop:1
            }}>Interswitch is The Gateway to Africa's Payment Ecosystem. We create and sustain a payment ecosystem that helps commerce evolve, businesses grow and individuals thrive.</p>
        </div>
        </div>
    )
}
  
  return (
    <div style={{marginTop:'10px'}}>
    
  {
    !smScreen ?    
    
  (<div className={classes.footerLinksContainer} style={{backgroundColor:'#fff', borderTop:'1px solid #F3F5F6'}}>
        <div className={classes.linkContainer1}>
            <p className={classes.footerLinksHead} style={{color:'#00425F'}}>ABOUT US</p>
            <a href='https://www.interswitchgroup.com/quickteller' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}}>About Quickteller</a>
            <a href='https://www.interswitchgroup.com/about-us' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50', fontFamily:'AvertaRegular'}}>About Interswitch</a>
            <a href='https://www.interswitchgroup.com/leadership' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50', fontFamily:'AvertaRegular'}}>Leadership</a>
            <a href='https://www.interswitchgroup.com/corporate-responsibility' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50', fontFamily:'AvertaRegular'}}>Corporate Responsibiltiy</a>
            <a href='https://www.interswitchgroup.com/career/' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}}>Careers</a>
        </div>
        <div  className={classes.linkContainer2}>
            <p className={classes.footerLinksHead} style={{color:'#00425F'}}>QUICKTELLER PRODUCTS</p>
            <a href='https://www.interswitchgroup.com/quickteller' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}}>Quickteller</a>
            <a href='https://www.interswitchgroup.com/quickteller-for-business' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}}>Quickteller Business</a>
            <a href='#.com' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}}>Quickteller Corporate</a>
            <a href='https://www.interswitchgroup.com/financial-inclusion' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}}>Quickteller Paypoint</a>
        </div> 
        <div className={classes.linkContainer3}>
            <p className={classes.footerLinksHead} style={{color:'#00425F'}}>MORE</p>
            <a href='https://www.interswitchgroup.com/security' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}} >Security</a>
            <a href='https://www.interswitchgroup.com/privacy' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}} >Privacy Policy</a>
            <a href='https://www.interswitchgroup.com/terms-condition' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}} >Terms & Conditions</a>
            <a href='https://www.interswitchgroup.com/press-kit' className={classes.footerLinks} target="_blank" rel="noopener noreferrer"  style={{color:'#353F50',fontFamily:'AvertaRegular'}} >Press Kits</a>
        </div>
        <div className={classes.linkContainer4}>
            <p className={classes.footerLinksHead} style={{color:'#00425F'}}>INTERSWITCH</p>
            <p className={classes.footerLinks} style={{cursor:'auto', color:'#353F50',fontFamily:'AvertaRegular'}}>Interswitch is The Gateway to Africa's Payment Ecosystem. We create and sustain a payment ecosystem that helps commerce evolve, businesses grow and individuals thrive.</p>
        </div>
    </div>) : 
    <FooterAccordion />}

        <div className={classes.footerSocialContainer} style={{backgroundColor:'#fff', borderTop:'1px solid #F3F5F6'}}>
                <div>
                    <img  src={logo} alt="interswitch logo" />
                </div>
                <p className={classes.footerLink3} style={{color:'#353F50'}}>&copy; {`2002 - ${new Date().getFullYear()} Interswitch Group`}</p >
                <div className={classes.socialContainer}>
                    <a href='https://www.linkedin.com/company/interswitch-limited' target="_blank" rel="noopener noreferrer"> <img src={linkedin} alt="linkedin"  /> </a>
                    <a href='https://twitter.com/Quickteller' target="_blank" rel="noopener noreferrer">  <img src={twitter} alt="twitter" /> </a>
                    <a href='https://web.facebook.com/Quickteller/?_rdc=1&_rdr' target="_blank" rel="noopener noreferrer">  <img src={facebook} alt="facebook" /> </a>
                    <a href='https://www.instagram.com/quicktellerng' target="_blank" rel="noopener noreferrer">  <img src={instagram} alt="instagram" /> </a>
                    <a href='https://www.youtube.com/user/interswitch' target="_blank" rel="noopener noreferrer">  <img src={youtube} alt="youtube" /> </a>
                </div>
        </div>
  </div>
  )
}

export default TransactionFooter