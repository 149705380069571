import React from "react";
import logo from "../../Assets/qtc-logo.svg";
import classes from "./Home.module.css";
import { Link } from 'react-router-dom';
import mobile from '../../Assets/mobile.svg'

const Nav = ({trans}) => {
  const [show, setShow] = React.useState(false)
  return (
    <nav className={classes.navContainer}>
      <div className={classes.logoContainer}>
      <Link to="/">
        <img src={logo} alt="interswitch logo" />
      </Link>
      </div>
      <div className={classes.navLogoContainer}>
        <Link to="/login" className={classes.navLogin}>Log in </Link>
        <Link to="/login" className={classes.navLogin} style={{
          color:'#fff',
          backgroundColor:'#0275D8',
          marginLeft:'20px'
        }}>Create an Account</Link>
      </div>
      <div className={classes.navLogoContainerMobile}>
        { trans ? <button className={classes.navStatus}>Transaction Status</button> :
        <Link to="/login" className={classes.navLogin} style={{
          color:'#fff',
          backgroundColor:'#0275D8',
          marginLeft:'20px'
        }}>Get Started</Link>
        }
        <button onClick={()=>setShow(prev => !prev)} className={classes.mobileNavButton}>
          <img src={mobile} alt="mobile-close" />
        </button>

        {show &&  
        <div className={classes.mobileNavDropdown}>
          <Link to="/login">Login</Link>
          <Link to="/login">Sign Up</Link>
          <Link to="/">About Quickteller Corporate</Link>
          <Link>Check Transaction Status</Link>
        </div>}
      </div>
    </nav>
  );
};

export default Nav;