import * as types from './changePassword.constant';

export function changePassword(payload) {
    return {
        type: types.CHANGE_PASSWORD,
        payload,
    }
}

export function changePasswordSuccess(response) {
    return {
        type: types.CHANGE_PASSWORD_SUCCESS,
        payload: response,
    }
}

export function changePasswordFailure(error) {
    return {
        type: types.CHANGE_PASSWORD_FAILURE,
        error,
    }
}
