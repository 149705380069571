
export const GET_AUDIT_TRAIL = 'GET_AUDIT_TRAIL';
export const GET_AUDIT_TRAIL_SUCCESS = 'GET_AUDIT_TRAIL_SUCCESS';
export const GET_AUDIT_TRAIL_FAILURE = 'GET_AUDIT_TRAIL_FAILURE';

export const GET_AUDIT_TRAIL_DOWNLOAD = 'GET_AUDIT_TRAIL_DOWNLOAD';
export const GET_AUDIT_TRAIL_DOWNLOAD_SUCCESS = 'GET_AUDIT_TRAIL_DOWNLOAD_SUCCESS';
export const GET_AUDIT_TRAIL_DOWNLOAD_FAILURE = 'GET_AUDIT_TRAIL_DOWNLOAD_FAILURE';

export const SEARCH_AUDIT_TRAIL = 'SEARCH_AUDIT_TRAIL';
export const SEARCH_AUDIT_TRAIL_SUCCESS = 'SEARCH_AUDIT_TRAIL_SUCCESS';
export const SEARCH_AUDIT_TRAIL_FAILURE = 'SEARCH_AUDIT_TRAIL_FAILURE';

export const SEARCH_AUDIT_TRAIL_DOWNLOAD = 'SEARCH_AUDIT_TRAIL_DOWNLOAD';
export const SEARCH_AUDIT_TRAIL_DOWNLOAD_SUCCESS = 'SEARCH_AUDIT_TRAIL_DOWNLOAD_SUCCESS';
export const SEARCH_AUDIT_TRAIL_DOWNLOAD_FAILURE = 'SEARCH_AUDIT_TRAIL_DOWNLOAD_FAILURE';
