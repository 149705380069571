export const GET_REVENUE_SHARE_REPORT = 'GET_REVENUE_SHARE_REPORT';
export const GET_REVENUE_SHARE_REPORT_SUCCESS = 'GET_REVENUE_SHARE_REPORT_SUCCESS';
export const GET_REVENUE_SHARE_REPORT_FAILURE = 'GET_REVENUE_SHARE_REPORT_FAILURE';

export const GET_REVENUE_SHARE_REPORT_DOWNLOAD = 'GET_REVENUE_SHARE_REPORT_DOWNLOAD';
export const GET_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS = 'GET_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS';
export const GET_REVENUE_SHARE_REPORT_DOWNLOAD_FAILURE = 'GET_REVENUE_SHARE_REPORT_DOWNLOAD_FAILURE';

export const TRACK_REVENUE_SHARE_REPORT_DOWNLOAD = 'TRACK_REVENUE_SHARE_REPORT_DOWNLOAD';
export const TRACK_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS = 'TRACK_REVENUE_SHARE_REPORT_DOWNLOAD_SUCCESS';

