export const VALIDATE_CUSTOMER = 'VALIDATE_CUSTOMER';
export const VALIDATE_CUSTOMER_SUCCESS = 'VALIDATE_CUSTOMER_SUCCESS';
export const VALIDATE_CUSTOMER_FAILURE = 'VALIDATE_CUSTOMER_FAILURE';

export const VALIDATE_CUSTOMER_BULK = 'VALIDATE_CUSTOMER_BULK';
export const VALIDATE_CUSTOMER_BULK_SUCCESS = 'VALIDATE_CUSTOMER_BULK_SUCCESS';
export const VALIDATE_CUSTOMER_BULK_FAILURE = 'VALIDATE_CUSTOMER_BULK_FAILURE';

export const VALIDATE_CUSTOMER_BULK_TAX = 'VALIDATE_CUSTOMER_BULK_TAX';
export const VALIDATE_CUSTOMER_BULK_FIRS = 'VALIDATE_CUSTOMER_BULK_FIRS';

export const DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE';
export const DOWNLOAD_TEMPLATE_FAILURE = 'DOWNLOAD_TEMPLATE_FAILURE';

export const DOWNLOAD_VALIDATION_STATUS = 'DOWNLOAD_VALIDATION_STATUS';
export const DOWNLOAD_VALIDATION_STATUS_FAILURE = 'DOWNLOAD_TEMPLATE_FAILURE';

export const GET_BULK_VALIDATION_STATUS = 'GET_BULK_VALIDATION_STATUS';
export const GET_BULK_VALIDATION_STATUS_SUCCESS = 'GET_BULK_VALIDATION_STATUS_SUCCESS';
export const GET_BULK_VALIDATION_STATUS_FAILURE = 'GET_BULK_VALIDATION_STATUS_FAILURE';

export const GET_PAYMENT_ITEMS = 'GET_PAYMENT_ITEMS';
export const GET_PAYMENT_ITEMS_SUCCESS = 'GET_PAYMENT_ITEMS_SUCCESS';
export const GET_PAYMENT_ITEMS_FAILURE = 'GET_PAYMENT_ITEMS_FAILURE';

export const GET_CUSTOMER_VALIDATION_INFO = 'GET_CUSTOMER_VALIDATION_INFO';
export const GET_CUSTOMER_VALIDATION_INFO_SUCCESS = 'GET_CUSTOMER_VALIDATION_INFO_SUCCESS';
export const GET_CUSTOMER_VALIDATION_INFO_FAILURE = 'GET_CUSTOMER_VALIDATION_INFO_FAILURE';

export const SAVE_CUSTOMER_VALIDATION_SNAPSHOT = 'SAVE_CUSTOMER_VALIDATION_SNAPSHOT';
export const REMOVE_CUSTOMER_VALIDATION_SNAPSHOT = 'REMOVE_CUSTOMER_VALIDATION_SNAPSHOT';

export const RESET_CUSTOMER_VALIDATION = 'RESET_CUSTOMER_VALIDATION';
export const UNRESET_CUSTOMER_VALIDATION = 'UNRESET_CUSTOMER_VALIDATION';

export const SHOW_ADD_PREVIOUS_BILLER_PAYMENT = 'SHOW_ADD_PREVIOUS_BILLER_PAYMENT';
export const HIDE_ADD_PREVIOUS_BILLER_PAYMENT = 'HIDE_ADD_PREVIOUS_BILLER_PAYMENT';

export const OPEN_CUSTOMER_VALIDATION = 'OPEN_CUSTOMER_VALIDATION';
export const CLOSE_CUSTOMER_VALIDATION = 'CLOSE_CUSTOMER_VALIDATION';

export const UNDO_VALIDATED_CUSTOMER = 'UNDO_VALIDATED_CUSTOMER';

export const SET_TAX_PAYMENT_ACTIVE = 'SET_TAX_PAYMENT_ACTIVE';
export const SET_TAX_PAYMENT_INACTIVE = 'SET_TAX_PAYMENT_INACTIVE';
