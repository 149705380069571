import { combineReducers } from 'redux';

import rxConfiguration from './configuration/redux/configuration.reducer';
import rxPendingApprovals from './pendingApprovals/redux/pendingApprovals.reducer';
import rxApprovalRequests from './approvalRequests/redux/approvalRequests.reducer';

const approvalsReducers = combineReducers({
    rxConfiguration,
    rxPendingApprovals,
    rxApprovalRequests,
});

export default approvalsReducers;
