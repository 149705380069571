import * as types from './paymentInstruments.constant';

const initialState = {
    paymentInstrument: null,
    paymentInstrumentError: null,
    paymentInstrumentLoading: false,
    paymentInstruments: null,
    paymentInstrumentsError: null,
    paymentInstrumentsLoading: false,
    createPaymentInstrumentError: null,
    createPaymentInstrumentSuccess: null,
    createPaymentInstrumentLoading: false,
    updatePaymentInstrumentError: null,
    updatePaymentInstrumentSuccess: null,
    updatePaymentInstrumentLoading: false,
    updatePaymentInstrumentStatusError: null,
    updatePaymentInstrumentStatusSuccess: null,
    updatePaymentInstrumentStatusLoading: false,
    pendingPaymentInstrumentApproval: null,
    getPendingPaymentInstrumentApprovalError: null,
    getPendingPaymentInstrumentApprovalLoading: false,
    pendingPaymentInstrumentsApproval: null,
    pendingPaymentInstrumentsApprovalCount: null,
    getPendingPaymentInstrumentsApprovalError: null,
    getPendingPaymentInstrumentsApprovalLoading: false,
    createPaymentInstrumentApprovalVerdictError: null,
    createPaymentInstrumentApprovalVerdictSuccess: null,
    createPaymentInstrumentApprovalVerdictLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_PAYMENT_INSTRUMENT:
            return {
                ...state,
                paymentInstrumentLoading: true,
                paymentInstrumentError: null,
                paymentInstrument: null,
            }

        case types.GET_PAYMENT_INSTRUMENT_SUCCESS:
            return {
                ...state,
                paymentInstrumentLoading: false,
                paymentInstrument: action.payload,
            }

        case types.GET_PAYMENT_INSTRUMENT_FAILURE:
            return {
                ...state,
                paymentInstrumentLoading: false,
                paymentInstrumentError: action.error,
            }
        case types.GET_PAYMENT_INSTRUMENTS:
            return {
                ...state,
                paymentInstrumentsLoading: true,
                paymentInstrumentsError: null,
                paymentInstruments: null,
            }

        case types.GET_PAYMENT_INSTRUMENTS_SUCCESS:
            return {
                ...state,
                paymentInstrumentsLoading: false,
                paymentInstruments: action.payload,
            }

        case types.GET_PAYMENT_INSTRUMENTS_FAILURE:
            return {
                ...state,
                paymentInstrumentsLoading: false,
                paymentInstrumentsError: action.error,
            }

        case types.CREATE_PAYMENT_INSTRUMENT_ISW:
            return {
                ...state,
                createPaymentInstrumentError: null,
                createPaymentInstrumentSuccess: null,
                createPaymentInstrumentLoading: true,
            }

        case types.CREATE_PAYMENT_INSTRUMENT_ISW_SUCCESS:
            return {
                ...state,
                createPaymentInstrumentLoading: false,
                createPaymentInstrumentSuccess: action.payload,
            }

        case types.CREATE_PAYMENT_INSTRUMENT_ISW_FAILURE:
            return {
                ...state,
                createPaymentInstrumentLoading: false,
                createPaymentInstrumentError: action.error,
            }

        case types.UPDATE_PAYMENT_INSTRUMENT_ISW:
            return {
                ...state,
                updatePaymentInstrumentError: null,
                updatePaymentInstrumentSuccess: null,
                updatePaymentInstrumentLoading: true,
            }

        case types.UPDATE_PAYMENT_INSTRUMENT_ISW_SUCCESS:
            return {
                ...state,
                updatePaymentInstrumentLoading: false,
                updatePaymentInstrumentSuccess: action.payload,
            }

        case types.UPDATE_PAYMENT_INSTRUMENT_ISW_FAILURE:
            return {
                ...state,
                updatePaymentInstrumentLoading: false,
                updatePaymentInstrumentError: action.error,
            }

        case types.UPDATE_PAYMENT_INSTRUMENT_STATUS:
            return {
                ...state,
                updatePaymentInstrumentStatusError: null,
                updatePaymentInstrumentStatusSuccess: null,
                updatePaymentInstrumentStatusLoading: true,
            }

        case types.UPDATE_PAYMENT_INSTRUMENT_STATUS_SUCCESS:
            return {
                ...state,
                updatePaymentInstrumentStatusLoading: false,
                updatePaymentInstrumentStatusSuccess: action.payload,
            }

        case types.UPDATE_PAYMENT_INSTRUMENT_STATUS_FAILURE:
            return {
                ...state,
                updatePaymentInstrumentStatusLoading: false,
                updatePaymentInstrumentStatusError: action.error,
            }

        case types.GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL:
            return {
                ...state,
                pendingPaymentInstrumentApproval: null,
                getPendingPaymentInstrumentApprovalError: null,
                getPendingPaymentInstrumentApprovalLoading: true,
            }

        case types.GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingPaymentInstrumentApprovalLoading: false,
                pendingPaymentInstrumentApproval: action.payload,
            }

        case types.GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingPaymentInstrumentApprovalLoading: false,
                getPendingPaymentInstrumentApprovalError: action.error,
            }

        case types.GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL:
            return {
                ...state,
                pendingPaymentInstrumentsApproval: null,
                getPendingPaymentInstrumentsApprovalError: null,
                getPendingPaymentInstrumentsApprovalLoading: true,
            }

        case types.GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingPaymentInstrumentsApprovalLoading: false,
                ...action.payload,
            }

        case types.GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingPaymentInstrumentsApprovalLoading: false,
                getPendingPaymentInstrumentsApprovalError: action.error,
            }

        case types.CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT:
            return {
                ...state,
                createPaymentInstrumentApprovalVerdictError: null,
                createPaymentInstrumentApprovalVerdictSuccess: null,
                createPaymentInstrumentApprovalVerdictLoading: true,
            }

        case types.CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createPaymentInstrumentApprovalVerdictLoading: false,
                createPaymentInstrumentApprovalVerdictSuccess: action.payload,
            }

        case types.CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createPaymentInstrumentApprovalVerdictLoading: false,
                createPaymentInstrumentApprovalVerdictError: action.error,
            }

        default:
            return state;
    }
}
