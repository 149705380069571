import * as types from './paymentInstruments.constant';

export function getPaymentInstrument(params) {
    return {
        type: types.GET_PAYMENT_INSTRUMENT,
        payload: params,
    }
}

export function getPaymentInstrumentSuccess(response) {
    return {
        type: types.GET_PAYMENT_INSTRUMENT_SUCCESS,
        payload: response,
    }
}

export function getPaymentInstrumentFailure(error) {
    return {
        type: types.GET_PAYMENT_INSTRUMENT_FAILURE,
        error,
    }
}

export function getPaymentInstruments(params) {
    return {
        type: types.GET_PAYMENT_INSTRUMENTS,
        payload: params,
    }
}

export function getPaymentInstrumentsSuccess(response) {
    return {
        type: types.GET_PAYMENT_INSTRUMENTS_SUCCESS,
        payload: response,
    }
}

export function getPaymentInstrumentsFailure(error) {
    return {
        type: types.GET_PAYMENT_INSTRUMENTS_FAILURE,
        error,
    }
}

export function createPaymentInstrumentIsw(payload) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_ISW,
        payload,
    }
}

export function createPaymentInstrumentIswSuccess(response) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_ISW_SUCCESS,
        payload: response,
    }
}

export function createPaymentInstrumentIswFailure(error) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_ISW_FAILURE,
        error,
    }
}

export function updatePaymentInstrumentIsw(payload) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_ISW,
        payload,
    }
}

export function updatePaymentInstrumentIswSuccess(response) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_ISW_SUCCESS,
        payload: response,
    }
}

export function updatePaymentInstrumentIswFailure(error) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_ISW_FAILURE,
        error,
    }
}

export function updatePaymentInstrumentStatus(payload) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_STATUS,
        payload,
    }
}

export function updatePaymentInstrumentStatusSuccess(response) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_STATUS_SUCCESS,
        payload: response,
    }
}

export function updatePaymentInstrumentStatusFailure(error) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_STATUS_FAILURE,
        error,
    }
}

export function getPendingPaymentInstrumentApproval(params) {
    return {
        type: types.GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL,
        payload: params,
    }
}

export function getPendingPaymentInstrumentApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingPaymentInstrumentApprovalFailure(error) {
    return {
        type: types.GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_FAILURE,
        error,
    }
}

export function getPendingPaymentInstrumentsApproval(params) {
    return {
        type: types.GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL,
        payload: params,
    }
}

export function getPendingPaymentInstrumentsApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingPaymentInstrumentsApprovalFailure(error) {
    return {
        type: types.GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_FAILURE,
        error,
    }
}

export function createPaymentInstrumentApprovalVerdict(payload) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT,
        payload,
    }
}

export function createPaymentInstrumentApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createPaymentInstrumentApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_FAILURE,
        error,
    }
}