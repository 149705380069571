import * as types from './common-report.constant';

const initialState = {
    getBankBranchLoading: false,
    getBankBranchError: null,
    bankBranch: null,
};

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_BANK_BRANCH:
            return {
                ...state,
                getBankBranchLoading: true,
                getBankBranchError: null,
                bankBranch: null,
            }
        
        case types.GET_BANK_BRANCH_SUCCESS:
            return {
                ...state,
                getBankBranchLoading: false,
                bankBranch: action.payload,
            }

        case types.GET_BANK_BRANCH_FAILURE:
            return {
                ...state,
                getBankBranchLoading: false,
                getBankBranchError: action.error,
            }
            
        default:
            return state;
    }
}
