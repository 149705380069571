const BASE_URL_USER = process.env.REACT_APP_BASE_URL_USER;
const BASE_URL_TRANS = process.env.REACT_APP_BASE_URL_TRANS;

export default {
    getApprovalConfiguration: () => ({
        baseUrl: BASE_URL_USER,
        url: `approval/configs/business`,
        method: 'GET',
        auth: true,
    }),

    createApprovalConfiguration: (payload) => ({
        baseUrl: BASE_URL_USER,
        url: `approval/config`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updateApprovalConfiguration: (id, payload) => ({
        baseUrl: BASE_URL_USER,
        url: `approval/config/update/${id}`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getUserPendingApprovals: (businessId, userId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `approvals/pending/${businessId}/${userId}`,
        method: 'GET',
        auth: true,
    }),

    createApprovalVerdit: (payload) => ({
        baseUrl: BASE_URL_TRANS,
        url: `approvals`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getApprovalLogs: (transactionId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `approvals/${transactionId}`,
        method: 'GET',
        auth: true,
    }),

    getApprovalStatus: (businessId, approvalConfigId, transactionId) => ({
        baseUrl: BASE_URL_TRANS,
        url: `approvals/${businessId}/${approvalConfigId}/${transactionId}`,
        method: 'GET',
        auth: true,
    }),

    getPendingBizOwnerApprovals: () => ({
        baseUrl: BASE_URL_USER,
        url: `isw/approval/pending`,
        method: 'GET',
        auth: true,
    }),

    createBizOwnerApprovalVerdict: (payload) => ({
        baseUrl: BASE_URL_USER,
        url: `isw/approval/verdict`,
        method: 'POST',
        data: payload,
        auth: true,
    }),
};
