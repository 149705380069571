import * as types from './firsTaxPayments.constant';

const initialState = {
    taxTypes: null,
    taxTypesError: null,
    taxTypesLoading: false,
    taxLocations: null,
    taxLocationsError: null,
    taxLocationsLoading: false,
    validateTinError: null,
    validateTinSuccess: null,
    validateTinLoading: false,
    validateDocumentNumberError: null,
    validateDocumentNumberSuccess: null,
    validateDocumentNumberLoading: false,
}

export default function (state = initialState, action) {
    switch(action.type) {

        case types.GET_TAX_TYPES:
            return {
                ...state,
                taxTypesLoading: true,
                taxTypesError: null,
                taxTypes: null,
            }

        case types.GET_TAX_TYPES_SUCCESS:
            return {
                ...state,
                taxTypesLoading: false,
                taxTypes: action.payload,
            }

        case types.GET_TAX_TYPES_FAILURE:
            return {
                ...state,
                taxTypesLoading: false,
                taxTypesError: action.error,
            }

        case types.GET_TAX_LOCATIONS:
            return {
                ...state,
                taxLocationsLoading: true,
                taxLocationsError: null,
                taxLocations: null,
            }

        case types.GET_TAX_LOCATIONS_SUCCESS:
            return {
                ...state,
                taxLocationsLoading: false,
                taxLocations: action.payload,
            }

        case types.GET_TAX_LOCATIONS_FAILURE:
            return {
                ...state,
                taxLocationsLoading: false,
                taxLocationsError: action.error,
            }

        case types.VALIDATE_TIN:
            return {
                ...state,
                validateTinLoading: true,
                validateTinSuccess: null,
                validateTinError: null,
            }

        case types.VALIDATE_TIN_SUCCESS:
            return {
                ...state,
                validateTinLoading: false,
                validateTinSuccess: action.payload,
            }

        case types.VALIDATE_TIN_FAILURE:
            return {
                ...state,
                validateTinLoading: false,
                validateTinError: action.error,
            }

        case types.VALIDATE_DOCUMENT_NUMBER:
            return {
                ...state,
                validateDocumentNumberLoading: true,
                validateDocumentNumberSuccess: null,
                validateDocumentNumberError: null,
            }

        case types.VALIDATE_DOCUMENT_NUMBER_SUCCESS:
            return {
                ...state,
                validateDocumentNumberLoading: false,
                validateDocumentNumberSuccess: action.payload,
            }

        case types.VALIDATE_DOCUMENT_NUMBER_FAILURE:
            return {
                ...state,
                validateDocumentNumberLoading: false,
                validateDocumentNumberError: action.error,
            }

        default:
            return state;

    }
}