import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/settings';
import { nominateBusinessOwnerSuccess, nominateBusinessOwnerFailure } from './nominateBusinessOwner.action';
import { NOMINATE_BUSINESS_OWNER } from './nominateBusinessOwner.constant';

async function nominateBusinessOwnerRequest(payload) {
    const options = endpoint.nominateBusinessOwner(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* nominateBusinessOwnerWorker({ payload }) {
    try {
        const response = yield call(nominateBusinessOwnerRequest, payload);
        yield put(nominateBusinessOwnerSuccess(response));
    } catch(err) {
        yield put(nominateBusinessOwnerFailure(err));
    }
}


function* nominateBusinessOwnerWatcher() {
    yield takeLatest(NOMINATE_BUSINESS_OWNER, nominateBusinessOwnerWorker);
}


export default function*() {
    yield all([
        fork(nominateBusinessOwnerWatcher),
    ]);
}