import * as types from './transactionLimits.constant';

const initialState = {
    cumulDailyTransLimit: null,
    cumulDailyTransLimitError: null,
    cumulDailyTransLimitLoading: false,
    cumulDailyTransLimits: null,
    cumulDailyTransLimitsError: null,
    cumulDailyTransLimitsLoading: false,
    createGlobalTransLimitError: null,
    createGlobalTransLimitSuccess: null,
    createGlobalTransLimitLoading: false,
    updateGlobalTransLimitError: null,
    updateGlobalTransLimitSuccess: null,
    updateGlobalTransLimitLoading: false,
    createCumulDailyTransLimitError: null,
    createCumulDailyTransLimitSuccess: null,
    createCumulDailyTransLimitLoading: false,
    updateCumulDailyTransLimitError: null,
    updateCumulDailyTransLimitSuccess: null,
    updateCumulDailyTransLimitLoading: false,
    updateBizCumulDailyTransLimitError: null,
    updateBizCumulDailyTransLimitSuccess: null,
    updateBizCumulDailyTransLimitLoading: false,
    pendingTransLimitApproval: null,
    getPendingTransLimitApprovalError: null,
    getPendingTransLimitApprovalLoading: false,
    pendingTransLimitsApproval: null,
    getPendingTransLimitsApprovalError: null,
    getPendingTransLimitsApprovalLoading: false,
    createTransLimitApprovalVerdictError: null,
    createTransLimitApprovalVerdictSuccess: null,
    createTransLimitApprovalVerdictLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_CUMUL_DAILY_TRANS_LIMIT:
            return {
                ...state,
                cumulDailyTransLimitLoading: true,
                cumulDailyTransLimitError: null,
                cumulDailyTransLimit: null,
            };

        case types.GET_CUMUL_DAILY_TRANS_LIMIT_SUCCESS:
            return {
                ...state,
                cumulDailyTransLimitLoading: false,
                cumulDailyTransLimit: action.payload,
            };

        case types.GET_CUMUL_DAILY_TRANS_LIMIT_FAILURE:
            return {
                ...state,
                cumulDailyTransLimitLoading: false,
                cumulDailyTransLimitError: action.error,
            };

        case types.GET_CUMUL_DAILY_TRANS_LIMITS:
            return {
                ...state,
                cumulDailyTransLimitsLoading: true,
                cumulDailyTransLimitsError: null,
                cumulDailyTransLimits: null,
            };

        case types.GET_CUMUL_DAILY_TRANS_LIMITS_SUCCESS:
            return {
                ...state,
                cumulDailyTransLimitsLoading: false,
                cumulDailyTransLimits: action.payload,
            };

        case types.GET_CUMUL_DAILY_TRANS_LIMITS_FAILURE:
            return {
                ...state,
                cumulDailyTransLimitsLoading: false,
                cumulDailyTransLimitsError: action.error,
            };

        case types.CREATE_GLOBAL_TRANS_LIMIT:
            return {
                ...state,
                createGlobalTransLimitError: null,
                createGlobalTransLimitSuccess: null,
                createGlobalTransLimitLoading: true,
            };

        case types.CREATE_GLOBAL_TRANS_LIMIT_SUCCESS:
            return {
                ...state,
                createGlobalTransLimitLoading: false,
                createGlobalTransLimitSuccess: action.payload,
            };

        case types.CREATE_GLOBAL_TRANS_LIMIT_FAILURE:
            return {
                ...state,
                createGlobalTransLimitLoading: false,
                createGlobalTransLimitError: action.error,
            };

        case types.UPDATE_GLOBAL_TRANS_LIMIT:
            return {
                ...state,
                updateGlobalTransLimitError: null,
                updateGlobalTransLimitSuccess: null,
                updateGlobalTransLimitLoading: true,
            };

        case types.UPDATE_GLOBAL_TRANS_LIMIT_SUCCESS:
            return {
                ...state,
                updateGlobalTransLimitLoading: false,
                updateGlobalTransLimitSuccess: action.payload,
            };

        case types.UPDATE_GLOBAL_TRANS_LIMIT_FAILURE:
            return {
                ...state,
                updateGlobalTransLimitLoading: false,
                updateGlobalTransLimitError: action.error,
            };

        case types.CREATE_CUMUL_DAILY_TRANS_LIMIT:
            return {
                ...state,
                createCumulDailyTransLimitError: null,
                createCumulDailyTransLimitSuccess: null,
                createCumulDailyTransLimitLoading: true,
            };

        case types.CREATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS:
            return {
                ...state,
                createCumulDailyTransLimitLoading: false,
                createCumulDailyTransLimitSuccess: action.payload,
            };

        case types.CREATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE:
            return {
                ...state,
                createCumulDailyTransLimitLoading: false,
                createCumulDailyTransLimitError: action.error,
            };

        case types.UPDATE_CUMUL_DAILY_TRANS_LIMIT:
            return {
                ...state,
                updateCumulDailyTransLimitError: null,
                updateCumulDailyTransLimitSuccess: null,
                updateCumulDailyTransLimitLoading: true,
            };

        case types.UPDATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS:
            return {
                ...state,
                updateCumulDailyTransLimitLoading: false,
                updateCumulDailyTransLimitSuccess: action.payload,
            };

        case types.UPDATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE:
            return {
                ...state,
                updateCumulDailyTransLimitLoading: false,
                updateCumulDailyTransLimitError: action.error,
            };

        case types.UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT:
            return {
                ...state,
                updateBizCumulDailyTransLimitError: null,
                updateBizCumulDailyTransLimitSuccess: null,
                updateBizCumulDailyTransLimitLoading: true,
            };

        case types.UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_SUCCESS:
            return {
                ...state,
                updateBizCumulDailyTransLimitLoading: false,
                updateBizCumulDailyTransLimitSuccess: action.payload,
            };

        case types.UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_FAILURE:
            return {
                ...state,
                updateBizCumulDailyTransLimitLoading: false,
                updateBizCumulDailyTransLimitError: action.error,
            };

        case types.GET_PENDING_TRANS_LIMIT_APPROVAL:
            return {
                ...state,
                pendingTransLimitApproval: null,
                getPendingTransLimitApprovalError: null,
                getPendingTransLimitApprovalLoading: true,
            };

        case types.GET_PENDING_TRANS_LIMIT_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingTransLimitApprovalLoading: false,
                pendingTransLimitApproval: action.payload,
            };

        case types.GET_PENDING_TRANS_LIMIT_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingTransLimitApprovalLoading: false,
                getPendingTransLimitApprovalError: action.error,
            };

        case types.GET_PENDING_TRANS_LIMITS_APPROVAL:
            return {
                ...state,
                pendingTransLimitsApproval: null,
                getPendingTransLimitsApprovalError: null,
                getPendingTransLimitsApprovalLoading: true,
            };

        case types.GET_PENDING_TRANS_LIMITS_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingTransLimitsApprovalLoading: false,
                pendingTransLimitsApproval: action.payload,
            };

        case types.GET_PENDING_TRANS_LIMITS_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingTransLimitsApprovalLoading: false,
                getPendingTransLimitsApprovalError: action.error,
            };

        case types.CREATE_TRANS_LIMIT_APPROVAL_VERDICT:
            return {
                ...state,
                createTransLimitApprovalVerdictError: null,
                createTransLimitApprovalVerdictSuccess: null,
                createTransLimitApprovalVerdictLoading: true,
            };

        case types.CREATE_TRANS_LIMIT_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createTransLimitApprovalVerdictLoading: false,
                createTransLimitApprovalVerdictSuccess: action.payload,
            };

        case types.CREATE_TRANS_LIMIT_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createTransLimitApprovalVerdictLoading: false,
                createTransLimitApprovalVerdictError: action.error,
            };

        default:
            return state;
    }
}
