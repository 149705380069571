import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/approvals';
import { CREATE_APPROVAL_VERDICT, GET_APPROVAL_LOGS } from './approvalRequests.constant';
import { createApprovalVerdictSuccess, createApprovalVerdictFailure, getApprovalLogsSuccess, getApprovalLogsFailure } from './approvalRequests.action';


async function createApprovalVerdictRequest(payload) {
    const options = endpoint.createApprovalVerdit(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getApprovalLogsRequest({transactionId}) {
    const options = endpoint.getApprovalLogs(transactionId)
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* createApprovalVerdictWorker({ payload }) {
    try {
        let response = yield call(createApprovalVerdictRequest, payload);
        yield put(createApprovalVerdictSuccess(response.responseData));
    } catch(err) {
        yield put(createApprovalVerdictFailure(err));
    }
}

function* getApprovalLogsWorker({ payload }) {
    try {
        let response = yield call(getApprovalLogsRequest, payload);
        yield put(getApprovalLogsSuccess(response.approvalS));
    } catch(err) {
        yield put(getApprovalLogsFailure(err));
    }
}


function* createApprovalVerdictWatcher() {
    yield takeLatest(CREATE_APPROVAL_VERDICT, createApprovalVerdictWorker);
}

function* getApprovalLogsWatcher() {
    yield takeLatest(GET_APPROVAL_LOGS, getApprovalLogsWorker);
}


export default function*() {
    yield all([
        fork(createApprovalVerdictWatcher),
        fork(getApprovalLogsWatcher),
    ]);
}
