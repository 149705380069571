const BASE_URL = process.env.REACT_APP_BASE_URL_USER + 'isw/fees/';

export default {
    // SETTLEMENT PARTIES
    getSettlementParty: (id) => ({
        baseUrl: BASE_URL,
        url: `settlementParty/${id}`,
        method: 'GET',
        auth: true,
    }),

    getSettlementParties: (page, size, status, search)  => ({
        baseUrl: BASE_URL,
        url: `settlementParty?pageNumber=${page}&pageSize=${size}&status=${status}&searchText=${search}`,
        method: 'GET',
        auth: true,
    }),

    createSettlementParty: (payload) => ({
        baseUrl: BASE_URL,
        url: `settlementParty`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updateSettlementParty: (payload) => ({
        baseUrl: BASE_URL,
        url: `settlementParty`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    updateSettlementPartyStatus: (payload) => ({
        baseUrl: BASE_URL,
        url: `settlementParty/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getPendingSettlementPartyApproval: (id) => ({
        baseUrl: BASE_URL,
        url: `approval/settlementParty/${id}`,
        method: 'GET',
        auth: true,
    }),

    getPendingSettlementPartiesApproval: (page, size, status, action, search) => ({
        baseUrl: BASE_URL,
        url: `approval/settlementParty?pageNumber=${page}&pageSize=${size}&searchText=${search}&requestType=${action}&approvalStatus=${status}`,
        method: 'GET',
        auth: true,
    }),

    createSettlementPartyApprovalVerdict: (payload) => ({
        baseUrl: BASE_URL,
        url: `approval/settlementParty`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    // PAYMENT INSTRUMENTS
    getPaymentInstrument: (id)  => ({
        baseUrl: BASE_URL,
        url: `instrument/${id}`,
        method: 'GET',
        auth: true,
    }),

    getPaymentInstruments: (page, size, status, search)  => ({
        baseUrl: BASE_URL,
        url: `instrument?pageNumber=${page}&pageSize=${size}&status=${status}&searchText=${search}`,
        method: 'GET',
        auth: true,
    }),

    createPaymentInstrument: (payload) => ({
        baseUrl: BASE_URL,
        url: `instrument`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updatePaymentInstrument: (payload) => ({
        baseUrl: BASE_URL,
        url: `instrument`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    updatePaymentInstrumentStatus: (payload) => ({
        baseUrl: BASE_URL,
        url: `instrument/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getPendingPaymentInstrumentApproval: (id) => ({
        baseUrl: BASE_URL,
        url: `approval/instrument/${id}`,
        method: 'GET',
        auth: true,
    }),

    getPendingPaymentInstrumentsApproval: (page, size, status, action, search) => ({
        baseUrl: BASE_URL,
        url: `approval/instrument?pageNumber=${page}&pageSize=${size}&searchText=${search}&requestType=${action}&approvalStatus=${status}`,
        method: 'GET',
        auth: true,
    }),

    createPaymentInstrumentApprovalVerdict: (payload) => ({
        baseUrl: BASE_URL,
        url: `approval/instrument`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    //FEE CONFIGURATION
    getFeeConfiguration: (code)  => ({
        baseUrl: BASE_URL,
        url: `feeconfig/${code}`,
        method: 'GET',
        auth: true,
    }),

    getFeeConfigurations: (page, size, status, search)  => ({
        baseUrl: BASE_URL,
        url: `feeconfig?pageNumber=${page}&pageSize=${size}&status=${status}&searchText=${search}`,
        method: 'GET',
        auth: true,
    }),

    createFeeConfiguration: (payload) => ({
        baseUrl: BASE_URL,
        url: `feeconfig`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updateFeeConfiguration: (payload) => ({
        baseUrl: BASE_URL,
        url: `feeconfig`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    updateFeeConfigurationStatus: (payload) => ({
        baseUrl: BASE_URL,
        url: `feeconfig/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getPendingFeeConfigurationApproval: (id) => ({
        baseUrl: BASE_URL,
        url: `approval/feeconfig/${id}`,
        method: 'GET',
        auth: true,
    }),

    getPendingFeeConfigurationsApproval: (page, size, status, action, search) => ({
        baseUrl: BASE_URL,
        url: `approval/feeconfig?pageNumber=${page}&pageSize=${size}&searchText=${search}&requestType=${action}&approvalStatus=${status}`,
        method: 'GET',
        auth: true,
    }),

    createFeeConfigurationApprovalVerdict: (payload) => ({
        baseUrl: BASE_URL,
        url: `approval/feeconfig`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

     // BUSINESSES FEE CONFIGURATION
     getBusinessFeeConfig: (id)  => ({
        baseUrl: BASE_URL,
        url: `business/${id}`,
        method: 'GET',
        auth: true,
    }),

    assignFeeConfigToBusiness: (payload) => ({
        baseUrl: BASE_URL,
        url: `business`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    updateBusinessFeeConfig: (payload) => ({
        baseUrl: BASE_URL,
        url: `business`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    updateBusinessFeeConfigStatus: (payload) => ({
        baseUrl: BASE_URL,
        url: `business/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getPendingBusinessFeeConfigApproval: (id) => ({
        baseUrl: BASE_URL,
        url: `approval/business/${id}`,
        method: 'GET',
        auth: true,
    }),

    getPendingBusinessesFeeConfigApproval: (page, size, status, action, search) => ({
        baseUrl: BASE_URL,
        url: `approval/business?pageNumber=${page}&pageSize=${size}&searchText=${search}&requestType=${action}&approvalStatus=${status}`,
        method: 'GET',
        auth: true,
    }),

    createBusinessFeeConfigApprovalVerdict: (payload) => ({
        baseUrl: BASE_URL,
        url: `approval/business`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    //REPORTS
    getRevenueShareReport: (payload) => ({
        baseUrl: BASE_URL,
        url: `report/revenueshare`,
        method: 'POST',
        data: payload,
        auth: true,
    }),

    trackReportDownload: (trackingId) => ({
        baseUrl: BASE_URL,
        url: `report/downloadlink?trackingId=${trackingId}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),
};
