import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/feeMgt';
import { GET_BUSINESS_FEE_CONFIG, ASSIGN_FEE_CONFIG_TO_BUSINESS, UPDATE_BUSINESS_FEE_CONFIG, UPDATE_BUSINESS_FEE_CONFIG_STATUS, GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL, GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL, CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT } from './businessesFeeConfig.constant';
import { getBusinessFeeConfigSuccess, getBusinessFeeConfigFailure, assignFeeConfigToBusinessSuccess, assignFeeConfigToBusinessFailure, updateBusinessFeeConfigSuccess, updateBusinessFeeConfigFailure, updateBusinessFeeConfigStatusSuccess, updateBusinessFeeConfigStatusFailure, getPendingBusinessFeeConfigApprovalSuccess, getPendingBusinessFeeConfigApprovalFailure, getPendingBusinessesFeeConfigApprovalSuccess, getPendingBusinessesFeeConfigApprovalFailure, createBusinessFeeConfigApprovalVerdictSuccess, createBusinessFeeConfigApprovalVerdictFailure } from './businessesFeeConfig.action';

async function getBusinessFeeConfigRequest({ businessId }) {
    const options = endpoint.getBusinessFeeConfig(businessId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function assignFeeConfigToBusinessRequest(payload) {
    const options = endpoint.assignFeeConfigToBusiness(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateBusinessFeeConfigRequest(payload) {
    const options = endpoint.updateBusinessFeeConfig(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateBusinessFeeConfigStatusRequest(payload) {
    const options = endpoint.updateBusinessFeeConfigStatus(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingBusinessFeeConfigApprovalRequest({ requestId }) {
    const options = endpoint.getPendingBusinessFeeConfigApproval(requestId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingBusinessesFeeConfigApprovalRequest({ pageNumber, pageSize, status, actionType, searchParam }) {
    const options = endpoint.getPendingBusinessesFeeConfigApproval(pageNumber, pageSize, status, actionType, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createBusinessFeeConfigApprovalVerdictRequest(payload) {
    const options = endpoint.createBusinessFeeConfigApprovalVerdict(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getBusinessFeeConfigWorker({ payload }) {
    try {
        const response = yield call(getBusinessFeeConfigRequest, payload);
        yield put(getBusinessFeeConfigSuccess(response));
    } catch(err) {
        yield put(getBusinessFeeConfigFailure(err));
    }
}

function* assignFeeConfigToBusinessWorker({ payload }) {
    try {
        const response = yield call(assignFeeConfigToBusinessRequest, payload);
        yield put(assignFeeConfigToBusinessSuccess(response.responseDescription));
    } catch(err) {
        yield put(assignFeeConfigToBusinessFailure(err));
    }
}

function* updateBusinessFeeConfigWorker({ payload }) {
    try {
        const response = yield call(updateBusinessFeeConfigRequest, payload);
        yield put(updateBusinessFeeConfigSuccess(response.responseDescription));
    } catch(err) {
        yield put(updateBusinessFeeConfigFailure(err));
    }
}

function* updateBusinessFeeConfigStatusWorker({ payload }) {
    try {
        const response = yield call(updateBusinessFeeConfigStatusRequest, payload);
        yield put(updateBusinessFeeConfigStatusSuccess(response.responseDescription));
    } catch(err) {
        yield put(updateBusinessFeeConfigStatusFailure(err));
    }
}

function* getPendingBusinessFeeConfigApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingBusinessFeeConfigApprovalRequest, payload);
        yield put(getPendingBusinessFeeConfigApprovalSuccess(response));
    } catch(err) {
        yield put(getPendingBusinessFeeConfigApprovalFailure(err));
    }
}

function* getPendingBusinessesFeeConfigApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingBusinessesFeeConfigApprovalRequest, payload);
        const data = response.data || [];

        yield put(getPendingBusinessesFeeConfigApprovalSuccess({
            pendingBusinessesFeeConfigApproval: response,
            pendingBusinessesFeeConfigApprovalCount: data.length,
        }));
    } catch(err) {
        yield put(getPendingBusinessesFeeConfigApprovalFailure(err));
    }
}

function* createBusinessFeeConfigApprovalVerdictWorker({ payload }) {
    try {
        const response = yield call(createBusinessFeeConfigApprovalVerdictRequest, payload);
        yield put(createBusinessFeeConfigApprovalVerdictSuccess(response.responseDescription));
    } catch(err) {
        yield put(createBusinessFeeConfigApprovalVerdictFailure(err));
    }
}


function* getBusinessFeeConfigWatcher() {
    yield takeLatest(GET_BUSINESS_FEE_CONFIG, getBusinessFeeConfigWorker);
}

function* assignFeeConfigToBusinessWatcher() {
    yield takeLatest(ASSIGN_FEE_CONFIG_TO_BUSINESS, assignFeeConfigToBusinessWorker);
}

function* updateBusinessFeeConfigWatcher() {
    yield takeLatest(UPDATE_BUSINESS_FEE_CONFIG, updateBusinessFeeConfigWorker);
}

function* updateBusinessFeeConfigStatusWatcher() {
    yield takeLatest(UPDATE_BUSINESS_FEE_CONFIG_STATUS, updateBusinessFeeConfigStatusWorker);
}

function* getPendingBusinessFeeConfigApprovalWatcher() {
    yield takeLatest(GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL, getPendingBusinessFeeConfigApprovalWorker);
}

function* getPendingBusinessesFeeConfigApprovalWatcher() {
    yield takeLatest(GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL, getPendingBusinessesFeeConfigApprovalWorker);
}

function* createBusinessFeeConfigApprovalVerdictWatcher() {
    yield takeLatest(CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT, createBusinessFeeConfigApprovalVerdictWorker);
}


export default function*() {
    yield all([
        fork(getBusinessFeeConfigWatcher),
        fork(updateBusinessFeeConfigWatcher),
        fork(assignFeeConfigToBusinessWatcher),
        fork(updateBusinessFeeConfigStatusWatcher),
        fork(getPendingBusinessFeeConfigApprovalWatcher),
        fork(getPendingBusinessesFeeConfigApprovalWatcher),
        fork(createBusinessFeeConfigApprovalVerdictWatcher),
    ]);
}
