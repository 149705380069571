const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    changePassword: (payload) => ({
        baseUrl: BASE_URL,
        url: 'password/change',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getProfile: () => ({
        baseUrl: BASE_URL,
        url: 'users/me',
        method: 'GET',
        auth: true,
    }),

    updateProfile: (payload) => ({
        baseUrl: BASE_URL,
        url: 'users',
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getBusinessUsers: () => ({
        baseUrl: BASE_URL,
        url: 'users',
        method: 'GET',
        auth: true,
    }),

    searchBusinessUsers: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `users/search?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    createBusinessUser: (payload) => ({
        baseUrl: BASE_URL,
        url: 'users/',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    editBusinessUser: (payload) => ({
        baseUrl: BASE_URL,
        url: `users/${payload.id}/update`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    toggleBusinessUser: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `users/${id}/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    updateBusinessUserRole: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `users/${id}/role`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    resetBusinessUserPassword: (payload) => ({
        baseUrl: BASE_URL,
        url: 'password/notify-user',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    getBusinessRoles: () => ({
        baseUrl: BASE_URL,
        url: 'roles',
        method: 'GET',
        auth: true,
    }),

    searchBusinessRoles: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `roles/search?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    getBusinessRole: (id) => ({
        baseUrl: BASE_URL,
        url: `roles/${id}`,
        method: 'GET',
        auth: true,
    }),

    getBusinessRoleUsers: (id) => ({
        baseUrl: BASE_URL,
        url: `roles/${id}/users`,
        method: 'GET',
        auth: true,
    }),

    editBusinessRole: (payload) => ({
        baseUrl: BASE_URL,
        url: `roles/${payload.id}`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    createBusinessRole: (payload) => ({
        baseUrl: BASE_URL,
        url: 'roles/',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    deleteBusinessRole: (payload) => ({
        baseUrl: BASE_URL,
        url: 'roles/delete',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    assignPermissionstoRole: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `roles/${id}/permissions`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    getPermissions: () => ({
        baseUrl: BASE_URL,
        url: `permissions`,
        method: 'GET',
        auth: true,
    }),

    adminGetAdminRole: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin/${id}`,
        method: 'GET',
        auth: true,
    }),

    adminGetAdminRoles: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin`,
        method: 'GET',
        auth: true,
    }),

    adminSearchAdminRoles: (page, size, type, search) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin/search?pageNumber=${page}&pageSize=${size}&roleTypeId=${type}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    adminGetAdminRoleUsers: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin/${id}/users`,
        method: 'GET',
        auth: true,
    }),

    adminGetBusinessRole: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/${id}`,
        method: 'GET',
        auth: true,
    }),

    adminGetBusinessRoles: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}/roles`,
        method: 'GET',
        auth: true,
    }),

    adminSearchBusinessRoles: (id, page, size, search) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}/roles/search?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    adminCreateBusinessRole: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/roles/',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminCreateAdminRole: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/roles/admin/new',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminEditBusinessRole: (payload) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/${payload.id}`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminEditAdminRole: (payload) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin/${payload.id}`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminDeleteBusinessRole: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/roles/delete',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminDeleteAdminRole: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin/${id}/delete`,
        method: 'DELETE',
        auth: true,
    }),

    adminGetBusinessUsers: (id) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}/users`,
        method: 'GET',
        auth: true,
    }),

    adminSearchBusinessUsers: (id, page, size, search) => ({
        baseUrl: BASE_URL,
        url: `isw/business/${id}/users/search?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    adminSearchAllAdminUsers: (page, size, search) => ({
        baseUrl: BASE_URL,
        url: `isw/users/admin/search?pageNumber=${page}&pageSize=${size}&search=${search}`,
        method: 'GET',
        auth: true,
        encode: true,
    }),

    adminCreateBusinessUser: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/users/',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminCreateAdminUser: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/users/admin/create',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminEditAdminUser: (payload) => ({
        baseUrl: BASE_URL,
        url: `isw/users/admin/${payload.id}/update`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminUpdateBusinessUserRole: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/users/${id}/role`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminUpdateAdminUserRole: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/users/admin/${id}/role`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminResetBusinessUserPassword: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/password/notify-user',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminResetAdminUserPassword: (payload) => ({
        baseUrl: BASE_URL,
        url: 'isw/password/admin/notify-user',
        method: 'POST',
        data: payload,
        auth: true,
    }),

    adminToggleBusinessUser: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/users/${id}/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminToggleAdminUser: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/users/admin/${id}/status`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminAssignPermissionstoRole: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/${id}/permissions`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminAssignAdminPermissionstoRole: (id, payload) => ({
        baseUrl: BASE_URL,
        url: `isw/roles/admin/${id}/permissions`,
        method: 'PUT',
        data: payload,
        auth: true,
    }),

    adminGetPermissions: () => ({
        baseUrl: BASE_URL,
        url: `isw/business/permissions`,
        method: 'GET',
        auth: true,
    }),

    adminGetAdminPermissions: () => ({
        baseUrl: BASE_URL,
        url: `isw/permissions/admin`,
        method: 'GET',
        auth: true,
    }),
};
