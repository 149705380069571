import * as types from './settlementPositionsReport.constant';

export function getSettlementPositionsReportParameters(params) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getSettlementPositionsReportParametersSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getSettlementPositionsReportParametersFailure(error) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getSettlementPositionsReport(params) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT,
        payload: params,
    }
}

export function getSettlementPositionsReportSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_SUCCESS,
        payload: response,
    }
}

export function getSettlementPositionsReportFailure(error) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_FAILURE,
        error,
    }
}

export function getSettlementPositionsReportDownload(params) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getSettlementPositionsReportDownloadSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getSettlementPositionsReportDownloadFailure(error) {
    return {
        type: types.GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackSettlementPositionsReportDownload(params) {
    return {
        type: types.TRACK_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackSettlementPositionsReportDownloadSuccess(response) {
    return {
        type: types.TRACK_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}
