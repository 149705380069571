import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/payments';
import { INITIATE_PAYMENT, INITIATE_FIRS_PAYMENT, INITIATE_BULK_PAYMENT } from './initiatePayment.constant';
import { initiatePaymentSuccess, initiatePaymentFailure, initiateBulkPaymentSuccess, initiateBulkPaymentFailure } from './initiatePayment.action';


async function initiatePaymentRequest(payload) {
    const options = endpoint.initiatePayment(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}
async function initiateFirsPaymentRequest(payload) {
    const options = endpoint.initiateFirsPayment(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}
async function initiateBulkPaymentRequest({ payload, batchId, multitax }) {
    const options = endpoint.initiateBulkPayment(payload, batchId, multitax);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* initiatePaymentWorker({ payload }) {
    try {
        let response = yield call(initiatePaymentRequest, payload);

        if (typeof response !== 'string') {
            response = 'Payment request was successfully initiated!';
        }
        yield put(initiatePaymentSuccess(response));
    } catch(err) {
        yield put(initiatePaymentFailure(err));
    }
}
function* initiateFirsPaymentWorker({ payload }) {
    try {
        let response = yield call(initiateFirsPaymentRequest, payload);

        if (typeof response !== 'string') {
            response = 'Payment request was successfully initiated!';
        }
        yield put(initiatePaymentSuccess(response));
    } catch(err) {
        yield put(initiatePaymentFailure(err));
    }
}
function* initiateBulkPaymentWorker({ payload }) {
    try {
        let response = yield call(initiateBulkPaymentRequest, payload);
        yield put(initiateBulkPaymentSuccess(response.data));
    } catch(err) {
        yield put(initiateBulkPaymentFailure(err));
    }
}


function* initiatePaymentWatcher() {
    yield takeLatest(INITIATE_PAYMENT, initiatePaymentWorker);
}
function* initiateFirsPaymentWatcher() {
    yield takeLatest(INITIATE_FIRS_PAYMENT, initiateFirsPaymentWorker);
}
function* initiateBulkPaymentWatcher() {
    yield takeLatest(INITIATE_BULK_PAYMENT, initiateBulkPaymentWorker);
}


export default function*() {
    yield all([
        fork(initiatePaymentWatcher),
        fork(initiateFirsPaymentWatcher),
        fork(initiateBulkPaymentWatcher),
    ]);
}
