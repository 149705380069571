import * as types from './shared.constant';

export function logout() {
    return {
        type: types.LOGOUT,
    }
}

export function getBusinessTypes() {
    return {
        type: types.GET_BUSINESS_TYPES,
    }
}

export function getBusinessTypesSuccess(response) {
    return {
        type: types.GET_BUSINESS_TYPES_SUCCESS,
        payload: response,
    }
}

export function getBusinessTypesFailure(error) {
    return {
        type: types.GET_BUSINESS_TYPES_FAILURE,
        error
    }
}

export function getBanksList(params) {
    return {
        type: types.GET_BANKS_LIST,
        payload: params,
    }
}

export function getBanksListSuccess(response) {
    return {
        type: types.GET_BANKS_LIST_SUCCESS,
        payload: response,
    }
}

export function getBanksListFailure(error) {
    return {
        type: types.GET_BANKS_LIST_FAILURE,
        error,
    }
}

