import * as types from './paymentOptions.constant';

export function getCardBalance(payload) {
    return {
        type: types.GET_CARD_BALANCE,
        payload
    }
}

export function getCardBalanceSuccess(response) {
    return {
        type: types.GET_CARD_BALANCE_SUCCESS,
        payload: response,
    }
}

export function getCardBalanceFailure(error) {
    return {
        type: types.GET_CARD_BALANCE_FAILURE,
        error,
    }
}

export function getCardBalanceReset() {
    return {
        type: types.GET_CARD_BALANCE_RESET,
    }
}

export function createPaymentInstrument(payload) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT,
        payload,
    };
}

export function createPaymentInstrumentSuccess(response) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_SUCCESS,
        payload: response,
    };
}

export function createPaymentInstrumentFailure(error) {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_FAILURE,
        error,
    };
}

export function createPaymentInstrumentReset() {
    return {
        type: types.CREATE_PAYMENT_INSTRUMENT_RESET,
    };
}

export function updatePaymentInstrument(payload) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT,
        payload,
    };
}

export function updatePaymentInstrumentSuccess(response) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_SUCCESS,
        payload: response,
    };
}

export function updatePaymentInstrumentFailure(error) {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_FAILURE,
        error,
    };
}

export function updatePaymentInstrumentReset() {
    return {
        type: types.UPDATE_PAYMENT_INSTRUMENT_RESET,
    };
}

export function getSecureData() {
    return {
        type: types.GET_SECURE_DATA
    }
}

export function getSecureDataSuccess(response) {
    return {
        type: types.GET_SECURE_DATA_SUCCESS,
        payload: response,
    };
}


export function getSecureDataFailure(error) {
    return {
        type: types.GET_SECURE_DATA_FAILURE,
        error,
    };
}





