import * as types from './roles.constant';

export function getRole(payload) {
    return {
        type: types.GET_ROLE,
        payload,
    }
}

export function getRoleSuccess(response) {
    return {
        type: types.GET_ROLE_SUCCESS,
        payload: response,
    }
}

export function getRoleFailure(error) {
    return {
        type: types.GET_ROLE_FAILURE,
        error,
    }
}

export function getRoleUsers(payload) {
    return {
        type: types.GET_ROLE_USERS,
        payload,
    }
}

export function getRoleUsersSuccess(response) {
    return {
        type: types.GET_ROLE_USERS_SUCCESS,
        payload: response,
    }
}

export function getRoleUsersFailure(error) {
    return {
        type: types.GET_ROLE_USERS_FAILURE,
        error,
    }
}

export function getAllRoles(params) {
    return {
        type: types.GET_ALL_ROLES,
        payload: params,
    }
}

export function getAllRolesSuccess(response) {
    return {
        type: types.GET_ALL_ROLES_SUCCESS,
        payload: response,
    }
}

export function getAllRolesFailure(error) {
    return {
        type: types.GET_ALL_ROLES_FAILURE,
        error,
    }
}

export function editRole(payload) {
    return {
        type: types.EDIT_ROLE,
        payload,
    }
}

export function editRoleSuccess(response) {
    return {
        type: types.EDIT_ROLE_SUCCESS,
        payload: response,
    }
}

export function editRoleFailure(error) {
    return {
        type: types.EDIT_ROLE_FAILURE,
        error,
    }
}

export function createRole(payload) {
    return {
        type: types.CREATE_ROLE,
        payload,
    }
}

export function createRoleSuccess(response) {
    return {
        type: types.CREATE_ROLE_SUCCESS,
        payload: response,
    }
}

export function createRoleFailure(error) {
    return {
        type: types.CREATE_ROLE_FAILURE,
        error,
    }
}

export function deleteRole(payload) {
    return {
        type: types.DELETE_ROLE,
        payload,
    }
}

export function deleteRoleSuccess(response) {
    return {
        type: types.DELETE_ROLE_SUCCESS,
        payload: response,
    }
}

export function deleteRoleFailure(error) {
    return {
        type: types.DELETE_ROLE_FAILURE,
        error,
    }
}

export function assignPermissionsToRole(payload) {
    return {
        type: types.ASSIGN_PERMISSIONS_TO_ROLE,
        payload,
    }
}

export function assignPermissionsToRoleSuccess(response) {
    return {
        type: types.ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS,
        payload: response,
    }
}

export function assignPermissionsToRoleFailure(error) {
    return {
        type: types.ASSIGN_PERMISSIONS_TO_ROLE_FAILURE,
        error,
    }
}

export function getPermissions(params) {
    return {
        type: types.GET_PERMISSIONS,
        payload: params,
    }
}

export function getPermissionsSuccess(response) {
    return {
        type: types.GET_PERMISSIONS_SUCCESS,
        payload: response,
    }
}

export function getPermissionsFailure(error) {
    return {
        type: types.GET_PERMISSIONS_FAILURE,
        error,
    }
}
