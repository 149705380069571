import { combineReducers } from 'redux';
import rxSettlementParties from './settlementParties/redux/settlementParties.reducer';
import rxFeeConfigurations from './feeConfigurations/redux/feeConfigurations.reducer';
import rxPaymentInstruments from './paymentInstruments/redux/paymentInstruments.reducer';
import rxBusinessesFeeConfig from './businessesFeeConfig/redux/businessesFeeConfig.reducer';
import rxRevenueShareReport from './reports/revenueShareReport/redux/revenueShareReport.reducer';

const feeMgtReducers = combineReducers({
    rxSettlementParties,
    rxFeeConfigurations,
    rxPaymentInstruments,
    rxBusinessesFeeConfig,
    rxRevenueShareReport,
})

export default feeMgtReducers;
