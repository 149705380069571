import * as types from './generalTaxPayments.constant';

const initialState = {
    taxAuthoritiesInfo: null,
    taxAuthoritiesInfoError: null,
    taxAuthoritiesInfoLoading: false,
}

export default function (state = initialState, action) {
    switch(action.type) {

        case types.GET_TAX_AUTHORITIES_INFO:
            return {
                ...state,
                taxAuthoritiesInfoLoading: true,
                taxAuthoritiesInfoError: null,
                taxAuthoritiesInfo: null,
            }

        case types.GET_TAX_AUTHORITIES_INFO_SUCCESS:
            return {
                ...state,
                taxAuthoritiesInfoLoading: false,
                taxAuthoritiesInfo: action.payload,
            }

        case types.GET_TAX_AUTHORITIES_INFO_FAILURE:
            return {
                ...state,
                taxAuthoritiesInfoLoading: false,
                taxAuthoritiesInfoError: action.error,
            }

        case types.VALIDATE_TAX_UPLOAD:
            return {
                ...state,
                validateTaxUploadLoading: true,
                validateTaxUploadSuccess: null,
                validateTaxUploadError: null,
            }

        case types.VALIDATE_TAX_UPLOAD_SUCCESS:
            return {
                ...state,
                validateTaxUploadLoading: false,
                validateTaxUploadSuccess: action.payload,
            }

        case types.VALIDATE_TAX_UPLOAD_FAILURE:
            return {
                ...state,
                validateTaxUploadLoading: false,
                validateTaxUploadError: action.error,
            }

        default:
            return state;
    }
}