export const GET_BILLER_CATEGORIES = 'GET_BILLER_CATEGORIES';
export const GET_BILLER_CATEGORIES_SUCCESS = 'GET_BILLER_CATEGORIES_SUCCESS';
export const GET_BILLER_CATEGORIES_FAILURE = 'GET_BILLER_CATEGORIES_FAILURE';

export const SEARCH_BILLERS = 'SEARCH_BILLERS';
export const SEARCH_BILLERS_SUCCESS = 'SEARCH_BILLERS_SUCCESS';
export const SEARCH_BILLERS_FAILURE = 'SEARCH_BILLERS_FAILURE';

export const GET_BILLER_INFO = 'GET_BILLER_INFO';
export const GET_BILLER_INFO_SUCCESS = 'GET_BILLER_INFO_SUCCESS';
export const GET_BILLER_INFO_FAILURE = 'GET_BILLER_INFO_FAILURE';
