import * as types from './paymentSearch.constant';

const initialState = {
    searchPaymentReportLoading: false,
    searchPaymentReportError: null,
    paymentReport: null,
    searchPaymentReportDownloadLoading: false,
    searchPaymentReportDownloadError: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case types.SEARCH_PAYMENT_REPORT:
            return {
                ...state,
                searchPaymentReportLoading: true,
                searchPaymentReportError: null,
                paymentReport: null,
            }

        case types.SEARCH_PAYMENT_REPORT_SUCCESS:
            return {
                ...state,
                searchPaymentReportLoading: false,
                paymentReport: action.payload,
            }

        case types.SEARCH_PAYMENT_REPORT_FAILURE:
            return {
                ...state,
                searchPaymentReportLoading: false,
                searchPaymentReportError: action.error,
            }
        
        case types.SEARCH_PAYMENT_REPORT_DOWNLOAD:
            return {
                ...state,
                searchPaymentReportDownloadLoading: true,
                searchPaymentReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.SEARCH_PAYMENT_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                searchPaymentReportDownloadLoading: false,
                reportDownloadLink: action.payload,
            }

        case types.SEARCH_PAYMENT_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                searchPaymentReportDownloadLoading: false,
                searchPaymentReportDownloadError: action.error,
            }
            
        default:
            return state;
    }
}
