import React, { useState } from 'react'
import TransactionSide from './TransactionSide'
import classes from './Transaction.module.css'
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom'
import Axios from 'axios'
import close from '../../Assets/close.svg';
import search from '../../Assets/searchIcon.svg';
import caretRight from '../../Assets/CaretRight.svg';
import dstv from '../../Assets/dstv.svg'
import iroko from '../../Assets/irokotv.svg'
import trans from '../../Assets/trans.svg'
import monitor from '../../Assets/monitor.svg'

const TransactionMain = () => {
  const [allCategories, setAllCategories] = useState([])
  const [loadingTopCategories, setLoadingTopCategories] = useState(false)

  const LoadingSkeleton = () => 
  Array.from(new Array(8)).map((e,i)=> 
  <div className={classes.billerLink} key={i}>
    <Skeleton variant="circle" width={50} height={50} animation="wave" />
    <Skeleton variant="rect" width={70} height={6} animation="wave" />
  </div>
  )
    const getAllCategories = async () => {
      setLoadingTopCategories(true)
    try {
      const res = await Axios.get(`${process.env.REACT_APP_BASE_CATALOGUE}categories/list`)
      setAllCategories(res.data.data);
      setLoadingTopCategories(false)
    } catch (error) {
      console.log(error)
    }
    }
    React.useEffect(()=>{
    getAllCategories()
    },[])
  return (
    <main className={classes.transactionMain}>
        {/* <div>Search All Categories</div> */}
 
            {/* <p>Payment Category Page</p> */}
            <div className={classes.transactionInnerMain}>
                <div className={classes.paymentCategoryHeader}>
                    <p className={classes.categoriesHeader}>Payment Category</p>
                    <div className={classes.billersContainers}>
                        {loadingTopCategories ? <LoadingSkeleton/> : allCategories.map(e => <Link key={e.id} to={`/transaction/${e.routeId}`} className={classes.billerLink}>
                        <img src={e.imagePath} alt={e.name} />
                        {e.name}
                        </Link>)}
                    </div>
                </div>
                {/* <div className={classes.transactionInner1}>
                  <div className={classes.transactionInnerfirst}>
                    <p className={classes.categoriesHeader} style={{padding: '20px 40px', borderBottom: '1px solid #F3F5F6'}}>Payment Categories</p>
                    <div className={classes.billersContainers}>
                      {loadingTopCategories ? <LoadingSkeleton/> : allCategories.map(e => <Link key={e.id} to={`/transaction/${e.routeId}`} className={classes.billerLink}>
                      <img src={e.imagePath} alt={e.name} style={{width:30, height:30}} />
                      {e.name}
                      </Link>)}
                    </div>
                  </div>
                  <div className={classes.transactionInnersecond} style={{paddingRight:20}}>
                    <div className={classes.transactionInnersecondHeader} >
                      <img src={monitor} alt="" style={{ marginRight:10}} />
                      <p className={classes.categoriesHeader}>Cable TV Billers</p> 
                      <button>
                        <img src={close} alt="close" />  
                      </button> 
                    </div>
                    <div className={classes.billerSearch}>
                      <img src={search} alt="search icon" style={{width:15, height:15, marginRight:18}} />
                      <input type="text" placeholder='Search Billers' />
                    </div>
                    <button className={classes.billerContent}>
                      <img src={dstv} alt=""  style={{width:25, height:25,  marginRight:10}}/>
                      <p>MultiChoice</p>
                      <img src={caretRight} alt="caret right" style={{width:15, height:15, marginLeft: 'auto'}}/>
                    </button>
                    <button className={classes.billerContent}>
                      <img src={iroko} alt=""  style={{width:25, height:25, marginRight:10 }} />
                      <p>Iroko Tv</p>
                      <img src={caretRight} alt="caret right" style={{width:15, height:15, marginLeft: 'auto'}} />
                    </button>
                  </div>
                </div>
                <div className={classes.transactionInner2}>
                  <p className={classes.categoriesHeader}>Transaction Details</p>
                  <div className={classes.transactionInner2Main}>
                    <img src={trans} alt="transaction icon" style={{margin:'0 auto'}}/>
                    <p>Select a payment category and <br /> biller from the left pane to pay</p>
                  </div>
                </div> */}
                <div className={classes.transactionInner3}>
                  <TransactionSide />
                </div>
            </div>
    </main>
  )
}

export default TransactionMain