import * as types from './approvalRequests.constant';

export function createApprovalVerdict(payload) {
    return {
        type: types.CREATE_APPROVAL_VERDICT,
        payload,
    }
}

export function createApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_APPROVAL_VERDICT_FAILURE,
        error,
    }
}

export function getApprovalLogs(params) {
    return {
        type: types.GET_APPROVAL_LOGS,
        payload: params,
    }
}

export function getApprovalLogsSuccess(response) {
    return {
        type: types.GET_APPROVAL_LOGS_SUCCESS,
        payload: response,
    }
}

export function getApprovalLogsFailure(error) {
    return {
        type: types.GET_APPROVAL_LOGS_FAILURE,
        error,
    }
}
