import * as types from './businesses.constant';

export function getAllBusinesses(params = {}) {
    return {
        type: types.GET_ALL_BUSINESSES,
        payload: params,
    }
}

export function getAllBusinessesSuccess(response) {
    return {
        type: types.GET_ALL_BUSINESSES_SUCCESS,
        payload: response,
    }
}

export function getAllBusinessesFailure(error) {
    return {
        type: types.GET_ALL_BUSINESSES_FAILURE,
        error,
    }
}

export function getBusiness(id) {
    return {
        type: types.GET_BUSINESS,
        payload: id,
    }
}

export function getBusinessSuccess(response) {
    return {
        type: types.GET_BUSINESS_SUCCESS,
        payload: response,
    }
}

export function getBusinessFailure(error) {
    return {
        type: types.GET_BUSINESS_FAILURE,
        error,
    }
}

export function toggleBusiness(payload) {
    return {
        type: types.TOGGLE_BUSINESS,
        payload,
    }
}

export function toggleBusinessSuccess(response) {
    return {
        type: types.TOGGLE_BUSINESS_SUCCESS,
        payload: response,
    }
}

export function toggleBusinessFailure(error) {
    return {
        type: types.TOGGLE_BUSINESS_FAILURE,
        error,
    }
}

export function mapBusinessToPaydirect(payload) {
    return {
        type: types.MAP_BUSINESS_TO_PAYDIRECT,
        payload,
    }
}

export function mapBusinessToPaydirectSuccess(response) {
    return {
        type: types.MAP_BUSINESS_TO_PAYDIRECT_SUCCESS,
        payload: response,
    }
}

export function mapBusinessToPaydirectFailure(error) {
    return {
        type: types.MAP_BUSINESS_TO_PAYDIRECT_FAILURE,
        error,
    }
}

export function searchPaydirectBillers(params) {
    return {
        type: types.SEARCH_PAYDIRECT_BILLERS,
        payload: params,
    }
}

export function searchPaydirectBillersSuccess(response) {
    return {
        type: types.SEARCH_PAYDIRECT_BILLERS_SUCCESS,
        payload: response,
    }
}

export function searchPaydirectBillersFailure(error) {
    return {
        type: types.SEARCH_PAYDIRECT_BILLERS_FAILURE,
        error,
    }
}
