export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';

export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';

export const RESEND_VERFICATION = 'RESEND_VERFICATION';
export const RESEND_VERFICATION_SUCCESS = 'RESEND_VERFICATION_SUCCESS';
export const RESEND_VERFICATION_FAILURE = 'RESEND_VERFICATION_FAILURE';

export const GET_BUSINESS_OWNER = 'GET_BUSINESS_OWNER';
export const GET_BUSINESS_OWNER_SUCCESS = 'GET_BUSINESS_OWNER_SUCCESS';
export const GET_BUSINESS_OWNER_FAILURE = 'GET_BUSINESS_OWNER_FAILURE';

export const REGISTER_BUSINESS_OWNER = 'REGISTER_BUSINESS_OWNER';
export const REGISTER_BUSINESS_OWNER_SUCCESS = 'REGISTER_BUSINESS_OWNER_SUCCESS';
export const REGISTER_BUSINESS_OWNER_FAILURE = 'REGISTER_BUSINESS_OWNER_FAILURE';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const RESEND_OTP = 'RESEND_OTP';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';

