import * as types from './savedPayments.constant';

const initialState = {
    getSavedPaymentsLoading: false,
    getSavedPaymentsError: null,
    savedPayments: null,
    getSavedPaymentLoading: false,
    getSavedPaymentError: null,
    savedPayment: null,
    createSavedPaymentLoading: false,
    editSavedPaymentLoading: false,
    deleteSavedPaymentLoading: false,
    paymentToAdd: null,
    paymentItemPaymentToAdd: null,
    successMessage: null,
    errorMessage: null,
}

export default function(state = initialState, action) {
    switch (action.type) {
        case types.GET_SAVED_PAYMENTS:
            return {
                ...state,
                savedPayments: null,
                getSavedPaymentsError: null,
                getSavedPaymentsLoading: true,
            }
        
        case types.GET_SAVED_PAYMENTS_SUCCESS:
            return {
                ...state,
                savedPayments: action.payload,
                getSavedPaymentsLoading: false,
            }
        
        case types.GET_SAVED_PAYMENTS_FAILURE:
            return {
                ...state,
                getSavedPaymentsError: action.error,
                getSavedPaymentsLoading: false,
            }

        case types.GET_SAVED_PAYMENT:
            return {
                ...state,
                savedPayment: null,
                getSavedPaymentError: null,
                getSavedPaymentLoading: true,
            }

        case types.GET_SAVED_PAYMENT_SUCCESS:
            return {
                ...state,
                savedPayment: action.payload,
                getSavedPaymentLoading: false,
            }

        case types.GET_SAVED_PAYMENT_FAILURE:
            return {
                ...state,
                getSavedPaymentError: action.error,
                getSavedPaymentLoading: false,
            }

        case types.CREATE_SAVED_PAYMENT:
            return {
                ...state,
                createSavedPaymentLoading: true,
            }

        case types.CREATE_SAVED_PAYMENT_SUCCESS:
            return {
                ...state,
                successMessage: action.payload,
                createSavedPaymentLoading: false,
            }

        case types.CREATE_SAVED_PAYMENT_FAILURE:
            return {
                ...state,
                errorMessage: action.error,
                createSavedPaymentLoading: false,
            }

        case types.EDIT_SAVED_PAYMENT:
            return {
                ...state,
                editSavedPaymentLoading: true,
            }

        case types.EDIT_SAVED_PAYMENT_SUCCESS:
            return {
                ...state,
                successMessage: action.payload,
                editSavedPaymentLoading: false,
            }

        case types.EDIT_SAVED_PAYMENT_FAILURE:
            return {
                ...state,
                errorMessage: action.error,
                editSavedPaymentLoading: false,
            }

        case types.DELETE_SAVED_PAYMENT:
            return {
                ...state,
                deleteSavedPaymentLoading: true,
            }

        case types.DELETE_SAVED_PAYMENT_SUCCESS:
            return {
                ...state,
                successMessage: action.payload,
                deleteSavedPaymentLoading: false,
            }

        case types.DELETE_SAVED_PAYMENT_FAILURE:
            return {
                ...state,
                errorMessage: action.error,
                deleteSavedPaymentLoading: false,
            }

        case types.UPDATE_PAYMENT_TO_SAVED_PAYMENTS:
            return {
                ...state,
                paymentToAdd: action.payload,
            }
        
        case types.UPDATE_PAYMENT_ITEM_TO_PAYMENT_ITEMS:
            return {
                ...state,
                paymentItemPaymentToAdd: action.payload,
            }

        case types.HIDE_ALERT_SAVED_PAYMENTS_MESSAGE:
            return {
                ...state,
                errorMessage: null,
                successMessage: null,
            }
            
        default:
            return state
    }
}
