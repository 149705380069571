import * as types from './businessesFeeConfig.constant';

const initialState = {
    businessFeeConfig: null,
    businessFeeConfigError: null,
    businessFeeConfigLoading: false,
    assignFeeConfigToBusinessError: null,
    assignFeeConfigToBusinessSuccess: null,
    assignFeeConfigToBusinessLoading: false,
    updateBusinessFeeConfigError: null,
    updateBusinessFeeConfigSuccess: null,
    updateBusinessFeeConfigLoading: false,
    updateBusinessFeeConfigStatusError: null,
    updateBusinessFeeConfigStatusSuccess: null,
    updateBusinessFeeConfigStatusLoading: false,
    pendingBusinessFeeConfigApproval: null,
    getPendingBusinessFeeConfigApprovalError: null,
    getPendingBusinessFeeConfigApprovalLoading: false,
    pendingBusinessesFeeConfigApproval: null,
    pendingBusinessesFeeConfigApprovalCount: null,
    getPendingBusinessesFeeConfigApprovalError: null,
    getPendingBusinessesFeeConfigApprovalLoading: false,
    createBusinessFeeConfigApprovalVerdictError: null,
    createBusinessFeeConfigApprovalVerdictSuccess: null,
    createBusinessFeeConfigApprovalVerdictLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_BUSINESS_FEE_CONFIG:
            return {
                ...state,
                businessFeeConfigLoading: true,
                businessFeeConfigError: null,
                businessFeeConfig: null,
            }

        case types.GET_BUSINESS_FEE_CONFIG_SUCCESS:
            return {
                ...state,
                businessFeeConfigLoading: false,
                businessFeeConfig: action.payload,
            }

        case types.GET_BUSINESS_FEE_CONFIG_FAILURE:
            return {
                ...state,
                businessFeeConfigLoading: false,
                businessFeeConfigError: action.error,
            }

        case types.ASSIGN_FEE_CONFIG_TO_BUSINESS:
            return {
                ...state,
                assignFeeConfigToBusinessError: null,
                assignFeeConfigToBusinessSuccess: null,
                assignFeeConfigToBusinessLoading: true,
            }

        case types.ASSIGN_FEE_CONFIG_TO_BUSINESS_SUCCESS:
            return {
                ...state,
                assignFeeConfigToBusinessLoading: false,
                assignFeeConfigToBusinessSuccess: action.payload,
            }

        case types.ASSIGN_FEE_CONFIG_TO_BUSINESS_FAILURE:
            return {
                ...state,
                assignFeeConfigToBusinessLoading: false,
                assignFeeConfigToBusinessError: action.error,
            }

        case types.UPDATE_BUSINESS_FEE_CONFIG:
            return {
                ...state,
                updateBusinessFeeConfigError: null,
                updateBusinessFeeConfigSuccess: null,
                updateBusinessFeeConfigLoading: true,
            }

        case types.UPDATE_BUSINESS_FEE_CONFIG_SUCCESS:
            return {
                ...state,
                updateBusinessFeeConfigLoading: false,
                updateBusinessFeeConfigSuccess: action.payload,
            }

        case types.UPDATE_BUSINESS_FEE_CONFIG_FAILURE:
            return {
                ...state,
                updateBusinessFeeConfigLoading: false,
                updateBusinessFeeConfigError: action.error,
            }

        case types.UPDATE_BUSINESS_FEE_CONFIG_STATUS:
            return {
                ...state,
                updateBusinessFeeConfigStatusError: null,
                updateBusinessFeeConfigStatusSuccess: null,
                updateBusinessFeeConfigStatusLoading: true,
            }

        case types.UPDATE_BUSINESS_FEE_CONFIG_STATUS_SUCCESS:
            return {
                ...state,
                updateBusinessFeeConfigStatusLoading: false,
                updateBusinessFeeConfigStatusSuccess: action.payload,
            }

        case types.UPDATE_BUSINESS_FEE_CONFIG_STATUS_FAILURE:
            return {
                ...state,
                updateBusinessFeeConfigStatusLoading: false,
                updateBusinessFeeConfigStatusError: action.error,
            }

        case types.GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL:
            return {
                ...state,
                pendingBusinessFeeConfigApproval: null,
                getPendingBusinessFeeConfigApprovalError: null,
                getPendingBusinessFeeConfigApprovalLoading: true,
            }

        case types.GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingBusinessFeeConfigApprovalLoading: false,
                pendingBusinessFeeConfigApproval: action.payload,
            }

        case types.GET_PENDING_BUSINESS_FEE_CONFIG_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingBusinessFeeConfigApprovalLoading: false,
                getPendingBusinessFeeConfigApprovalError: action.error,
            }

        case types.GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL:
            return {
                ...state,
                pendingBusinessesFeeConfigApproval: null,
                getPendingBusinessesFeeConfigApprovalError: null,
                getPendingBusinessesFeeConfigApprovalLoading: true,
            }

        case types.GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingBusinessesFeeConfigApprovalLoading: false,
                ...action.payload,
            }

        case types.GET_PENDING_BUSINESSES_FEE_CONFIG_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingBusinessesFeeConfigApprovalLoading: false,
                getPendingBusinessesFeeConfigApprovalError: action.error,
            }

        case types.CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT:
            return {
                ...state,
                createBusinessFeeConfigApprovalVerdictError: null,
                createBusinessFeeConfigApprovalVerdictSuccess: null,
                createBusinessFeeConfigApprovalVerdictLoading: true,
            }

        case types.CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createBusinessFeeConfigApprovalVerdictLoading: false,
                createBusinessFeeConfigApprovalVerdictSuccess: action.payload,
            }

        case types.CREATE_BUSINESS_FEE_CONFIG_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createBusinessFeeConfigApprovalVerdictLoading: false,
                createBusinessFeeConfigApprovalVerdictError: action.error,
            }

        default:
            return state;
    }
}
