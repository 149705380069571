import * as types from './migrateBusiness.constant';


export function authenticatePaydirectUser(payload) {
    return {
        type: types.AUTHENTICATE_PAYDIRECT_USER,
        payload,
    }
}

export function authenticatePaydirectUserSuccess(response) {
    return {
        type: types.AUTHENTICATE_PAYDIRECT_USER_SUCCESS,
        payload: response,
    }
}

export function authenticatePaydirectUserFailure(error) {
    return {
        type: types.AUTHENTICATE_PAYDIRECT_USER_FAILURE,
        error
    }
}

export function createUserProfile(payload) {
    return {
        type: types.CREATE_USER_PROFILE,
        payload,
    }
}

export function createUserProfileSuccess(response) {
    return {
        type: types.CREATE_USER_PROFILE_SUCCESS,
        payload: response,
    }
}

export function createUserProfileFailure(error) {
    return {
        type: types.CREATE_USER_PROFILE_FAILURE,
        error
    }
}

