import * as types from './pendingApprovals.constant';

const initialState = {
    pendingApprovals: null,
    pendingApprovalsError: null,
    pendingApprovalsLoading: false,
    pendingApprovalsCount: null,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_PENDING_APPROVALS:
            return {
                ...state,
                pendingApprovalsLoading: true,
                pendingApprovalsError: null,
                pendingApprovals: null,
            }

        case types.GET_PENDING_APPROVALS_SUCCESS:
            return {
                ...state,
                pendingApprovalsLoading: false,
                ...action.payload,
            }

        case types.GET_PENDING_APPROVALS_FAILURE:
            return {
                ...state,
                pendingApprovalsLoading: false,
                pendingApprovalsError: action.error,
            }
        
        default:
            return state;
    }
}
