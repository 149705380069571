import { all } from 'redux-saga/effects';
import settlementPartiesSaga from './settlementParties/redux/settlementParties.saga';
import feeConfigurationsSaga from './feeConfigurations/redux/feeConfigurations.saga';
import paymentInstrumentsSaga from './paymentInstruments/redux/paymentInstruments.saga';
import businessesFeeConfigSaga from './businessesFeeConfig/redux/businessesFeeConfig.saga';
import revenueShareReport from './reports/revenueShareReport/redux/revenueShareReport.saga';

export default function*() {
    yield all([
        revenueShareReport(),
        settlementPartiesSaga(),
        feeConfigurationsSaga(),
        paymentInstrumentsSaga(),
        businessesFeeConfigSaga(),
    ]);
}