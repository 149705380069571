const BASE_URL = process.env.REACT_APP_BASE_URL_USER;

export default {
    getAuditTrail: ({ StartDate, EndDate, pageNumber, pageSize, IsDownload }) => ({
        baseUrl: BASE_URL,
        url: `audit?start=${StartDate}&end=${EndDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&isDownload=${IsDownload}`,
        method: 'GET',
        auth: true,
    }),

    searchAuditTrail: ({ StartDate, EndDate, SearchKey, SearchTerm, pageNumber, pageSize, IsDownload }) => ({
        baseUrl: BASE_URL,
        url: `audit/search?start=${StartDate}&end=${EndDate}&searchTerm=${SearchTerm}&searchKey=${SearchKey}&pageNumber=${pageNumber}&pageSize=${pageSize}&isDownload=${IsDownload}`,
        method: 'GET',
        auth: true,
    }),

    iswAdminGetAuditTrail: ({ businessId, StartDate, EndDate, pageNumber, pageSize, IsDownload }) => ({
        baseUrl: BASE_URL,
        url: `isw/audit/business?start=${StartDate}&end=${EndDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&businessId=${businessId}&isDownload=${IsDownload}`,
        method: 'GET',
        auth: true,
    }),

    iswAdminSearchAuditTrail: ({ businessId, StartDate, EndDate, SearchKey, SearchTerm, pageNumber, pageSize, IsDownload }) => ({
        baseUrl: BASE_URL,
        url: `isw/audit/search?start=${StartDate}&end=${EndDate}&searchTerm=${SearchTerm}&searchKey=${SearchKey}&pageNumber=${pageNumber}&pageSize=${pageSize}&businessId=${businessId}&isDownload=${IsDownload}`,
        method: 'GET',
        auth: true,
    }),
};
