import * as types from './transactions.constant';

export function getAllTransactionsList(params) {
    return {
        type: types.GET_ALL_TRANSACTIONS_LIST,
        payload: params,
    };
}

export function getAllTransactionsListSuccess(response) {
    return {
        type: types.GET_ALL_TRANSACTIONS_LIST_SUCCESS,
        payload: response,
    };
}

export function getAllTransactionsListFailure(error) {
    return {
        type: types.GET_ALL_TRANSACTIONS_LIST_FAILURE,
        error,
    };
}

export function getSingleTransactionsList(params) {
    return {
        type: types.GET_SINGLE_TRANSACTIONS_LIST,
        payload: params,
    };
}

export function getSingleTransactionsListSuccess(response) {
    return {
        type: types.GET_SINGLE_TRANSACTIONS_LIST_SUCCESS,
        payload: response,
    };
}

export function getSingleTransactionsListFailure(error) {
    return {
        type: types.GET_SINGLE_TRANSACTIONS_LIST_FAILURE,
        error,
    };
}

export function getBatchTransactionsList(params) {
    return {
        type: types.GET_BATCH_TRANSACTIONS_LIST,
        payload: params,
    };
}

export function getBatchTransactionsListSuccess(response) {
    return {
        type: types.GET_BATCH_TRANSACTIONS_LIST_SUCCESS,
        payload: response,
    };
}

export function getBatchTransactionsListFailure(error) {
    return {
        type: types.GET_BATCH_TRANSACTIONS_LIST_FAILURE,
        error,
    };
}

export function getTransactionsAwaitingPayment(params) {
    return {
        type: types.GET_TRANSACTIONS_AWAITING_PAYMENT,
        payload: params,
    };
}

export function getTransactionsAwaitingPaymentSuccess(response) {
    return {
        type: types.GET_TRANSACTIONS_AWAITING_PAYMENT_SUCCESS,
        payload: response,
    };
}

export function getTransactionsAwaitingPaymentFailure(error) {
    return {
        type: types.GET_TRANSACTIONS_AWAITING_PAYMENT_FAILURE,
        error,
    };
}

export function getTransactionPayments(params) {
    return {
        type: types.GET_TRANSACTION_PAYMENTS,
        payload: params,
    };
}

export function getTransactionPaymentsSuccess(response) {
    return {
        type: types.GET_TRANSACTION_PAYMENTS_SUCCESS,
        payload: response,
    };
}

export function getTransactionPaymentsFailure(error) {
    return {
        type: types.GET_TRANSACTION_PAYMENTS_FAILURE,
        error,
    };
}

export function getTransactionBatch(params) {
    return {
        type: types.GET_TRANSACTION_BATCH,
        payload: params,
    };
}

export function getTransactionBatchSuccess(response) {
    return {
        type: types.GET_TRANSACTION_BATCH_SUCCESS,
        payload: response,
    };
}

export function getTransactionBatchFailure(error) {
    return {
        type: types.GET_TRANSACTION_BATCH_FAILURE,
        error,
    };
}

export function getTransactionBatchReceiptLink(params) {
    return {
        type: types.GET_TRANSACTION_BATCH_RECEIPT_LINK,
        payload: params,
    };
}

export function getTransactionBatchReceiptLinkSuccess(response) {
    return {
        type: types.GET_TRANSACTION_BATCH_RECEIPT_LINK_SUCCESS,
        payload: response,
    };
}

export function getTransactionBatchReceiptLinkFailure(error) {
    return {
        type: types.GET_TRANSACTION_BATCH_RECEIPT_LINK_FAILURE,
        error,
    };
}

export function getAllTransactionsListDownload(params) {
    return {
        type: types.GET_ALL_TRANSACTIONS_LIST_DOWNLOAD,
        payload: params,
    }
}

export function getAllTransactionsListDownloadSuccess(response) {
    return {
        type: types.GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getAllTransactionsListDownloadFailure(error) {
    return {
        type: types.GET_ALL_TRANSACTIONS_LIST_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackAllTransactionsListDownload(params) {
    return {
        type: types.TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD,
        payload: params,
    }
}

export function trackAllTransactionsListDownloadSuccess(response) {
    return {
        type: types.TRACK_ALL_TRANSACTIONS_LIST_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

