import * as types from './settlementParties.constant';

export function getSettlementParty(params) {
    return {
        type: types.GET_SETTLEMENT_PARTY,
        payload: params,
    }
}

export function getSettlementPartySuccess(response) {
    return {
        type: types.GET_SETTLEMENT_PARTY_SUCCESS,
        payload: response,
    }
}

export function getSettlementPartyFailure(error) {
    return {
        type: types.GET_SETTLEMENT_PARTY_FAILURE,
        error,
    }
}

export function getSettlementParties(params) {
    return {
        type: types.GET_SETTLEMENT_PARTIES,
        payload: params,
    }
}

export function getSettlementPartiesSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_PARTIES_SUCCESS,
        payload: response,
    }
}

export function getSettlementPartiesFailure(error) {
    return {
        type: types.GET_SETTLEMENT_PARTIES_FAILURE,
        error,
    }
}

export function createSettlementParty(payload) {
    return {
        type: types.CREATE_SETTLEMENT_PARTY,
        payload,
    }
}

export function createSettlementPartySuccess(response) {
    return {
        type: types.CREATE_SETTLEMENT_PARTY_SUCCESS,
        payload: response,
    }
}

export function createSettlementPartyFailure(error) {
    return {
        type: types.CREATE_SETTLEMENT_PARTY_FAILURE,
        error,
    }
}

export function updateSettlementParty(payload) {
    return {
        type: types.UPDATE_SETTLEMENT_PARTY,
        payload,
    }
}

export function updateSettlementPartySuccess(response) {
    return {
        type: types.UPDATE_SETTLEMENT_PARTY_SUCCESS,
        payload: response,
    }
}

export function updateSettlementPartyFailure(error) {
    return {
        type: types.UPDATE_SETTLEMENT_PARTY_FAILURE,
        error,
    }
}

export function updateSettlementPartyStatus(payload) {
    return {
        type: types.UPDATE_SETTLEMENT_PARTY_STATUS,
        payload,
    }
}

export function updateSettlementPartyStatusSuccess(response) {
    return {
        type: types.UPDATE_SETTLEMENT_PARTY_STATUS_SUCCESS,
        payload: response,
    }
}

export function updateSettlementPartyStatusFailure(error) {
    return {
        type: types.UPDATE_SETTLEMENT_PARTY_STATUS_FAILURE,
        error,
    }
}

export function getPendingSettlementPartyApproval(params) {
    return {
        type: types.GET_PENDING_SETTLEMENT_PARTY_APPROVAL,
        payload: params,
    }
}

export function getPendingSettlementPartyApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_SETTLEMENT_PARTY_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingSettlementPartyApprovalFailure(error) {
    return {
        type: types.GET_PENDING_SETTLEMENT_PARTY_APPROVAL_FAILURE,
        error,
    }
}

export function getPendingSettlementPartiesApproval(params) {
    return {
        type: types.GET_PENDING_SETTLEMENT_PARTIES_APPROVAL,
        payload: params,
    }
}

export function getPendingSettlementPartiesApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingSettlementPartiesApprovalFailure(error) {
    return {
        type: types.GET_PENDING_SETTLEMENT_PARTIES_APPROVAL_FAILURE,
        error,
    }
}

export function createSettlementPartyApprovalVerdict(payload) {
    return {
        type: types.CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT,
        payload,
    }
}

export function createSettlementPartyApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createSettlementPartyApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_SETTLEMENT_PARTY_APPROVAL_VERDICT_FAILURE,
        error,
    }
}