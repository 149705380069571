import * as types from './feeConfigurations.constant';

const initialState = {
    feeConfiguration: null,
    feeConfigurationError: null,
    feeConfigurationLoading: false,
    feeConfigurations: null,
    feeConfigurationsError: null,
    feeConfigurationsLoading: false,
    createFeeConfigurationError: null,
    createFeeConfigurationSuccess: null,
    createFeeConfigurationLoading: false,
    updateFeeConfigurationError: null,
    updateFeeConfigurationSuccess: null,
    updateFeeConfigurationLoading: false,
    updateFeeConfigurationStatusError: null,
    updateFeeConfigurationStatusSuccess: null,
    updateFeeConfigurationStatusLoading: false,
    pendingFeeConfigurationApproval: null,
    getPendingFeeConfigurationApprovalError: null,
    getPendingFeeConfigurationApprovalLoading: false,
    pendingFeeConfigurationsApproval: null,
    pendingFeeConfigurationsApprovalCount: null,
    getPendingFeeConfigurationsApprovalError: null,
    getPendingFeeConfigurationsApprovalLoading: false,
    createFeeConfigurationApprovalVerdictError: null,
    createFeeConfigurationApprovalVerdictSuccess: null,
    createFeeConfigurationApprovalVerdictLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.GET_FEE_CONFIGURATION:
            return {
                ...state,
                feeConfigurationLoading: true,
                feeConfigurationError: null,
                feeConfiguration: null,
            }

        case types.GET_FEE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                feeConfigurationLoading: false,
                feeConfiguration: action.payload,
            }

        case types.GET_FEE_CONFIGURATION_FAILURE:
            return {
                ...state,
                feeConfigurationLoading: false,
                feeConfigurationError: action.error,
            }
        case types.GET_FEE_CONFIGURATIONS:
            return {
                ...state,
                feeConfigurationsLoading: true,
                feeConfigurationsError: null,
                feeConfigurations: null,
            }

        case types.GET_FEE_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                feeConfigurationsLoading: false,
                feeConfigurations: action.payload,
            }

        case types.GET_FEE_CONFIGURATIONS_FAILURE:
            return {
                ...state,
                feeConfigurationsLoading: false,
                feeConfigurationsError: action.error,
            }

        case types.CREATE_FEE_CONFIGURATION:
            return {
                ...state,
                createFeeConfigurationError: null,
                createFeeConfigurationSuccess: null,
                createFeeConfigurationLoading: true,
            }

        case types.CREATE_FEE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                createFeeConfigurationLoading: false,
                createFeeConfigurationSuccess: action.payload,
            }

        case types.CREATE_FEE_CONFIGURATION_FAILURE:
            return {
                ...state,
                createFeeConfigurationLoading: false,
                createFeeConfigurationError: action.error,
            }

        case types.UPDATE_FEE_CONFIGURATION:
            return {
                ...state,
                updateFeeConfigurationError: null,
                updateFeeConfigurationSuccess: null,
                updateFeeConfigurationLoading: true,
            }

        case types.UPDATE_FEE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                updateFeeConfigurationLoading: false,
                updateFeeConfigurationSuccess: action.payload,
            }

        case types.UPDATE_FEE_CONFIGURATION_FAILURE:
            return {
                ...state,
                updateFeeConfigurationLoading: false,
                updateFeeConfigurationError: action.error,
            }

        case types.UPDATE_FEE_CONFIGURATION_STATUS:
            return {
                ...state,
                updateFeeConfigurationStatusError: null,
                updateFeeConfigurationStatusSuccess: null,
                updateFeeConfigurationStatusLoading: true,
            }

        case types.UPDATE_FEE_CONFIGURATION_STATUS_SUCCESS:
            return {
                ...state,
                updateFeeConfigurationStatusLoading: false,
                updateFeeConfigurationStatusSuccess: action.payload,
            }

        case types.UPDATE_FEE_CONFIGURATION_STATUS_FAILURE:
            return {
                ...state,
                updateFeeConfigurationStatusLoading: false,
                updateFeeConfigurationStatusError: action.error,
            }

        case types.GET_PENDING_FEE_CONFIGURATION_APPROVAL:
            return {
                ...state,
                pendingFeeConfigurationApproval: null,
                getPendingFeeConfigurationApprovalError: null,
                getPendingFeeConfigurationApprovalLoading: true,
            }

        case types.GET_PENDING_FEE_CONFIGURATION_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingFeeConfigurationApprovalLoading: false,
                pendingFeeConfigurationApproval: action.payload,
            }

        case types.GET_PENDING_FEE_CONFIGURATION_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingFeeConfigurationApprovalLoading: false,
                getPendingFeeConfigurationApprovalError: action.error,
            }

        case types.GET_PENDING_FEE_CONFIGURATIONS_APPROVAL:
            return {
                ...state,
                pendingFeeConfigurationsApproval: null,
                getPendingFeeConfigurationsApprovalError: null,
                getPendingFeeConfigurationsApprovalLoading: true,
            }

        case types.GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_SUCCESS:
            return {
                ...state,
                getPendingFeeConfigurationsApprovalLoading: false,
                ...action.payload,
            }

        case types.GET_PENDING_FEE_CONFIGURATIONS_APPROVAL_FAILURE:
            return {
                ...state,
                getPendingFeeConfigurationsApprovalLoading: false,
                getPendingFeeConfigurationsApprovalError: action.error,
            }

        case types.CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT:
            return {
                ...state,
                createFeeConfigurationApprovalVerdictError: null,
                createFeeConfigurationApprovalVerdictSuccess: null,
                createFeeConfigurationApprovalVerdictLoading: true,
            }

        case types.CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createFeeConfigurationApprovalVerdictLoading: false,
                createFeeConfigurationApprovalVerdictSuccess: action.payload,
            }

        case types.CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createFeeConfigurationApprovalVerdictLoading: false,
                createFeeConfigurationApprovalVerdictError: action.error,
            }

        default:
            return state;
    }
}
