import * as types from './transactionReport.constant';

const initialState = {
    getTransactionReportParametersLoading: false,
    getTransactionReportParametersError: null,
    transactionReportParameters: null,
    getTransactionReportLoading: false,
    getTransactionReportError: null,
    transactionReport: null,
    getTransactionReportDownloadLoading: false,
    getTransactionReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_TRANSACTION_REPORT_PARAMETERS:
            return {
                ...state,
                getTransactionReportParametersLoading: true,
                getTransactionReportParametersError: null,
                transactionReportParameters: null,
            }

        case types.GET_TRANSACTION_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getTransactionReportParametersLoading: false,
                transactionReportParameters: action.payload,
            }

        case types.GET_TRANSACTION_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getTransactionReportParametersLoading: false,
                getTransactionReportParametersError: action.error,
            }

        case types.GET_TRANSACTION_REPORT:
            return {
                ...state,
                getTransactionReportLoading: true,
                getTransactionReportError: null,
                transactionReport: null,
            }

        case types.GET_TRANSACTION_REPORT_SUCCESS:
            return {
                ...state,
                getTransactionReportLoading: false,
                transactionReport: action.payload,
            }

        case types.GET_TRANSACTION_REPORT_FAILURE:
            return {
                ...state,
                getTransactionReportLoading: false,
                getTransactionReportError: action.error,
            }

        case types.GET_TRANSACTION_REPORT_DOWNLOAD:
            return {
                ...state,
                getTransactionReportDownloadLoading: true,
                getTransactionReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_TRANSACTION_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getTransactionReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_TRANSACTION_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getTransactionReportDownloadLoading: false,
                getTransactionReportDownloadError: action.error,
            }

        case types.TRACK_TRANSACTION_REPORT_DOWNLOAD:
            return {
                ...state,
                getTransactionReportDownloadLoading: true,
                getTransactionReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_TRANSACTION_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getTransactionReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }
    
        default:
            return state;
    }
}
