import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/reports';
import { GET_TRANSACTION_REPORT_PARAMETERS, GET_TRANSACTION_REPORT, GET_TRANSACTION_REPORT_DOWNLOAD, TRACK_TRANSACTION_REPORT_DOWNLOAD } from './transactionReport.constant';
import { getTransactionReportParametersSuccess, getTransactionReportParametersFailure, getTransactionReportSuccess, getTransactionReportFailure, getTransactionReportDownloadSuccess, getTransactionReportDownloadFailure, trackTransactionReportDownloadSuccess } from './transactionReport.action';


async function getTransactionReportParametersRequest({productId, currencyCode}) {
    const options = endpoint.getTransactionReportParameters(productId, currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getTransactionReportRequest(payload) {
    const options = endpoint.getTransactionReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackTransactionReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getTransactionReportParametersWorker({ payload }) {
    try {
        const response = yield call(getTransactionReportParametersRequest, payload);
        yield put(getTransactionReportParametersSuccess(response.parameter));
    } catch(err) {
        yield put(getTransactionReportParametersFailure(err));
    }
}

function* getTransactionReportWorker({ payload }) {
    try {
        const response = yield call(getTransactionReportRequest, payload);
        yield put(getTransactionReportSuccess(response));
    } catch(err) {
        yield put(getTransactionReportFailure(err));
    }
}

function* getTransactionReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getTransactionReportRequest, payload);
        yield put(getTransactionReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getTransactionReportDownloadFailure(err));
    }
}

function* trackTransactionReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackTransactionReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackTransactionReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getTransactionReportDownloadFailure(err));
    }
}




function* getTransactionReportParametersWatcher() {
    yield takeLatest(GET_TRANSACTION_REPORT_PARAMETERS, getTransactionReportParametersWorker);
}

function* getTransactionReportWatcher() {
    yield takeLatest(GET_TRANSACTION_REPORT, getTransactionReportWorker);
}

function* getTransactionReportDownloadWatcher() {
    yield takeLatest(GET_TRANSACTION_REPORT_DOWNLOAD, getTransactionReportDownloadWorker);
}

function* trackTransactionReportDownloadWatcher() {
    yield takeLatest(TRACK_TRANSACTION_REPORT_DOWNLOAD, trackTransactionReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getTransactionReportParametersWatcher),
        fork(getTransactionReportWatcher),
        fork(getTransactionReportDownloadWatcher),
        fork(trackTransactionReportDownloadWatcher)
    ]);
}
