import * as types from './auditTrail.constant';


export function getAuditTrail(params) {
    return {
        type: types.GET_AUDIT_TRAIL,
        payload: params,
    }
}

export function getAuditTrailSuccess(response) {
    return {
        type: types.GET_AUDIT_TRAIL_SUCCESS,
        payload: response,
    }
}

export function getAuditTrailFailure(error) {
    return {
        type: types.GET_AUDIT_TRAIL_FAILURE,
        error,
    }
}

export function getAuditTrailDownload(params) {
    return {
        type: types.GET_AUDIT_TRAIL_DOWNLOAD,
        payload: params,
    }
}

export function getAuditTrailDownloadSuccess(response) {
    return {
        type: types.GET_AUDIT_TRAIL_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getAuditTrailDownloadFailure(error) {
    return {
        type: types.GET_AUDIT_TRAIL_DOWNLOAD_FAILURE,
        error,
    }
}


export function searchAuditTrail(params) {
    return {
        type: types.SEARCH_AUDIT_TRAIL,
        payload: params,
    }
}

export function searchAuditTrailSuccess(response) {
    return {
        type: types.SEARCH_AUDIT_TRAIL_SUCCESS,
        payload: response,
    }
}

export function searchAuditTrailFailure(error) {
    return {
        type: types.SEARCH_AUDIT_TRAIL_FAILURE,
        error,
    }
}

export function searchAuditTrailDownload(params) {
    return {
        type: types.SEARCH_AUDIT_TRAIL_DOWNLOAD,
        payload: params,
    }
}

export function searchAuditTrailDownloadSuccess(response) {
    return {
        type: types.SEARCH_AUDIT_TRAIL_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function searchAuditTrailDownloadFailure(error) {
    return {
        type: types.SEARCH_AUDIT_TRAIL_DOWNLOAD_FAILURE,
        error,
    }
}
