import { all } from 'redux-saga/effects';

import configurationSaga from './configuration/redux/configuration.saga';
import pendingApprovalsSaga from './pendingApprovals/redux/pendingApprovals.saga';
import approvalRequestsSaga from './approvalRequests/redux/approvalRequests.saga';

export default function*() {
    yield all([
        configurationSaga(),
        pendingApprovalsSaga(),
        approvalRequestsSaga(),
    ]);
}
