export const GET_PAYMENT_INSTRUMENT = 'GET_PAYMENT_INSTRUMENT';
export const GET_PAYMENT_INSTRUMENT_SUCCESS = 'GET_PAYMENT_INSTRUMENT_SUCCESS';
export const GET_PAYMENT_INSTRUMENT_FAILURE = 'GET_PAYMENT_INSTRUMENT_FAILURE';

export const GET_PAYMENT_INSTRUMENTS = 'GET_PAYMENT_INSTRUMENTS';
export const GET_PAYMENT_INSTRUMENTS_SUCCESS = 'GET_PAYMENT_INSTRUMENTS_SUCCESS';
export const GET_PAYMENT_INSTRUMENTS_FAILURE = 'GET_PAYMENT_INSTRUMENTS_FAILURE';

export const CREATE_PAYMENT_INSTRUMENT_ISW = 'CREATE_PAYMENT_INSTRUMENT_ISW';
export const CREATE_PAYMENT_INSTRUMENT_ISW_SUCCESS = 'CREATE_PAYMENT_INSTRUMENT_ISW_SUCCESS';
export const CREATE_PAYMENT_INSTRUMENT_ISW_FAILURE = 'CREATE_PAYMENT_INSTRUMENT_ISW_FAILURE';

export const UPDATE_PAYMENT_INSTRUMENT_ISW = 'UPDATE_PAYMENT_INSTRUMENT_ISW';
export const UPDATE_PAYMENT_INSTRUMENT_ISW_SUCCESS = 'UPDATE_PAYMENT_INSTRUMENT_ISW_SUCCESS';
export const UPDATE_PAYMENT_INSTRUMENT_ISW_FAILURE = 'UPDATE_PAYMENT_INSTRUMENT_ISW_FAILURE';

export const UPDATE_PAYMENT_INSTRUMENT_STATUS = 'UPDATE_PAYMENT_INSTRUMENT_STATUS';
export const UPDATE_PAYMENT_INSTRUMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_INSTRUMENT_STATUS_SUCCESS';
export const UPDATE_PAYMENT_INSTRUMENT_STATUS_FAILURE = 'UPDATE_PAYMENT_INSTRUMENT_STATUS_FAILURE';

export const GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL = 'GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL';
export const GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_SUCCESS = 'GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_SUCCESS';
export const GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_FAILURE = 'GET_PENDING_PAYMENT_INSTRUMENT_APPROVAL_FAILURE';

export const GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL = 'GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL';
export const GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_SUCCESS = 'GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_SUCCESS';
export const GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_FAILURE = 'GET_PENDING_PAYMENT_INSTRUMENTS_APPROVAL_FAILURE';

export const CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT = 'CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT';
export const CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_SUCCESS = 'CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_SUCCESS';
export const CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_FAILURE = 'CREATE_PAYMENT_INSTRUMENT_APPROVAL_VERDICT_FAILURE';
