import axios from 'axios';
import formurlencoded from 'form-urlencoded';
import { getPersistedState } from './helper';

const token = () => {
    const { rxAuth: { authUser: { tokenData: { access_token } = {} } = {} } = {} } = getPersistedState() || {};
    return access_token;
};

const CancelToken = axios.CancelToken;
let cancel;

const request = {
    make: (_options) => {
        const options = _options || {};
        const payload = options.data || {};
        const headers = options.headers || {};

        if (options.gatewayAuth && !(process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION')) {
            headers['Authorization'] = `Bearer ${process.env.REACT_APP_TOKEN}`;
        }

        if (options.auth) {
            headers['Authorization'] = `Bearer ${token()}`;
        }

        if (options.multitax) {
            headers['contentType'] = 'FIRS';
            headers['itemType'] = 'multitax';
        }

        headers['Content-Type'] = _options.upload ? 'multipart/form-data' : _options.encode ? 'application/x-www-form-urlencoded' : 'application/json';

        return new Promise((resolve, reject) => {
            axios({
                baseURL: options.baseUrl,
                method: options.method,
                url: options.url,
                headers,
                data: options.encode ? formurlencoded(payload) : payload,
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                responseType: options.download ? 'blob' : null,
                validateStatus: (status) => status < 400,
            })
                .then((res) => {
                    if (res.data.responseCode) {
                        switch (+res.data.responseCode) {
                            case 70040:
                            case 90000:
                                return resolve(res.data);
                            default:
                                reject(res);
                        }
                    }
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response ? err.response : err.request ? err.request : err);
                });
        });
    },

    cancel: () => {
        cancel && cancel();
    },

    parseError: (error) => {
        const getErrorMessage = (err) => {
            if (err.data && err.data.detail) return err.data.detail;
            if (err.data && err.data.message) return err.data.message;
            if (err.data && err.data.description) return err.data.description;
            if (err.data && err.data.errorMessage) return err.data.errorMessage;
            if (err.data && err.data.responseMessage) return err.data.responseMessage;
            if (err.data && err.data.responseDescription) return err.data.responseDescription;
            if (err.data && err.data.errors && Array.isArray(err.data.errors)) {
                return err.data.errors.map((e) => e.field + ' ' + e.message).join('; ');
            }
            if (err.data && err.data.error && err.data.error.errors && Array.isArray(err.data.error.errors)) {
                return err.data.error.errors.map((e) => e.message).join('; ');
            }
            if (err.data && err.data.error) return err.data.error;
            if (err.statusText) return err.statusText;
            if (typeof err === 'string') return err;
            return 'Something went wrong. Please try again';
        };

        throw new Error(getErrorMessage(error)).toString();
    },
};

export default request;
