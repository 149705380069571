import * as types from './paymentReversalReport.constant';

export function getPaymentReversalReportParameters(params) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getPaymentReversalReportParametersSuccess(response) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getPaymentReversalReportParametersFailure(error) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getPaymentReversalReport(params) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT,
        payload: params,
    }
}

export function getPaymentReversalReportSuccess(response) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_SUCCESS,
        payload: response,
    }
}

export function getPaymentReversalReportFailure(error) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_FAILURE,
        error,
    }
}

export function getPaymentReversalReportDownload(params) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getPaymentReversalReportDownloadSuccess(response) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getPaymentReversalReportDownloadFailure(error) {
    return {
        type: types.GET_PAYMENT_REVERSAL_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackPaymentReversalReportDownload(params) {
    return {
        type: types.TRACK_PAYMENT_REVERSAL_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackPaymentReversalReportDownloadSuccess(response) {
    return {
        type: types.TRACK_PAYMENT_REVERSAL_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

