import * as types from './changePassword.constant';

const initialState = {
    changePassError: null,
    changePassSuccess: null,
    changePassLoading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {

        case types.CHANGE_PASSWORD:
            return {
                ...state,
                changePassError: null,
                changePassSuccess: null,
                changePassLoading: true,
            };
        
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassLoading: false,
                changePassSuccess: action.payload,
            };

        case types.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePassLoading: false,
                changePassError: action.error,
            };

        default:
            return state;
    }
}
