import * as types from './users.constant';

const initialState = {
    getAllUsersLoading: false,
    getAllUsersError: null,
    allUsers: null,
    createUserLoading: false,
    createUserSuccess: null,
    createUserError: null,
    editUserLoading: false,
    editUserSuccess: null,
    editUserError: null,
    toggleUserLoading: false,
    toggleUserSuccess: null,
    toggleUserError: null,
    updateUserRoleLoading: false,
    updateUserRoleSuccess: null,
    updateUserRoleError: null,
    resetUserPasswordLoading: false,
    resetUserPasswordSuccess: null,
    resetUserPasswordError: null,
}

export default function(state = initialState, action) {
    switch(action.type) {

        case types.GET_ALL_USERS:
            return {
                ...state,
                getAllUsersLoading: true,
                getAllUsersError: null,
                allUsers: null,
            }

        case types.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                getAllUsersLoading: false,
                allUsers: action.payload,
            }

        case types.GET_ALL_USERS_FAILURE:
            return {
                ...state,
                getAllUsersLoading: false,
                getAllUsersError: action.error,
            }

        case types.CREATE_USER:
            return {
                ...state,
                createUserLoading: true,
                createUserSuccess: null,
                createUserError: null,
            }

        case types.CREATE_USER_SUCCESS:
            return {
                ...state,
                createUserLoading: false,
                createUserSuccess: action.payload,
            }

        case types.CREATE_USER_FAILURE:
            return {
                ...state,
                createUserLoading: false,
                createUserError: action.error,
            }

        case types.EDIT_USER:
            return {
                ...state,
                editUserLoading: true,
                editUserSuccess: null,
                editUserError: null,
            }

        case types.EDIT_USER_SUCCESS:
            return {
                ...state,
                editUserLoading: false,
                editUserSuccess: action.payload,
            }

        case types.EDIT_USER_FAILURE:
            return {
                ...state,
                editUserLoading: false,
                editUserError: action.error,
            }

        case types.TOGGLE_USER:
            return {
                ...state,
                toggleUserLoading: true,
                toggleUserSuccess: null,
                toggleUserError: null,
            }

        case types.TOGGLE_USER_SUCCESS:
            return {
                ...state,
                toggleUserLoading: false,
                toggleUserSuccess: action.payload,
            }

        case types.TOGGLE_USER_FAILURE:
            return {
                ...state,
                toggleUserLoading: false,
                toggleUserError: action.error,
            }

        case types.UPDATE_USER_ROLE:
            return {
                ...state,
                updateUserRoleLoading: true,
                updateUserRoleSuccess: null,
                updateUserRoleError: null,
            }

        case types.UPDATE_USER_ROLE_SUCCESS:
            return {
                ...state,
                updateUserRoleLoading: false,
                updateUserRoleSuccess: action.payload,
            }

        case types.UPDATE_USER_ROLE_FAILURE:
            return {
                ...state,
                updateUserRoleLoading: false,
                updateUserRoleError: action.error,
            }

        case types.RESET_USER_PASSWORD:
            return {
                ...state,
                resetUserPasswordLoading: true,
                resetUserPasswordSuccess: null,
                resetUserPasswordError: null,
            }

        case types.RESET_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                resetUserPasswordLoading: false,
                resetUserPasswordSuccess: action.payload,
            }

        case types.RESET_USER_PASSWORD_FAILURE:
            return {
                ...state,
                resetUserPasswordLoading: false,
                resetUserPasswordError: action.error,
            }

        default:
            return state;
    }
}
