import * as types from './paymentUploads.constant';

export function getPaymentUploads(params) {
    return {
        type: types.GET_PAYMENT_UPLOADS,
        payload: params,
    };
}

export function getPaymentUploadsSuccess(response) {
    return {
        type: types.GET_PAYMENT_UPLOADS_SUCCESS,
        payload: response,
    }
}

export function getPaymentUploadsFailure(error) {
    return {
        type: types.GET_PAYMENT_UPLOADS_FAILURE,
        error,
    }
}
