import * as types from './settlementSplitReport.constant';

export function getSettlementSplitReportParameters(params) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS,
        payload: params,
    }
}

export function getSettlementSplitReportParametersSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS_SUCCESS,
        payload: response,
    }
}

export function getSettlementSplitReportParametersFailure(error) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS_FAILURE,
        error,
    }
}

export function getSettlementSplitReport(params) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT,
        payload: params,
    }
}

export function getSettlementSplitReportSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_SUCCESS,
        payload: response,
    }
}

export function getSettlementSplitReportFailure(error) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_FAILURE,
        error,
    }
}

export function getSettlementSplitReportDownload(params) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function getSettlementSplitReportDownloadSuccess(response) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}

export function getSettlementSplitReportDownloadFailure(error) {
    return {
        type: types.GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD_FAILURE,
        error,
    }
}

export function trackSettlementSplitReportDownload(params) {
    return {
        type: types.TRACK_SETTLEMENT_SPLIT_REPORT_DOWNLOAD,
        payload: params,
    }
}

export function trackSettlementSplitReportDownloadSuccess(response) {
    return {
        type: types.TRACK_SETTLEMENT_SPLIT_REPORT_DOWNLOAD_SUCCESS,
        payload: response,
    }
}
