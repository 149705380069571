import * as types from './billers.constant';

export function getBillerCategories() {
    return {
        type: types.GET_BILLER_CATEGORIES,
    }
}

export function getBillerCategoriesSuccess(response) {
    return {
        type: types.GET_BILLER_CATEGORIES_SUCCESS,
        payload: response,
    }
}

export function getBillerCategoriesFailure(error) {
    return {
        type: types.GET_BILLER_CATEGORIES_FAILURE,
        error,
    }
}

export function searchBillers(params) {
    return {
        type: types.SEARCH_BILLERS,
        payload: params,
    }
}

export function searchBillersSuccess(response) {
    return {
        type: types.SEARCH_BILLERS_SUCCESS,
        payload: response,
    }
}

export function searchBillersFailure(error) {
    return {
        type: types.SEARCH_BILLERS_FAILURE,
        error,
    }
}

export function getBillerInfo(payload) {
    return {
        type: types.GET_BILLER_INFO,
        payload,
    }
}

export function getBillerInfoSuccess(response) {
    return {
        type: types.GET_BILLER_INFO_SUCCESS,
        payload: response,
    }
}

export function getBillerInfoFailure(error) {
    return {
        type: types.GET_BILLER_INFO_FAILURE,
        error,
    }
}

