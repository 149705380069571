import * as types from './settlementPositionsReport.constant';

const initialState = {
    getSettlementPositionsReportParametersLoading: false,
    getSettlementPositionsReportParametersError: null,
    settlementPositionsReportParameters: null,
    getSettlementPositionsReportLoading: false,
    getSettlementPositionsReportError: null,
    settlementPositionsReport: null,
    getSettlementPositionsReportDownloadLoading: false,
    getSettlementPositionsReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS:
            return {
                ...state,
                getSettlementPositionsReportParametersLoading: true,
                getSettlementPositionsReportParametersError: null,
                settlementPositionsReportParameters: null,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getSettlementPositionsReportParametersLoading: false,
                settlementPositionsReportParameters: action.payload,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getSettlementPositionsReportParametersLoading: false,
                getSettlementPositionsReportParametersError: action.error,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT:
            return {
                ...state,
                getSettlementPositionsReportLoading: true,
                getSettlementPositionsReportError: null,
                settlementPositionsReport: null,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_SUCCESS:
            return {
                ...state,
                getSettlementPositionsReportLoading: false,
                settlementPositionsReport: action.payload,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_FAILURE:
            return {
                ...state,
                getSettlementPositionsReportLoading: false,
                getSettlementPositionsReportError: action.error,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD:
            return {
                ...state,
                getSettlementPositionsReportDownloadLoading: true,
                getSettlementPositionsReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getSettlementPositionsReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getSettlementPositionsReportDownloadLoading: false,
                getSettlementPositionsReportDownloadError: action.error,
            }

        case types.TRACK_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD:
            return {
                ...state,
                getSettlementPositionsReportDownloadLoading: true,
                getSettlementPositionsReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_SETTLEMENT_POSITIONS_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getSettlementPositionsReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }
        
        default:
            return state;
    }
}
