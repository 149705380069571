export const GET_PAYMENT_INSTRUMENT_CONFIG = 'GET_PAYMENT_INSTRUMENT_CONFIG';
export const GET_PAYMENT_INSTRUMENT_CONFIG_SUCCESS = 'GET_PAYMENT_INSTRUMENT_CONFIG_SUCCESS';
export const GET_PAYMENT_INSTRUMENT_CONFIG_FAILURE = 'GET_PAYMENT_INSTRUMENT_CONFIG_FAILURE';

export const PROVIDE_PAYMENT_INSTRUMENT = 'PROVIDE_PAYMENT_INSTRUMENT';
export const PROVIDE_PAYMENT_INSTRUMENT_SUCCESS = 'PROVIDE_PAYMENT_INSTRUMENT_SUCCESS';
export const PROVIDE_PAYMENT_INSTRUMENT_FAILURE = 'PROVIDE_PAYMENT_INSTRUMENT_FAILURE';

export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_FAILURE = 'MAKE_PAYMENT_FAILURE';

export const SHOW_PAYMENT_INSTRUMENT_DIALOG = 'SHOW_PAYMENT_INSTRUMENT_DIALOG';
export const HIDE_PAYMENT_INSTRUMENT_DIALOG = 'HIDE_PAYMENT_INSTRUMENT_DIALOG';
