import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/reports';
import { GET_PAYMENT_REPORT_PARAMETERS, GET_PAYMENT_REPORT, GET_PAYMENT_REPORT_DOWNLOAD, TRACK_PAYMENT_REPORT_DOWNLOAD } from './paymentReport.constant';
import { getPaymentReportParametersSuccess, getPaymentReportParametersFailure, getPaymentReportSuccess, getPaymentReportFailure, getPaymentReportDownloadSuccess, getPaymentReportDownloadFailure, trackPaymentReportDownloadSuccess } from './paymentReport.action';


async function getPaymentReportParametersRequest({productId, currencyCode}) {
    const options = endpoint.getPaymentReportParameters(productId, currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPaymentReportRequest(payload) {
    const options = endpoint.getPaymentReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackPaymentReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPaymentReportParametersWorker({ payload }) {
    try {
        const response = yield call(getPaymentReportParametersRequest, payload);
        yield put(getPaymentReportParametersSuccess(response.parameter));
    } catch(err) {
        yield put(getPaymentReportParametersFailure(err));
    }
}

function* getPaymentReportWorker({ payload }) {
    try {
        const response = yield call(getPaymentReportRequest, payload);
        yield put(getPaymentReportSuccess(response));
    } catch(err) {
        yield put(getPaymentReportFailure(err));
    }
}

function* getPaymentReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getPaymentReportRequest, payload);
        yield put(getPaymentReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getPaymentReportDownloadFailure(err));
    }
}

function* trackPaymentReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackPaymentReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackPaymentReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getPaymentReportDownloadFailure(err));
    }
}


function* getPaymentReportParametersWatcher() {
    yield takeLatest(GET_PAYMENT_REPORT_PARAMETERS, getPaymentReportParametersWorker);
}

function* getPaymentReportWatcher() {
    yield takeLatest(GET_PAYMENT_REPORT, getPaymentReportWorker);
}

function* getPaymentReportDownloadWatcher() {
    yield takeLatest(GET_PAYMENT_REPORT_DOWNLOAD, getPaymentReportDownloadWorker);
}

function* trackPaymentReportDownloadWatcher() {
    yield takeLatest(TRACK_PAYMENT_REPORT_DOWNLOAD, trackPaymentReportDownloadWorker);
}


export default function*() {
    yield all([
        fork(getPaymentReportParametersWatcher),
        fork(getPaymentReportWatcher),
        fork(getPaymentReportDownloadWatcher),
        fork(trackPaymentReportDownloadWatcher)
    ]);
}
