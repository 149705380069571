import * as types from './pendingBizOwnerApprovals.constant';

export function getPendingBizOwnerApprovals() {
    return {
        type: types.GET_PENDING_BUSINESS_OWNER_APPROVALS,
    }
}

export function getPendingBizOwnerApprovalsSuccess(response) {
    return {
        type: types.GET_PENDING_BUSINESS_OWNER_APPROVALS_SUCCESS,
        payload: response,
    }
}

export function getPendingBizOwnerApprovalsFailure(error) {
    return {
        type: types.GET_PENDING_BUSINESS_OWNER_APPROVALS_FAILURE,
        error,
    }
}
