import * as types from './initiatePayment.constant';

export function initiatePayment(payload) {
    return {
        type: types.INITIATE_PAYMENT,
        payload,
    }
}

export function initiateFirsPayment(payload) {
    return {
        type: types.INITIATE_FIRS_PAYMENT,
        payload,
    }
}

export function initiatePaymentSuccess(response) {
    return {
        type: types.INITIATE_PAYMENT_SUCCESS,
        payload: response,
    }
}

export function initiatePaymentFailure(error) {
    return {
        type: types.INITIATE_PAYMENT_FAILURE,
        error,
    }
}

export function initiateBulkPayment(payload) {
    return {
        type: types.INITIATE_BULK_PAYMENT,
        payload,
    }
}

export function initiateBulkPaymentSuccess(response) {
    return {
        type: types.INITIATE_BULK_PAYMENT_SUCCESS,
        payload: response,
    }
}

export function initiateBulkPaymentFailure(error) {
    return {
        type: types.INITIATE_BULK_PAYMENT_FAILURE,
        error,
    }
}
