import { all, fork, call, put, delay, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/reports';
import { GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS, GET_PAYMENT_NOTIF_STATUS_REPORT, GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD, TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD, RESEND_PAYMENT_NOTIFICATION } from './paymentNotifStatusReport.constant';
import { getPaymentNotifStatusReportParametersSuccess, getPaymentNotifStatusReportParametersFailure, getPaymentNotifStatusReportSuccess, getPaymentNotifStatusReportFailure, getPaymentNotifStatusReportDownloadSuccess, getPaymentNotifStatusReportDownloadFailure, trackPaymentNotifStatusReportDownloadSuccess, resendPaymentNotificationSuccess, resendPaymentNotificationFailure } from './paymentNotifStatusReport.action';


async function getPaymentNotifStatusReportParametersRequest({productId, currencyCode}) {
    const options = endpoint.getPaymentNotifStatusReportParameters(productId, currencyCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPaymentNotifStatusReportRequest(payload) {
    const options = endpoint.getPaymentNotifStatusReport(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function trackPaymentNotifStatusReportRequest(payload) {
    const options = endpoint.trackReportDownload(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function resendPaymentNotificationRequest({productId, payload}) {
    const options = endpoint.resendPaymentNotification(productId, payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getPaymentNotifStatusReportParametersWorker({ payload }) {
    try {
        const response = yield call(getPaymentNotifStatusReportParametersRequest, payload);
        yield put(getPaymentNotifStatusReportParametersSuccess(response.parameter));
    } catch(err) {
        yield put(getPaymentNotifStatusReportParametersFailure(err));
    }
}

function* getPaymentNotifStatusReportWorker({ payload }) {
    try {
        const response = yield call(getPaymentNotifStatusReportRequest, payload);
        yield put(getPaymentNotifStatusReportSuccess(response));
    } catch(err) {
        yield put(getPaymentNotifStatusReportFailure(err));
    }
}

function* getPaymentNotifStatusReportDownloadWorker({ payload }) {
    try {
        const response = yield call(getPaymentNotifStatusReportRequest, payload);
        yield put(getPaymentNotifStatusReportDownloadSuccess(response.trackingId));
    } catch(err) {
        yield put(getPaymentNotifStatusReportDownloadFailure(err));
    }
}

function* trackPaymentNotifStatusReportDownloadWorker({ payload }) {
    let response;
    try {
        while(true) {
            response = yield call(trackPaymentNotifStatusReportRequest, payload);
            if (response.downloadLink) {
                yield put(trackPaymentNotifStatusReportDownloadSuccess(response.downloadLink));
                return;
            }
            yield delay(7000)
        }
    } catch(err) {
        yield put(getPaymentNotifStatusReportDownloadFailure(err));
    }
}

function* resendPaymentNotificationWorker({ payload }) {
    try {
        const response = yield call(resendPaymentNotificationRequest, payload);
        yield put(resendPaymentNotificationSuccess(response.responseDescription));
    } catch(err) {
        yield put(resendPaymentNotificationFailure(err));
    }
}


function* getPaymentNotifStatusReportParametersWatcher() {
    yield takeLatest(GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS, getPaymentNotifStatusReportParametersWorker);
}

function* getPaymentNotifStatusReportWatcher() {
    yield takeLatest(GET_PAYMENT_NOTIF_STATUS_REPORT, getPaymentNotifStatusReportWorker);
}

function* getPaymentNotifStatusReportDownloadWatcher() {
    yield takeLatest(GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD, getPaymentNotifStatusReportDownloadWorker);
}

function* trackPaymentNotifStatusReportDownloadWatcher() {
    yield takeLatest(TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD, trackPaymentNotifStatusReportDownloadWorker);
}

function* resendPaymentNotificationWatcher() {
    yield takeLatest(RESEND_PAYMENT_NOTIFICATION, resendPaymentNotificationWorker);
}


export default function*() {
    yield all([
        fork(getPaymentNotifStatusReportParametersWatcher),
        fork(getPaymentNotifStatusReportWatcher),
        fork(getPaymentNotifStatusReportDownloadWatcher),
        fork(trackPaymentNotifStatusReportDownloadWatcher),
        fork(resendPaymentNotificationWatcher),
    ]);
}
