import * as types from './transactionLimits.constant';

export function getCumulDailyTransLimit(params) {
    return {
        type: types.GET_CUMUL_DAILY_TRANS_LIMIT,
        payload: params,
    }
}

export function getCumulDailyTransLimitSuccess(response) {
    return {
        type: types.GET_CUMUL_DAILY_TRANS_LIMIT_SUCCESS,
        payload: response,
    }
}

export function getCumulDailyTransLimitFailure(error) {
    return {
        type: types.GET_CUMUL_DAILY_TRANS_LIMIT_FAILURE,
        error,
    }
}

export function getCumulDailyTransLimits(params) {
    return {
        type: types.GET_CUMUL_DAILY_TRANS_LIMITS,
        payload: params,
    }
}

export function getCumulDailyTransLimitsSuccess(response) {
    return {
        type: types.GET_CUMUL_DAILY_TRANS_LIMITS_SUCCESS,
        payload: response,
    }
}

export function getCumulDailyTransLimitsFailure(error) {
    return {
        type: types.GET_CUMUL_DAILY_TRANS_LIMITS_FAILURE,
        error,
    }
}

export function createCumulDailyTransLimit(payload) {
    return {
        type: types.CREATE_CUMUL_DAILY_TRANS_LIMIT,
        payload,
    }
}

export function createCumulDailyTransLimitSuccess(response) {
    return {
        type: types.CREATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS,
        payload: response,
    }
}

export function createCumulDailyTransLimitFailure(error) {
    return {
        type: types.CREATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE,
        error,
    }
}

export function updateCumulDailyTransLimit(payload) {
    return {
        type: types.UPDATE_CUMUL_DAILY_TRANS_LIMIT,
        payload,
    }
}

export function updateCumulDailyTransLimitSuccess(response) {
    return {
        type: types.UPDATE_CUMUL_DAILY_TRANS_LIMIT_SUCCESS,
        payload: response,
    }
}

export function updateCumulDailyTransLimitFailure(error) {
    return {
        type: types.UPDATE_CUMUL_DAILY_TRANS_LIMIT_FAILURE,
        error,
    }
}

export function createGlobalTransLimit(payload) {
    return {
        type: types.CREATE_GLOBAL_TRANS_LIMIT,
        payload,
    }
}

export function createGlobalTransLimitSuccess(response) {
    return {
        type: types.CREATE_GLOBAL_TRANS_LIMIT_SUCCESS,
        payload: response,
    }
}

export function createGlobalTransLimitFailure(error) {
    return {
        type: types.CREATE_GLOBAL_TRANS_LIMIT_FAILURE,
        error,
    }
}

export function updateGlobalTransLimit(payload) {
    return {
        type: types.UPDATE_GLOBAL_TRANS_LIMIT,
        payload,
    }
}

export function updateGlobalTransLimitSuccess(response) {
    return {
        type: types.UPDATE_GLOBAL_TRANS_LIMIT_SUCCESS,
        payload: response,
    }
}

export function updateGlobalTransLimitFailure(error) {
    return {
        type: types.UPDATE_GLOBAL_TRANS_LIMIT_FAILURE,
        error,
    }
}

export function updateBizCumulDailyTransLimit(payload) {
    return {
        type: types.UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT,
        payload,
    }
}

export function updateBizCumulDailyTransLimitSuccess(response) {
    return {
        type: types.UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_SUCCESS,
        payload: response,
    }
}

export function updateBizCumulDailyTransLimitFailure(error) {
    return {
        type: types.UPDATE_BIZ_CUMUL_DAILY_TRANS_LIMIT_FAILURE,
        error,
    }
}

export function getPendingTransLimitApproval(params) {
    return {
        type: types.GET_PENDING_TRANS_LIMIT_APPROVAL,
        payload: params,
    }
}

export function getPendingTransLimitApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_TRANS_LIMIT_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingTransLimitApprovalFailure(error) {
    return {
        type: types.GET_PENDING_TRANS_LIMIT_APPROVAL_FAILURE,
        error,
    }
}

export function getPendingTransLimitsApproval(params) {
    return {
        type: types.GET_PENDING_TRANS_LIMITS_APPROVAL,
        payload: params,
    }
}

export function getPendingTransLimitsApprovalSuccess(response) {
    return {
        type: types.GET_PENDING_TRANS_LIMITS_APPROVAL_SUCCESS,
        payload: response,
    }
}

export function getPendingTransLimitsApprovalFailure(error) {
    return {
        type: types.GET_PENDING_TRANS_LIMITS_APPROVAL_FAILURE,
        error,
    }
}

export function createTransLimitApprovalVerdict(payload) {
    return {
        type: types.CREATE_TRANS_LIMIT_APPROVAL_VERDICT,
        payload,
    }
}

export function createTransLimitApprovalVerdictSuccess(response) {
    return {
        type: types.CREATE_TRANS_LIMIT_APPROVAL_VERDICT_SUCCESS,
        payload: response,
    }
}

export function createTransLimitApprovalVerdictFailure(error) {
    return {
        type: types.CREATE_TRANS_LIMIT_APPROVAL_VERDICT_FAILURE,
        error,
    }
}