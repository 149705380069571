import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import request from '../../../../utils/request';
import endpoint from '../../../../uris/feeMgt';
import { GET_FEE_CONFIGURATION, GET_FEE_CONFIGURATIONS, CREATE_FEE_CONFIGURATION, UPDATE_FEE_CONFIGURATION, UPDATE_FEE_CONFIGURATION_STATUS, GET_PENDING_FEE_CONFIGURATION_APPROVAL, GET_PENDING_FEE_CONFIGURATIONS_APPROVAL, CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT } from './feeConfigurations.constant';
import { getFeeConfigurationSuccess, getFeeConfigurationFailure, getFeeConfigurationsSuccess, getFeeConfigurationsFailure, createFeeConfigurationSuccess, createFeeConfigurationFailure, updateFeeConfigurationSuccess, updateFeeConfigurationFailure, updateFeeConfigurationStatusSuccess, updateFeeConfigurationStatusFailure, getPendingFeeConfigurationApprovalSuccess, getPendingFeeConfigurationApprovalFailure, getPendingFeeConfigurationsApprovalSuccess, getPendingFeeConfigurationsApprovalFailure, createFeeConfigurationApprovalVerdictSuccess, createFeeConfigurationApprovalVerdictFailure } from './feeConfigurations.action';

async function getFeeConfigurationRequest({ configCode }) {
    const options = endpoint.getFeeConfiguration(configCode);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getFeeConfigurationsRequest({ pageNumber, pageSize, status, searchParam }) {
    const options = endpoint.getFeeConfigurations(pageNumber, pageSize, status, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createFeeConfigurationRequest(payload) {
    const options = endpoint.createFeeConfiguration(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateFeeConfigurationRequest(payload) {
    const options = endpoint.updateFeeConfiguration(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function updateFeeConfigurationStatusRequest(payload) {
    const options = endpoint.updateFeeConfigurationStatus(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingFeeConfigurationApprovalRequest({ requestId }) {
    const options = endpoint.getPendingFeeConfigurationApproval(requestId);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function getPendingFeeConfigurationsApprovalRequest({ pageNumber, pageSize, status, actionType, searchParam }) {
    const options = endpoint.getPendingFeeConfigurationsApproval(pageNumber, pageSize, status, actionType, searchParam);
    return await request.make(options).then(res => res).catch(request.parseError);
}

async function createFeeConfigurationApprovalVerdictRequest(payload) {
    const options = endpoint.createFeeConfigurationApprovalVerdict(payload);
    return await request.make(options).then(res => res).catch(request.parseError);
}


function* getFeeConfigurationWorker({ payload }) {
    try {
        const response = yield call(getFeeConfigurationRequest, payload);
        yield put(getFeeConfigurationSuccess(response));
    } catch(err) {
        yield put(getFeeConfigurationFailure(err));
    }
}

function* getFeeConfigurationsWorker({ payload }) {
    try {
        const response = yield call(getFeeConfigurationsRequest, payload);
        yield put(getFeeConfigurationsSuccess(response));
    } catch(err) {
        yield put(getFeeConfigurationsFailure(err));
    }
}

function* createFeeConfigurationWorker({ payload }) {
    try {
        const response = yield call(createFeeConfigurationRequest, payload);
        yield put(createFeeConfigurationSuccess(response.responseDescription));
    } catch(err) {
        yield put(createFeeConfigurationFailure(err));
    }
}

function* updateFeeConfigurationWorker({ payload }) {
    try {
        const response = yield call(updateFeeConfigurationRequest, payload);
        yield put(updateFeeConfigurationSuccess(response.responseDescription));
    } catch(err) {
        yield put(updateFeeConfigurationFailure(err));
    }
}

function* updateFeeConfigurationStatusWorker({ payload }) {
    try {
        const response = yield call(updateFeeConfigurationStatusRequest, payload);
        yield put(updateFeeConfigurationStatusSuccess(response.responseDescription));
    } catch(err) {
        yield put(updateFeeConfigurationStatusFailure(err));
    }
}

function* getPendingFeeConfigurationApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingFeeConfigurationApprovalRequest, payload);
        yield put(getPendingFeeConfigurationApprovalSuccess(response));
    } catch(err) {
        yield put(getPendingFeeConfigurationApprovalFailure(err));
    }
}

function* getPendingFeeConfigurationsApprovalWorker({ payload }) {
    try {
        const response = yield call(getPendingFeeConfigurationsApprovalRequest, payload);
        const data = response.data || [];

        yield put(getPendingFeeConfigurationsApprovalSuccess({
            pendingFeeConfigurationsApproval: response,
            pendingFeeConfigurationsApprovalCount: data.length,
        }));
    } catch(err) {
        yield put(getPendingFeeConfigurationsApprovalFailure(err));
    }
}

function* createFeeConfigurationApprovalVerdictWorker({ payload }) {
    try {
        const response = yield call(createFeeConfigurationApprovalVerdictRequest, payload);
        yield put(createFeeConfigurationApprovalVerdictSuccess(response.responseDescription));
    } catch(err) {
        yield put(createFeeConfigurationApprovalVerdictFailure(err));
    }
}


function* getFeeConfigurationWatcher() {
    yield takeLatest(GET_FEE_CONFIGURATION, getFeeConfigurationWorker);
}

function* getFeeConfigurationsWatcher() {
    yield takeLatest(GET_FEE_CONFIGURATIONS, getFeeConfigurationsWorker);
}

function* createFeeConfigurationWatcher() {
    yield takeLatest(CREATE_FEE_CONFIGURATION, createFeeConfigurationWorker);
}

function* updateFeeConfigurationWatcher() {
    yield takeLatest(UPDATE_FEE_CONFIGURATION, updateFeeConfigurationWorker);
}

function* updateFeeConfigurationStatusWatcher() {
    yield takeLatest(UPDATE_FEE_CONFIGURATION_STATUS, updateFeeConfigurationStatusWorker);
}

function* getPendingFeeConfigurationApprovalWatcher() {
    yield takeLatest(GET_PENDING_FEE_CONFIGURATION_APPROVAL, getPendingFeeConfigurationApprovalWorker);
}

function* getPendingFeeConfigurationsApprovalWatcher() {
    yield takeLatest(GET_PENDING_FEE_CONFIGURATIONS_APPROVAL, getPendingFeeConfigurationsApprovalWorker);
}

function* createFeeConfigurationApprovalVerdictWatcher() {
    yield takeLatest(CREATE_FEE_CONFIGURATION_APPROVAL_VERDICT, createFeeConfigurationApprovalVerdictWorker);
}


export default function*() {
    yield all([
        fork(getFeeConfigurationWatcher),
        fork(getFeeConfigurationsWatcher),
        fork(createFeeConfigurationWatcher),
        fork(updateFeeConfigurationWatcher),
        fork(updateFeeConfigurationStatusWatcher),
        fork(getPendingFeeConfigurationApprovalWatcher),
        fork(getPendingFeeConfigurationsApprovalWatcher),
        fork(createFeeConfigurationApprovalVerdictWatcher),
    ]);
}
