import * as types from './bizOwnerApprovalRequests.constant';

const initialState = {
    createBizOwnerApprovalVerdictSuccess: null,
    createBizOwnerApprovalVerdictError: null,
    createBizOwnerApprovalVerdictLoading: false,
}

export default function(state = initialState, action) {

    switch(action.type) {

        case types.CREATE_BUSINESS_OWNER_APPROVAL_VERDICT:
            return {
                ...state,
                createBizOwnerApprovalVerdictLoading: true,
                createBizOwnerApprovalVerdictError: null,
                createBizOwnerApprovalVerdictSuccess: null,
            }

        case types.CREATE_BUSINESS_OWNER_APPROVAL_VERDICT_SUCCESS:
            return {
                ...state,
                createBizOwnerApprovalVerdictLoading: false,
                createBizOwnerApprovalVerdictSuccess: action.payload,
            }

        case types.CREATE_BUSINESS_OWNER_APPROVAL_VERDICT_FAILURE:
            return {
                ...state,
                createBizOwnerApprovalVerdictLoading: false,
                createBizOwnerApprovalVerdictError: action.error,
            }

        
        default:
            return state;
    }
}
