export const GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS = 'GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS';
export const GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_SUCCESS = 'GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_SUCCESS';
export const GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_FAILURE = 'GET_PAYMENT_NOTIF_STATUS_REPORT_PARAMETERS_FAILURE';

export const GET_PAYMENT_NOTIF_STATUS_REPORT = 'GET_PAYMENT_NOTIF_STATUS_REPORT';
export const GET_PAYMENT_NOTIF_STATUS_REPORT_SUCCESS = 'GET_PAYMENT_NOTIF_STATUS_REPORT_SUCCESS';
export const GET_PAYMENT_NOTIF_STATUS_REPORT_FAILURE = 'GET_PAYMENT_NOTIF_STATUS_REPORT_FAILURE';

export const GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD = 'GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD';
export const GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS = 'GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS';
export const GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_FAILURE = 'GET_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_FAILURE';

export const TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD = 'TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD';
export const TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS = 'TRACK_PAYMENT_NOTIF_STATUS_REPORT_DOWNLOAD_SUCCESS';

export const RESEND_PAYMENT_NOTIFICATION = 'RESEND_PAYMENT_NOTIFICATION';
export const RESEND_PAYMENT_NOTIFICATION_SUCCESS = 'RESEND_PAYMENT_NOTIFICATION_SUCCESS';
export const RESEND_PAYMENT_NOTIFICATION_FAILURE = 'RESEND_PAYMENT_NOTIFICATION_FAILURE'; 