import * as types from './cart.constant';

const initialState = {
    cart: [],
    cartHasChanged: false,
    savePaymentLoading: false,
    savePaymentSuccess: null,
    savePaymentError: null,
    getSavedPaymentLoading: false,
    savedPayment: null,
    getSavedPaymentError: null,
    editSavedPaymentLoading: false,
    editSavedPaymentSuccess: null,
    editSavedPaymentError: null,
    deleteSavedPaymentLoading: false,
    deleteSavedPaymentError: null,
    deleteSavedPaymentSuccess: null,
    showCart: false,
    activePayment: null,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case types.ADD_PAYMENT_TO_CART:
            return {
                ...state,
                cart: [
                    ...state.cart,
                    action.payload,
                ],
                cartHasChanged: true,
            }

        case types.REMOVE_PAYMENT_FROM_CART:
            const cart_r = [...state.cart];
            cart_r.splice(action.payload, 1);
            
            return {
                ...state,
                cart: cart_r,
                cartHasChanged: true,
            }

        case types.EDIT_PAYMENT_IN_CART:
            const cart_e = [...state.cart];
            const { index, data } = action.payload;
            cart_e.splice(index, 1, data);

            return {
                ...state,
                cart: cart_e,
                cartHasChanged: true,
            }

        case types.SAVE_CART_PAYMENT:
            return {
                ...state,
                savePaymentLoading: true,
                savePaymentError: null,
                savePaymentSuccess: null,
            }

        case types.SAVE_CART_PAYMENT_SUCCESS:
            return {
                ...state,
                savePaymentLoading: false,
                savePaymentSuccess: action.payload,
                cartHasChanged: false,
            }
        
        case types.SAVE_CART_PAYMENT_FAILURE:
            return {
                ...state,
                savePaymentLoading: false,
                savePaymentError: action.error,
            }

        case types.GET_SAVED_CART_PAYMENT:
            return {
                ...state,
                getSavedPaymentLoading: true,
                getSavedPaymentError: null,
                savedPayment: null,
            }

        case types.GET_SAVED_CART_PAYMENT_SUCCESS:
            return {
                ...state,
                getSavedPaymentLoading: false,
                savedPayment: action.payload,
            }

        case types.GET_SAVED_CART_PAYMENT_FAILURE:
            return {
                ...state,
                getSavedPaymentLoading: false,
                getSavedPaymentError: action.error,
            }
        
        case types.EDIT_SAVED_CART_PAYMENT:
            return {
                ...state,
                editSavedPaymentLoading: true,
                editSavedPaymentError: null,
                editSavedPaymentSuccess: null,
            }
        
        case types.EDIT_SAVED_CART_PAYMENT_SUCCESS:
            return {
                ...state,
                editSavedPaymentLoading: false,
                editSavedPaymentSuccess: action.payload,
            }

        case types.EDIT_SAVED_CART_PAYMENT_FAILURE:
            return {
                ...state,
                editSavedPaymentLoading: false,
                editSavedPaymentError: action.error,
            }

        case types.DELETE_SAVED_CART_PAYMENT:
            return {
                ...state,
                deleteSavedPaymentLoading: true,
                deleteSavedPaymentError: null,
                deleteSavedPaymentSuccess: null,
            }

        case types.DELETE_SAVED_CART_PAYMENT_SUCCESS:
            return {
                ...state,
                deleteSavedPaymentLoading: false,
                deleteSavedPaymentSuccess: action.payload,
            }

        case types.DELETE_SAVED_CART_PAYMENT_FAILURE:
            return {
                ...state,
                deleteSavedPaymentLoading: false,
                deleteSavedPaymentError: action.error,
            }

        case types.SET_ACTIVE_PAYMENT:
            return {
                ...state,
                activePayment: action.payload,
            }

        case types.UNSET_ACTIVE_PAYMENT:
            return {
                ...state,
                activePayment: null,
            }

        case types.OPEN_CART:
            return {
                ...state,
                showCart: true,
            }

        case types.CLOSE_CART:
            return {
                ...state,
                showCart: false,
            }

        case types.DESTROY_CART:
            return {
                ...state,
                ...initialState,
            }
    
        default:
            return state
    }
}
