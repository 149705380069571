import * as types from './settlementSplitReport.constant';

const initialState = {
    getSettlementSplitReportParametersLoading: false,
    getSettlementSplitReportParametersError: null,
    settlementSplitReportParameters: null,
    getSettlementSplitReportLoading: false,
    getSettlementSplitReportError: null,
    settlementSplitReport: null,
    getSettlementSplitReportDownloadLoading: false,
    getSettlementSplitReportDownloadError: null,
    reportDownloadTrackId: null,
    reportDownloadLink: null,
};

export default function(state = initialState, action) {
    switch(action.type) {
        
        case types.GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS:
            return {
                ...state,
                getSettlementSplitReportParametersLoading: true,
                getSettlementSplitReportParametersError: null,
                settlementSplitReportParameters: null,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS_SUCCESS:
            return {
                ...state,
                getSettlementSplitReportParametersLoading: false,
                settlementSplitReportParameters: action.payload,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_PARAMETERS_FAILURE:
            return {
                ...state,
                getSettlementSplitReportParametersLoading: false,
                getSettlementSplitReportParametersError: action.error,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT:
            return {
                ...state,
                getSettlementSplitReportLoading: true,
                getSettlementSplitReportError: null,
                settlementSplitReport: null,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_SUCCESS:
            return {
                ...state,
                getSettlementSplitReportLoading: false,
                settlementSplitReport: action.payload,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_FAILURE:
            return {
                ...state,
                getSettlementSplitReportLoading: false,
                getSettlementSplitReportError: action.error,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD:
            return {
                ...state,
                getSettlementSplitReportDownloadLoading: true,
                getSettlementSplitReportDownloadError: null,
                reportDownloadTrackId: null,
                reportDownloadLink: null,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getSettlementSplitReportDownloadLoading: false,
                reportDownloadTrackId: action.payload,
            }

        case types.GET_SETTLEMENT_SPLIT_REPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                getSettlementSplitReportDownloadLoading: false,
                getSettlementSplitReportDownloadError: action.error,
            }

        case types.TRACK_SETTLEMENT_SPLIT_REPORT_DOWNLOAD:
            return {
                ...state,
                getSettlementSplitReportDownloadLoading: true,
                getSettlementSplitReportDownloadError: null,
                reportDownloadLink: null,
            }

        case types.TRACK_SETTLEMENT_SPLIT_REPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                getSettlementSplitReportDownloadLoading: false,
                reportDownloadTrackId: null,
                reportDownloadLink: action.payload,
            }
            
        
        default:
            return state;
    }
}
